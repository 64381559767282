import React from "react"

import { SkinAsset } from "@components/Avatar/types"
import { Svg } from "@components/Svg"

import { cls1, cls3 } from "./nh.module.scss"

export const NakedHeadAsset = ({ skinTone }: SkinAsset) => {
  return (
    <Svg viewBox="0 0 80.73 87.04">
      <g id="HEAD">
        <path
          className={cls1}
          d="M304.08,463.7c7.54,0,18.41-5.44,20.75-10.5,1.94-4.19,1.73-10.07,3-13.84,1.37-4.14,4.82-10.75,5.28-18,.36-5.72-2.07-27-22.11-35.37-18.43-7.72-40-1.86-49.39,15.56-5.63,10.45-2.72,27.79.27,33.31,1.94,3.58,4.55,15.36,8.13,19.07C275,459.1,294.07,463.7,304.08,463.7Z"
          transform="translate(-255.38 -379.66)"
        />
        <path
          className={skinTone}
          d="M304.08,463.7c7.54,0,18.41-5.44,20.75-10.5,1.94-4.19,1.73-10.07,3-13.84,1.37-4.14,4.82-10.75,5.28-18,.36-5.72-2.07-27-22.11-35.37-18.43-7.72-40-1.86-49.39,15.56-5.63,10.45-2.72,27.79.27,33.31,1.94,3.58,4.55,15.36,8.13,19.07C275,459.1,294.07,463.7,304.08,463.7Z"
          transform="translate(-255.38 -379.66)"
        />
        <path
          className={cls3}
          d="M303.44,382.11c-18.28,2.38-27.81,12.45-30.46,19.33-3.32,8.64-2.77,28.11,1.93,41.16.69,1.89,6.17,6.33,7.67,9.83,3.42,8,15.75,10.88,22.61,12.9-14.19-.63-24.61-4.59-35.19-9.15-6.81-9.68-19-34.25-10.5-53.5C266.25,391.68,274.57,379.46,303.44,382.11Z"
          transform="translate(-255.38 -379.66)"
        />
      </g>
    </Svg>
  )
}
