import React from "react"

import { LinkButton } from "@components/Buttons/link-button"
import { ContainerRowCol } from "@components/Containers/positioning-wrappers"

import { LINK_APP } from "@helpers/constants/links"
import { H2 } from "@helpers/constants/style"

export const SuccessView = ({ descriptor }: { descriptor: string }) => {
  return (
    <ContainerRowCol>
      <h1 className={H2}>Payment Succeeded 🏁</h1>
      <p>Thank you for signing up</p>
      <p>
        You should see a line item of <strong>"{descriptor}"</strong> on your
        card statement
      </p>
      <LinkButton to={LINK_APP}>Begin</LinkButton>
    </ContainerRowCol>
  )
}
