import { UserType } from "@redux/ducks/misc/user"

import { ENGLISH_REMOTE_ATTRIBUTE_KEY_COMMON_MODULE } from "@views/english-demo-1/common/constants"
import {
  getUserResponses,
  setUserResponses,
} from "@views/english-demo-1/utils/get-user-responses"

import { getUsername } from "@helpers/auth"
import { getUserRemoteData, saveProgress } from "@helpers/user-data"
import { getEnglishUserTableItemId } from "@helpers/user-data/get-user-table-item-id"
import { RemoteAttributeType } from "@helpers/user-data/types"

export const loadEnglishDataFromRemoteIntoCache = async (
  user: UserType,
  remoteAttribute: RemoteAttributeType
) => {
  const username = getUsername(user)
  if (!username) {
    console.log("no username")
    return
  }

  const remoteId = getEnglishUserTableItemId(username)

  const { value: remoteResponses } = await getUserRemoteData({
    remoteId,
    remoteAttribute,
  })
  setUserResponses(remoteResponses)
}

export const saveEnglishDataFromCacheToRemote = async (user: UserType) => {
  const username = getUsername(user)
  if (!username) {
    console.log("User is not logged in")
    return
  }
  const remoteId = getEnglishUserTableItemId(username)

  await saveProgress({
    remoteId,
    remoteAttribute: ENGLISH_REMOTE_ATTRIBUTE_KEY_COMMON_MODULE,
    value: getUserResponses(),
  })
}
