import { Cache } from "aws-amplify"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { CenteredWrapper } from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"
import { Img } from "@components/Img"
import { LogoImg } from "@components/logo"

import { ENGLISH_BATCHED_PROBLEMS } from "@views/english-demo-1/common/constants"
import { ScenePropsWithInputProps } from "@views/english-demo-1/types"
import {
  useScrollTop,
  useIfActiveScene,
} from "@views/english-demo-1/utils/hooks"

import dungeonSrc from "@images/dungeons/skeleton-dungeon.png"

export const Action0 = ({
  nextScene,
  isActiveScene,
  setSceneById,
}: ScenePropsWithInputProps) => {
  useScrollTop(isActiveScene)
  useIfActiveScene(isActiveScene, () => {
    // The first scene, clears the cache of the batched content, allowing for updated content to come through
    // We don't clear the cache of the user responses, to allow for them to save this if they create a new account.
    Cache.removeItem(ENGLISH_BATCHED_PROBLEMS)
  })
  return (
    <Container className="text-center">
      <Row>
        <Col xs={12}>
          <CenteredWrapper>
            <div>
              <CenteredWrapper>
                <LogoImg />
              </CenteredWrapper>
              <div className="col-12">
                <h1>Year 12 HSC English</h1>
              </div>
              <Img src={dungeonSrc} className="d-block mx-auto mb-4" />
              <h2>Short Answer Section</h2>
            </div>
          </CenteredWrapper>
          <p>
            We're going to answer a short answer question, similar to what
            you'll see in Paper 1 of the HSC Common Module on Texts and Human
            Experiences. Step into a world where we achieve this in a gameful
            way.
          </p>
          <CenteredWrapper>
            <>
              <Button onClick={nextScene} className="mx-2" variant="success">
                Tutorial
              </Button>
              <Button
                onClick={() => {
                  setSceneById("practice-list")
                }}
                className="mx-2"
              >
                Skip To Dungeon
              </Button>
            </>
          </CenteredWrapper>
          <CenteredWrapper>
            <div className="mt-3">
              <GoogleFormLinkGeneralFeedback />
            </div>
          </CenteredWrapper>
        </Col>
      </Row>
    </Container>
  )
}
