import React from "react"

import { HairAsset } from "@components/Avatar/types"
import { Svg } from "@components/Svg"

import { cls1, cls3, cls4, cls5, cls6, cls7, cls9 } from "./bh.module.scss"

export const BanditHairAsset = ({ skinTone, hairColour }: HairAsset) => {
  return (
    <Svg viewBox="0 0 120.64 113.72">
      <g className={cls1}>
        <g id="HAIR">
          <path d="M67.85,28A94.86,94.86,0,0,0,56,26a105.53,105.53,0,0,0-11.77-.58,139.14,139.14,0,0,0-23.38,2.21l.77-5.39a64,64,0,0,1,5.63,3.57,32.47,32.47,0,0,1,5.16,4.53,27.87,27.87,0,0,1,3.88,5.59A45.71,45.71,0,0,1,38.93,42c-3.19-3.05-6-6.3-9.3-8.62a72,72,0,0,0-10.73-6l-7.2-3.56,8-1.83A80.93,80.93,0,0,1,32,20.09a73.27,73.27,0,0,1,12.48.12,59.36,59.36,0,0,1,12.23,2.47A41.47,41.47,0,0,1,67.85,28Z" />
          <path
            className={hairColour}
            d="M67.84,28C55,22.2,37.68,21.2,20.25,24.78c10.31,5.44,13,8.36,18.68,17.18"
          />
          <path
            className={cls3}
            d="M42.3,38.67c-9.21-1-20,1.42-28.25,9.11,11.59,1.24,15.14,3,23.71,9.06Z"
          />
          <path
            className={hairColour}
            d="M42.3,38.67c-9.21-1-20,1.42-28.25,9.11,11.59,1.24,15.14,3,23.71,9.06Z"
          />
          <path
            className={cls3}
            d="M67.1,24.17C62,16.47,53.19,9.66,42,8.27c6.46,9.71,7.4,13.55,8.2,24Z"
          />
          <path
            className={hairColour}
            d="M67.1,24.17C62,16.47,53.19,9.66,42,8.27c6.46,9.71,7.4,13.55,8.2,24Z"
          />
          <path
            className={cls3}
            d="M106.6,54.34c-6-8.88-11.67-11-22.17-6-21.06-6.6-35.44-5.78-38.17,8l.17,6.33L41.51,81.88l-2.23,9.3-6.85-2S25.1,66.5,25.76,56.67s3.5-18.83,14.5-27.83C50.39,20.55,73.1,17,91.26,27A29,29,0,0,1,106.6,54.34Z"
          />
          <path
            className={hairColour}
            d="M106.6,54.34c-6-8.88-11.67-11-22.17-6-21.06-6.6-35.44-5.78-38.17,8l.17,6.33L41.51,81.88l-2.23,9.3-6.85-2S25.1,66.5,25.76,56.67s3.5-18.83,14.5-27.83C50.39,20.55,73.1,17,91.26,27A29,29,0,0,1,106.6,54.34Z"
          />
          <path d="M73.76,23.5a99.1,99.1,0,0,0-11.92-3.79,122.12,122.12,0,0,0-12.05-2.37,130.4,130.4,0,0,0-24.32-1.28l1.47-5.24c1.86,1.41,3.69,2.88,5.45,4.48a40.7,40.7,0,0,1,4.94,5.29A29.24,29.24,0,0,1,41,26.8a34.7,34.7,0,0,1,2.24,6.7c-1.44-1.85-2.85-3.68-4.29-5.41a46.24,46.24,0,0,0-4.65-4.73,101.94,101.94,0,0,0-10.73-7.83l-6.67-4.48,8.14-.76a75.93,75.93,0,0,1,13-.09,75.62,75.62,0,0,1,12.78,2.05A65.74,65.74,0,0,1,63,16.54,44.47,44.47,0,0,1,73.76,23.5Z" />
          <path
            className={hairColour}
            d="M73.76,23.5C61.79,16,43,11.92,25.26,13.17c9.5,6.75,13.5,10.83,18,20.33"
          />
          <path d="M54.51,26a95.09,95.09,0,0,0-11.89,1.89,104.13,104.13,0,0,0-11.33,3.25A138.75,138.75,0,0,0,9.88,40.76l-1-5.35c2.15.42,4.31.91,6.47,1.56a32.37,32.37,0,0,1,6.35,2.63,28.54,28.54,0,0,1,5.48,4,44.72,44.72,0,0,1,4.46,4.88c-4-1.87-7.75-4-11.58-5.17A72.45,72.45,0,0,0,8,41.12l-8-1,7-4.3A82.07,82.07,0,0,1,18,30a73.48,73.48,0,0,1,11.86-3.9,60.68,60.68,0,0,1,12.37-1.61A41.51,41.51,0,0,1,54.51,26Z" />
          <path
            className={hairColour}
            d="M54.51,26c-14-1.37-30.75,3.26-46.1,12.27,11.51,1.82,15,3.74,23.22,10.25"
          />
          <path d="M35.14,35.88c-2.48,2.23-5.1,4.2-7.48,6.28a39.72,39.72,0,0,0-6.25,6.63,45.63,45.63,0,0,0-4.47,7.8c-1.26,2.79-2.34,5.71-3.42,8.66l-4-3.5a37.56,37.56,0,0,1,5.13-1.69,26.55,26.55,0,0,1,5.42-.79,22,22,0,0,1,5.44.47,36.51,36.51,0,0,1,5,1.64,24.35,24.35,0,0,1-2.6.44l-2.52.37a33.57,33.57,0,0,0-4.7,1.12A55.71,55.71,0,0,0,12,67L6.13,69.94,8,63.51A59.47,59.47,0,0,1,11.57,54a35.16,35.16,0,0,1,5.87-8.6,29.89,29.89,0,0,1,8.36-6.09A50.47,50.47,0,0,1,35.14,35.88Z" />
          <path
            className={hairColour}
            d="M35.14,35.88C23.89,42,16.64,45.75,10.76,64.38c10.59-4.87,16.07-3.19,19.82-3"
          />
          <path d="M84.26,24.79a72.13,72.13,0,0,0-7.93-7.16,46.68,46.68,0,0,0-8.76-5.26C61.41,9.53,54.69,7.93,47.82,6.61l2.83-4.49a77.87,77.87,0,0,1,6.67,12.39,46.77,46.77,0,0,1,2.17,6.77,43.4,43.4,0,0,1,1,7c-1.12-2.06-2.19-4.11-3.31-6.09s-2.27-3.9-3.51-5.76c-2.44-3.74-5.14-7.33-7.81-11L42,0l6.74.9A68.2,68.2,0,0,1,59.64,3.22,44.68,44.68,0,0,1,70,7.8a34.66,34.66,0,0,1,8.64,7.39A32.74,32.74,0,0,1,84.26,24.79Z" />
          <path
            className={hairColour}
            d="M84.26,24.79c-7-11-16.75-18-36-21,6.5,9.5,9.5,15,12.25,24.5"
          />
          <path d="M103,42.25c-2-3.22-3.66-6.56-5.44-9.76a78.51,78.51,0,0,0-5.69-9.09,45.08,45.08,0,0,0-7.07-7.52,52.66,52.66,0,0,0-8.6-5.8l4-3.71a23.06,23.06,0,0,1,1.34,10.05,26.14,26.14,0,0,1-.89,4.84,20.75,20.75,0,0,1-1.86,4.49c-.19-1.61-.37-3.17-.59-4.68s-.44-3-.75-4.44a34.15,34.15,0,0,0-2.56-8L71.29,1.32l7.53,3.61a42.43,42.43,0,0,1,9.88,6.45,37.32,37.32,0,0,1,7.51,9.17,46.93,46.93,0,0,1,4.48,10.64A77.93,77.93,0,0,1,103,42.25Z" />
          <path
            className={hairColour}
            d="M103,42.25C98.24,29,94.26,16,77.51,7.5c2.25,5.25,2.5,12,1.25,18.25"
          />
          <path d="M93.61,37.77a62.46,62.46,0,0,1,1.28-6.4c.52-2.09,1.09-4.13,1.65-6.16l1.7-6,1.64-6L103,1.89l2.69,11.45a52.71,52.71,0,0,1,1.43,15.35A46.07,46.07,0,0,1,106,36.3a42.76,42.76,0,0,1-2.36,7.29c-.31-2.56-.47-5.06-.67-7.52s-.41-4.88-.69-7.26c-.56-4.77-1.41-9.44-2.44-14.14l5.79.13a61.74,61.74,0,0,1-2.1,6.19,62.62,62.62,0,0,1-2.73,5.94c-1,1.91-2.12,3.78-3.32,5.58A56.92,56.92,0,0,1,93.61,37.77Z" />
          <path
            className={hairColour}
            d="M93.61,37.77c3.68-8.3,6.61-14.57,9.15-23.77,2.52,11.07,2.38,19.31.89,29.59"
          />
          <path d="M34.51,54.13a40.11,40.11,0,0,0-6.91,7.68A43.11,43.11,0,0,0,23,70.64a59.44,59.44,0,0,0-3.73,19.41l-4.8-2.26a43.62,43.62,0,0,1,10.25-6A36.42,36.42,0,0,1,30.38,80a28.85,28.85,0,0,1,5.85-.76c-1.66,1.07-3.28,2.07-4.86,3.11s-3.11,2.08-4.61,3.16c-3,2.15-5.82,4.42-8.54,6.82l-4.68,4.11L13.42,90A47.27,47.27,0,0,1,14.55,79a42.14,42.14,0,0,1,3.82-10.47,33.14,33.14,0,0,1,6.76-8.86A24.57,24.57,0,0,1,34.51,54.13Z" />
          <path
            className={hairColour}
            d="M34.51,54.13c-13.22,7.3-18.13,23.12-18.16,35.92A56.86,56.86,0,0,1,36.23,79.21"
          />
          <path d="M22.81,68.37a40,40,0,0,0,.08,10.33,42.34,42.34,0,0,0,2.58,9.61,59.72,59.72,0,0,0,10.34,16.85l-5.06,1.56a43.42,43.42,0,0,1,3.5-11.35,36.47,36.47,0,0,1,3-5.15,28.21,28.21,0,0,1,3.8-4.5c-.5,1.9-1,3.74-1.48,5.57s-.9,3.64-1.27,5.44c-.75,3.61-1.31,7.19-1.7,10.8l-.68,6.19-4.39-4.62a47.38,47.38,0,0,1-6.63-8.92A42.45,42.45,0,0,1,20.6,89.87a33.29,33.29,0,0,1-1-11.1A24.53,24.53,0,0,1,22.81,68.37Z" />
          <path
            className={hairColour}
            d="M22.81,68.37C18,82.68,25,97.66,33.65,107.13A56.74,56.74,0,0,1,41,85.72"
          />
          <path d="M32.44,66.15a40.11,40.11,0,0,0,.09,10.34A42.46,42.46,0,0,0,35.1,86.1,59.52,59.52,0,0,0,45.45,103l-5.07,1.56c.37-2.54.49-5.44.63-8.29s.26-5.77.44-8.68.42-5.84.84-8.77c.2-1.46.42-2.92.75-4.37a27.39,27.39,0,0,1,1.22-4.27c0,3,.31,5.81.57,8.7l.85,8.65c.26,2.9.5,5.8.63,8.74a60.59,60.59,0,0,1-.13,9.1l-.65,6.2-4.41-4.63A47.07,47.07,0,0,1,34.48,98a42.42,42.42,0,0,1-4.24-10.31,33,33,0,0,1-1-11.09A25,25,0,0,1,32.44,66.15Z" />
          <path
            className={hairColour}
            d="M32.44,66.15c-4.83,14.31,2.23,29.3,10.84,38.77,1.08-7.72-.64-26.67,1-34.79"
          />
          <path d="M39.51,56.88a65.25,65.25,0,0,0-.45,10.57,55.53,55.53,0,0,0,1.36,10.12A47.4,47.4,0,0,0,43.62,87a35.43,35.43,0,0,0,5.2,8.2l-5.06,1.56a40.59,40.59,0,0,0,.32-4.21c0-1.48,0-3,.05-4.52,0-3,0-6.13,0-9.22s.13-6.22.43-9.34c.14-1.56.29-3.12.57-4.68a23.74,23.74,0,0,1,1.16-4.57,72.27,72.27,0,0,0,.8,9.2l1.26,9.14c.4,3.06.77,6.14,1,9.25.14,1.56.24,3.13.29,4.74a33.13,33.13,0,0,1-.15,5l-.69,6.2-4.38-4.63a33.19,33.19,0,0,1-6.27-9.74,39.13,39.13,0,0,1-2.43-22.19A31.79,31.79,0,0,1,39.51,56.88Z" />
          <path
            className={hairColour}
            d="M39.51,56.88C34.68,71.19,38,87.7,46.66,97.17c1.07-7.72-2-28.83-.33-36.95"
          />
          <path d="M43.14,41.13a38.72,38.72,0,0,0-9.83,6,52.07,52.07,0,0,0-7.74,8.11,66.39,66.39,0,0,0-6.1,9.38,60.07,60.07,0,0,0-4.41,10.15l-4-3.51a83.29,83.29,0,0,0,7.76-4.18c2.63-1.56,5.3-3.14,8-4.68s5.45-3,8.3-4.38c1.42-.68,2.85-1.35,4.33-1.9A26.88,26.88,0,0,1,44,54.75a85.18,85.18,0,0,0-7.52,5.44L29.2,65.93c-2.45,1.9-4.92,3.79-7.48,5.6-1.27.92-2.58,1.8-3.94,2.66a36.73,36.73,0,0,1-4.37,2.46L7.71,79.4l1.73-6.26a49,49,0,0,1,4.87-11.41,51.48,51.48,0,0,1,7.41-9.84A40.07,40.07,0,0,1,31.5,44.4,26.35,26.35,0,0,1,43.14,41.13Z" />
          <path
            className={hairColour}
            d="M43.14,41.13C27.26,44.5,15.85,61.68,12.25,74c7.15-3.09,24-16.37,31.76-19.21"
          />
          <path d="M95.64,43.63c.35-.67.71-1.32,1.1-1.94s.76-1.26,1.17-1.87c.81-1.22,1.64-2.41,2.52-3.56l.43-.57,1.13,0q5.92.21,11.83.66l1.88.14.42,2.11c.67,3.32,1.24,6.66,1.73,10,.25,1.67.49,3.34.69,5s.4,3.36.59,5l1.51,13-6.72-11c-.69-1.13-1.4-2.23-2.06-3.37s-1.34-2.27-2-3.42-1.28-2.31-1.88-3.49-1.21-2.36-1.78-3.57l1.08.59c-1.1-.21-2.19-.44-3.29-.71s-2.2-.54-3.29-.88c1.11-.27,2.23-.47,3.34-.65s2.23-.35,3.34-.49l.46-.06.62.65c.92,1,1.82,1.94,2.69,2.93s1.74,2,2.59,3,1.68,2,2.49,3.07,1.6,2.09,2.4,3.13l-5.21,2c-.27-1.67-.57-3.33-.8-5s-.48-3.34-.69-5q-.65-5-1-10.09l2.31,2.26q-5.85-.93-11.67-2.09L103.1,39q-1.75,1.26-3.61,2.44c-.61.39-1.25.76-1.89,1.13S96.31,43.29,95.64,43.63Z" />
          <polyline
            className={hairColour}
            points="95.64 43.63 101.76 37.63 113.51 39 116.28 59.09 107.35 46.1 100.72 45.87"
          />
          <path d="M87.93,43.34c-.67-.34-1.33-.68-2-1.06s-1.28-.74-1.89-1.13c-1.24-.79-2.44-1.6-3.61-2.45l1.56.54q-5.82,1.15-11.67,2.09l2.3-3c.66,2.81,1.23,5.64,1.73,8.48.26,1.42.49,2.85.7,4.27s.4,2.86.59,4.29l-5.39-.85c.51-.83,1-1.67,1.52-2.49s1.06-1.64,1.61-2.44,1.12-1.61,1.71-2.39,1.2-1.56,1.84-2.33l1-1.15.8.53c.88.58,1.74,1.17,2.59,1.8s1.7,1.26,2.53,2c-1.07-.14-2.11-.33-3.14-.55s-2.07-.44-3.08-.7l1.77-.62c-.26,1-.54,1.91-.84,2.84s-.62,1.86-1,2.78-.69,1.83-1.06,2.72-.77,1.79-1.15,2.68l-3.63,8.45L70,56.26c-.27-1.42-.57-2.83-.8-4.26s-.49-2.84-.7-4.27c-.43-2.85-.79-5.71-1.05-8.59l-.27-2.85,2.56-.19q5.91-.45,11.83-.66l1.13,0,.43.57c.88,1.15,1.71,2.34,2.52,3.56.41.6.79,1.24,1.17,1.86S87.58,42.67,87.93,43.34Z" />
          <polyline
            className={hairColour}
            points="87.93 43.34 81.81 
37.34 70.06 38.71 72.85 55.8 78.18 45.46 83.85 47.96"
          />
          <path d="M64.29,34.75c-1.73-1.57-3.3-3.15-4.93-4.51a27.93,27.93,0,0,0-5-3.46A23.44,23.44,0,0,0,49,24.71a36.75,36.75,0,0,0-5.86-.95l2.22-5a19.69,19.69,0,0,1,5.1,9.58,15.91,15.91,0,0,1,.22,5.37,12.47,12.47,0,0,1-1.69,4.92A25.42,25.42,0,0,0,48,33.83a23.53,23.53,0,0,0-1.68-4.15,25.38,25.38,0,0,0-5.18-6.77l-5.7-5.29,7.92.25a26.29,26.29,0,0,1,7.23,1.19,20.27,20.27,0,0,1,6.6,3.54,19.85,19.85,0,0,1,4.66,5.6A21.5,21.5,0,0,1,64.29,34.75Z" />
          <path
            className={hairColour}
            d="M64.29,34.75c-4.14-7.41-9.53-13.37-21.06-13.93,4.67,4.61,7.2,11.36,5.71,17.77"
          />
          <g className={cls4}>
            <path
              className={cls5}
              d="M86.24,27.09C85.12,18,80,8.73,74.72,4.94A47,47,0,0,1,78.8,25.67C81.19,28.37,82.61,29.2,86.24,27.09Z"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M96.2,39.62a66.24,66.24,0,0,0,6.73-29.89L93.5,37.46A2.29,2.29,0,0,0,96.2,39.62Z"
            />
          </g>
          <g className={cls4}>
            <polygon
              className={cls5}
              points="118.01 63.45 110.06 44.33 102.39 46.08 107.51 47.56 118.01 63.45"
            />
          </g>
          <g className={cls4}>
            <polygon
              className={cls5}
              points="81.65 36.39 73.84 40.96 72.36 60.22 68.32 37.19 81.65 36.39"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M57.2,37.63C54.11,28.55,49.37,22.55,41,19.42a28.6,28.6,0,0,1,8,19C51.92,41.27,56.78,39.8,57.2,37.63Z"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M45.91,1.75C57,7.35,64.16,16.38,69.54,25.88c.31,5.12-2.63,7.87-5.75,7.25-1.75-2.42-3.31-6.17-5-8.88C55.67,17.14,50.65,10,45.91,1.75Z"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M51.54,13.5l1.37,5.38c-9.4-4-20.87-5.5-30.12-5.25C29.35,17.5,33.56,20.79,37.66,26l6.75-.87-4.75-5.5,19.13,4.62L53.66,13.63Z"
            />
          </g>
          <g className={cls4}>
            <polygon
              className={cls5}
              points="32.53 22.25 33.41 24.5 19.29 25.5 24.04 29 37.66 26 33.91 21.88 32.53 22.25"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M43.42,50.59c2.54.52,3.21,1.55.43,4.21-10.62,6.13-20.32,15.33-33.56,21C17.64,65.72,24.12,57.34,43.42,50.59Z"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M38.54,38.25c4.35.1,5.07,1.32,4.56,2.93-12.25,1.89-24.21,12.84-28,23L9,66.59C12.78,57.19,17.43,47.79,38.54,38.25Z"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M34.6,36.07c-8.8-1.32-18.82.31-28.88,2.93,6,.62,11.54,1.75,15.94,4C25.63,40.61,29.46,37.87,34.6,36.07Z"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M17.5,73.68l1.58,2.14-2.7,15.87L20.77,88c-.63-6.32-.68-12.27,1-17.12Z"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M20.65,74.46l2.82.9a19.84,19.84,0,0,0,3.94,10.36,95.62,95.62,0,0,0,6.42,24L36.3,98.79a35.39,35.39,0,0,1-5.06-14.3C26.51,81.33,24.07,72,28.87,67.14l-.78-1.8-6.31,5.52Z"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M43,55.24c-2.84,16.41-1.37,31,4.07,43.51-11.61-10-12.3-25.25-8.16-40.86Z"
            />
          </g>
          <g className={cls4}>
            <path
              className={cls5}
              d="M38,87.5,44.7,98.67l-.5,7.58a54.06,54.06,0,0,1-8.75-20.58l2.42-.42Z"
            />
          </g>
          <g className={cls6}>
            <path
              className={cls7}
              d="M12.2,73.88a55.62,55.62,0,0,1,6.59-5.65c1.14-.88,2.34-1.66,3.5-2.49s2.4-1.56,3.6-2.34,2.46-1.47,3.7-2.17,2.5-1.4,3.8-2a56.69,56.69,0,0,1,8-3.39l.11.17L27,65.1l-14.65,9Z"
            />
          </g>
          <g className={cls6}>
            <path
              className={cls7}
              d="M39,58.93c0,3.17-.06,6.22,0,9.24,0,.75.08,1.51.08,2.25s.09,1.5.14,2.24l.18,2.22c.08.74.2,1.47.29,2.21s.22,1.46.33,2.2.32,1.44.44,2.17L41,83.63c.22.71.4,1.43.59,2.16.86,2.87,1.94,5.7,3.09,8.64l-.16.12a40.12,40.12,0,0,1-6.78-17.18,51.84,51.84,0,0,1,1.1-18.49Z"
            />
          </g>
          <g className={cls6}>
            <path
              className={cls7}
              d="M9.7,38.56a8.34,8.34,0,0,1,3.48-.75,11.14,11.14,0,0,1,3.36.4,11.47,11.47,0,0,1,3.11,1.32A8.56,8.56,0,0,1,22.3,41.9l-.07.18c-2.05-.71-4.1-1.42-6.21-1.94a64.62,64.62,0,0,0-6.35-1.38Z"
            />
          </g>
          <g className={cls6}>
            <path
              className={cls7}
              d="M43.93,21.09A10.11,10.11,0,0,1,47.51,24,13,13,0,0,1,48.78,26a16.75,16.75,0,0,1,.93,2.13,15.74,15.74,0,0,1,.56,2.25,11.86,11.86,0,0,1,.16,2.31,10,10,0,0,1-1.06,4.51h-.2a23.64,23.64,0,0,0-1.35-8.42,24.63,24.63,0,0,0-4-7.51Z"
            />
          </g>
          <g className={cls6}>
            <path
              className={cls7}
              d="M48.35,3.7a23.68,23.68,0,0,1,4.57,4.48c.69.81,1.26,1.7,1.87,2.56s1.1,1.81,1.62,2.73,1,1.89,1.37,2.86.8,2,1.09,3A23,23,0,0,1,60,25.62l-.19.07a87.81,87.81,0,0,0-5.19-11.28,100.69,100.69,0,0,0-6.47-10.6Z"
            />
          </g>
          <g className={cls6}>
            <path
              className={cls7}
              d="M26.4,13.91a11.08,11.08,0,0,1,4.1,1.29,15.54,15.54,0,0,1,1.79,1.09c.57.41,1.1.85,1.63,1.3s1,1,1.45,1.51a14.67,14.67,0,0,1,1.24,1.69,10.89,10.89,0,0,1,1.65,4l-.16.12a47.37,47.37,0,0,0-5.52-5.81,51.5,51.5,0,0,0-6.29-5Z"
            />
          </g>
          <g className={cls6}>
            <path
              className={cls7}
              d="M78.11,8.64a11.75,11.75,0,0,1,2,3.7,17.39,17.39,0,0,1,.53,2c.12.67.2,1.34.28,2s0,1.36,0,2a16,16,0,0,1-.26,2.05A10,10,0,0,1,79,24.34l-.2,0a37.35,37.35,0,0,0,.06-7.84,70.53,70.53,0,0,0-1-7.79Z"
            />
          </g>
        </g>
        <g id="EAR">
          <path d="M36.24,71.47a12.37,12.37,0,0,0-4-2.32,3.58,3.58,0,0,0-1.71-.05,3.45,3.45,0,0,0-1.24.81,7.33,7.33,0,0,0-1.82,6.67,13.65,13.65,0,0,0,1.22,3.81,9.36,9.36,0,0,0,2.21,3l.32.25a3,3,0,0,0,.3.18,3,3,0,0,0,.8.3,5,5,0,0,0,1.86.1,7.61,7.61,0,0,0,4-2.14,6.2,6.2,0,0,1-1,2.44,6.38,6.38,0,0,1-2,2,6.57,6.57,0,0,1-6.16.29,10.74,10.74,0,0,1-4.37-4.08,14.51,14.51,0,0,1-2-5.37,11.66,11.66,0,0,1,.55-6,9.21,9.21,0,0,1,4-4.72l.74-.38a6.71,6.71,0,0,1,.83-.26,6.25,6.25,0,0,1,1.73-.19,6,6,0,0,1,3,1.17,7.81,7.81,0,0,1,1.84,2.08A9,9,0,0,1,36.24,71.47Z" />
          <path
            className={skinTone}
            d="M36.24,71.47s-4.46-7.33-9.3-2.16c-5,5.38-.23,15.56,4.41,16.39s6.75-3.59,6.75-3.59"
          />
          <path d="M34,81.36A3.11,3.11,0,0,1,33,82a1.84,1.84,0,0,1-1.32-.06,2.18,2.18,0,0,1-1-1,3.49,3.49,0,0,1-.38-1.29,3.68,3.68,0,0,1,.16-1.38,2.33,2.33,0,0,1,1-1.3,2.26,2.26,0,0,1,1.76-.2,4.06,4.06,0,0,1,1.28.66l-1.57.73,0-1.46a10,10,0,0,0-.2-1.38,3.83,3.83,0,0,0-1-2.21,1.5,1.5,0,0,0-.89-.39,1.82,1.82,0,0,0-1.07.34,4,4,0,0,0-1.46,2.43A3.6,3.6,0,0,1,29,72.23a2.78,2.78,0,0,1,1.68-1,2.94,2.94,0,0,1,2,.45,5.09,5.09,0,0,1,1.93,3.14,11.29,11.29,0,0,1,.26,1.7c0,.55,0,1.11,0,1.66l0,1.91-1.54-1.18a3.54,3.54,0,0,0-.64-.41.48.48,0,0,0-.37,0c-.2,0-.57.57-.6,1.14a1.86,1.86,0,0,0,.41,1.56,1.35,1.35,0,0,0,.81.32A4.53,4.53,0,0,0,34,81.36Z" />
          <path
            className={cls9}
            d="M27.4,68c-4.62,6.38,1.25,19.25,10.25,15.13A7.52,7.52,0,0,1,31,86.55c-2.86-.24-6.07-4.8-7.06-9.18A8.68,8.68,0,0,1,27.4,68Z"
          />
        </g>
      </g>
    </Svg>
  )
}
