export const dimensions = {
  width: 220,
  height: 300,
}

export const originPosition = {
  left: 0.45 * dimensions.width,
  top: 0.1 * dimensions.height,
}

export const sizing1 = {
  top: 0,
  left: 0,
  width: 220,
  height: 217,
}

export const sizing2 = {
  top: 95,
  left: 9,
  width: 121,
  height: 216,
}
