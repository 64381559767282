import { useState } from "react"

import { getUserResponses } from "@views/english-demo-1/utils/get-user-responses"
import {
  getBatchedProblems,
  createEmptyBatchedProblems,
} from "@views/english-demo-1/utils/helpers"
import { useIfActiveScene } from "@views/english-demo-1/utils/hooks"
import { loadEnglishDataFromRemoteIntoCache } from "@views/english-demo-1/utils/save-load-data"

import { useUserFromState } from "@helpers/auth"
import { REMOTE_ATTRIBUTE_OBJECT_TRIANGLE } from "@helpers/constants/remote-attributes"

export const useEnglishProblems = (
  isActiveScene: boolean,
  callback?: () => void
) => {
  const [englishProblems, setEnglishProblems] = useState(
    createEmptyBatchedProblems()
  )

  useIfActiveScene(isActiveScene, () => {
    const getData = async () => {
      const batchedProblems = await getBatchedProblems()
      setEnglishProblems(batchedProblems)
    }
    callback && callback()
    getData()
  })

  return englishProblems
}

export const useLoadUserResponses = (isActiveScene: boolean) => {
  const user = useUserFromState()
  const userResponses = getUserResponses(null)
  useIfActiveScene(isActiveScene, () => {
    if (userResponses === null) {
      const init = async () => {
        try {
          await loadEnglishDataFromRemoteIntoCache(
            user,
            REMOTE_ATTRIBUTE_OBJECT_TRIANGLE
          )
        } catch (e) {
          console.log(e)
        }
      }
      // Cache is empty, retrieve from remote
      init()
    }
  })
}
