import { classnames } from "classnames-joiner"
import React from "react"

import { hair, part } from "@components/Avatar/a.module.scss"
import { AvatarItemInner } from "@components/Avatar/common/avatar-item"
import { SkinAndHairAvatarProps } from "@components/Avatar/types"
import { getTranslatedFactoredStyles } from "@components/Avatar/utils"

import { dimensions, originPosition, sizing1, sizing2 } from "./constants"
import { FringeStraightLongHairAsset } from "./hair-1"
import { FringeStraightLongHair2Asset } from "./hair-2"
import { extraHair } from "./s.module.scss"

// The back piece needs "part" because it needs to change the hair index
export const FringeStraightLongHair = ({
  skinTone,
  hairColour,
  ...baseAvatar
}: SkinAndHairAvatarProps) => {
  const { placementPosition, rotation, factor } = baseAvatar
  const translatedFactoredStyles = getTranslatedFactoredStyles({
    dimensions,
    originPosition,
    placementPosition,
    rotation,
    factor,
  })

  return (
    <>
      <AvatarItemInner
        translatedFactoredStyles={translatedFactoredStyles}
        originPosition={originPosition}
        baseAvatar={baseAvatar}
        className={hair}
      >
        {/* <InnerImage src={src1} sizing={sizing1} factor={baseAvatar.factor} /> */}
        <FringeStraightLongHairAsset
          sizing={sizing1}
          factor={baseAvatar.factor}
          skinTone={skinTone}
          hairColour={hairColour}
        />
      </AvatarItemInner>
      <AvatarItemInner
        translatedFactoredStyles={translatedFactoredStyles}
        originPosition={originPosition}
        baseAvatar={baseAvatar}
        className={classnames([part, extraHair])}
      >
        {/* <InnerImage src={src2} sizing={sizing2} factor={baseAvatar.factor} /> */}
        <FringeStraightLongHair2Asset
          sizing={sizing2}
          factor={baseAvatar.factor}
          skinTone={skinTone}
          hairColour={hairColour}
        />
      </AvatarItemInner>
    </>
  )

  // return (
  //   <div className={className}>
  //     <img
  //       style={createFactoredSizing(sizing1, factor)}
  //       src={src1}
  //       alt="shoulder length straight hair"
  //       className={hair}
  //     />
  //     <img
  //       style={createFactoredSizing(sizing2, factor)}
  //       src={src2}
  //       alt="shoulder length straight back hair"
  //       className={classnames([part, extraHair])}
  //     />
  //   </div>
  // )
}
