import React from "react"
import Button from "react-bootstrap/Button"

import { NoArgVoid } from "@components/types"

import { navItemClasses } from "./constants"

export const NavButton = ({
  onClick,
  label,
}: {
  onClick: NoArgVoid
  label: string
}) => {
  return (
    <Button onClick={onClick} className={navItemClasses} variant="link">
      {label}
    </Button>
  )
}
