import { useState, useCallback, useEffect } from "react"

import { QueryParamValueType } from "@components/types"

import { getIsBrowser } from "@helpers/misc"

import { getQueryStringValue } from "./get-query-string-value"
import { setQueryStringValue } from "./set-query-string-value"
import { ModeType } from "./types"

// https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f

export const useQueryString = (key: string) => {
  const currentQueryString = getIsBrowser() ? window.location.search : ""

  const [paramValue, setParamValue] = useState<QueryParamValueType>(
    getQueryStringValue(key)
  )

  useEffect(() => {
    const newParamValue = getQueryStringValue(key)
    if (newParamValue !== paramValue) {
      setParamValue(newParamValue)
    }
  }, [key, currentQueryString])

  const onSetParamValue = useCallback(
    (newValue: QueryParamValueType, mode?: ModeType) => {
      if (typeof newValue !== "string") {
        return
      }

      // Do not do a check of current value and the new value since useState sets a new instance
      // We could use getQueryStringValue to read the current value but it's a wasteful check

      setParamValue(newValue)
      setQueryStringValue({ key, value: newValue, mode })
    },
    [key]
  )

  return {
    paramValue,
    onSetParamValue,
  }
}
