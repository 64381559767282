import { useState, useEffect } from "react"

import { SyllabusCodeType } from "@views/multiple-choice-problem/types"
import {
  getListOfSyllabusNames,
  createEmptySyllabusNames,
} from "@views/multiple-choice-problem/utilites/getNiceName"

export const useSyllabusNames = (syllabusCodes: SyllabusCodeType[]) => {
  const [syllabusNames, setSyllabusNames] = useState(createEmptySyllabusNames())

  const syllabusCodesStr = JSON.stringify(syllabusCodes)

  useEffect(() => {
    const init = async () => {
      const newSyllabusNames = await getListOfSyllabusNames(syllabusCodes)
      setSyllabusNames(newSyllabusNames)
    }
    init()
  }, [syllabusCodesStr])

  return syllabusNames
}
