import React from "react"
import { useSelector, useDispatch } from "react-redux"

import { setUserSyncedActionCreator } from "@redux/ducks/user-synced"
import { savePhysicsUserSyncedThunkCreator } from "@redux/ducks/user-synced/async"
import { selectRootState } from "@redux/selectors"

import ContentContainer from "@components/ContentContainer"
import { DriverAction } from "@components/Helmets/DriverHelmet"
import { ToastHelmetContainer } from "@components/Helmets/ReactToastifyHelmet"

import { steps } from "./constants"
import { MultipleChoiceProblemInner } from "./multiple-choice-problem-inner/index"

type Props = {
  aboveProblemPartElement?: React.ReactNode
  belowSolutionElement?: React.ReactNode
  monster?: React.ReactElement
  monsterTitle?: string
}

export const MultipleChoiceProblemView = ({
  aboveProblemPartElement,
  belowSolutionElement,
  monster,
  monsterTitle,
}: Props) => {
  const rootState = useSelector(selectRootState)
  const dispatch = useDispatch()

  return (
    <>
      <ToastHelmetContainer />
      <DriverAction
        steps={steps}
        shouldShow={!rootState.userSynced.hasSeenPhysicsOnboarding}
        driverOptions={{
          onReset: () => {
            dispatch(
              setUserSyncedActionCreator({
                hasSeenPhysicsOnboarding: true,
              })
            )
            dispatch(savePhysicsUserSyncedThunkCreator())
          },
        }}
      />
      <ContentContainer>
        <MultipleChoiceProblemInner
          aboveProblemPartElement={aboveProblemPartElement}
          belowSolutionElement={belowSolutionElement}
          monster={monster}
          monsterTitle={monsterTitle}
        />
      </ContentContainer>
    </>
  )
}
