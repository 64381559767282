import React from "react"

import { TEXT_PRIMARY } from "@helpers/constants/style"

export const RestingFlavourText = () => {
  return (
    <p>
      <em className={TEXT_PRIMARY}>
        Not enough potions were available to heal you, so instead you rest for a
        period of time to recover your damage after making a wrong move. Take
        the time to contemplate your next move.
      </em>
    </p>
  )
}
