import React from "react"
import Badge from "react-bootstrap/Badge"

interface Props {
  children: string
  className?: string
}

const getVariant = (val: string) => {
  switch (val) {
    case "M5":
    case "IQ4":
      return "danger"
    case "M6":
    case "IQ1":
    default:
      return "primary"
    case "M7":
    case "IQ2":
      return "warning"
    case "M8":
    case "IQ3":
      return "success"
  }
}

export const SyllabusBadge = ({ children, className }: Props) => {
  return (
    <Badge className={className} variant={getVariant(children)}>
      {children}
    </Badge>
  )
}

export const QuestionBadge = ({ children, className }: Props) => {
  let variant = "success"
  if (children === "similar") {
    variant = "warning"
  }

  return (
    <Badge className={className} variant={variant}>
      {children}
    </Badge>
  )
}
