import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { Img } from "@components/Img"

import { ScreenPropsType } from "@views/onboarding/common/types"
import { NavigationButtonArea } from "@views/onboarding/common/ui/navigation-button-area"

import { W_100, MB_2, TEXT_CENTER } from "@helpers/constants/style"

import orcArmoured from "@images/monsters/orcs/orc-armoured.png"
import orcTopKnot from "@images/monsters/orcs/orc-top-knot.png"

export const WhyPastPaperPractice = ({
  onboardingScreenKey,
  onComplete,
  className,
}: ScreenPropsType) => {
  return (
    <Container className={className}>
      <Row>
        <Col xs={12}>
          <h1>Why Past Paper Practice?</h1>
        </Col>
        <Col xs={6}>
          <Img alt="" src={orcTopKnot} className={`${W_100} ${MB_2}`} />
          <p className={TEXT_CENTER}>
            <strong>Last year's paper</strong>
          </p>
        </Col>
        <Col xs={6}>
          <Img alt="" src={orcArmoured} className={`${W_100} ${MB_2}`} />
          <p className={TEXT_CENTER}>
            <strong>This year's paper</strong>
          </p>
        </Col>
        <Col xs={12}>
          <p>
            Exam questions are designed to obscure the concepts, to really test
            if you've learnt the content. The best way to practice for these
            types of questions, after you've learnt the basics, is to practice
            against questions just like them.
          </p>
          <NavigationButtonArea
            onComplete={onComplete}
            onboardingScreenKey={onboardingScreenKey}
          />
        </Col>
      </Row>
    </Container>
  )
}
