import React from "react"

import { capitaliseWords } from "@helpers/strings"

interface SimpleSyllabusViewProps {
  code: string
  name: string
}
export const SimpleSyllabusView = ({ code, name }: SimpleSyllabusViewProps) => {
  return (
    <div>
      <b>{code}: </b>
      <span>{capitaliseWords(name, false)}</span>
    </div>
  )
}
