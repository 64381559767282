import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Confetti from "react-confetti"

import { GoogleFormLink } from "@components/Forms/GoogleFormLink"

import { Party } from "@views/english-demo-1/common/party"
import { SceneProps } from "@views/english-demo-1/types"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"

import { MB_4, TEXT_CENTER, W_100 } from "@helpers/constants/style"

const ENGLISH_DEMO_FEEDBACK_LINK = "https://forms.gle/sdXyf58tNxXkSLMF9"

export const Action9 = ({ isActiveScene, nextScene }: SceneProps) => {
  useScrollTop(isActiveScene)
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Confetti numberOfPieces={100} friction={0.97} className={W_100} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h1 className={TEXT_CENTER}>Congratulations!</h1>
            <p>
              You've successfully scouted the stimulus, tanked through the tough
              identification of key symbols and techniques, made a response, and
              empathised on your finished result!
            </p>
            <p>
              This is the epic formula you need to succeed in all of your short
              answer English questions.
            </p>
            <GoogleFormLink
              text="Completed the demo? Leave us feedback here."
              href={ENGLISH_DEMO_FEEDBACK_LINK}
            />
          </Col>
        </Row>
      </Container>
      <Party />
      <Container fluid>
        <Row>
          <Col xs={12} className={TEXT_CENTER}>
            <p>
              Upon defeating the gatekeeper of the door, the dungeon opens
              itself up to the party of heroes.
            </p>
            <Button className={MB_4} onClick={nextScene}>
              Enter the Dungeon
            </Button>
            <p>
              <GoogleFormLink
                text="Leave some feedback, we'd love to hear from you."
                href={ENGLISH_DEMO_FEEDBACK_LINK}
              />
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}
