import React from "react"
import { Helmet } from "react-helmet"

import {
  useDriverActions,
  UseDriverActionsProps,
} from "@helpers/hooks/use-driver-actions"

const DriverHelmet = () => {
  return (
    <Helmet>
      <link rel="stylesheet" href="/styles/driver.min.css" />
    </Helmet>
  )
}

export const DriverAction = (props: UseDriverActionsProps) => {
  useDriverActions(props)
  return <DriverHelmet />
}
