import React from "react"

import { MonsterPropsType } from "@components/types"

import { DifficultyType } from "@views/multiple-choice-problem/types"

import orcArmoured from "@images/monsters/orcs/orc-armoured.png"
import orcBald from "@images/monsters/orcs/orc-bald.png"
import orcTopKnot from "@images/monsters/orcs/orc-top-knot.png"

const commonImgProps = {
  className: "d-block w-100",
  style: {
    maxWidth: "200px",
  },
}

export function orcCases(difficulty: DifficultyType): MonsterPropsType {
  if (difficulty >= 2 && difficulty <= 3) {
    return {
      src: orcTopKnot,
      imgEl: <img {...commonImgProps} src={orcTopKnot} alt="top knot orc" />,
      monsterTitle: "Top Knot Orc",
      containerClassName: "containerGreater",
    }
  } else if (difficulty >= 4) {
    return {
      src: orcArmoured,
      imgEl: <img {...commonImgProps} src={orcArmoured} alt="armoured orc" />,
      monsterTitle: "Armoured Orc",
      containerClassName: "containerMajor",
    }
  }

  return {
    src: orcBald,
    imgEl: <img {...commonImgProps} src={orcBald} alt="bald orc" />,
    monsterTitle: "Bald Orc",
    containerClassName: "containerMinor",
  }
}
