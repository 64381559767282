/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPaymentIntent = /* GraphQL */ `
  query GetPaymentIntent($paymentPayload: PaymentPayloadInput, $env: String) {
    getPaymentIntent(paymentPayload: $paymentPayload, env: $env) {
      id
      client_secret
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      triangle
      square
      pentagon
      hexagon
      heptagon
      heads
      tails
      one
      two
      three
      a
      b
      c
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        triangle
        square
        pentagon
        hexagon
        heptagon
        heads
        tails
        one
        two
        three
        a
        b
        c
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCreatorSharerBlob = /* GraphQL */ `
  query GetCreatorSharerBlob($id: ID!) {
    getCreatorSharerBlob(id: $id) {
      id
      triangle
      square
      pentagon
      one
      two
      three
      a
      b
      c
      createdAt
      updatedAt
    }
  }
`;
export const listCreatorSharerBlobs = /* GraphQL */ `
  query ListCreatorSharerBlobs(
    $filter: ModelCreatorSharerBlobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreatorSharerBlobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        triangle
        square
        pentagon
        one
        two
        three
        a
        b
        c
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
