import { OnboardingConfigType } from "./types"

export const ONBOARDING_SCREEN_KEY_WHAT = "whatIsEduCats"
export const ONBOARDING_SCREEN_KEY_WHY = "whyPastPaperPractice"
export const ONBOARDING_SCREEN_KEY_HOW = "howEduCatsHelps"

export const onboardingConfig: OnboardingConfigType = {
  screensList: [
    ONBOARDING_SCREEN_KEY_WHAT,
    ONBOARDING_SCREEN_KEY_WHY,
    ONBOARDING_SCREEN_KEY_HOW,
  ],
}
