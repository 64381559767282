import { useMetaState } from "@helpers/hooks/use-meta-state"
import { getUserRemoteData } from "@helpers/user-data"
import { RemoteAttributeType } from "@helpers/user-data/types"

interface Props {
  remoteId?: string
  cacheKey: string
  remoteAttribute: RemoteAttributeType
  isLoggedIn: boolean
}

export const useUserDataMetaState = ({
  remoteId,
  cacheKey,
  remoteAttribute,
  isLoggedIn,
}: Props) => {
  const remoteThunk =
    isLoggedIn && remoteId
      ? async () => {
          const response = await getUserRemoteData({
            remoteId,
            remoteAttribute,
          })

          return response.value
        }
      : async () => {}

  const metaState = useMetaState({
    key: cacheKey,
    remoteThunk,
    extraDeps: [isLoggedIn, remoteId],
  })

  return metaState
}
