import { Cache, Analytics } from "aws-amplify"
import React from "react"
import Button from "react-bootstrap/Button"

import { CenteredWrapper } from "@components/Containers/positioning-wrappers"
import { Input } from "@components/Form/Input"
import { Wrapper } from "@components/Form/types"

import { BossEmpathScene } from "@views/english-demo-1/common/boss-empath-scene"
import { ENGLISH_DEMO_USER_RESPONSES } from "@views/english-demo-1/common/constants"
import {
  DetailedBossMonster,
  BossMonster,
} from "@views/english-demo-1/common/minion"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { goblin } from "@views/english-demo-1/practice-problem/pp.module.scss"
import { ScenePropsWithInputProps } from "@views/english-demo-1/types"
import { findSceneIndexBySceneId } from "@views/english-demo-1/utils/"
import { AvatarsContext } from "@views/english-demo-1/utils/context"
import { getUserResponses } from "@views/english-demo-1/utils/get-user-responses"
import {
  useScrollTop,
  useIfActiveScene,
} from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"
import { useEnglishProblem } from "@views/english-demo-1/utils/hooks/use-english-problem"
import { saveEnglishDataFromCacheToRemote } from "@views/english-demo-1/utils/save-load-data"

import { useUserFromState } from "@helpers/auth"
import { HERO_CLASS_EMPATH } from "@helpers/constants/hero-class"
import { CACHE_KEY_ACTIVE_MONSTER_DETAILS } from "@helpers/constants/monster-class"
import { jsxJSONArrayProcessor } from "@helpers/jsxJSONProcessor"

import { Question } from "./question"

const LabelWrapper = ({ children }: Wrapper) => <b>{children}</b>

export const PracticeProblemReflection = ({
  index,
  scenes,
  previousScene,
  setSceneById,
  inputProps,
  isActiveScene,
  setShowStimulus,
}: ScenePropsWithInputProps) => {
  const englishProblem = useEnglishProblem(isActiveScene, setShowStimulus)
  const englishProblemId = englishProblem?.id
  const user = useUserFromState()

  useScrollTop(isActiveScene)
  useIfActiveScene(
    isActiveScene,
    () => {
      if (englishProblemId) {
        const userResponses = getUserResponses()
        const userMark = userResponses[englishProblemId]?.mark
        inputProps.setValue(userMark ? userMark : "")
      }
    },
    [englishProblemId]
  )

  const context = React.useContext(AvatarsContext)

  const avatar = (
    <section>
      <div className={avatarContainer}>
        {useEnglishDemoAvatar({
          heroClass: HERO_CLASS_EMPATH,
        })}
      </div>
      <CenteredWrapper>
        <Button
          variant="success"
          className="mr-4"
          onClick={context.avatarToggles["empath"]}
        >
          👱‍♀️ 👨‍🦱 Personalise
        </Button>
      </CenteredWrapper>
    </section>
  )

  if (!englishProblem) {
    return <div></div>
  }

  // Gather the short response scene's answer
  const shortResponseIndex = findSceneIndexBySceneId(
    "practice-problem-maker",
    scenes
  )
  let previousSceneInputProps =
    scenes[shortResponseIndex ? shortResponseIndex : index - 1].inputProps

  const composedQuestion = (
    <>
      <Question
        question={englishProblem.question}
        triangleCount={englishProblem.triangles.length}
      />
      {previousSceneInputProps && (
        <Input {...previousSceneInputProps} LabelWrapper={LabelWrapper} />
      )}
    </>
  )

  const saveData = async () => {
    try {
      await saveEnglishDataFromCacheToRemote(user)
    } catch (e) {
      console.log(e)
    }
  }

  const elementWhenAnsweredCorrectly = (
    <Button
      onClick={() => {
        setSceneById("practice-list")
        if (englishProblem && previousSceneInputProps) {
          const userResponses = getUserResponses()

          userResponses[englishProblem.id] = {
            ...userResponses[englishProblem.id],
            res: previousSceneInputProps.value,
            mark: inputProps.value,
          }

          Cache.setItem(ENGLISH_DEMO_USER_RESPONSES, userResponses)
          saveData()

          Analytics.record({
            name: "practice-problem-reflection-button-finish",
            attributes: { id: englishProblem.id },
          })
        }
      }}
    >
      Finish
    </Button>
  )

  const fromCache = Cache.getItem(CACHE_KEY_ACTIVE_MONSTER_DETAILS)

  const activeMonsterDetails = fromCache
    ? fromCache
    : {
        src: "",
        title: "",
      }
  const markValue = englishProblem.triangles.length + 1
  return (
    <section>
      <BossEmpathScene
        question={composedQuestion}
        sampleAnswer={jsxJSONArrayProcessor(englishProblem.sampleResponse)}
        minion={
          <DetailedBossMonster
            marks={markValue}
            question={englishProblem.question}
            bossMonsterTitle={activeMonsterDetails.title}
            bossMonster={
              <BossMonster
                src={activeMonsterDetails.src}
                alt={activeMonsterDetails.title}
                className={goblin}
              />
            }
          />
        }
        avatar={avatar}
        inputProps={inputProps}
        previousScene={previousScene}
        questionMaxMarks={englishProblem.triangles.length + 1}
        elementWhenAnsweredCorrectly={elementWhenAnsweredCorrectly}
      />
    </section>
  )
}
