import React from "react"
import { toast } from "react-toastify"

type ToastTypes = "info" | "success" | "warn" | "error"

const defaultOptions = {
  autoClose: 10000,
}

export function toastNotification(
  content: React.ReactNode,
  type?: ToastTypes,
  options: any = {}
) {
  if (!content) {
    return
  }

  const selectedOptions = { ...defaultOptions, ...options }

  if (type) {
    toast[type](content, selectedOptions)
  } else {
    toast(content, selectedOptions)
  }
}
