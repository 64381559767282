import React from "react"

import { head } from "@components/Avatar/a.module.scss"
import { AvatarItem } from "@components/Avatar/common/avatar-item"
import { SkinAvatarProps } from "@components/Avatar/types"

import { NakedHeadAsset } from "./asset"
import { dimensions, originPosition } from "./constants"

export const NakedHead = ({ skinTone, ...baseAvatar }: SkinAvatarProps) => {
  return (
    <AvatarItem
      className={head}
      dimensions={dimensions}
      originPosition={originPosition}
      baseAvatar={baseAvatar}
    >
      <NakedHeadAsset skinTone={skinTone} />
    </AvatarItem>
  )
}
