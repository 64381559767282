import React from "react"

import { useInputProps } from "@components/Form/Input"
import { useRangeProps } from "@components/Form/Range"

import { ENGLISH_REFLECTION_LABEL_DISPLAY } from "@views/english-demo-1/common/constants"

import { FONT_WEIGHT_NORMAL } from "@helpers/constants/style"

import { Action0 } from "../action-0"
import { Action1 } from "../action-1"
import { Action1A } from "../action-1-a"
import { Action1B } from "../action-1-b"
import { Action1C } from "../action-1-c"
import { Action3 } from "../action-3"
import { Action4 } from "../action-4"
import { Action5 } from "../action-5"
import { Action6A } from "../action-6-a"
import { Action6B } from "../action-6-b"
import { Action6C } from "../action-6-c"
import { Action6D } from "../action-6-d"
import { Action6E } from "../action-6-e"
import { Action7 } from "../action-7"
import { Action7B } from "../action-7-b"
import { Action8 } from "../action-8"
import { Action9 } from "../action-9"
import { PracticeList } from "../practice-list"
import { PracticeProblem } from "../practice-problem"
import { PracticeProblemReflection } from "../practice-problem-reflection"
import { Scene } from "../types"

const extendedResponseLabelDisplay = (
  <>
    Your response <span className={FONT_WEIGHT_NORMAL}>(1-2 sentences)</span>
  </>
)

export const findSceneIndexBySceneId = (sceneID: string, scenes: Scene[]) => {
  for (let i = 0; i < scenes.length; i++) {
    if (scenes[i].sceneID === sceneID) {
      return i
    }
  }
}

export const useScenes = (): Scene[] => {
  const scenes: Scene[] = [
    { sceneID: "0", component: Action0 },
    { sceneID: "1", component: Action1 },
    {
      sceneID: "1a",
      component: Action1A,
    },
    {
      sceneID: "1b",
      component: Action1B,
    },
    {
      sceneID: "1c",
      component: Action1C,
    },
    {
      sceneID: "3",
      component: Action3,
      question:
        "The first line of the first 4 stanzas are the same ‘Go and open the door’. Which of the following techniques are used in this? Select all that apply.",
      checkboxOptions: [
        {
          label: "Juxtaposition",
          shouldBeChecked: false,
        },
        {
          label: "Symbolic recurring motif",
          shouldBeChecked: true,
        },
        {
          label: "Repetition",
          shouldBeChecked: true,
        },
        {
          label: "Instructional language",
          shouldBeChecked: true,
        },
      ],
    },
    {
      sceneID: "4",
      component: Action4,
      question: (
        <div>
          <h2 className="h4">
            How would you describe the poem changes as it progresses?
          </h2>
          <ul>
            <li>
              Stanza 1: <i>a garden; or a magic city.</i>
            </li>
            <li>
              Stanza 2: <i>or an eye, or the picture</i>
            </li>
            <li>
              Stanza 3: <i>there’s a fog</i>
            </li>
            <li>
              Stanza 4:{" "}
              <i>if there’s only; the hollow wind; even if; nothing</i>
            </li>
          </ul>
        </div>
      ),
      multipleChoiceOptions: [
        {
          label: "A",
          content: "Tone in imagery gradually growing more frightening",
          shouldBeSelected: true,
        },
        { label: "B", content: "Tone in imagery gradually growing brighter" },
        {
          label: "C",
          content: "Tone in imagery gradually growing stronger",
        },
      ],
    },
    {
      sceneID: "5",
      component: Action5,
      question: (
        <h2 className="h4">What technique does the final stanza use?</h2>
      ),
      multipleChoiceOptions: [
        {
          label: "A",
          content: "Symbolism",
          shouldBeSelected: true,
        },
        { label: "B", content: "Alliteration" },
        {
          label: "C",
          content: "Emotive Language",
        },
      ],
    },
    {
      sceneID: "6a",
      component: Action6A,
      question: (
        <div>
          <h2 className="h4">
            What do you think is the effect of the use of{" "}
            <u>symbolic recurring motif, repetition, encouraging language</u> in{" "}
            <i>‘Go and open the door’</i>?
          </h2>
        </div>
      ),
      sampleAnswer: "Encourages the audience to embrace new experiences.",
      inputProps: useInputProps({
        id: "scene6ainput",
        labelDisplay: extendedResponseLabelDisplay,
      }),
    },
    {
      sceneID: "6b",
      component: Action6B,
      question: (
        <div>
          <h2 className="h4">
            What do you think is the effect of the use of{" "}
            <u>tone in imagery gradually growing more frightening</u> in{" "}
          </h2>
          <ul>
            <li>
              Stanza 1: <i>a garden; or a magic city</i>
            </li>
            <li>
              Stanza 2: <i>or an eye, or the picture</i>
            </li>
            <li>
              Stanza 3: <i>there’s a fog</i>
            </li>
            <li>
              Stanza 4:{" "}
              <i>if there’s only; the hollow wind; even if; nothing</i>
            </li>
          </ul>
        </div>
      ),
      sampleAnswer:
        "Discusses the possibilities of new experiences, with a focus on the dark frightening possibilities.",
      inputProps: useInputProps({
        id: "scene6binput",
        labelDisplay: extendedResponseLabelDisplay,
      }),
    },
    {
      sceneID: "6c",
      component: Action6C,
      question: (
        <div>
          <h2 className="h4">
            What do you think is the effect of the use of <u>symbolism</u> in
            the final stanza?
          </h2>
        </div>
      ),
      sampleAnswer:
        "Concludes that even if the possibilities are frightening there is something worthwhile in every new experience.",
      inputProps: useInputProps({
        id: "scene6cinput",
        labelDisplay: extendedResponseLabelDisplay,
      }),
    },
    {
      sceneID: "6d",
      component: Action6D,
      question: (
        <div>
          <h2 className="h4">
            Describe what you believe the poem is figuratively conveying, noting
            that the literal portrayal is:
          </h2>
          <p>
            <i>
              The poem encourages the reader to open a door, describing to them
              various different scenarios that are outside the door.
            </i>
          </p>
        </div>
      ),
      sampleAnswer:
        "The poem encourages the reader to embrace new experiences, even if they are frightening, as all new experiences are worthwhile.",
      inputProps: useInputProps({
        id: "scene6dinput",
        labelDisplay: extendedResponseLabelDisplay,
      }),
    },
    { sceneID: "6e", component: Action6E },
    {
      sceneID: "7",
      component: Action7,
      inputProps: useInputProps({
        id: "scene7dinput",
        labelDisplay: "Your response",
        as: "textarea",
        rows: 5,
        belowContent: "Drag the corner to add more lines.",
      }),
    },
    {
      sceneID: "7b",
      component: Action7B,
    },
    {
      sceneID: "8",
      component: Action8,
      inputProps: useInputProps({
        id: "scene8input",
        type: "number",
        labelDisplay: ENGLISH_REFLECTION_LABEL_DISPLAY,
        feedback: {
          display: "Please enter a mark within the mark range.",
          type: "invalid",
        },
        belowContent:
          "The goal is to refine your response until you're comfortable awarding yourself full marks.",
      }),
    },
    {
      sceneID: "9",
      component: Action9,
    },
    {
      sceneID: "practice-list",
      component: PracticeList,
    },
    {
      sceneID: "practice-problem-maker",
      component: PracticeProblem,
      inputProps: useInputProps({
        id: "practiceproblemmaker",
        labelDisplay: "",
        as: "textarea",
        rows: 6,
        belowContent: "Drag the corner to add more lines.",
      }),
    },
    {
      sceneID: "practice-problem-empath",
      component: PracticeProblemReflection,
      inputProps: useInputProps({
        id: "practiceproblemempath",
        type: "number",
        labelDisplay: ENGLISH_REFLECTION_LABEL_DISPLAY,
        feedback: {
          display: "Please enter a mark within the mark range.",
          type: "invalid",
        },
        belowContent:
          "The goal is to refine your response until you're comfortable awarding yourself full marks.",
      }),
    },
  ]

  return scenes
}
