import { getIsBrowser } from "@helpers/misc"

// getQueryStringValue: returns the query string value for the provided key.
export const getQueryStringValue = (key: string) => {
  if (!getIsBrowser()) {
    return
  }

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)

  return params.get(key)
}
