import { classnames } from "classnames-joiner"
import React from "react"

import { part } from "@components/Avatar/a.module.scss"
import { AvatarItem } from "@components/Avatar/common/avatar-item"
import { BaseAvatarProps } from "@components/Avatar/types"

import { dimensions, originPosition } from "./constants"
import { scholarWeapon } from "./sw.module.scss"
import src from "./weapon.svg"

// The book weapon doesn't have a handle, so it doesn't make sense to be below the body layer
// Don't import the common weapon class, but import the common part class

export const ScholarWeapon = (baseAvatar: BaseAvatarProps) => {
  return (
    <AvatarItem
      className={classnames([part, scholarWeapon])}
      src={src}
      dimensions={dimensions}
      originPosition={originPosition}
      baseAvatar={baseAvatar}
    />
  )
}
