import { TriangleEmoji } from "accessible-emojis"
import React from "react"
import { ReactNode } from "react-markdown/lib/react-markdown"

import { ECAccordion } from "@components/ECAccordion"

import { FONT_WEIGHT_NORMAL } from "@helpers/constants/style"

export interface TechniqueBoxProps {
  techniqueNo: number
  evidence: ReactNode
  identification: ReactNode
  explanation: ReactNode
  sampleExplanation?: ReactNode
  closed?: boolean
}

export const TechniqueBox = ({
  techniqueNo,
  evidence,
  identification,
  explanation,
  sampleExplanation,
  closed,
}: TechniqueBoxProps) => {
  const bgClasses = [
    "bg-yellow-shade-01",
    "bg-green-shade-01",
    "bg-teal-shade-01",
  ]

  const bgClassIndex = (techniqueNo - 1) % bgClasses.length
  const bgClass = bgClasses[bgClassIndex]
  const heading = (
    <strong>
      Technique <TriangleEmoji className={FONT_WEIGHT_NORMAL} /> {techniqueNo}
    </strong>
  )

  return (
    <ECAccordion
      closed={closed}
      heading={heading}
      headingWrapperClassName={`${bgClass} rounded-top p-3`}
    >
      <div className={`${bgClass} px-3 pb-3 rounded-bottom`}>
        <ul className="mb-0">
          <li>
            <b>Evidence:</b> {evidence}
          </li>
          <li>
            <b>Identification(s):</b> {identification}
          </li>
          <li>
            <b>Explanation:</b> {explanation}
          </li>
          {sampleExplanation && (
            <li>
              <b>Sample Explanation:</b> {sampleExplanation}
            </li>
          )}
        </ul>
      </div>
    </ECAccordion>
  )
}
