import { Cache } from "aws-amplify"

import { ENGLISH_DEMO_USER_RESPONSES } from "@views/english-demo-1/common/constants"

export const getUserResponses = (defaultVal: any = {}) => {
  const fromCache = Cache.getItem(ENGLISH_DEMO_USER_RESPONSES)
  return fromCache ? fromCache : defaultVal
}

export const setUserResponses = (data: object) => {
  Cache.setItem(ENGLISH_DEMO_USER_RESPONSES, data)
}
