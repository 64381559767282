export const USER_CATEGORY_L1 = "unsigned"

export const USER_CATEGORY_L2 = "signed-free-user"

export const USER_CATEGORY_L3 = "signed-paid"

export type UserCategoryType =
  | typeof USER_CATEGORY_L1
  | typeof USER_CATEGORY_L2
  | typeof USER_CATEGORY_L3
