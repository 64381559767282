import { classnames } from "classnames-joiner"
import React from "react"
import { useSelector } from "react-redux"

import { StateType } from "@redux/ducks"
import { selectProblem, selectRootState } from "@redux/selectors"

import { createMonster } from "@components/Monster"
import { HeroStats } from "@components/hero-stats"
import { MonsterStats } from "@components/monster-stats"

import {
  topRect,
  bottomRect,
} from "@views/multiple-choice-problem/q.module.scss"
import { UNSCORED_DIFFICULTY } from "@views/multiple-choice-problem/types"

import {
  COL_12,
  OFFSET_MD_1,
  COL_MD_4,
  ORDER_1,
  ORDER_MD_2,
  MT_2,
  MT_10,
  MB_3,
} from "@helpers/constants/style"

type PropsType = {
  monster?: React.ReactElement
  monsterTitle?: string
}

export const StatsPanel = ({ monster, monsterTitle }: PropsType) => {
  const rootState: StateType = useSelector(selectRootState)

  const problem = selectProblem(rootState)

  if (!problem) {
    return null
  }

  let displayMonster = monster
  let displayMonsterTitle = monsterTitle

  if (!displayMonster || !displayMonsterTitle) {
    const createdMonster = createMonster({
      difficulty:
        problem.difficulty === undefined
          ? UNSCORED_DIFFICULTY
          : problem.difficulty,
      dungeonMonster: rootState.misc.dungeonMonster,
    })

    displayMonster = createdMonster.monster
    displayMonsterTitle = createdMonster.monsterTitle
  }

  return (
    <div
      className={classnames([
        COL_12,
        OFFSET_MD_1,
        COL_MD_4,
        ORDER_1,
        ORDER_MD_2,
      ])}
    >
      <div className={classnames([topRect, MT_10, MB_3])} />
      {problem.qType === "multiple-choice" && (
        <MonsterStats
          problem={problem}
          monsterTitle={displayMonsterTitle}
          monster={displayMonster}
        />
      )}
      <HeroStats
        stars={rootState.userSynced.starsTank}
        potions={rootState.userSynced.potionsTank}
        userStatus={rootState.userSynced.userStatus}
        modalId={rootState.ui.modal.modalId}
        video={rootState.misc.problem?.video}
        notes={rootState.misc.problem?.notes}
      />
      <div className={classnames([bottomRect, MT_2])} />
    </div>
  )
}
