import React from "react"

import { AvatarItem } from "@components/Avatar/common/avatar-item"
import { BaseAvatarProps } from "@components/Avatar/types"

import { dimensions, originPosition } from "./constants"
import src from "./weapon.svg"

export const CrystalWizardWeapon = (baseAvatar: BaseAvatarProps) => {
  return (
    <AvatarItem
      src={src}
      dimensions={dimensions}
      originPosition={originPosition}
      baseAvatar={baseAvatar}
    />
  )
}
