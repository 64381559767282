import { JSXJSONType } from "@helpers/jsxJSONProcessor/types"

export type TopicCodeType = "IQ1" | "IQ2" | "IQ3" | "IQ4" | "IQ5"

export type TopicNameType =
  | "projectile motion"
  | "circular motion"
  | "motion in gravitational fields"
  | "electromagnetic spectrum"
  | "charged particles, conductors and electric and magnetic fields"
  | "the motor effect"
  | "electromagnetic induction"
  | "applications of the motor effect"
  | "electromagnetic induction"
  | "light: wave model"
  | "light: quantum model"
  | "light and special relativity"
  | "structure of the atom"
  | "origins of the elements"
  | "quantum mechanical nature of the atom"
  | "properties of the nucleus"
  | "deep inside the atom"
  | null

export const M1 = "M1"
export const M2 = "M2"
export const M3 = "M3"
export const M4 = "M4"
export const M5 = "M5"
export const M6 = "M6"
export const M7 = "M7"
export const M8 = "M8"

export const castModuleCodeTypeWithDefault = (
  val: any,
  defaultVal: ModuleCodeType = M5
): ModuleCodeType => {
  if (
    val === M1 ||
    val === M2 ||
    val === M3 ||
    val === M4 ||
    val === M5 ||
    val === M6 ||
    val === M7 ||
    val === M8
  ) {
    return val
  }
  return defaultVal
}

export type ModuleCodeType =
  | typeof M1
  | typeof M2
  | typeof M3
  | typeof M4
  | typeof M5
  | typeof M6
  | typeof M7
  | typeof M8

export type ModuleNameType =
  | "kinematics"
  | "dynamics"
  | "waves and thermodynamics"
  | "electricity and magnetism"
  | "advanced mechanics"
  | "electromagnetism"
  | "the nature of light"
  | "from the universe to the atom"

export type DotPointCodeType = "D1" | "D2" | "D3" | "D4" | "D5" | "D6" | "D7"

export type SyllabusCodeType = {
  moduleCode: ModuleCodeType
  topicCode: TopicCodeType
  dotPointCode: DotPointCodeType
}

export type DifficultyType = 0 | 1 | 2 | 3 | 4 | 5

export const UNSCORED_DIFFICULTY = 0

export interface OriginType {
  author: string
  year: number
  id: string
  similarityId?: string // If the question is not exact, give it a similarity id
}

export interface ProblemV2BaseType {
  id: string
  subject: "physics"
  year: number
  marks: number
  origin: OriginType
  difficulty?: DifficultyType
  difficultyJustification?: string[]
  video?: string
  notes?: JSXJSONType[]
}

export interface OptionsType {
  [key: string]: JSXJSONType[]
}

export const Q_TYPE_MUTLIPLE_CHOICE = "multiple-choice"
export interface ProblemV2MultipleChoiceType extends ProblemV2BaseType {
  qType: "multiple-choice"
  syllabusCodes: SyllabusCodeType[]
  question: JSXJSONType[]
  options: OptionsType
  answer: string
  solution?: JSXJSONType[]
}

export const Q_TYPE_EXTENDED_RESPONSE_MULTI_PART =
  "extended-response-multi-part"
export interface ProblemV2ExtendedResponseMultiType extends ProblemV2BaseType {
  qType: typeof Q_TYPE_EXTENDED_RESPONSE_MULTI_PART
  question?: JSXJSONType[]
  partsList: string[]
  parts: {
    [key: string]:
      | ProblemV2ExtendedResponsePartType
      | ProblemV2ExtendedResponseMultiType
  }
}

interface CriteriaItemType {
  critera: "string"
  marks: number
}

export const Q_TYPE_EXTENDED_RESPONSE_PART = "extended-response-part"
export interface ProblemV2ExtendedResponsePartType extends ProblemV2BaseType {
  qType: "extended-response-part"
  syllabusCodes: SyllabusCodeType[]
  question: JSXJSONType[]
  answer?: JSXJSONType[]
  solution: JSXJSONType[]
  critera: CriteriaItemType[]
}

export type ProblemV2Type =
  | ProblemV2MultipleChoiceType
  | ProblemV2ExtendedResponseMultiType
