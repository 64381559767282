import { navigate } from "gatsby"
import { useCallback } from "react"

import { useUsername } from "@helpers/auth"
import {
  LINK_PHYSICS,
  LINK_ENGLISH,
  LINK_SUBJECT_SELECTION,
} from "@helpers/constants/links"
import {
  QUERY_PARAM_SUBJECT,
  QUERY_PARAM_VALUE_PHYSICS,
  QUERY_PARAM_VALUE_ENGLISH,
} from "@helpers/constants/query-params"
import { useQueryString } from "@helpers/hooks/use-query-string"
import { USER_SETTINGS_HAS_SEEN_ONBOARDING } from "@helpers/hooks/use-user-settings-data-meta-state"
import { saveProgress } from "@helpers/user-data"
import { getSettingsUserTableItemId } from "@helpers/user-data/get-user-table-item-id"

import { onboardingConfig } from "./constants"
import { OnboardingScreenKeyType } from "./types"

export const castScreenKeyWithDefault = (
  val: any,
  defaultVal: OnboardingScreenKeyType
): OnboardingScreenKeyType => {
  if (onboardingConfig.screensList.includes(val)) {
    return val
  }
  return defaultVal
}

export const progressToNextScreen = ({
  activeScreen,
  onSetScreenParamValue,
}: {
  activeScreen: OnboardingScreenKeyType
  onSetScreenParamValue: (screen: OnboardingScreenKeyType) => void
}) => {
  const currentScreenIndex = onboardingConfig.screensList.indexOf(activeScreen)

  const nextScreen = onboardingConfig.screensList[currentScreenIndex + 1]
  onSetScreenParamValue(nextScreen)
}

export const useRedirectToProduct = () => {
  const { paramValue: subjectParamValue } = useQueryString(QUERY_PARAM_SUBJECT)

  console.log({ subjectParamValue })

  return () => {
    switch (subjectParamValue) {
      case QUERY_PARAM_VALUE_PHYSICS:
        navigate(LINK_PHYSICS)
        return
      case QUERY_PARAM_VALUE_ENGLISH:
        navigate(LINK_ENGLISH)
        return
      default:
        navigate(LINK_SUBJECT_SELECTION)
        return
    }
  }
}

export const useRedirectToProductWithSave = () => {
  const username = useUsername()
  const redirectToProduct = useRedirectToProduct()

  return () => {
    const remoteId = username ? getSettingsUserTableItemId(username) : undefined
    if (username && remoteId) {
      console.log("saveProgress", {
        remoteId,
        remoteAttribute: USER_SETTINGS_HAS_SEEN_ONBOARDING.remoteAttribute,
        value: true,
      })

      saveProgress({
        remoteId,
        remoteAttribute: USER_SETTINGS_HAS_SEEN_ONBOARDING.remoteAttribute,
        value: true,
      })
    }

    redirectToProduct()
  }
}
