import React from "react"
import Modal from "react-bootstrap/Modal"
import { useDispatch } from "react-redux"

import { useHideModal } from "./hooks"

interface SimpleCenteredModalProps {
  headerContent: React.ReactNode
  bodyContent: React.ReactNode
  footerContent: React.ReactNode
  show: boolean
  keyboard?: boolean
  mode?: "lock" | "free"
}

type BackdropType = "static" | boolean

export const SimpleCenteredModal = ({
  show,
  headerContent,
  bodyContent,
  footerContent,
  mode = "lock",
}: SimpleCenteredModalProps) => {
  const onHide = useHideModal()

  const props =
    mode === "free"
      ? {
          backdrop: true as BackdropType,
          keyboard: true,
          onHide,
        }
      : {
          backdrop: "static" as BackdropType,
          keyboard: false,
        }

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="simple-centered-modal"
      centered
      {...props}
    >
      <Modal.Header closeButton={false}>{headerContent}</Modal.Header>
      <Modal.Body>{bodyContent}</Modal.Body>
      <Modal.Footer>{footerContent}</Modal.Footer>
    </Modal>
  )
}
