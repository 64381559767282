import { StateType } from "@redux/ducks"
import { setMiscActionCreator } from "@redux/ducks/misc"
import { selectPhysicsMCUserResponses } from "@redux/ducks/misc/physics-mc-user-responses"
import { savePhysicsMCUserResponsesThunkCreator } from "@redux/ducks/misc/physics-mc-user-responses/async"
import { setUIActionCreator } from "@redux/ducks/ui"
import { setUserSyncedActionCreator } from "@redux/ducks/user-synced"
import { savePhysicsUserSyncedThunkCreator } from "@redux/ducks/user-synced/async"
import { USER_STATUS_OK } from "@redux/ducks/user-synced/user-status"
import {
  selectProblemHasAlreadyBeenAnswered,
  selectProblem,
} from "@redux/selectors"
import { DispatchType, ReduxType } from "@redux/types"

const createUpdatedMCPhysicsUserResponses = ({
  rootState,
  dispatch,
}: {
  rootState: StateType
  dispatch: DispatchType
}) => {
  const problem = selectProblem(rootState)
  const problemId = problem?.id
  const problemMarks = problem?.marks

  if (problemId === undefined || problemMarks === undefined) {
    return
  }

  const physicsMCUserResponses = selectPhysicsMCUserResponses(rootState)

  const newPhysicsMCUserResponses = {
    ...physicsMCUserResponses,
    data: {
      ...physicsMCUserResponses.data,
      responses: {
        ...physicsMCUserResponses.data.responses,
        [problemId]: {
          marks: problemMarks,
        },
      },
    },
  }

  // Update store
  dispatch(
    setMiscActionCreator({
      physicsMCUserResponses: newPhysicsMCUserResponses,
    })
  )

  // Update the remote
  dispatch(savePhysicsMCUserResponsesThunkCreator())
}

export function onAnsweredCorrectly({ rootState, dispatch }: ReduxType) {
  const problem = selectProblem(rootState)
  dispatch(
    setUIActionCreator({
      solutionShow: true,
      optionsAreLocked: true,
    })
  )

  // Assign rewards and save to state
  const problemHasAlreadyBeenAnswered =
    selectProblemHasAlreadyBeenAnswered(rootState)

  if (problemHasAlreadyBeenAnswered) {
    return
  }

  // It's important to have a difficulty score otherwise people don't gain stars.
  const difficultyVal = problem?.difficulty ? problem?.difficulty : 0
  const marksVal = problem?.marks ? problem?.marks : 0
  dispatch(
    setUserSyncedActionCreator({
      potionsTank: rootState.userSynced.potionsTank + marksVal,
      starsTank: rootState.userSynced.starsTank + difficultyVal,
      userStatus: USER_STATUS_OK,
    })
  )

  dispatch(savePhysicsUserSyncedThunkCreator())

  // Update the user responses to show the current question is now a part of that list.
  createUpdatedMCPhysicsUserResponses({
    rootState,
    dispatch,
  })
}
