// extracted by mini-css-extract-plugin
export var cls10 = "fsl-module--cls-10--FQoxS";
export var cls11 = "fsl-module--cls-11--h-qU6";
export var cls12 = "fsl-module--cls-12--oXTOr";
export var cls13 = "fsl-module--cls-13--JhjWe";
export var cls14 = "fsl-module--cls-14--WJfQ3";
export var cls15 = "fsl-module--cls-15--MqdI6";
export var cls16 = "fsl-module--cls-16--uoVcT";
export var cls18 = "fsl-module--cls-18--1wExP";
export var cls2 = "fsl-module--cls-2--I8SkQ";
export var cls5 = "fsl-module--cls-5--Eyaw4";
export var cls6 = "fsl-module--cls-6--u2sz8";
export var cls7 = "fsl-module--cls-7--toa4M";
export var cls8 = "fsl-module--cls-8--eVi3Q";
export var cls9 = "fsl-module--cls-9--coDQu";