import { classnames } from "classnames-joiner"
import React from "react"

import { Star, EmptyStar } from "@components/Stars"

import { DifficultyType } from "@views/multiple-choice-problem/types"

import { D_FLEX, ALIGN_ITEMS_CENTER } from "@helpers/constants/style"

interface Props {
  difficulty: DifficultyType
}

export const DifficultyRatingWithFallback = ({
  difficulty,
}: {
  difficulty?: DifficultyType
}) => {
  if (difficulty === undefined) {
    return <div>Unscored</div>
  }
  return <DifficultyRating difficulty={difficulty} />
}

export const DifficultyRating = ({ difficulty }: Props) => {
  const maxDifficulty = 5
  let difficultyRatingList = []
  for (let i = 0; i < maxDifficulty; i++) {
    if (i < difficulty) {
      difficultyRatingList.push(<Star key={`star-icon-${i}`} />)
    } else {
      difficultyRatingList.push(<EmptyStar key={`empty-star-icon-${i}`} />)
    }
  }

  return (
    <div className={classnames([D_FLEX, ALIGN_ITEMS_CENTER])}>
      {difficultyRatingList}
    </div>
  )
}
