// Read from state
import { useSelector } from "react-redux"

import { useMetaStatus } from "@helpers/hooks/use-meta-status"

import { getPhysicsMCUserResponsesThunkCreator } from "./async"
import { selectPhysicsMCUserResponses } from "./selectors"

// Read from state
export const usePhysicsMCUserResponses = () => {
  return useSelector(selectPhysicsMCUserResponses)
}

// Read from state, but fetch if it hasn't successfully fetched
export const useFetchPhysicsMCUserResponses = () => {
  const physicsMCUserResponses = usePhysicsMCUserResponses()

  console.log("useFetchPhysicsMCUserResponses", physicsMCUserResponses)

  useMetaStatus({
    metaStatusValue: physicsMCUserResponses.status,
    usernameThunkCreator: getPhysicsMCUserResponsesThunkCreator,
  })

  return physicsMCUserResponses
}
