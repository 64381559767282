import {
  META_STATUS_NEUTRAL,
  META_STATUS_LOADING,
  META_STATUS_ERROR,
  META_STATUS_SUCCESS,
} from "@helpers/hooks/use-meta-status/constants"
import { MetaStatusValueType } from "@helpers/hooks/use-meta-status/types"

const checkStatusCreator =
  (statusValue: MetaStatusValueType) =>
  (currentStatusValue: MetaStatusValueType) => {
    return currentStatusValue === statusValue
  }

export const getCompoundMetaStatusValue = (
  metaStatusList: MetaStatusValueType[]
) => {
  // This relies on a priority
  if (metaStatusList.some(checkStatusCreator(META_STATUS_NEUTRAL))) {
    return META_STATUS_NEUTRAL
  }
  if (metaStatusList.some(checkStatusCreator(META_STATUS_LOADING))) {
    return META_STATUS_LOADING
  }
  if (metaStatusList.some(checkStatusCreator(META_STATUS_ERROR))) {
    return META_STATUS_ERROR
  }

  return META_STATUS_SUCCESS
}
