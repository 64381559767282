import { QUESTION_STATS_DOM_ID } from "@components/monster-stats"
import { PROBLEM_OPTIONS_DOM_ID } from "@components/problem/options/constants"

import { EDUCATIONAL_CONTAINER_DOM_ID } from "@views/multiple-choice-problem/multiple-choice-problem-inner/educational-container"

export const steps = [
  {
    element: `#${EDUCATIONAL_CONTAINER_DOM_ID}`,
    popover: {
      title: "✍️ Question Area",
      description:
        "Your typical past paper style question. Interact here to answer.",
    },
  },
  {
    element: `#${QUESTION_STATS_DOM_ID}`,
    popover: {
      title: "👹 Question Stats",
      description:
        "Questions are represented as monsters! Monsters have unique stats for syllabus, difficulty, and mark value.",
    },
  },
  {
    element: "#hero-stats",
    popover: {
      title: "⚔️ Hero Stats",
      description:
        "<p>This is you, the hero of your own learning journey!</p><p>You can use potions from your inventory to heal and get help.</p>",
    },
  },
  // {
  //   element: "#hero-stats-use-potions",
  //   popover: {
  //     title: "🖐️ Healing (getting help)",
  //     description:
  //       "When you find yourself damaged or just stuck, spend potions to help you answer the question.",
  //   },
  // },
  {
    element: `#${PROBLEM_OPTIONS_DOM_ID}`,
    popover: {
      title: "🖐️ Lets get started",
      description:
        "Have a read of the question, and try answering here when ready.",
    },
  },
]
