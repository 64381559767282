import React, { ReactNode, useState } from "react"
import Col from "react-bootstrap/Col"

import ContentContainer from "@components/ContentContainer"
import { CenteredLoader } from "@components/Loader"
import { AppLayout } from "@components/layouts/app-layout"
import { NumberSetter } from "@components/types"
import { RouterItem } from "@components/types"

import {
  Scene,
  StimulusOptions,
  SetShowStimulus,
} from "@views/english-demo-1/types"
import { findSceneIndexBySceneId } from "@views/english-demo-1/utils"

import { D_NONE, D_BLOCK, ANIMATION_FADEIN } from "@helpers/constants/style"

import { sceneWrapper, stimulus } from "./ed1.module.scss"
import { useScenes } from "./utils"
import { AvatarsProvider } from "./utils/context"

const sceneManager = (
  scenes: Scene[],
  sceneIndex: number,
  setSceneIndex: NumberSetter,
  setShowStimulus: SetShowStimulus
) => {
  const lastIndex = scenes.length - 1

  const scene1bIndex = findSceneIndexBySceneId("1b", scenes)
  const scene0Index = findSceneIndexBySceneId("0", scenes)
  const scene9Index = findSceneIndexBySceneId("9", scenes)
  return scenes.map((scene, index) => {
    const { sceneID, component, ...restProps } = scene

    const setSceneById = (sceneId: string) => {
      const sceneIndex = findSceneIndexBySceneId(sceneId, scenes)
      if (typeof sceneIndex === "number") {
        setSceneIndex(sceneIndex)
      }
    }

    const getScene = (index: number) => {
      setSceneIndex(index)

      if (index === scene1bIndex) {
        setShowStimulus({
          src: "https://www.scottishpoetrylibrary.org.uk/poem/door/",
          title: "The Door by Miroslav Holub",
        })
      } else if (index === scene0Index || index === scene9Index) {
        setShowStimulus(false)
      }
    }

    const nextScene = () => {
      const nextIndex = index === lastIndex ? 0 : index + 1
      getScene(nextIndex)
    }

    const previousScene = () => {
      const prevIndex = index === 0 ? lastIndex : index - 1
      getScene(prevIndex)
    }

    const isActiveScene = index === sceneIndex

    let className
    if (!isActiveScene) {
      className = D_NONE
    } else if (sceneID === "1b") {
      className = D_BLOCK
    } else {
      className = ANIMATION_FADEIN
    }

    return (
      <div
        data-index={index}
        data-sceneid={sceneID}
        key={sceneID}
        className={className}
      >
        <scene.component
          index={index}
          scenes={scenes}
          isActiveScene={isActiveScene}
          previousScene={previousScene}
          nextScene={nextScene}
          setSceneById={setSceneById}
          setShowStimulus={setShowStimulus}
          {...restProps}
        />
      </div>
    )
  })
}

interface ShowStimulusViewProps {
  sceneCallback: () => ReactNode
  src: string
  title: string
}

const ShowStimulusView = ({
  sceneCallback,
  src,
  title,
}: ShowStimulusViewProps) => {
  const [stimulusLoaded, setStimulusLoaded] = useState(false)

  return (
    <ContentContainer>
      <Col xs={12} md={6}>
        <div className={sceneWrapper + " mt-10"}>{sceneCallback()}</div>
      </Col>
      <Col xs={12} md={6}>
        {!stimulusLoaded && <CenteredLoader />}
        <iframe
          id="english-demo-1-stimulus"
          className={
            stimulus + " w-100 h-100 border border-top-0 border-right-0"
          }
          src={src}
          title={title}
          onLoad={() => setStimulusLoaded(true)}
        />
      </Col>
    </ContentContainer>
  )
}

interface HideStimulusViewProps {
  sceneCallback: () => ReactNode
}

const HideStimulusView = ({ sceneCallback }: HideStimulusViewProps) => {
  return (
    <ContentContainer rowConstrained={true}>
      <Col xs={12}>
        <div className="mt-10">{sceneCallback()}</div>
      </Col>
    </ContentContainer>
  )
}

const EnglishDemo1 = () => {
  const [sceneIndex, setSceneIndex] = useState(0)
  const [showStimulus, setShowStimulus] = useState<false | StimulusOptions>(
    false
  )

  const scenes = useScenes()

  const sceneCallback = React.useCallback(
    () => sceneManager(scenes, sceneIndex, setSceneIndex, setShowStimulus),
    [scenes, sceneIndex, setSceneIndex, setShowStimulus]
  )

  return (
    <AvatarsProvider>
      {showStimulus ? (
        <ShowStimulusView
          sceneCallback={sceneCallback}
          src={showStimulus.src}
          title={showStimulus.title}
        />
      ) : (
        <HideStimulusView sceneCallback={sceneCallback} />
      )}
    </AvatarsProvider>
  )
}

export const EnglishDemo1View = (props: RouterItem) => {
  return (
    <AppLayout>
      <EnglishDemo1 />
    </AppLayout>
  )
}
