import React from "react"

import {
  CenteredWrapper,
  ContainerRowCol,
} from "@components/Containers/positioning-wrappers"
import { Img } from "@components/Img"

import { ScreenPropsType } from "@views/onboarding/common/types"
import { NavigationButtonArea } from "@views/onboarding/common/ui/navigation-button-area"

import { MB_2 } from "@helpers/constants/style"

import examSrc from "@images/general/exam-scroll-swords.svg"

export const HowEduCatsHelps = ({
  onboardingScreenKey,
  onComplete,
  className,
}: ScreenPropsType) => {
  return (
    <ContainerRowCol className={className}>
      <h1>How EduCats Helps</h1>

      <CenteredWrapper>
        <Img
          className={MB_2}
          style={{
            maxWidth: 220,
          }}
          alt="exam"
          src={examSrc}
        />
      </CenteredWrapper>
      <p>EduCats helps by making past paper practice easy. We help you:</p>
      <ul>
        <li className={MB_2}>
          <strong>Find relevant past paper questions.</strong> Why scour a full
          paper, when you can search by subject, topic, and even by difficulty
          or mark value?
        </li>
        <li className={MB_2}>
          <strong>Encourage you to do past papers earlier.</strong> Don't wait
          until the final few weeks before an exam!
        </li>
        <li className={MB_2}>
          <strong>Stay regular with past paper practice.</strong> Like a workout
          routine for your mind.
        </li>
        <li className={MB_2}>
          <strong>Track your progress.</strong> Track the questions you've
          already done, so you can uncover new ones and get personalised
          suggestions.
        </li>
      </ul>
      <NavigationButtonArea
        onComplete={onComplete}
        onboardingScreenKey={onboardingScreenKey}
      />
    </ContainerRowCol>
  )
}
