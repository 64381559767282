import React from "react"
import Button from "react-bootstrap/Button"

import {
  CenteredWrapper,
  SpacedWrapper,
} from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import { CharacterIntroScene } from "@views/english-demo-1/common/character-intro-scene"
import { SceneProps } from "@views/english-demo-1/types"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"

import { HERO_CLASS_SCOUT } from "@helpers/constants/hero-class"
import { H_100 } from "@helpers/constants/style"

const intro = (
  <>
    <h1>Scout the Question</h1>
    <p>
      Answering a short answer question starts with gathering information from
      different places. We'll need to read the question, digest what it's asking
      of us, and then read the stimulus.
    </p>
    <p>
      Our <b>Scout</b> is here to help us with just that.
    </p>
  </>
)

const description = (
  <CenteredWrapper className={H_100}>
    <div>
      <h2 className="text-center">Scout</h2>
      <p>
        Scouts are information gatherers. They explore the unknowns, and help
        you find meaning.
      </p>
    </div>
  </CenteredWrapper>
)

export const Action1A = ({
  previousScene,
  nextScene,
  isActiveScene,
}: SceneProps) => {
  useScrollTop(isActiveScene)
  return (
    <>
      <SpacedWrapper>
        <>
          <Button onClick={previousScene}>Back</Button>
          <GoogleFormLinkGeneralFeedback />
        </>
      </SpacedWrapper>
      <CharacterIntroScene
        intro={intro}
        description={description}
        avatarType={HERO_CLASS_SCOUT}
        primaryButtonOnClick={nextScene}
        primaryButtonText="Begin Scouting"
      />
    </>
  )
}
