import { OgreEmoji } from "accessible-emojis"
import { classnames } from "classnames-joiner"
import React from "react"

import { LeftAlignedVerticallyCenteredWrapper } from "@components/Containers/positioning-wrappers"
import Potion from "@components/Potion"
import { TableRow } from "@components/Table"
import { CellTypes } from "@components/Table/types"
import { DifficultyRatingWithFallback } from "@components/difficulty-rating"

import { ProblemV2MultipleChoiceType } from "@views/multiple-choice-problem/types"

import { W_50, PX_2, PL_2, FONT_WEIGHT_NORMAL } from "@helpers/constants/style"
import { simplePluralisation } from "@helpers/strings"

import { useSyllabusNames } from "./hooks"

type Props = {
  problem: ProblemV2MultipleChoiceType
  monsterTitle: string
  monster: any
}

const CELL_CLASS_NAME = classnames([W_50, PX_2])
export const QUESTION_STATS_DOM_ID = "question-stats"

export const MonsterStats = ({ problem, monsterTitle, monster }: Props) => {
  const syllabusNames = useSyllabusNames(problem.syllabusCodes)

  return (
    <table id={QUESTION_STATS_DOM_ID}>
      <thead>
        <TableRow
          cells={[
            {
              type: "th",
              className: "text-center",
              content: "Question Stats",
              colSpan: 2,
            },
          ]}
        ></TableRow>
      </thead>
      <tbody>
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: CELL_CLASS_NAME,
              content: simplePluralisation({
                singleName: "Module",
                isPlural: syllabusNames.moduleNames.length > 1,
              }),
            },
            {
              className: CELL_CLASS_NAME,
              content: syllabusNames.moduleNames,
            },
          ]}
        />
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: CELL_CLASS_NAME,
              content: simplePluralisation({
                singleName: "Topic",
                isPlural: syllabusNames.topicNames.length > 1,
              }),
            },
            {
              className: CELL_CLASS_NAME,
              content: syllabusNames.topicNames,
            },
          ]}
        />
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: CELL_CLASS_NAME,
              content: simplePluralisation({
                singleName: "Dot Point",
                isPlural: syllabusNames.dotPoints.length > 1,
              }),
            },
            {
              className: CELL_CLASS_NAME,
              content: syllabusNames.dotPoints,
            },
          ]}
        />
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: CELL_CLASS_NAME,
              content:
                // TODO: Update the name once we migrate original content
                "Origin",
            },
            {
              className: CELL_CLASS_NAME,
              content: `Q${problem.origin.id}, ${problem.origin.author} ${problem.origin.year}`,
            },
          ]}
        />
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: CELL_CLASS_NAME,
              content: "Difficulty Rating",
            },
            {
              className: CELL_CLASS_NAME,
              content: (
                <DifficultyRatingWithFallback difficulty={problem.difficulty} />
              ) as JSX.Element,
            },
          ]}
        />
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: CELL_CLASS_NAME,
              content: (
                <div>
                  Item Drops <i>(marks)</i>
                </div>
              ) as JSX.Element,
            },
            {
              className: CELL_CLASS_NAME,
              content: (
                <LeftAlignedVerticallyCenteredWrapper>
                  <Potion />
                  <strong className={PL_2}>x {problem.marks}</strong>
                </LeftAlignedVerticallyCenteredWrapper>
              ) as JSX.Element,
            },
          ]}
        />
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: CELL_CLASS_NAME,
              content: (
                <>
                  <OgreEmoji className={FONT_WEIGHT_NORMAL} /> {monsterTitle}
                </>
              ),
            },
            { content: monster },
          ]}
        />
      </tbody>
    </table>
  )
}
