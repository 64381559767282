import React from "react"
import Button from "react-bootstrap/Button"

import { EndWrapper } from "@components/Containers/positioning-wrappers"

import { HasAnsweredViewProps, SampleResponseProps } from "./types"

const SampleResponse = ({ sampleAnswer }: SampleResponseProps) => {
  return (
    <div>
      <div>
        <b>Sample Response</b>
      </div>
      <p>{sampleAnswer}</p>
    </div>
  )
}

export const HasAnsweredView = ({
  value,
  sampleAnswer,
  nextScene,
}: HasAnsweredViewProps) => {
  const [showAnswer, setShowAnswer] = React.useState(false)

  return (
    <>
      <p>{value}</p>
      {!showAnswer && (
        <Button className="mb-4" onClick={() => setShowAnswer(true)}>
          See Sample Response
        </Button>
      )}
      {showAnswer && (
        <>
          <SampleResponse sampleAnswer={sampleAnswer} />
          <EndWrapper>
            <Button onClick={nextScene} className="mb-4">
              Next
            </Button>
          </EndWrapper>
        </>
      )}
    </>
  )
}
