import React from "react"
import Badge from "react-bootstrap/Badge"

interface Props {
  children: React.ReactChild
}

export const StimulusBadge = ({ children }: Props) => {
  let variant = "primary"
  if (children === "Image") {
    variant = "warning"
  } else if (children === "Speech") {
    variant = "success"
  }

  return <Badge variant={variant}>{children}</Badge>
}
