import React from "react"

import { Img } from "@components/Img"

import bloodSrc from "@images/emojis/blood.png"

import campfireIconSrc from "./campfire-min.png"

type IconProps = {
  className?: string
}

export const CampfireIcon = ({ className }: IconProps) => {
  return <Img alt="" src={campfireIconSrc} />
}

export const DropOfBloodIcon = ({ className }: IconProps) => (
  <Img alt="" src={bloodSrc} className={className} />
)
