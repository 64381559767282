import React from "react"

import { MonsterPropsType } from "@components/types"

import { DifficultyType } from "@views/multiple-choice-problem/types"

import skeletonArcherSrc from "@images/monsters/skeletons/skeleton-archer-min.png"
import skeletonFighterSrc from "@images/monsters/skeletons/skeleton-fighter-min.png"
import skeletonMageSrc from "@images/monsters/skeletons/skeleton-mage-min.png"

export function skeletonCases(difficulty: DifficultyType): MonsterPropsType {
  if (difficulty >= 2 && difficulty <= 3) {
    return {
      src: skeletonArcherSrc,
      imgEl: (
        <img
          className="d-block w-100"
          style={{
            maxWidth: "135px",
          }}
          src={skeletonArcherSrc}
          alt="greater skeleton archer"
        />
      ),
      monsterTitle: "Skeleton Archer",
      containerClassName: "containerGreater",
    }
  } else if (difficulty >= 4) {
    return {
      src: skeletonMageSrc,
      imgEl: (
        <img
          className="d-block w-100"
          style={{
            maxWidth: "135px",
          }}
          src={skeletonMageSrc}
          alt="major skeleton mage"
        />
      ),
      monsterTitle: "Skeleton Mage",
      containerClassName: "containerMajor",
    }
  }

  return {
    src: skeletonFighterSrc,
    imgEl: (
      <img
        className="d-block w-100"
        style={{
          maxWidth: "135px",
        }}
        src={skeletonFighterSrc}
        alt="minor skeleton soldier"
      />
    ),
    monsterTitle: "Skeleton Soldier",
    containerClassName: "containerMinor",
  }
}
