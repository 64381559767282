// extracted by mini-css-extract-plugin
export var cls1 = "slw-module--cls-1--70MTO";
export var cls10 = "slw-module--cls-10--IVHI8";
export var cls11 = "slw-module--cls-11--cQetH";
export var cls12 = "slw-module--cls-12--uflTl";
export var cls13 = "slw-module--cls-13--s19Ae";
export var cls14 = "slw-module--cls-14---m4nA";
export var cls15 = "slw-module--cls-15--rUW+M";
export var cls16 = "slw-module--cls-16--PrTvd";
export var cls17 = "slw-module--cls-17--nY4sE";
export var cls18 = "slw-module--cls-18--ca95e";
export var cls19 = "slw-module--cls-19--Osm8P";
export var cls2 = "slw-module--cls-2--6QPXh";
export var cls20 = "slw-module--cls-20--5NseL";
export var cls21 = "slw-module--cls-21--qppJ+";
export var cls22 = "slw-module--cls-22--y4mhy";
export var cls23 = "slw-module--cls-23--NpSfC";
export var cls24 = "slw-module--cls-24--8XiPZ";
export var cls25 = "slw-module--cls-25--UUL4i";
export var cls26 = "slw-module--cls-26--0uTzH";
export var cls27 = "slw-module--cls-27--ZBfpE";
export var cls28 = "slw-module--cls-28--lM4Er";
export var cls3 = "slw-module--cls-3--TPoqP";
export var cls5 = "slw-module--cls-5--i0dHd";
export var cls6 = "slw-module--cls-6--FXalX";
export var cls7 = "slw-module--cls-7--vGvlV";
export var cls8 = "slw-module--cls-8--8-Gu5";
export var cls9 = "slw-module--cls-9--4bvnc";