import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"
import { DriverAction } from "@components/Helmets/DriverHelmet"

import {
  mainQuestion,
  mainQuestionMarkValue,
} from "@views/english-demo-1/common/constants"
import {
  DetailedBossMonster,
  SkeletonKing,
} from "@views/english-demo-1/common/minion"
import { ProgressBar } from "@views/english-demo-1/common/progress-bar"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { SceneProps } from "@views/english-demo-1/types"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import { HERO_CLASS_SCOUT } from "@helpers/constants/hero-class"
import { getBreakpoint } from "@helpers/getBreakpoint"

import { steps } from "./constants"

export const Action1B = ({
  previousScene,
  nextScene,
  isActiveScene,
}: SceneProps) => {
  const avatar = useEnglishDemoAvatar({
    heroClass: HERO_CLASS_SCOUT,
  })

  useScrollTop(isActiveScene)
  const isDesktop = getBreakpoint() === "md"
  return (
    <div>
      {isDesktop && <DriverAction steps={steps} shouldShow={isActiveScene} />}
      <ProgressBar index={1} />
      <SpacedWrapper>
        <>
          <Button onClick={previousScene}>Back</Button>
          <GoogleFormLinkGeneralFeedback />
        </>
      </SpacedWrapper>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <div id="action-1b-3" className={avatarContainer}>
              {avatar}
            </div>
          </Col>
          <Col xs={12} md={6}>
            <DetailedBossMonster
              questionId="action-1b-1"
              containerId="action-1b-2"
              marks={mainQuestionMarkValue}
              question={mainQuestion}
              bossMonster={<SkeletonKing />}
              bossMonsterTitle="Skeleton King"
            />
          </Col>
        </Row>
      </Container>

      <Button className="my-4" onClick={nextScene}>
        Send in the Tank
      </Button>
    </div>
  )
}
