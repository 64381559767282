import { classnames } from "classnames-joiner"
import React from "react"

import { CenteredWrapper } from "@components/Containers/positioning-wrappers"
import { demonCases } from "@components/Monster/demonCases"
import { goblinCases } from "@components/Monster/goblinCases"
import { orcCases } from "@components/Monster/orcCases"
import { skeletonCases } from "@components/Monster/skeletonCases"
import { vampireCases } from "@components/Monster/vampireCases"
import { MonsterPropsType } from "@components/types"

import { DifficultyType } from "@views/multiple-choice-problem/types"

import { MonsterClassType } from "@helpers/constants/monster-class"
import { P_2 } from "@helpers/constants/style"

import * as styles from "./m.module.scss"

interface Props {
  className?: string
  difficulty: DifficultyType
  dungeonMonster: MonsterClassType
  containerClassName?: string
}

function getMonsterProps(
  dungeonMonster: MonsterClassType,
  difficulty: DifficultyType
): MonsterPropsType {
  switch (dungeonMonster) {
    case "skeleton":
    default:
      return skeletonCases(difficulty)

    case "orc":
      return orcCases(difficulty)

    case "goblin":
      return goblinCases(difficulty)

    case "vampire":
      return vampireCases(difficulty)

    case "demon":
      return demonCases(difficulty)
  }
}

export const createMonster = ({
  className,
  difficulty,
  dungeonMonster,
  containerClassName,
}: Props) => {
  const { src, imgEl, monsterTitle, ...remainingMonsterProps } =
    getMonsterProps(dungeonMonster, difficulty)

  // Allow the containerClassName to be set as a prop to accomodate multiple monsters in extended answer type questions
  if (!containerClassName) {
    containerClassName = remainingMonsterProps.containerClassName
  }

  return {
    src,
    monsterTitle,
    monster: (
      <CenteredWrapper
        className={classnames([styles[containerClassName], P_2, className])}
      >
        {imgEl}
      </CenteredWrapper>
    ),
  }
}
