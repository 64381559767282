import { useEffect, useState } from "react"

import { MetaStateType } from "@components/types"

import { getFromCacheOrRemote } from "@helpers/cache"

interface Props {
  key: string
  remoteThunk: () => Promise<any>
  extraDeps?: (string | boolean | undefined)[]
}

export const useMetaState = ({
  key,
  remoteThunk,
  extraDeps,
}: Props): MetaStateType => {
  const [metaState, setMetaState] = useState({
    data: undefined,
    hasErrored: false,
    isFetching: true,
  })
  // Starting isFetching as true will always show the loader for 1 frame, but avoids having to consider the state when isFetching is false, but the data is also not ready.

  const deps = Array.isArray(extraDeps) ? [key, ...extraDeps] : [key]

  useEffect(() => {
    const init = async () => {
      if (key === "") {
        return
      }

      try {
        let data

        const dataFromCacheOrRemote = await getFromCacheOrRemote({
          key,
          remoteThunk,
        })

        // For some reason, Gatsby returns {} when visiting a static URL that doesn't exist
        // E.g. http://localhost:9000/data/physics-problems/somemadeupvalue.json
        // Deciding to not class this as an error but instead as an invalid reason to set the data.
        if (typeof dataFromCacheOrRemote === "object") {
          // Objects need more validation
          if (
            dataFromCacheOrRemote !== null &&
            Object.keys(dataFromCacheOrRemote).length !== 0
          ) {
            data = dataFromCacheOrRemote
          }
        } else {
          // We allows other data types to directly set from cache / remote
          data = dataFromCacheOrRemote
        }

        setMetaState({
          data,
          hasErrored: false,
          isFetching: false,
        })
      } catch (e) {
        console.log(e)
        setMetaState({
          data: undefined,
          hasErrored: true,
          isFetching: false,
        })
      }
    }

    init()
  }, deps)

  return metaState
}
