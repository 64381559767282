import React from "react"
import Button from "react-bootstrap/Button"
import { useDispatch } from "react-redux"

import { setUIActionCreator } from "@redux/ducks/ui"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"

import { MB_3 } from "@helpers/constants/style"

export const ProblemHasBeenAnswered = () => {
  const dispatch = useDispatch()
  return (
    <SpacedWrapper className={MB_3}>
      <>
        <strong>Question has been answered.</strong>
        <Button
          variant="success"
          onClick={() => {
            dispatch(
              setUIActionCreator({
                solutionShow: true,
                optionsAreLocked: true,
              })
            )
          }}
        >
          Show Solution
        </Button>
      </>
    </SpacedWrapper>
  )
}
