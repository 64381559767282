import {
  M5,
  M6,
  M7,
  M8,
  ModuleCodeType,
} from "@views/multiple-choice-problem/types"

import {
  MonsterClassType,
  MONSTER_CLASS_GOBLIN,
  MONSTER_CLASS_ORC,
  MONSTER_CLASS_VAMPIRE,
  MONSTER_CLASS_DEMON,
  MONSTER_CLASS_SKELETON,
} from "@helpers/constants/monster-class"

export const getMonsterTypeByPhysicsModule = (
  moduleCode: ModuleCodeType
): MonsterClassType => {
  switch (moduleCode) {
    case M5:
      return MONSTER_CLASS_GOBLIN
    case M6:
      return MONSTER_CLASS_ORC
    case M7:
      return MONSTER_CLASS_VAMPIRE
    case M8:
      return MONSTER_CLASS_DEMON
    default:
      return MONSTER_CLASS_SKELETON
  }
}
