import React from "react"

import { Svg } from "@components/Svg"

// https://www.svgrepo.com/svg/162137/plain-triangle
export const Triangle = ({
  className,
  fill,
}: {
  className?: string
  fill?: string
}) => {
  return (
    <Svg className={className} viewBox="0 0 480.554 480.553" y="0px" x="0px">
      <g>
        <path d="M251.093,37.38c-2.235-3.859-6.357-6.235-10.816-6.235s-8.582,2.376-10.816,6.235L1.684,430.645   c-2.24,3.867-2.245,8.636-0.013,12.507s6.361,6.257,10.83,6.257h455.553c4.469,0,8.598-2.386,10.83-6.257   c2.231-3.871,2.227-8.641-0.014-12.507L251.093,37.38z M34.186,424.409L240.276,68.585l206.091,355.824H34.186L34.186,424.409z" />
      </g>
      <path d="M 35.766176,422.15267 C 36.394697,421.09503 82.611018,341.2906 138.46911,244.80949 194.32721,148.32838 240.14075,69.388526 240.27699,69.387581 c 0.13624,-9.45e-4 46.28419,79.496309 102.55101,176.660569 56.26681,97.16427 102.48157,176.96947 102.69947,177.3449 0.33628,0.57939 -30.69645,0.6826 -205.25394,0.6826 H 34.623411 Z" />
      <path
        className={fill}
        d="M 73.930345,358.06153 C 139.63276,244.62684 179.67798,175.50855 207.32751,127.81719 c 14.77039,-25.47674 28.17072,-48.714367 29.77852,-51.639173 1.60779,-2.924806 3.03472,-5.317828 3.17096,-5.317828 0.13624,0 1.56331,2.393022 3.17126,5.317828 1.60796,2.924806 12.10868,21.146353 23.33495,40.492323 29.10891,50.16272 88.02741,151.83782 135.93353,234.57923 l 41.16135,71.09212 -3.33672,0.31003 c -1.83519,0.17051 -93.5474,0.33771 -203.80491,0.37156 l -200.468187,0.0615 z"
      />
    </Svg>
  )
}
