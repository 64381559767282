import { selectUserSynced } from "@redux/ducks/user-synced"
import { selectUsername } from "@redux/selectors"
import { DispatchType, GetStateType } from "@redux/types"

import { PHYSICS_USER_SYNCED } from "@views/physics/common/constants"

import {
  META_STATUS_LOADING,
  META_STATUS_ERROR,
  META_STATUS_SUCCESS,
} from "@helpers/hooks/use-meta-status/constants"
import { getUserRemoteData, saveProgress } from "@helpers/user-data"
import { getPhysicsUserTableItemId } from "@helpers/user-data/get-user-table-item-id"

import { setUserSyncedActionCreator } from "./index"

export const savePhysicsUserSyncedThunkCreator =
  () => async (_: DispatchType, getState: GetStateType) => {
    const rootState = getState()
    const userSynced = selectUserSynced(rootState)

    // Save to remote only for logged in users
    const username = selectUsername(rootState)
    if (!username) {
      return
    }

    const remoteId = getPhysicsUserTableItemId(username)

    await saveProgress({
      remoteId,
      remoteAttribute: PHYSICS_USER_SYNCED.remoteAttribute,
      value: userSynced,
    })
  }

export const getPhysicsUserSyncedThunkCreator =
  (username: string) => async (dispatch: DispatchType, _: GetStateType) => {
    const remoteId = getPhysicsUserTableItemId(username)

    dispatch(
      setUserSyncedActionCreator({ metaStatusValue: META_STATUS_LOADING })
    )

    const { value: userSyncedDataFromRemote, error } = await getUserRemoteData({
      remoteId,
      remoteAttribute: PHYSICS_USER_SYNCED.remoteAttribute,
    })

    console.log("getPhysicsUserSyncedThunkCreator", {
      userSyncedDataFromRemote,
      error,
    })

    if (error === undefined) {
      dispatch(
        setUserSyncedActionCreator({
          ...userSyncedDataFromRemote,
          metaStatusValue: META_STATUS_SUCCESS,
        })
      )
    } else {
      dispatch(
        setUserSyncedActionCreator({ metaStatusValue: META_STATUS_ERROR })
      )
    }
  }
