import React from "react"
import { useSelector } from "react-redux"

import {
  selectProblem,
  selectProblemHasAlreadyBeenAnswered,
  selectShowSolution,
} from "@redux/selectors"

import { ANIMATION_FADEIN } from "@helpers/constants/style"

import { Options } from "./options"
import { containerStyle } from "./p.module.scss"
import { ProblemHasBeenAnswered } from "./problem-has-been-answered"
import { Question } from "./question"
import { Solution } from "./solution"
import { ProblemProps } from "./types"

export const Problem = ({
  onAnsweredCorrectly,
  belowSolutionElement,
}: ProblemProps) => {
  const problem = useSelector(selectProblem)
  const showSolution = useSelector(selectShowSolution)
  const problemHasAlreadyBeenAnswered = useSelector(
    selectProblemHasAlreadyBeenAnswered
  )

  // Only handle new multiple choice problems from now
  if (!problem || problem.qType !== "multiple-choice") {
    return null
  }

  return (
    <article className={containerStyle}>
      {problemHasAlreadyBeenAnswered && <ProblemHasBeenAnswered />}
      <Question content={problem.question} />
      <Options
        options={problem.options}
        onAnsweredCorrectly={onAnsweredCorrectly}
        answer={problem.answer}
      />
      {showSolution && (
        <Solution
          className={ANIMATION_FADEIN}
          label={problem.answer}
          solution={problem.solution}
          belowSolutionElement={belowSolutionElement}
        />
      )}
    </article>
  )
}
