import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { NoArgVoid } from "@components/types"

import { AvatarsContext } from "@views/english-demo-1/utils/context"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import {
  HERO_CLASS_SCOUT,
  HERO_CLASS_TANK,
  HERO_CLASS_EMPATH,
  HERO_CLASS_MAKER,
} from "@helpers/constants/hero-class"

interface CharacterIntroScene {
  intro: React.ReactNode
  description: React.ReactNode
  avatarType:
    | typeof HERO_CLASS_SCOUT
    | typeof HERO_CLASS_TANK
    | typeof HERO_CLASS_EMPATH
    | typeof HERO_CLASS_MAKER
  primaryButtonOnClick: NoArgVoid
  primaryButtonText: string
}

export const CharacterIntroScene = ({
  intro,
  description,
  avatarType,
  primaryButtonOnClick,
  primaryButtonText,
}: CharacterIntroScene) => {
  const context = React.useContext(AvatarsContext)

  const avatar = useEnglishDemoAvatar({
    heroClass: avatarType,
  })

  return (
    <div>
      {intro}
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            {avatar}
          </Col>
          <Col xs={12} md={6}>
            {description}
          </Col>
        </Row>
      </Container>

      <div className="d-flex align-items-center justify-content-center mt-7 mb-4">
        <Button
          variant="success"
          className="mr-4"
          onClick={context.avatarToggles[avatarType]}
        >
          👱‍♀️ 👨‍🦱 Personalise
        </Button>
        <Button onClick={primaryButtonOnClick}>{primaryButtonText}</Button>
      </div>
    </div>
  )
}
