import { PartyPopperEmoji } from "accessible-emojis"
import { classnames } from "classnames-joiner"
import React from "react"

import { FONT_WEIGHT_NORMAL } from "@helpers/constants/style"
import { jsxJSONArrayProcessor } from "@helpers/jsxJSONProcessor"
import { JSXJSONType } from "@helpers/jsxJSONProcessor/types"

type PropsType = {
  className?: string
  label: string
  solution?: JSXJSONType[]
  belowSolutionElement?: React.ReactNode
}

export const Solution = ({
  className,
  label,
  solution,
  belowSolutionElement,
}: PropsType) => {
  return (
    <div className={classnames([className, "rounded border px-4 py-3"])}>
      <h2 className="mb-0">
        {label}: <span className="text-success">Correct answer!</span>{" "}
        <PartyPopperEmoji className={FONT_WEIGHT_NORMAL} />
      </h2>

      {solution && (
        <>
          <hr />
          <h5 className="mb-1">
            <u>Solution</u>
          </h5>
          <article className="text-primary font-weight-500">
            {jsxJSONArrayProcessor(solution)}
          </article>
          <hr />
        </>
      )}

      {belowSolutionElement}
    </div>
  )
}
