import React from "react"
import Button from "react-bootstrap/Button"

import { EndWrapper } from "@components/Containers/positioning-wrappers"

import { HasAnsweredViewProps } from "./types"

export const HasAnsweredView = ({
  value,
  nextScene,
  onEdit,
}: HasAnsweredViewProps) => {
  return (
    <section>
      <p className="mt-5">{value}</p>
      <EndWrapper className="mb-4">
        <>
          <Button variant="success" className="mx-2" onClick={onEdit}>
            <span className="px-2">Edit</span>
          </Button>
          <Button className="mx-2" onClick={nextScene}>
            Next
          </Button>
        </>
      </EndWrapper>
    </section>
  )
}
