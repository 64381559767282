import React from "react"

import { Img } from "@components/Img"
import { MonsterPropsType } from "@components/types"

import { DifficultyType } from "@views/multiple-choice-problem/types"

import goblinWarrior from "@images/monsters/goblins/goblic-warrior.png"
import goblinAxeman from "@images/monsters/goblins/goblin-axeman.png"
import goblinBomber from "@images/monsters/goblins/goblin-bomber.png"
import goblinJungler from "@images/monsters/goblins/goblin-jungler.png"
import goblinShaman from "@images/monsters/goblins/goblin-shaman.png"

import { goblin } from "./m.module.scss"

const commonImgProps = {
  className: "d-block " + goblin,
}

export function goblinCases(difficulty: DifficultyType): MonsterPropsType {
  if (difficulty === 2) {
    return {
      src: goblinBomber,
      imgEl: <Img {...commonImgProps} src={goblinBomber} alt="goblin bomber" />,
      monsterTitle: "Goblin Bomber",
      containerClassName: "containerGreater",
    }
  } else if (difficulty === 3) {
    return {
      src: goblinAxeman,
      imgEl: <Img {...commonImgProps} src={goblinAxeman} alt="goblin axeman" />,
      monsterTitle: "Goblin Axeman",
      containerClassName: "containerGreater",
    }
  } else if (difficulty === 4) {
    return {
      src: goblinWarrior,
      imgEl: (
        <Img {...commonImgProps} src={goblinWarrior} alt="goblin warrior" />
      ),
      monsterTitle: "Goblin Warrior",
      containerClassName: "containerMajor",
    }
  } else if (difficulty >= 5) {
    return {
      src: goblinShaman,
      imgEl: <Img {...commonImgProps} src={goblinShaman} alt="goblin shaman" />,
      monsterTitle: "Goblin Shaman",
      containerClassName: "containerMajor",
    }
  }

  return {
    src: goblinJungler,
    imgEl: <Img {...commonImgProps} src={goblinJungler} alt="goblin jungler" />,
    monsterTitle: "Goblin Jungler",
    containerClassName: "containerMinor",
  }
}
