import { Router } from "@reach/router"
import React from "react"

// import { ConfiguredProvider } from "@redux/index"
import { SignedInView } from "@components/signed-in-route"

// import { AvatarJig } from "@views/AvatarJig"
import { SignOutView } from "@views/SignOut"
import { CheckoutView } from "@views/checkout"
import { EnglishDemo1View } from "@views/english-demo-1"
import { OnboardingView } from "@views/onboarding"
import { PhysicsIntroView } from "@views/physics/intro"
import { PhysicsPracticeListView } from "@views/physics/practice-list-view"
import { PhysicsPracticeProblemView } from "@views/physics/practice-problem"
import { ShopView } from "@views/shop"
import { SubjectSelectionView } from "@views/subject-selection"

import {
  LINK_APP,
  LINK_PARTIAL_PHYSICS,
  LINK_PARTIAL_PHYSICS_LIST,
  LINK_PARTIAL_PHYSICS_PROBLEM,
  LINK_PARTIAL_SIGN_OUT,
  LINK_PARTIAL_ENGLISH,
  LINK_PARTIAL_SUBJECT_SELECTION,
  LINK_PARTIAL_CHECKOUT,
  LINK_PARTIAL_SHOP,
} from "@helpers/constants/links"

// import { getIsLocalHost } from "@helpers/misc"

const App = () => {
  return (
    <Router basepath={LINK_APP}>
      <OnboardingView path={"/"} />
      <PhysicsIntroView path={LINK_PARTIAL_PHYSICS} />
      <PhysicsPracticeListView path={LINK_PARTIAL_PHYSICS_LIST} />
      <PhysicsPracticeProblemView path={LINK_PARTIAL_PHYSICS_PROBLEM} />
      <EnglishDemo1View path={LINK_PARTIAL_ENGLISH} />
      {/* {getIsLocalHost() && <AvatarJig path="avatarJig" />} */}
      <ShopView path={LINK_PARTIAL_SHOP} />
      <SubjectSelectionView path={LINK_PARTIAL_SUBJECT_SELECTION} />
      <SignOutView path={LINK_PARTIAL_SIGN_OUT} />
      <SignedInView
        path={LINK_PARTIAL_CHECKOUT}
        onRenderElement={({ username }) => <CheckoutView username={username} />}
      />
    </Router>
  )
}

const AppWithProvider = () => {
  console.log("AppWithProvider")

  return (
    // <ConfiguredProvider>
    <App />
    // </ConfiguredProvider>
  )
}

export default AppWithProvider
