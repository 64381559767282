import React from "react"

import { ContainerRowCol } from "@components/Containers/positioning-wrappers"
import { CenteredLoader } from "@components/Loader"
import { ErrorMessage } from "@components/error-message"

import { ModuleCodeType } from "@views/multiple-choice-problem/types"
import {
  PROBLEMS_PER_PAGE,
  QUERY_PARAM_KEY_PAGE,
} from "@views/physics/common/constants"

import { chunkList } from "@helpers/chunk-list"
import { BREAKPOINT_MD_KEY } from "@helpers/constants/breakpoints"
import { getBreakpoint } from "@helpers/getBreakpoint"
import { useQueryString } from "@helpers/hooks/use-query-string"

import { usePhysicsListMetaState } from "./hooks"
import { PhysicsPagination } from "./physics-pagination"
import { PracticeListTitles } from "./practice-list-titles"
import { PracticeRowContainer } from "./practice-row-container"
import { getActivePage } from "./utils"

const renderProblemList = (problemKey: string) => {
  return <PracticeRowContainer key={problemKey} problemKey={problemKey} />
}

export const PhysicsPracticeListContainer = ({
  moduleCode,
}: {
  moduleCode: ModuleCodeType
}) => {
  const {
    data: physicsList,
    isFetching,
    hasErrored,
  } = usePhysicsListMetaState(moduleCode)

  if (isFetching || physicsList === undefined) {
    return (
      <ContainerRowCol>
        <CenteredLoader />
      </ContainerRowCol>
    )
  } else if (hasErrored) {
    return (
      <ContainerRowCol>
        <ErrorMessage />
      </ContainerRowCol>
    )
  }

  return <PhysicsPracticeList physicsList={physicsList} />
}

const PhysicsPracticeList = ({ physicsList }: { physicsList: string[] }) => {
  const { paramValue, onSetParamValue } = useQueryString(QUERY_PARAM_KEY_PAGE)

  const pageParamValueAsInt =
    typeof paramValue === "string" ? parseInt(paramValue) : NaN

  // Process pagination
  const physicsPageList = chunkList(physicsList, PROBLEMS_PER_PAGE)

  const { activePage, activeProblemList } = getActivePage(
    pageParamValueAsInt,
    physicsPageList
  )

  const pagination = (
    <ContainerRowCol>
      <PhysicsPagination
        physicsPageList={physicsPageList}
        activePage={activePage}
        onSetParamValue={onSetParamValue}
      />
    </ContainerRowCol>
  )

  return (
    <>
      {pagination}
      {getBreakpoint() === BREAKPOINT_MD_KEY && <PracticeListTitles />}
      {activeProblemList.map(renderProblemList)}
      {pagination}
    </>
  )
}
