import React from "react"

import { InnerSvg } from "@components/Avatar/common/avatar-item/inner-image"
import { MultiPartHairAsset } from "@components/Avatar/types"

import {
  cls1,
  cls2,
  cls3,
  cls5,
  cls6,
  cls7,
  cls8,
  cls9,
  cls10,
  cls11,
  cls12,
  cls13,
  cls14,
  cls15,
  cls16,
  cls17,
  cls18,
} from "./pfp1.module.scss"

const pathDef1 =
  "M10.61,50c1.13-6-.63-6.62,0-13.25.67-7.12,1.87-12.37,7.5-17.5C24,13.91,31.23,16.4,31.23,16.4,33.6,4.88,49.42,2.64,61.11,5.27,78.89,9.27,94.23,24.9,96.73,40l-2.31,5.23a18.64,18.64,0,0,1,1,8.11l-7,15.22S86.12,44,78,31.72l-.66-.86c-4.7-5.41-19.51-8.85-29.89-4-4.27-.14-10.37.12-12.87,6.87C33.1,37.85,32,43.66,28.3,48.57c-4.44,5.83-8.44,4.7-8.44,4.7-1.32,2.75-1.22,3.6-3.13,5.88C11,66,9.23,57.27,10.61,50Z"

const pathDef2 =
  "M12,41a41.56,41.56,0,0,1-1,12.43c-.14.52-.28,1-.45,1.56s-.36,1.06-.52,1.46a25.38,25.38,0,0,0-.84,2.69,28.81,28.81,0,0,0-.87,5.57,16.87,16.87,0,0,0,0,2.67,6.62,6.62,0,0,0,.22,1.24,11.62,11.62,0,0,0,.33,1.29,22.59,22.59,0,0,0,2.22,5,61.55,61.55,0,0,1,3,5.51A21.39,21.39,0,0,1,15.9,87l-4-1.31a11.22,11.22,0,0,0,.87-1,12.34,12.34,0,0,0,.83-1.21,13.89,13.89,0,0,0,1.24-2.65,11.36,11.36,0,0,0,.38-5.8c0-.25-.12-.49-.17-.74l-.23-.85c-.15-.57-.28-1.15-.41-1.74A22.46,22.46,0,0,1,14,68.08a16,16,0,0,1,1.33-6.93,27.92,27.92,0,0,0,0,3.42,18.52,18.52,0,0,0,.53,3.25,24.77,24.77,0,0,0,1.06,3.11c.21.51.41,1,.65,1.52l.34.76c.12.3.26.58.37.89a13.54,13.54,0,0,1,.52,7.73,15.09,15.09,0,0,1-1.31,3.7,16.89,16.89,0,0,1-1,1.7,12.78,12.78,0,0,1-1.26,1.66l-3.1,3.47-.86-4.77A19,19,0,0,0,9.64,82.5c-.76-1.65-1.84-3.28-2.87-5.14a24,24,0,0,1-2.54-6.17,13.77,13.77,0,0,1-.35-1.67,16.18,16.18,0,0,1-.21-1.77,21.23,21.23,0,0,1,.12-3.41A30.28,30.28,0,0,1,5.24,58a26.33,26.33,0,0,1,1.23-3c.25-.52.46-.92.68-1.33s.42-.85.63-1.28C9.4,48.8,10.63,44.92,12,41Z"

const pathDef3 =
  "M12,41s-1,8.74-3.5,14.13C6.36,59.77,5.61,65.4,6.11,68.52c1.26,7.9,6.37,10.63,7.5,18.75,0,0,5.75-5.87,2.87-13.62-2.23-6-1.87-9.13-1.12-12.5"

const pathDef4 =
  "M18,35a33.45,33.45,0,0,1,1.12,8.34,23.62,23.62,0,0,1-.36,4.32A20.31,20.31,0,0,1,17.44,52l-1.78,3.77c-.56,1.22-1.1,2.42-1.58,3.61a23.94,23.94,0,0,0-1.14,3.55,11.74,11.74,0,0,0-.31,1.72,7.75,7.75,0,0,0-.06,1.56c.14,1.89,1.3,4.21,2.62,6.33a59.2,59.2,0,0,0,4.69,6.28l1.3,1.51c.44.52,1,1.15,1.41,1.74A27.14,27.14,0,0,1,25,85.83a37.87,37.87,0,0,1,1.81,4,32.35,32.35,0,0,1,1.35,4.27l-4.65-.8.23-.45c.08-.19.17-.39.24-.59A12.9,12.9,0,0,0,24.38,91a14.4,14.4,0,0,0,.48-2.62,15.39,15.39,0,0,0-.48-5.31A20.17,20.17,0,0,0,22.2,78,60,60,0,0,1,19,72.36a15.26,15.26,0,0,1-1.08-3.61,13.62,13.62,0,0,1,0-3.77c.07-.61.19-1.2.31-1.79s.3-1.18.46-1.67c.32-1,.66-2,1.07-3.05a16.83,16.83,0,0,1,3.56-5.78,29.5,29.5,0,0,1,5-4.12c-.88,2-2,3.84-2.82,5.66a22.82,22.82,0,0,0-1.84,5.48l-.65,3c-.12.52-.17.92-.27,1.38s-.1.89-.12,1.32a11.23,11.23,0,0,0,.2,2.48,11.38,11.38,0,0,0,.85,2.34c.78,1.57,2,3.21,3.13,5.14a23.38,23.38,0,0,1,2.72,6.44A20.83,20.83,0,0,1,30,88.92a20.26,20.26,0,0,1-.71,3.53c-.17.58-.37,1.15-.6,1.72-.12.29-.24.57-.38.86s-.26.52-.5.94l-3.26,5.47-1.39-6.28a28.73,28.73,0,0,0-1.07-3.54,26.71,26.71,0,0,0-1.55-3.45,23.33,23.33,0,0,0-2-3.13c-.36-.51-.73-.91-1.17-1.43L16,82a64.6,64.6,0,0,1-5.13-6.82,26.5,26.5,0,0,1-2.12-3.95,14.17,14.17,0,0,1-1.22-4.79,20.39,20.39,0,0,1,2.23-9c1.18-2.62,2.75-5,3.94-7.27C16,45.91,16.53,40.46,18,35Z"

const pathDef5 =
  "M18,35c0,8.88-.76,12.92-2.77,16.82s-5,9.07-5.16,13.85,4.78,11.2,9.44,16.49a30.88,30.88,0,0,1,6.17,12.46S30.11,87.4,25.23,78C22.6,73,18.72,69.89,20.73,62.4c1.75-6.52,2.28-7.66,7.57-13.83"

const pathDef6 =
  "M90.86,42.4A31,31,0,0,1,93.69,45c.9.91,1.71,1.86,2.51,2.83a63.91,63.91,0,0,1,4.39,6l-4.54.92c.17-1.59.29-3.35.32-5s0-3.39-.09-5.06a26.24,26.24,0,0,0-2.08-9.45c-.68-1.5-1.5-3-2.37-4.5s-1.79-3-2.74-4.46c-1.88-3-3.89-5.89-5.86-8.91a62.38,62.38,0,0,1,8,7.2c1.25,1.31,2.45,2.67,3.58,4.11A38,38,0,0,1,98,33.3a19.66,19.66,0,0,1,1.31,2.64,24.3,24.3,0,0,1,.9,2.78,33.29,33.29,0,0,1,1,5.61,50.25,50.25,0,0,1-.28,11.16l-1,7.33-3.55-6.41a64,64,0,0,1-3.24-6.72c-.47-1.16-.92-2.33-1.3-3.55A29.7,29.7,0,0,1,90.86,42.4Z"

const pathDef7 =
  "M90.86,42.4l7.62,12.75s1.73-12.42-2-20.13S83.23,17.4,83.23,17.4"

const pathDef8 =
  "M87.86,47.77a29.21,29.21,0,0,1,2,4.31c.54,1.46,1.11,2.92,1.6,4.4,1,3,1.89,5.92,2.73,8.9.42,1.49.82,3,1.21,4.47l.58,2.2.14.51.06.18h0a.48.48,0,0,0,0-.14,3.92,3.92,0,0,0-.23-.41,2.51,2.51,0,0,0-.42-.44l-.07-.06h0c.09.05,0,0-.11,0a.9.9,0,0,0-.34,0,.82.82,0,0,0,.23-.22A6.35,6.35,0,0,0,96,70.14a20.09,20.09,0,0,0,1.29-3.86,39.74,39.74,0,0,0,1-8.61,39.09,39.09,0,0,0-1.14-8.73,78.74,78.74,0,0,0-6.65-17A52.17,52.17,0,0,1,100.29,48a37.28,37.28,0,0,1,2.19,9.48,34,34,0,0,1-.44,9.85,21.4,21.4,0,0,1-1.55,5,10.34,10.34,0,0,1-1.62,2.56,6.17,6.17,0,0,1-1.55,1.25,4.39,4.39,0,0,1-2.57.54,4.56,4.56,0,0,1-2.23-.9l-.11-.08-.12-.1a2.47,2.47,0,0,1-.44-.47,3.17,3.17,0,0,1-.25-.44c0-.09-.08-.21-.11-.27l0-.14-.1-.37-.14-.62c-.17-.79-.34-1.55-.48-2.32-.29-1.53-.57-3.05-.81-4.59-.49-3.06-.92-6.13-1.25-9.22-.18-1.54-.29-3.1-.44-4.65l-.17-2.34C88,49.33,88,48.53,87.86,47.77Z"

const pathDef9 =
  "M87.86,47.77c.37,0,5.69,25.64,6,25.88,3.75,2.87,7-7,6.5-16.38C99.86,48.77,96,40,90.48,31.9"

const pathDef10 =
  "M83.48,50c1.25,3.82,2.37,7.67,3.25,11.6A67.45,67.45,0,0,1,88.41,73.7,68.77,68.77,0,0,1,87.75,86a110.88,110.88,0,0,1-2.42,12l-3.86-2.54a51.79,51.79,0,0,0,9.59-10.55,18,18,0,0,0,2.69-6A19.79,19.79,0,0,0,94,75.48c0-1.18,0-2.39-.07-3.6A92.38,92.38,0,0,0,91.8,57.27c-.57-2.42-1.13-4.86-1.87-7.25-.35-1.2-.72-2.4-1.15-3.57A18.51,18.51,0,0,0,87.23,43a25.81,25.81,0,0,1,4.2,6.41,69.6,69.6,0,0,1,3,7.06,71.43,71.43,0,0,1,3.81,15c.15,1.3.28,2.61.33,4a24.43,24.43,0,0,1-.18,4.2,22,22,0,0,1-3.16,7.84,42.59,42.59,0,0,1-4.93,6.3,52.55,52.55,0,0,1-5.82,5.4l-5.84,4.63,2-7.17c1-3.79,2-7.58,2.8-11.4a77.66,77.66,0,0,0,1.49-11.55,85.63,85.63,0,0,0-.26-11.75C84.34,58,83.87,54,83.48,50Z"

export const PartedFrontPonytailHairAsset = ({
  sizing,
  factor,
  hairColour,
}: MultiPartHairAsset) => {
  return (
    <InnerSvg sizing={sizing} factor={factor} viewBox="0 0 105.36 103.81">
      <defs>
        <mask
          id="mask-pfp1"
          x="0"
          y="0"
          width="105.36"
          height="103.81"
          maskUnits="userSpaceOnUse"
        >
          <path className={cls1} d={pathDef1} />
          <path className={cls2} d={pathDef1} />
          <path d={pathDef2} />
          <path className={cls2} d={pathDef3} />
          <path d={pathDef4} />
          <path className={cls2} d={pathDef5} />
          <path d={pathDef6} />
          <path className={cls2} d={pathDef7} />
          <path d={pathDef8} />
          <path className={cls2} d={pathDef9} />
          <path d={pathDef10} />
          <path
            className={cls2}
            d="M83.48,50c3.68,17.56,5,26.88-.5,47.25,0,0,12.58-10,13.25-19.25.88-12-5.75-31.25-9-35"
          />
          <path d="M44.74,23.19a35.28,35.28,0,0,1,3.2-6.12A19.43,19.43,0,0,1,52.65,12a10.75,10.75,0,0,1,6.5-2.15,14.13,14.13,0,0,1,6.46,1.92,14.06,14.06,0,0,0-6.44-1.42,10,10,0,0,0-5.91,2.44,18.12,18.12,0,0,0-4,5.07,32.63,32.63,0,0,0-2.59,6Z" />
          <path d="M40.65,20.8a27.78,27.78,0,0,1,.92-3.43,25.69,25.69,0,0,1,1.31-3.23,14.7,14.7,0,0,1,1.85-2.94A8.19,8.19,0,0,1,47.36,9a7.72,7.72,0,0,0-2,2.59,13.64,13.64,0,0,0-1.07,3.05,21.64,21.64,0,0,0-.52,3.19A26.51,26.51,0,0,0,43.64,21Z" />
          <path d="M36.77,18.08a19.73,19.73,0,0,1,.64-1.88c.24-.62.5-1.22.79-1.8a9.4,9.4,0,0,1,2.28-3.13,9.11,9.11,0,0,0-1.34,3.46,18.33,18.33,0,0,0-.27,1.83,16.17,16.17,0,0,0-.12,1.81Z" />
          <path d="M43.39,23.16a44.56,44.56,0,0,0-5.9-.47,22.56,22.56,0,0,0-3,.12,10.42,10.42,0,0,0-2.91.66,10.46,10.46,0,0,1,2.81-1.15,23.72,23.72,0,0,1,3-.63,47.66,47.66,0,0,1,6.12-.53Z" />
          <path d="M34.61,17.81a12,12,0,0,0-5.63,0,12.53,12.53,0,0,1,5.77-.95Z" />
          <path d="M70.18,21.49c2.12,3.81,4.54,7.34,6.91,11a58.5,58.5,0,0,1,6.3,11.77,57.59,57.59,0,0,1,2.76,26.57l-4.41-1.69a27,27,0,0,0,3.57-7.46,50.19,50.19,0,0,0,1.88-8.51,54.24,54.24,0,0,0,.51-8.84,39,39,0,0,0-1.19-8.92,27.47,27.47,0,0,1,3.28,8.67,43,43,0,0,1,.9,9.34,42.15,42.15,0,0,1-1.14,9.44,27.68,27.68,0,0,1-3.83,9.09l-5.37,8,1-9.65a70.79,70.79,0,0,0-2-24.75,80.26,80.26,0,0,0-4.62-11.73A66.17,66.17,0,0,1,70.18,21.49Z" />
          <path
            className={cls2}
            d="M70.18,21.49c3,8,9,16.08,11.27,23.63a62.69,62.69,0,0,1,2.28,25.4c5.9-8.36,7-25.38,2.78-35.13"
          />
          <path d="M30.23,43a14.81,14.81,0,0,1,.2,6.56,47.55,47.55,0,0,1-1.57,6.17,37.08,37.08,0,0,0-1.37,5.53,9.6,9.6,0,0,0,.44,4.79l2.89,7.59L24.42,69a11.22,11.22,0,0,1-3-3.14,9.65,9.65,0,0,1-1.36-4,14.57,14.57,0,0,1,.1-3.86,18.7,18.7,0,0,1,.94-3.6c.39,1.19.72,2.35,1.05,3.47A26.08,26.08,0,0,0,23.31,61a7.94,7.94,0,0,0,1.56,2.37A9.37,9.37,0,0,0,27,65l-3.5,3a12.27,12.27,0,0,1-1-7.44,22.84,22.84,0,0,1,2.22-6.39c.95-1.92,2-3.68,2.92-5.47A35.13,35.13,0,0,0,30.23,43Z" />
          <path
            className={cls2}
            d="M30.23,43c-.12,8.75-7.9,16.2-4.5,24C22.06,64.62,21,61.4,21.11,54.4"
          />
          <path d="M50.85,26.34a8.89,8.89,0,0,1,3.33-3.94,13.09,13.09,0,0,1,4.55-1.75,12.77,12.77,0,0,0-3.92,2.53A7.31,7.31,0,0,0,52.75,27Z" />
        </mask>
        <linearGradient
          id="New_Gradient_Swatch_3-pfp1"
          x1="97.95"
          y1="51.83"
          x2="73.82"
          y2="53.08"
          gradientTransform="matrix(-1, 0, 0, 1, 100.21, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#7aabdc" />
          <stop offset="0.04" stopColor="#7aabdc" />
          <stop offset="1" stopColor="#7aabdc" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="radial-gradient-pfp1"
          cx="13.58"
          cy="25.51"
          r="58.68"
          gradientTransform="matrix(-1, 0, 0, 1, 100.21, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.15" stopColor="#f6e4d1" />
          <stop offset="0.93" stopColor="#fcc394" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="radial-gradient-2-pfp1"
          cx="71.75"
          cy="48.36"
          r="46.35"
          gradientTransform="matrix(-0.08, 1, 0.66, 0.05, 1.86, -25.67)"
        />
        <linearGradient
          id="linear-gradient-pfp1"
          x1="10.48"
          y1="25.86"
          x2="21.6"
          y2="29.95"
          gradientTransform="matrix(-1, 0, 0, 1, 100.21, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f7ebd9" />
          <stop offset="1" stopColor="#fae4b5" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2-pfp1"
          x1="67.62"
          y1="38.56"
          x2="80.8"
          y2="43.4"
        />
        <linearGradient
          id="linear-gradient-3-pfp1"
          x1="10.74"
          y1="49.66"
          x2="14.91"
          y2="51.19"
        />
        <linearGradient
          id="linear-gradient-4-pfp1"
          x1="2.46"
          y1="48.66"
          x2="7.14"
          y2="50.38"
        />
      </defs>
      <g className={cls3}>
        <g id="Layer_2-pfp1" data-name="Layer 2">
          <g id="Layer_1-2-pfp1" data-name="Layer 1">
            <path className={cls1} d={pathDef1} />
            <path className={hairColour} d={pathDef1} />
            <path d={pathDef2} />
            <path className={hairColour} d={pathDef3} />
            <path d={pathDef4} />
            <path className={hairColour} d={pathDef5} />
            <path d={pathDef6} />
            <path className={hairColour} d={pathDef7} />
            <path d={pathDef8} />
            <path className={hairColour} d={pathDef9} />
            <path d={pathDef10} />
            <path
              className={hairColour}
              d="M83.48,50c3.68,17.56,5,26.88-.5,47.25,0,0,12.58-10,13.25-19.25.88-12-5.75-31.25-9-35"
            />
            <path d="M44.74,23.19a35.28,35.28,0,0,1,3.2-6.12A19.43,19.43,0,0,1,52.65,12a10.75,10.75,0,0,1,6.5-2.15,14.13,14.13,0,0,1,6.46,1.92,14.06,14.06,0,0,0-6.44-1.42,10,10,0,0,0-5.91,2.44,18.12,18.12,0,0,0-4,5.07,32.63,32.63,0,0,0-2.59,6Z" />
            <path d="M40.65,20.8a27.78,27.78,0,0,1,.92-3.43,25.69,25.69,0,0,1,1.31-3.23,14.7,14.7,0,0,1,1.85-2.94A8.19,8.19,0,0,1,47.36,9a7.72,7.72,0,0,0-2,2.59,13.64,13.64,0,0,0-1.07,3.05,21.64,21.64,0,0,0-.52,3.19A26.51,26.51,0,0,0,43.64,21Z" />
            <path d="M36.77,18.08a19.73,19.73,0,0,1,.64-1.88c.24-.62.5-1.22.79-1.8a9.4,9.4,0,0,1,2.28-3.13,9.11,9.11,0,0,0-1.34,3.46,18.33,18.33,0,0,0-.27,1.83,16.17,16.17,0,0,0-.12,1.81Z" />
            <path d="M43.39,23.16a44.56,44.56,0,0,0-5.9-.47,22.56,22.56,0,0,0-3,.12,10.42,10.42,0,0,0-2.91.66,10.46,10.46,0,0,1,2.81-1.15,23.72,23.72,0,0,1,3-.63,47.66,47.66,0,0,1,6.12-.53Z" />
            <path d="M47,28.07a13.65,13.65,0,0,0-1.54-2.21,21.14,21.14,0,0,0-1.94-2.08A30.15,30.15,0,0,0,39,20.28a36.31,36.31,0,0,0-5.2-2.65c-.89-.4-1.83-.69-2.75-1l-2.82-.85.27-1.15c1,.24,2,.46,2.94.71s1.95.56,2.9.92a37.44,37.44,0,0,1,5.58,2.47A31.84,31.84,0,0,1,45,22.16a24.25,24.25,0,0,1,2.26,2.16,16.4,16.4,0,0,1,2,2.59Z" />
            <path d="M34.61,17.81a12,12,0,0,0-5.63,0,12.53,12.53,0,0,1,5.77-.95Z" />
            <path d="M70.18,21.49c2.12,3.81,4.54,7.34,6.91,11a58.5,58.5,0,0,1,6.3,11.77,57.59,57.59,0,0,1,2.76,26.57l-4.41-1.69a27,27,0,0,0,3.57-7.46,50.19,50.19,0,0,0,1.88-8.51,54.24,54.24,0,0,0,.51-8.84,39,39,0,0,0-1.19-8.92,27.47,27.47,0,0,1,3.28,8.67,43,43,0,0,1,.9,9.34,42.15,42.15,0,0,1-1.14,9.44,27.68,27.68,0,0,1-3.83,9.09l-5.37,8,1-9.65a70.79,70.79,0,0,0-2-24.75,80.26,80.26,0,0,0-4.62-11.73A66.17,66.17,0,0,1,70.18,21.49Z" />
            <path
              className={hairColour}
              d="M70.18,21.49c3,8,9,16.08,11.27,23.63a62.69,62.69,0,0,1,2.28,25.4c5.9-8.36,7-25.38,2.78-35.13"
            />
            <path d="M88.41,92.4a31.94,31.94,0,0,0,2.69-8c.61-2.81,1-5.68,1.36-8.55,0,2.89-.09,5.79-.37,8.7a33.28,33.28,0,0,1-1.86,8.68Z" />
            <path d="M30.23,43a14.81,14.81,0,0,1,.2,6.56,47.55,47.55,0,0,1-1.57,6.17,37.08,37.08,0,0,0-1.37,5.53,9.6,9.6,0,0,0,.44,4.79l2.89,7.59L24.42,69a11.22,11.22,0,0,1-3-3.14,9.65,9.65,0,0,1-1.36-4,14.57,14.57,0,0,1,.1-3.86,18.7,18.7,0,0,1,.94-3.6c.39,1.19.72,2.35,1.05,3.47A26.08,26.08,0,0,0,23.31,61a7.94,7.94,0,0,0,1.56,2.37A9.37,9.37,0,0,0,27,65l-3.5,3a12.27,12.27,0,0,1-1-7.44,22.84,22.84,0,0,1,2.22-6.39c.95-1.92,2-3.68,2.92-5.47A35.13,35.13,0,0,0,30.23,43Z" />
            <path
              className={hairColour}
              d="M30.23,43c-.12,8.75-7.9,16.2-4.5,24C22.06,64.62,21,61.4,21.11,54.4"
            />
            <path d="M50.85,26.34a8.89,8.89,0,0,1,3.33-3.94,13.09,13.09,0,0,1,4.55-1.75,12.77,12.77,0,0,0-3.92,2.53A7.31,7.31,0,0,0,52.75,27Z" />
            <path d="M56,24.81A8.23,8.23,0,0,1,59.42,23,15.77,15.77,0,0,1,63,22.46a15.46,15.46,0,0,0-3.16,1.47,6.75,6.75,0,0,0-2.3,2.15Z" />
            <path d="M62.34,25.57a5.71,5.71,0,0,1,2.54-1.17,10.68,10.68,0,0,1,2.5-.23,9.65,9.65,0,0,0-2.1,1.15,4.07,4.07,0,0,0-1.4,1.53Z" />
            <g className={cls5}>
              <path
                className={cls6}
                d="M94.19,86.67c.33-10.14,0-22-3.75-33.67a46.91,46.91,0,0,1-4.88,16.25c.86,11.09-1.28,20.94-3.5,30.75C82.06,100,91.19,93,94.19,86.67Z"
              />
            </g>
            <g className={cls5}>
              <path
                className={cls6}
                d="M83.44,73A49.24,49.24,0,0,0,81.6,38l3.59,3.75C83.13,34.86,77.88,27.93,70.3,22c1.19,2.65,2.55,6.23,3.72,8.77C80.63,45.11,84.05,58.92,83.44,73Z"
              />
            </g>
            <g className={cls5}>
              <path
                className={cls6}
                d="M101,64.25c-.88-3.1-1.72-7-3.88-9.91A31.57,31.57,0,0,1,97,58.92a69.31,69.31,0,0,0-7.84-14.46l.55,2.67c4.19,9.33,6.16,17.66,8.16,26C99.8,71,101,69.12,101,64.25Z"
              />
            </g>
            <g className={cls5}>
              <path
                className={cls6}
                d="M35.11,8.53a19.82,19.82,0,0,1,6.13-1.25,4.07,4.07,0,0,0-1,2.17A27.18,27.18,0,0,1,50.52,7.12c-2.66,1.18-5.5,3.34-6,6.06a8,8,0,0,1,4-2.55,5.3,5.3,0,0,0-.49,2.93A16.3,16.3,0,0,1,60,9.94a12.69,12.69,0,0,0-7.81,7.32,9.64,9.64,0,0,1,4.88-1.65,8.89,8.89,0,0,0-2.28,4c7.08-.66,11,.9,18.39,8.52a43.4,43.4,0,0,0-23.76-1.71l-.84,1.35c-3.92-5.45-9.62-9.7-17.89-12.21A11.9,11.9,0,0,1,35.11,8.53Z"
              />
            </g>
            <g className={cls5}>
              <path
                className={cls6}
                d="M4.5,65.31C5,60.44,9.44,52.38,9.31,48.25S9.15,33.63,10.23,30c3.42-11.72,10.78-15,17.33-15.4,11.59,4.08,18.76,8,20.25,13.88l-2.44-.56c-1.48-.89-3.76-1.24-8.31-1.44a15.57,15.57,0,0,1,4.63-1.63c-3.8-1.19-8.34-1.16-13.38-.31A8.28,8.28,0,0,1,32,21.34c-6.87.92-11.09,6.41-12.24,12.16a5.26,5.26,0,0,1,3.29-2.62c-2.26,2.89-2.34,6.7-2.87,12.18C17.8,57.74,12.37,59.5,13.87,67.19a13.53,13.53,0,0,1,1.61-3.81,21.82,21.82,0,0,0,2.9,11.19l-.32-3.38c5.41,11.09,8,16.45,7.44,25A79.09,79.09,0,0,0,17.69,82l-4.5,7.81s-1.88-7.43-3.13-9.68S4,70.19,4.5,65.31Z"
              />
            </g>
            <g className={cls7}>
              <path
                className={cls8}
                d="M25.5,15.58a24.41,24.41,0,0,0-7.17,5.1,22.91,22.91,0,0,0-4.45,7.16,37.48,37.48,0,0,0-2.14,8.38c-.42,2.91-.59,5.9-.82,9a28,28,0,0,1-1.51-9.16,24.53,24.53,0,0,1,1.69-9.33A18.45,18.45,0,0,1,16.77,19,15.28,15.28,0,0,1,25.5,15.58Z"
              />
            </g>
            <g className={cls7}>
              <path
                className={cls8}
                d="M11.19,47A90.35,90.35,0,0,1,8.75,57.77a50.45,50.45,0,0,0-1.37,5.07,17.72,17.72,0,0,0,.09,5l.16,1.35a11.46,11.46,0,0,0,.19,1.17,15.47,15.47,0,0,0,.79,2.35c.66,1.59,1.51,3.18,2.31,4.83a35.42,35.42,0,0,1,2.17,5.17,9.45,9.45,0,0,1,.26,5.61,11.12,11.12,0,0,0-1.6-5.06c-.84-1.56-1.86-3-2.85-4.57A44.92,44.92,0,0,1,6,73.9,16.09,16.09,0,0,1,5,71.1a11.53,11.53,0,0,1-.29-1.52L4.5,68.21a17.93,17.93,0,0,1,0-5.89,19.36,19.36,0,0,1,.84-2.83c.34-.9.68-1.71,1-2.56A90.5,90.5,0,0,1,11.19,47Z"
              />
            </g>
            <g className={cls7}>
              <path
                className={cls8}
                d="M18,38.67a42.75,42.75,0,0,1-.11,6.88A30,30,0,0,1,17.36,49a18.41,18.41,0,0,1-1.16,3.38c-.85,2.1-1.8,4.15-2.58,6.23-.41,1-.81,2.06-1.14,3.09a14.47,14.47,0,0,0-.69,2.95,14.2,14.2,0,0,0,1.09,6,16.32,16.32,0,0,0,1.41,2.8,14.19,14.19,0,0,0,.9,1.29l1,1.36a59.57,59.57,0,0,1,7.05,11.78c-2.9-3.54-5.83-7-8.86-10.28l-1.16-1.24a15.66,15.66,0,0,1-1.14-1.44,19.1,19.1,0,0,1-1.83-3.16,15.52,15.52,0,0,1-1.48-7.3,16.26,16.26,0,0,1,.89-3.65c.4-1.12.83-2.2,1.29-3.26,1-2.09,2-4.15,3.08-6.14a18.34,18.34,0,0,0,1.38-2.92,29.94,29.94,0,0,0,1-3.17C17,43.12,17.5,40.9,18,38.67Z"
              />
            </g>
            <g className={cls7}>
              <path
                className={cls8}
                d="M35.87,8.06a8.11,8.11,0,0,1-.52,2.43,12.26,12.26,0,0,1-.91,1.89A14.07,14.07,0,0,1,33.29,14a19.19,19.19,0,0,1-1.6,1.77,3.85,3.85,0,0,1-.61-2.58,5.72,5.72,0,0,1,.78-2.38,6.22,6.22,0,0,1,1.63-1.8A5.17,5.17,0,0,1,35.87,8.06Z"
              />
            </g>
            <g className={cls9}>
              <path
                className={cls8}
                d="M73.87,29.13a41,41,0,0,1,5.33,8,47.17,47.17,0,0,1,3.52,9,48.14,48.14,0,0,1,1.62,9.51A43.28,43.28,0,0,1,84,65.19c-.35-3.18-.7-6.31-1.21-9.41a85.63,85.63,0,0,0-2-9.15,82.66,82.66,0,0,0-3-8.87C76.61,34.85,75.27,32,73.87,29.13Z"
              />
            </g>
            <g className={cls9}>
              <path
                className={cls8}
                d="M86.81,67.94a39.33,39.33,0,0,1,1,7.94,48,48,0,0,1-.4,8,45.86,45.86,0,0,1-1.78,7.84,34.78,34.78,0,0,1-3.28,7.31c.62-2.6,1.24-5.15,1.77-7.71s1-5.13,1.31-7.71.61-5.18.83-7.79Z"
              />
            </g>
            <g className={cls10}>
              <path
                className={cls11}
                d="M32.94,10l4.62-.5L36.06,13l7.13-2.5a12.44,12.44,0,0,0-2.38,9.13S29.1,18.68,23.06,23c-4.08,2.92-7,10.75-7.5,16.25l3.25-2.87c2.63,15.75-7.47,20.58-6.5,27.75,1,7.53,2.63,9.12,2.63,9.12V69.38s6.25,9.62,7,12.87S25.5,96.19,25.5,96.19L2.69,96c-3-28.2.42-57.42,1.12-87.62Z"
              />
              <path
                className={cls12}
                d="M91.62,20.35c3.95,3.06,9.67,19.56,11.1,24.21,1.61,5.22,2.64,17.72,2.64,19.06,0,13.34-6,26.75-19.78,26.75-28.79,0-22.1-45.41-36.85-55.62C41.07,29.44,22,30.35,22,16.73,22,6.21,43.84,0,56.27,0,70.87,0,89.39,14.21,91.62,20.35Z"
              />
              <path
                className={cls13}
                d="M34.77,17.25c7.17,2.18,14.41,7.81,14.41,15.23,0,8.37-7.37,23.11-9.24,33.11-2.81,14.94-1.06,33.7-19.12,33.7C5,99.29,0,77.29,0,71.27,0,56.38,9.67,13.7,25.24,13.7a9.41,9.41,0,0,1,2,.22A20.41,20.41,0,0,1,34.77,17.25Z"
              />
            </g>
            <path
              className={cls14}
              d="M85.32,17.42c2.44,6.29,2,8.23-1.2,5.93,1.47,6.43-1.89,7-9.13,2.8,4.18,4,7.4,8.1,8.86,11.86-.7-5.39,2.6-8.4,6.46-4.2.58-4,1.1-5.29,6.41-.45Z"
            />
            <path
              className={cls15}
              d="M35.82,33.68c-3.07,4.74-5.66,6.86-6.59-.14-2,4.7-4.78,6.13-8.26,2.33-.19,5.25-.76,9.87-2.07,13.33,5.13-7.11,9.5-9.11,12.72-3.33Z"
            />
            <path
              className={cls16}
              d="M89.14,44.46c-1.26,3.79-2.83,4.4-4.69,2,.57,3,1,5.66,1.27,8.12,1.63-3.19,3-2.58,3.73.4Z"
            />
            <path
              className={cls17}
              d="M97.57,43.33c1.59,6.07-1,6.93-6.53,4.07L95.18,55c1.34-5.42,3-6.62,4.92-2.46Z"
            />
            <line
              className={cls18}
              x1="23.91"
              y1="25.25"
              x2="23.46"
              y2="25.92"
            />
            <path
              className={cls18}
              d="M78.59,29.69c1.55,1.48,2.27,3.55,3.46,5.29"
            />
            <path className={cls18} d="M83.5,37.6a8.28,8.28,0,0,1,.9,1.47" />
            <path
              className={cls18}
              d="M99.41,58.64c.18.86.91,1.45,1.18,2.27a14.48,14.48,0,0,1,.75,2.45"
            />
            <path className={cls18} d="M93.76,68.28a4,4,0,0,1,.1,1.46" />
            <path
              className={cls18}
              d="M82.4,15.34A27.35,27.35,0,0,1,79.15,15"
            />
            <line
              className={cls18}
              x1="55.57"
              y1="16.26"
              x2="54.55"
              y2="16.48"
            />
            <path className={cls18} d="M26.65,51.47a9.25,9.25,0,0,1,.67,3.14" />
            <g className={cls5}>
              <path
                className={cls6}
                d="M27.12,69.06s-3.33-5-4.06-8.5c-1-4.81.56-14.12.56-14.12s-3.06,8.5-3.18,10.94A13.93,13.93,0,0,0,27.12,69.06Z"
              />
            </g>
            <path className={cls18} d="M22.36,80.11c.15.75.52,1.44.67,2.19" />
          </g>
        </g>
      </g>
    </InnerSvg>
  )
}
