import React from "react"

import { ECAccordion } from "@components/ECAccordion"

import {
  TopicCodeType,
  TopicNameType,
  ModuleCodeType,
  ModuleNameType,
  SyllabusCodeType,
  M1,
  M2,
  M3,
  M4,
  M5,
  M6,
  M7,
  M8,
} from "@views/multiple-choice-problem/types"

import { getFromCacheOrRemote } from "@helpers/cache"
import { get } from "@helpers/get"
import { jsxJSONArrayProcessor } from "@helpers/jsxJSONProcessor"

import { SimpleSyllabusView } from "./view"

export function getModuleName(moduleCode: ModuleCodeType): ModuleNameType {
  switch (moduleCode) {
    case M1:
      return "kinematics"
    case M2:
      return "dynamics"
    case M3:
      return "waves and thermodynamics"
    case M4:
      return "electricity and magnetism"
    case M5:
      return "advanced mechanics"
    case M6:
      return "electromagnetism"
    case M7:
      return "the nature of light"
    case M8:
      return "from the universe to the atom"
  }
}

function getTopicName(
  moduleCode: ModuleCodeType,
  topicCode: TopicCodeType
): TopicNameType {
  if (moduleCode === M5 && topicCode === "IQ1") {
    return "projectile motion"
  } else if (moduleCode === M5 && topicCode === "IQ2") {
    return "circular motion"
  } else if (moduleCode === M5 && topicCode === "IQ3") {
    return "motion in gravitational fields"
  } else if (moduleCode === M5 && topicCode === "IQ4") {
    return "the motor effect"
  } else if (moduleCode === M6 && topicCode === "IQ1") {
    return "charged particles, conductors and electric and magnetic fields"
  } else if (moduleCode === M6 && topicCode === "IQ2") {
    return "the motor effect"
  } else if (moduleCode === M6 && topicCode === "IQ3") {
    return "electromagnetic induction"
  } else if (moduleCode === M6 && topicCode === "IQ4") {
    return "applications of the motor effect"
  } else if (moduleCode === M7 && topicCode === "IQ1") {
    return "electromagnetic spectrum"
  } else if (moduleCode === M7 && topicCode === "IQ2") {
    return "light: wave model"
  } else if (moduleCode === M7 && topicCode === "IQ3") {
    return "light: quantum model"
  } else if (moduleCode === M7 && topicCode === "IQ4") {
    return "light and special relativity"
  } else if (moduleCode === M8 && topicCode === "IQ1") {
    return "origins of the elements"
  } else if (moduleCode === M8 && topicCode === "IQ2") {
    return "structure of the atom"
  } else if (moduleCode === M8 && topicCode === "IQ3") {
    return "quantum mechanical nature of the atom"
  } else if (moduleCode === M8 && topicCode === "IQ4") {
    return "properties of the nucleus"
  } else if (moduleCode === M8 && topicCode === "IQ5") {
    return "deep inside the atom"
  }
  return null
}

type SyllabusNamesType = {
  moduleNames: JSX.Element[]
  topicNames: JSX.Element[]
  dotPoints: JSX.Element[]
}

export const createEmptySyllabusNames = (): SyllabusNamesType => ({
  moduleNames: [],
  topicNames: [],
  dotPoints: [],
})

const createRemoteThunk = (key: string) => async () => {
  const response = await fetch(`/data/syllabus/${key}.json`)
  return await response.json()
}

export async function getListOfSyllabusNames(
  syllabusCodes: SyllabusCodeType[]
): Promise<SyllabusNamesType> {
  let syllabusNames = createEmptySyllabusNames()

  for (let i = 0; i < syllabusCodes.length; i++) {
    const { moduleCode, topicCode, dotPointCode } = syllabusCodes[i]

    const key = `${moduleCode}-${topicCode}`
    const moduleTopicChunk = await getFromCacheOrRemote({
      key,
      remoteThunk: createRemoteThunk(key),
    })
    const content = get({
      object: moduleTopicChunk,
      path: ["dotpoints", "dict", dotPointCode],
      defaultValue: [
        {
          type: "p",
          content: [
            {
              type: "em",
              content: "Dot point content missing.",
            },
          ],
        },
      ],
    })

    syllabusNames.moduleNames.push(
      <SimpleSyllabusView
        key={`module-code-${moduleCode}-${i}`}
        code={moduleCode}
        name={getModuleName(moduleCode) || ""}
      />
    )
    syllabusNames.topicNames.push(
      <SimpleSyllabusView
        key={`topic-code-${moduleCode}-${topicCode}-${i}`}
        code={topicCode}
        name={getTopicName(moduleCode, topicCode) || ""}
      />
    )

    const dotPointCodeFull = `${moduleCode}-${topicCode}-${dotPointCode}`
    syllabusNames.dotPoints.push(
      <ECAccordion
        key={`dotpoint-code-${dotPointCodeFull}`}
        heading={<strong>{dotPointCodeFull}</strong>}
        closed={true}
      >
        {jsxJSONArrayProcessor(content)}
      </ECAccordion>
    )
  }

  return syllabusNames
}
