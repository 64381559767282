import { classnames } from "classnames-joiner"
import React from "react"

import { container } from "@components/Avatar/a.module.scss"
import {
  BASE_AVATAR_CONTAINER_WIDTH,
  BASE_AVATAR_CONTAINER_HEIGHT,
} from "@components/Avatar/constants"
import { createFactoredSizing } from "@components/Avatar/utils"

interface Props {
  children: React.ReactNode
  className?: string
  factor?: number
}

export const AvatarContainer = ({ children, className, factor }: Props) => {
  return (
    <div
      style={createFactoredSizing(
        {
          width: BASE_AVATAR_CONTAINER_WIDTH,
          height: BASE_AVATAR_CONTAINER_HEIGHT,
        },
        factor
      )}
      className={classnames([container, className])}
    >
      {children}
    </div>
  )
}
