import { MageEmoji } from "accessible-emojis"
import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import Row from "react-bootstrap/Row"

import {
  SpacedWrapper,
  LeftAlignedVerticallyCenteredWrapper,
} from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import {
  M5,
  M6,
  M7,
  M8,
  castModuleCodeTypeWithDefault,
} from "@views/multiple-choice-problem/types"
import { getModuleName } from "@views/multiple-choice-problem/utilites/getNiceName"
import {
  QUERY_PARAM_KEY_MODULE,
  QUERY_PARAM_KEY_PAGE,
} from "@views/physics/common/constants"

import { signIn, useUserFromState } from "@helpers/auth"
import { LINK_PHYSICS } from "@helpers/constants/links"
import {
  D_BLOCK,
  TEXT_CENTER,
  FONT_WEIGHT_NORMAL,
  MB_4,
  MB_5,
  ML_3,
} from "@helpers/constants/style"
import { useQueryString } from "@helpers/hooks/use-query-string"
import { capitaliseWords } from "@helpers/strings"

import { PhysicsPracticeListContainer } from "./physics-practice-list"
import { createModuleSelectionHandler } from "./utils"

export const PhysicsPracticeListViewContent = () => {
  const user = useUserFromState()
  const { onSetParamValue: onSetPageParamValue } =
    useQueryString(QUERY_PARAM_KEY_PAGE)
  const {
    paramValue: moduleParamValue,
    onSetParamValue: onSetModuleParamValue,
  } = useQueryString(QUERY_PARAM_KEY_MODULE)

  const moduleValue = castModuleCodeTypeWithDefault(moduleParamValue)
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <SpacedWrapper>
              <>
                <Link to={LINK_PHYSICS}>
                  <Button>Back</Button>
                </Link>
                <GoogleFormLinkGeneralFeedback />
              </>
            </SpacedWrapper>
          </Col>
          <Col xs={12}>
            <h1 className={TEXT_CENTER}>Dungeon</h1>
            <p>
              The dungeon presents a list of past paper (and past paper
              inspired) physics questions <strong>(monsters)</strong> you can
              practice against for your real HSC physics exam. Most questions
              come with notes, worked solutions, and videos too!
            </p>
            {user ? (
              <p>
                <MageEmoji className={FONT_WEIGHT_NORMAL} /> You're currently
                signed in. Your progress will be saved.
              </p>
            ) : (
              <div className={MB_4}>
                <Button onClick={signIn}>Sign In</Button>
                <span> and we'll save your progress.</span>
              </div>
            )}
          </Col>
          <Col>
            <div className={MB_5}>
              <LeftAlignedVerticallyCenteredWrapper>
                <>
                  <DropdownButton title="Select Module">
                    <Dropdown.Item
                      onClick={createModuleSelectionHandler({
                        moduleJustSelected: M5,
                        moduleValue,
                        onSetModuleParamValue,
                        onSetPageParamValue,
                      })}
                    >
                      Module 5
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={createModuleSelectionHandler({
                        moduleJustSelected: M6,
                        moduleValue,
                        onSetModuleParamValue,
                        onSetPageParamValue,
                      })}
                    >
                      Module 6
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={createModuleSelectionHandler({
                        moduleJustSelected: M7,
                        moduleValue,
                        onSetModuleParamValue,
                        onSetPageParamValue,
                      })}
                    >
                      Module 7
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={createModuleSelectionHandler({
                        moduleJustSelected: M8,
                        moduleValue,
                        onSetModuleParamValue,
                        onSetPageParamValue,
                      })}
                    >
                      Module 8
                    </Dropdown.Item>
                  </DropdownButton>
                  <strong className={`${D_BLOCK} ${ML_3}`}>
                    Module {moduleValue[1]}:{" "}
                    {capitaliseWords(getModuleName(moduleValue), false)}
                  </strong>
                </>
              </LeftAlignedVerticallyCenteredWrapper>
            </div>
          </Col>
        </Row>
      </Container>
      <PhysicsPracticeListContainer moduleCode={moduleValue} />
    </>
  )
}
