import React from "react"
import Pagination from "react-bootstrap/Pagination"

import { CenteredWrapper } from "@components/Containers/positioning-wrappers"
import { QueryParamValueType } from "@components/types"

export const PhysicsPagination = ({
  physicsPageList,
  activePage,
  onSetParamValue,
}: {
  physicsPageList: string[][]
  activePage: number
  onSetParamValue: (value: QueryParamValueType) => void
}) => {
  const paginationListUI = []

  for (let pageNumber = 1; pageNumber <= physicsPageList.length; pageNumber++) {
    paginationListUI.push(
      <Pagination.Item
        key={`physics-pagination-page-index-${pageNumber}`}
        active={activePage === pageNumber}
        onClick={() => {
          onSetParamValue(String(pageNumber))
        }}
      >
        {pageNumber}
      </Pagination.Item>
    )
  }

  return (
    <CenteredWrapper>
      <Pagination>{paginationListUI}</Pagination>
    </CenteredWrapper>
  )
}
