import { classnames } from "classnames-joiner"
import React from "react"

import { body } from "@components/Avatar/a.module.scss"
import { BaseAvatarProps } from "@components/Avatar/types"
import { createFactoredSizing } from "@components/Avatar/utils"
import { Svg } from "@components/Svg"

import {
  cls1,
  cls2,
  cls3,
  cls4,
  cls5,
  cls6,
  cls7,
  cls8,
  cls9,
  cls10,
  cls11,
  cls12,
  cls13,
  cls14,
  cls15,
  cls16,
  cls17,
  cls18,
  cls19,
  cls20,
  cls21,
  cls22,
} from "./cwb.module.scss"

const sizing = {
  top: 200,
  left: 39,
  width: 163,
}

export const CrystalWizardBody = ({ className, factor }: BaseAvatarProps) => {
  return (
    <Svg
      style={createFactoredSizing(sizing, factor)}
      viewBox="0 0 78.36 57.57"
      className={classnames([body, className])}
    >
      <g className={cls1}>
        <g id="Layer_1" data-name="Layer 1">
          <g id="L_ARM" data-name="L ARM">
            <g id="L_ARM-2" data-name="L ARM">
              <g id="L_ARM-3" data-name="L ARM">
                <g id="L_ARM-4" data-name="L ARM">
                  <path
                    className={cls2}
                    d="M274.22,482.25c-6.3,3.65.31,23.36.31,23.36,5,1.6,10.7-1.44,13.77-5.17-3-3.68-6.49-12.76-10.36-17.46A2.86,2.86,0,0,0,274.22,482.25Z"
                    transform="translate(-219.07 -477.82)"
                  />
                  <path
                    className={cls3}
                    d="M274.22,482.25c-6.3,3.65.31,23.36.31,23.36,5,1.6,10.7-1.44,13.77-5.17-3-3.68-6.49-12.76-10.36-17.46A2.86,2.86,0,0,0,274.22,482.25Z"
                    transform="translate(-219.07 -477.82)"
                  />
                </g>
                <path
                  className={cls4}
                  d="M273.26,481.38a58.78,58.78,0,0,0,9.74,24,9.78,9.78,0,0,1-9.05.87C271.07,501.19,267.58,486.81,273.26,481.38Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <g id="L_HAND" data-name="L HAND">
                <g id="L_HAND-2" data-name="L HAND">
                  <path
                    className={cls2}
                    d="M277.22,510.08c.88,1.35,2.91,3.77,4.36,4.3,2.14.78,9.15-3,9.26-6a10,10,0,0,0-2.7-6.08c-2.65-1.65-8,.37-10.05,2.71S276.34,508.74,277.22,510.08Z"
                    transform="translate(-219.07 -477.82)"
                  />
                  <path
                    className={cls3}
                    d="M277.22,510.08c.88,1.35,2.91,3.77,4.36,4.3,2.14.78,9.15-3,9.26-6a10,10,0,0,0-2.7-6.08c-2.65-1.65-8,.37-10.05,2.71S276.34,508.74,277.22,510.08Z"
                    transform="translate(-219.07 -477.82)"
                  />
                  <g className={cls5}>
                    <path
                      className={cls6}
                      d="M277.64,504.49c-2.29,2.59-1.94,4.15-1,5.65s3.22,4.16,4.82,4.75,6.18-1.23,9-3.46c-4.7-.31-9.14-5.33-10.28-8.94A10.08,10.08,0,0,0,277.64,504.49Z"
                      transform="translate(-219.07 -477.82)"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="L_ARM_CLOTH" data-name="L ARM CLOTH">
              <path
                className={cls7}
                d="M273.49,511.94c-1.53-7.62-5.77-26.67,0-30.42l.21-.13a3.82,3.82,0,0,1,5,.95c2.13,2.59,4.07,6.33,6,9.94,3.24,6.23,4.8,7.81,6.2,9.52C287.41,505.82,278.5,513.45,273.49,511.94Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls8}
                d="M273.49,511.94c-1.53-7.62-5.77-26.67,0-30.42l.21-.13a3.82,3.82,0,0,1,5,.95c2.13,2.59,4.07,6.33,6,9.94,3.24,6.23,4.8,7.81,6.2,9.52C287.41,505.82,278.5,513.45,273.49,511.94Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls9}
                d="M289.5,500.14l-5.66-1.28L282,507.11l-8.25-1.87-.84,3.73c.22,1.1.43,2.11.6,3,5,1.51,13.92-6.12,17.38-10.14C290.44,501.28,290,500.77,289.5,500.14Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls10}
                d="M289.5,500.14l-5.66-1.28L282,507.11l-8.25-1.87-.84,3.73c.22,1.1.43,2.11.6,3,5,1.51,13.92-6.12,17.38-10.14C290.44,501.28,290,500.77,289.5,500.14Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls5}>
                <path
                  className={cls11}
                  d="M273.65,479.9c-2,6.08,1.17,17.87,9.21,29.64-3.05,2.25-7,4.65-10.3,2.86,0,0-4.2-16.94-3.51-22.45S270,482.38,273.65,479.9Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M273.49,511.94s-.23-.46-.56-1.27-.8-2-1.25-3.39a52.25,52.25,0,0,1-2.31-10.7,35.81,35.81,0,0,1-.14-5.93,21.53,21.53,0,0,1,.34-2.75,14,14,0,0,1,.67-2.44,8.35,8.35,0,0,1,.95-1.94,5.8,5.8,0,0,1,1.09-1.28,3.72,3.72,0,0,1,.89-.57l.33-.15-.23.28a5.61,5.61,0,0,0-.53.82,8.12,8.12,0,0,0-.51,1.37,14.48,14.48,0,0,0-.32,1.87,59.67,59.67,0,0,0,.44,10.42c.39,3.83.85,7.71,1,10.67.11,1.48.14,2.73.15,3.6S273.49,511.94,273.49,511.94Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
            </g>
            <g id="L_FINGERS" data-name="L FINGERS">
              <path
                className={cls2}
                d="M282,513.38c-.27.85.9,5.46,2.62,5.31s9.22-4.3,9.25-6-3.7-3.79-4.82-3.95S282.29,512.26,282,513.38Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls3}
                d="M282,513.38c-.27.85.9,5.46,2.62,5.31s9.22-4.3,9.25-6-3.7-3.79-4.82-3.95S282.29,512.26,282,513.38Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls2}
                d="M284.78,508.31c-.35,1.45.79,4.17,1.82,4.12.79-1,4.09-3.47,5.21-3.53.36-1.63-1.15-4.21-2.59-4.5C288,505.05,285.11,506.93,284.78,508.31Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls3}
                d="M284.78,508.31c-.35,1.45.79,4.17,1.82,4.12.79-1,4.09-3.47,5.21-3.53.36-1.63-1.15-4.21-2.59-4.5C288,505.05,285.11,506.93,284.78,508.31Z"
                transform="translate(-219.07 -477.82)"
              />
            </g>
          </g>
          <g id="L_LEG" data-name="L LEG">
            <g id="L_LEG_2" data-name="L LEG 2">
              <path
                className={cls2}
                d="M265.54,522.42c2.81,1.27,8.86,1.81,12.12,1.57l.33,3.79s2.52,2.83,1.92,3.14c-2.22,1.16-12,1.32-14.73,0C264.6,530.62,265.54,522.42,265.54,522.42Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls3}
                d="M265.54,522.42c2.81,1.27,8.86,1.81,12.12,1.57l.33,3.79s2.52,2.83,1.92,3.14c-2.22,1.16-12,1.32-14.73,0C264.6,530.62,265.54,522.42,265.54,522.42Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls4}
                d="M263.94,528.19c4.46,2.31,9.87,2.41,16.65,1.58a2.44,2.44,0,0,1-2,2.57c-2.21.46-14.06,0-14.54-.64S263.94,528.19,263.94,528.19Z"
                transform="translate(-219.07 -477.82)"
              />
            </g>
            <g id="L_LEG_1" data-name="L LEG 1">
              <g id="L_LEG_1-2" data-name="L LEG 1">
                <path
                  className={cls2}
                  d="M277.81,507.24,277,522.49c-.32,3.58-7.67,3.89-9.87,1.41l-4.07-14.58C263,503.69,275.46,500.44,277.81,507.24Z"
                  transform="translate(-219.07 -477.82)"
                />
                <path
                  className={cls3}
                  d="M277.81,507.24,277,522.49c-.32,3.58-7.67,3.89-9.87,1.41l-4.07-14.58C263,503.69,275.46,500.44,277.81,507.24Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <path
                className={cls4}
                d="M271.19,501.85c-2.09,5.86-2.16,20.55,1.2,24.66-2.44.25-4.14.13-5.69-2-1.7-4.78-2.6-9.85-4.6-15.48C263.58,506.07,263.71,502.53,271.19,501.85Z"
                transform="translate(-219.07 -477.82)"
              />
            </g>
            <g id="L_LEG_1_CLOTH" data-name="L LEG 1 CLOTH">
              <path
                className={cls2}
                d="M271.87,526.51a7.39,7.39,0,0,1-5.46-2l-.15-.17-4.17-14.93v-.12a5.4,5.4,0,0,1,1.67-3.89,11.4,11.4,0,0,1,7.84-3c3.56,0,6.17,1.64,7.16,4.5l.06.19L278,522.54C277.78,525.29,274.76,526.51,271.87,526.51Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls14}
                d="M271.87,526.51a7.39,7.39,0,0,1-5.46-2l-.15-.17-4.17-14.93v-.12a5.4,5.4,0,0,1,1.67-3.89,11.4,11.4,0,0,1,7.84-3c3.56,0,6.17,1.64,7.16,4.5l.06.19L278,522.54C277.78,525.29,274.76,526.51,271.87,526.51Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls5}>
                <path
                  className={cls11}
                  d="M268.34,501.49c-1.73,11.07,1.26,18.78,5.16,25.51,0,0-6.83.23-7.52-1.72s-4.41-15.74-4.54-16.59C261.21,507,263.58,503,268.34,501.49Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <line
                className={cls15}
                x1="49.79"
                y1="38.2"
                x2="50.28"
                y2="39.42"
              />
            </g>
            <g id="L_LEG_2_CLOTH" data-name="L LEG 2 CLOTH">
              <path
                className={cls7}
                d="M265.29,523.1c2.86,1.15,8.93,1.44,12.18,1.06l.49,3.77s2.63,2.72,2.05,3.06c-2.17,1.26-11.91,1.82-14.72.6C264.7,531.34,265.29,523.1,265.29,523.1Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls16}
                d="M265.29,523.1c2.86,1.15,8.93,1.44,12.18,1.06l.49,3.77s2.63,2.72,2.05,3.06c-2.17,1.26-11.91,1.82-14.72.6C264.7,531.34,265.29,523.1,265.29,523.1Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                d="M278.6,528.61a20.57,20.57,0,0,0-7.19-.93,21.5,21.5,0,0,1,7.63-1Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls5}>
                <path
                  className={cls17}
                  d="M264,529.5c4.63,2,10.48,1.93,17.18.56a2.58,2.58,0,0,1-1.88,2.79c-2.18.63-14.42.72-14.95.14S264,529.5,264,529.5Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <path
                className={cls2}
                d="M263.91,520.64a43.26,43.26,0,0,0,14.82,1.11,4.77,4.77,0,0,1,.35,3.68,8.54,8.54,0,0,0-3.42-1.27l-1.82,1.74-3.68-2L268,525.75l-1.12-2-3.76,1A6.09,6.09,0,0,1,263.91,520.64Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls18}
                d="M263.91,520.64a43.26,43.26,0,0,0,14.82,1.11,4.77,4.77,0,0,1,.35,3.68,8.54,8.54,0,0,0-3.42-1.27l-1.82,1.74-3.68-2L268,525.75l-1.12-2-3.76,1A6.09,6.09,0,0,1,263.91,520.64Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls5}>
                <path
                  className={cls17}
                  d="M266,520.66a9.9,9.9,0,0,0-1.38,4.07l2-.09,1.13,2.49,2.12-2,2.33.75a6.3,6.3,0,0,1-1.58-4.52Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <line
                className={cls15}
                x1="48.64"
                y1="52.82"
                x2="49.05"
                y2="52.9"
              />
            </g>
          </g>
          <g id="R_LEG" data-name="R LEG">
            <g id="R_LEG_2" data-name="R LEG 2">
              <path
                className={cls2}
                d="M243.4,522.67c2.76,1.36,8.8,2.12,12.06,2l.2,3.79s2.42,2.92,1.81,3.21c-2.26,1.09-12,.9-14.72-.52C242.18,530.84,243.4,522.67,243.4,522.67Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls3}
                d="M243.4,522.67c2.76,1.36,8.8,2.12,12.06,2l.2,3.79s2.42,2.92,1.81,3.21c-2.26,1.09-12,.9-14.72-.52C242.18,530.84,243.4,522.67,243.4,522.67Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls4}
                d="M241.6,528.38c4.38,2.46,9.78,2.75,16.59,2.15a2.46,2.46,0,0,1-2.13,2.51c-2.23.38-14.05-.51-14.5-1.15S241.6,528.38,241.6,528.38Z"
                transform="translate(-219.07 -477.82)"
              />
            </g>
            <g id="R_LEG_1" data-name="R LEG 1">
              <g id="R_LEG_1-2" data-name="R LEG 1">
                <path
                  className={cls2}
                  d="M260,509.05l-4.11,14.22c-2.47,3.44-10.22,2.57-11.59-.32l.64-15.69C248.76,501.34,259.76,503.21,260,509.05Z"
                  transform="translate(-219.07 -477.82)"
                />
                <path
                  className={cls3}
                  d="M260,509.05l-4.11,14.22c-2.47,3.44-10.22,2.57-11.59-.32l.64-15.69C248.76,501.34,259.76,503.21,260,509.05Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <path
                className={cls4}
                d="M252.21,502.68c-3.46,5.17-4.9,18.57-2.64,23.37a7.48,7.48,0,0,1-6.12-2.58,76.2,76.2,0,0,1,.77-16.48C246.07,505,247.58,502.68,252.21,502.68Z"
                transform="translate(-219.07 -477.82)"
              />
            </g>
            <g id="R_LEG_1_CLOTH" data-name="R LEG 1 CLOTH">
              <path
                className={cls2}
                d="M250.32,526.5c-2.73,0-5.94-1-7-3.12l-.1-.22.65-16.21.15-.24a9.64,9.64,0,0,1,8.23-4.07c4.09,0,8.52,2.23,8.67,6.38v.16l-4.2,14.53-.1.14C255.45,525.54,253.14,526.5,250.32,526.5Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls14}
                d="M250.32,526.5c-2.73,0-5.94-1-7-3.12l-.1-.22.65-16.21.15-.24a9.64,9.64,0,0,1,8.23-4.07c4.09,0,8.52,2.23,8.67,6.38v.16l-4.2,14.53-.1.14C255.45,525.54,253.14,526.5,250.32,526.5Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls5}>
                <path
                  className={cls11}
                  d="M252.91,501.24c-5,10-5.59,18.49-3.88,26.07,0,0-6.58-1.82-6.66-3.88s.16-16.48.63-17.2S247.92,501.24,252.91,501.24Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
            </g>
            <g id="R_LEG_2_CLOTH" data-name="R LEG 2 CLOTH">
              <path
                className={cls7}
                d="M244.09,522.53c2.73,1.42,8.75,2.3,12,2.23l.13,3.8s2.35,3,1.74,3.24c-2.28,1-12,.67-14.71-.81C242.71,530.68,244.09,522.53,244.09,522.53Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls16}
                d="M244.09,522.53c2.73,1.42,8.75,2.3,12,2.23l.13,3.8s2.35,3,1.74,3.24c-2.28,1-12,.67-14.71-.81C242.71,530.68,244.09,522.53,244.09,522.53Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls2}
                d="M242.22,520.48a44.48,44.48,0,0,0,15,1.47,5.29,5.29,0,0,1,.41,3.76,13.55,13.55,0,0,0-3.72-1.55l-1.82,1.74-3.68-2-2.13,1.86-1.12-2a12.81,12.81,0,0,0-3.37,1.14C241.4,523.84,241.21,521.4,242.22,520.48Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls18}
                d="M242.22,520.48a44.48,44.48,0,0,0,15,1.47,5.29,5.29,0,0,1,.41,3.76,13.55,13.55,0,0,0-3.72-1.55l-1.82,1.74-3.68-2-2.13,1.86-1.12-2a12.81,12.81,0,0,0-3.37,1.14C241.4,523.84,241.21,521.4,242.22,520.48Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls5}>
                <path
                  className={cls17}
                  d="M244.13,520.64a5.57,5.57,0,0,0-1.42,4.32l2.12-.32,1.14,2.49,2.11-2,2.33.75a6.3,6.3,0,0,1-1.58-4.52Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <path
                d="M256.81,529.29a20.62,20.62,0,0,0-7.06-1.61,21.6,21.6,0,0,1,7.68-.29Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls5}>
                <path
                  className={cls17}
                  d="M242.29,527.88c4.22,2.72,10,3.7,16.83,3.5a2.56,2.56,0,0,1-2.33,2.42c-2.25.25-14.34-1.75-14.75-2.42S242.29,527.88,242.29,527.88Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <line
                className={cls15}
                x1="29.96"
                y1="43.65"
                x2="30.04"
                y2="44.79"
              />
            </g>
          </g>
          <g id="BELT">
            <g id="BELT-2" data-name="BELT">
              <g id="BELT-3" data-name="BELT">
                <path
                  className={cls2}
                  d="M281.75,509.13c-13.26.62-26.78.26-40.37-.38a13.69,13.69,0,0,1,.05-5.22c13.49.65,27,1,40.37.38A13.49,13.49,0,0,1,281.75,509.13Z"
                  transform="translate(-219.07 -477.82)"
                />
                <path
                  className={cls19}
                  d="M281.75,509.13c-13.26.62-26.78.26-40.37-.38a13.69,13.69,0,0,1,.05-5.22c13.49.65,27,1,40.37.38A13.49,13.49,0,0,1,281.75,509.13Z"
                  transform="translate(-219.07 -477.82)"
                />
                <path
                  className={cls20}
                  d="M282.47,509.4a322.51,322.51,0,0,1-41.85-.39l0-3.15c13.91.7,27.85,1,41.85.39Z"
                  transform="translate(-219.07 -477.82)"
                />
                <path
                  className={cls15}
                  d="M283,506.4c-.71,0-1.42.07-2.14.07"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
            </g>
            <line
              className={cls15}
              x1="42.48"
              y1="25.81"
              x2="42.97"
              y2="26.55"
            />
            <line
              className={cls15}
              x1="34.56"
              y1="28.56"
              x2="34.97"
              y2="28.56"
            />
          </g>
          <g id="CAPE_2" data-name="CAPE 2">
            <g id="R_CAPE_2" data-name="R CAPE 2">
              <path
                className={cls2}
                d="M268.57,486.86c-1.58,14.56-7.93,39.49-12.47,41.71-9.21,1.41-34.71-7-34.53-11.43,9.74-13.46,14.54-22.8,17.85-36.26C246.69,484.29,259.69,486.29,268.57,486.86Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls8}
                d="M268.57,486.86c-1.58,14.56-7.93,39.49-12.47,41.71-9.21,1.41-34.71-7-34.53-11.43,9.74-13.46,14.54-22.8,17.85-36.26C246.69,484.29,259.69,486.29,268.57,486.86Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls9}
                d="M256.1,528.57c1.08-.52,2.25-2.34,3.45-5-1.8,1.14-3.63,2.24-5.53,3.27-1.71-2.24-3.3-4.54-4.77-6.88a90.74,90.74,0,0,1-8.7,4c-1.28-2.48-2.44-5-3.47-7.53a86.67,86.67,0,0,1-8.75,2.78q-1.26-4-2.13-8l-.61.14c-1.24,1.85-2.57,3.76-4,5.77C221.39,521.61,246.89,530,256.1,528.57Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls10}
                d="M256.1,528.57c1.08-.52,2.25-2.34,3.45-5-1.8,1.14-3.63,2.24-5.53,3.27-1.71-2.24-3.3-4.54-4.77-6.88a90.74,90.74,0,0,1-8.7,4c-1.28-2.48-2.44-5-3.47-7.53a86.67,86.67,0,0,1-8.75,2.78q-1.26-4-2.13-8l-.61.14c-1.24,1.85-2.57,3.76-4,5.77C221.39,521.61,246.89,530,256.1,528.57Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls21}
                d="M248.06,483c-11.34,19.58-18.05,39.09,11,43.75,0,0,0,2.57-1.85,2.91-.89.17-12.31-.6-21.37-3.1-11.49-3.16-15.81-8.75-15.52-9.58.58-1.62,9.94-14,12.1-19.09s6.28-18.08,6.28-18.08C242.27,480.33,243.94,482.22,248.06,483Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M248.67,528.31a51.61,51.61,0,0,1-7.54-.83c-1.25-.22-2.49-.5-3.72-.79s-2.46-.62-3.68-1a52,52,0,0,1-7.2-2.66,23.24,23.24,0,0,1-3.48-1.9,11.32,11.32,0,0,1-1.69-1.36,4.72,4.72,0,0,1-.4-.46,3.71,3.71,0,0,1-.87-2.24v-.41l.28-.4c.82-1.19,1.64-2.37,2.49-3.53s1.63-2.37,2.44-3.55,1.59-2.39,2.38-3.59c.4-.6.79-1.2,1.15-1.81s.74-1.23,1.13-1.84c.79-1.2,1.43-2.47,2.15-3.71s1.35-2.53,2-3.8c.35-.63.64-1.29.95-1.94l1-1.95c.31-.65.59-1.32.89-2l.91-2c-.3,1.42-.6,2.86-1,4.27l-.63,2.1c-.23.7-.4,1.42-.68,2.1-.53,1.36-1,2.76-1.6,4.1s-1.18,2.7-1.88,4-1.34,2.61-2.1,3.87l-1.11,1.9c-.36.64-.75,1.25-1.16,1.86-.81,1.22-1.59,2.46-2.42,3.66s-1.68,2.39-2.53,3.57l.28-.81c0-.16.07.23.42.55a7.08,7.08,0,0,0,1.22,1,30,30,0,0,0,3.05,1.82c1.07.56,2.18,1.08,3.31,1.57s2.26,1,3.41,1.45l3.48,1.32c1.17.43,2.35.82,3.53,1.22C243.84,526.94,246.23,527.65,248.67,528.31Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <line
                className={cls15}
                x1="19.26"
                y1="34.47"
                x2="19.8"
                y2="35.91"
              />
              <line
                className={cls15}
                x1="19.06"
                y1="31.81"
                x2="19.06"
                y2="32.3"
              />
              <line
                className={cls15}
                x1="36.42"
                y1="37.75"
                x2="37.15"
                y2="37.26"
              />
            </g>
            <g id="L_CAPE_2" data-name="L CAPE 2">
              <path
                className={cls2}
                d="M294.9,523c-.44,1.66-7,4.05-9.62,4-1.32-.45-2.61-2.92-3.21-4.17-3.68-7.63-7.67-24.35-8.94-35.88.83-.85,3-1.69,3.43-1.44s4.63,13,8.28,20.39C289.28,515,295.39,521.17,294.9,523Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls8}
                d="M294.9,523c-.44,1.66-7,4.05-9.62,4-1.32-.45-2.61-2.92-3.21-4.17-3.68-7.63-7.67-24.35-8.94-35.88.83-.85,3-1.69,3.43-1.44s4.63,13,8.28,20.39C289.28,515,295.39,521.17,294.9,523Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls9}
                d="M291.78,518.57c-1.4,2.27-2.92,4.53-4.56,6.76-1.73-.72-3.41-1.51-5.09-2.33.63,1.28,1.87,3.6,3.15,4,2.64.06,9.18-2.33,9.62-4,.19-.72-.59-2.07-1.88-4Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls10}
                d="M291.78,518.57c-1.4,2.27-2.92,4.53-4.56,6.76-1.73-.72-3.41-1.51-5.09-2.33.63,1.28,1.87,3.6,3.15,4,2.64.06,9.18-2.33,9.62-4,.19-.72-.59-2.07-1.88-4Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls21}
                d="M274.4,485.29c3.29,16.29,8.67,31.28,17.31,41.52,0,0-5.19,1.61-6.81,1.11s-3.75-3.25-4.38-5.13-7.5-22.73-8.38-35.7C272.09,486.21,274.4,485.29,274.4,485.29Z"
                transform="translate(-219.07 -477.82)"
              />
              <line
                className={cls15}
                x1="61.04"
                y1="28.95"
                x2="61.29"
                y2="30.17"
              />
              <line
                className={cls15}
                x1="71.18"
                y1="47.07"
                x2="71.66"
                y2="47.93"
              />
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M285.28,527l-.45-.16a4.37,4.37,0,0,1-1.14-.82,11.56,11.56,0,0,1-1.37-1.67q-.36-.52-.72-1.11c-.26-.45-.44-.84-.69-1.29a28.51,28.51,0,0,1-1.4-2.92c-.44-1.07-.94-2.21-1.36-3.4s-.83-2.42-1.22-3.68-.71-2.56-1.07-3.85-.61-2.6-.9-3.88-.53-2.56-.72-3.79-.38-2.44-.55-3.58-.24-2.22-.33-3.22-.18-1.91-.19-2.73,0-1.53-.05-2.11c0-1.17,0-1.84,0-1.84s.2.65.49,1.78c.14.56.32,1.25.5,2s.44,1.66.66,2.63.49,2,.75,3.11.59,2.26.86,3.47.64,2.43.93,3.7.6,2.54,1,3.8.63,2.56,1,3.81.67,2.48,1,3.67.68,2.3,1,3.37.7,2.1,1,3c.15.46.3.91.45,1.33s.31.81.47,1.17a15.46,15.46,0,0,0,.92,1.83,5.45,5.45,0,0,0,.82,1Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
            </g>
          </g>
          <g id="R_ARM" data-name="R ARM">
            <g id="R_ARM-2" data-name="R ARM">
              <g id="R_HAND" data-name="R HAND">
                <path
                  className={cls22}
                  d="M244.19,508.41c-.57,3.37-.39,7.07-4.73,8.84-3.34,0-6-1.59-8.43-4.43-.08-1.45.49-4.33,1.18-5.17,1.21-2.76,4.89-3.82,8.22-2.36S244.19,508.41,244.19,508.41Z"
                  transform="translate(-219.07 -477.82)"
                />
                <path
                  className={cls3}
                  d="M244.19,508.41c-.57,3.37-.39,7.07-4.73,8.84-3.34,0-6-1.59-8.43-4.43-.08-1.45.49-4.33,1.18-5.17,1.21-2.76,4.89-3.82,8.22-2.36S244.19,508.41,244.19,508.41Z"
                  transform="translate(-219.07 -477.82)"
                />
                <path
                  d="M241.47,508a8.42,8.42,0,0,1,1.37-.24,10.06,10.06,0,0,1,1.22,0,8.07,8.07,0,0,1,2.11.4l.41.14.67,1.71a7,7,0,0,1,.47,2.75,7.56,7.56,0,0,1-.2,1.51,6,6,0,0,1-.86,2,4,4,0,0,1-.72.82,3,3,0,0,1-1.75.75,2.77,2.77,0,0,1-1.07-.15,2.58,2.58,0,0,1-.52-.24,5,5,0,0,1-1.16-1.06,7.11,7.11,0,0,1-1.29-2.71,15.46,15.46,0,0,1,2.83-.45,3.87,3.87,0,0,0,1-.16s.09,0,0-.09-.18-.11-.48-.1a2.26,2.26,0,0,0-1.25.49c-.21.17-.31.29-.37.34-.21.19-.18-.07-.21-.29a7.82,7.82,0,0,1,0-.84,9.87,9.87,0,0,1,.28-1.94l1.08,1.84a7.83,7.83,0,0,1-1.05-1.87,12.13,12.13,0,0,1-.37-1.16A8,8,0,0,1,241.47,508Z"
                  transform="translate(-219.07 -477.82)"
                />
                <path
                  className={cls3}
                  d="M241.47,508l3.16,2.28c.26,2.27.05,5-.77,5s-3.71-1.69-3.71-1.69"
                  transform="translate(-219.07 -477.82)"
                />
                <path
                  className={cls4}
                  d="M245.37,513.05s-9.88-3.28-11-2.57-1,5.86-1,5.86l-3.8-3,2.37-7.94c5-2.95,13.76-2.31,13.69,6.14Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <g id="R_ARM-3" data-name="R ARM">
                <g id="R_ARM-4" data-name="R ARM">
                  <path
                    className={cls2}
                    d="M248,484.32c-7.12-1.51-15.41,17.55-15.41,17.55,2.63,4.52,8.92,6.1,13.69,5.38.23-4.74,3.72-13.82,4-19.9A2.83,2.83,0,0,0,248,484.32Z"
                    transform="translate(-219.07 -477.82)"
                  />
                  <path
                    className={cls3}
                    d="M248,484.32c-7.12-1.51-15.41,17.55-15.41,17.55,2.63,4.52,8.92,6.1,13.69,5.38.23-4.74,3.72-13.82,4-19.9A2.83,2.83,0,0,0,248,484.32Z"
                    transform="translate(-219.07 -477.82)"
                  />
                </g>
                <path
                  className={cls4}
                  d="M247.83,483A58.71,58.71,0,0,0,239,507.38a9.81,9.81,0,0,1-7.31-5.41C232.94,496.28,240,483.25,247.83,483Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
            </g>
            <g id="R_ARM_CLOTH" data-name="R ARM CLOTH">
              <path
                className={cls7}
                d="M227.54,505.88c4-6.68,13.57-23.67,20.38-22.59l.24,0a3.82,3.82,0,0,1,3.07,4.05A59.67,59.67,0,0,1,249,498.76,42.32,42.32,0,0,0,247.24,510C242,510.66,230.25,510.36,227.54,505.88Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls8}
                d="M227.54,505.88c4-6.68,13.57-23.67,20.38-22.59l.24,0a3.82,3.82,0,0,1,3.07,4.05A59.67,59.67,0,0,1,249,498.76,42.32,42.32,0,0,0,247.24,510C242,510.66,230.25,510.36,227.54,505.88Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls9}
                d="M247.34,507.84,244,503.1,237.08,508l-4.88-6.9-3.12,2.2c-.57,1-1.09,1.86-1.54,2.61,2.71,4.48,14.44,4.78,19.7,4.11C247.27,509.31,247.29,508.64,247.34,507.84Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls10}
                d="M247.34,507.84,244,503.1,237.08,508l-4.88-6.9-3.12,2.2c-.57,1-1.09,1.86-1.54,2.61,2.71,4.48,14.44,4.78,19.7,4.11C247.27,509.31,247.29,508.64,247.34,507.84Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls21}
                d="M249.11,482.19c-5.59,3.14-11.09,14.06-13,28.18-3.77-.36-8.31-1.22-9.57-4.77,0,0,8.22-15.39,12.43-19S244.74,481.58,249.11,482.19Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M227.54,505.88s.14-.49.43-1.32.73-2,1.35-3.35a51.35,51.35,0,0,1,5.44-9.49,37,37,0,0,1,3.87-4.51,21.72,21.72,0,0,1,2.1-1.81,14.89,14.89,0,0,1,2.12-1.37,8.58,8.58,0,0,1,2-.8,6,6,0,0,1,1.66-.22,4.2,4.2,0,0,1,1,.17l.35.11-.36.06a4.58,4.58,0,0,0-.94.25,7.47,7.47,0,0,0-1.3.68,13.71,13.71,0,0,0-1.49,1.17,59.44,59.44,0,0,0-6.65,8c-2.28,3.11-4.54,6.29-6.39,8.6-.91,1.17-1.72,2.12-2.3,2.78S227.54,505.88,227.54,505.88Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <line
                className={cls15}
                x1="18.55"
                y1="23.44"
                x2="18.31"
                y2="24.54"
              />
            </g>
          </g>
          <g id="COVER_1" data-name="COVER 1">
            <g id="L_COVER_1" data-name="L COVER 1">
              <path
                className={cls2}
                d="M288.59,496.18a17.57,17.57,0,0,1-5.91,1.54c-3.85-2.21-7.83-7.42-10.21-11.49a21.45,21.45,0,0,0,5.6-1.23C279.86,488.05,285.22,493.68,288.59,496.18Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls8}
                d="M288.59,496.18a17.57,17.57,0,0,1-5.91,1.54c-3.85-2.21-7.83-7.42-10.21-11.49a21.45,21.45,0,0,0,5.6-1.23C279.86,488.05,285.22,493.68,288.59,496.18Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls9}
                d="M279.73,492l-1.17,2.38a19.57,19.57,0,0,0,4.12,3.35,17.57,17.57,0,0,0,5.91-1.54c-.42-.32-.88-.68-1.36-1.09Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls10}
                d="M279.73,492l-1.17,2.38a19.57,19.57,0,0,0,4.12,3.35,17.57,17.57,0,0,0,5.91-1.54c-.42-.32-.88-.68-1.36-1.09Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls21}
                d="M274.59,485a43.92,43.92,0,0,0,11.81,13l-3.44.75a29,29,0,0,1-11.25-12.94Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M272.47,486.23s.21.16.54.46.77.77,1.27,1.3,1.05,1.15,1.65,1.8c.3.33.57.69.89,1s.61.69.91,1.05.62.69.91,1,.59.69.89,1,.55.67.82,1,.53.6.76.88c.45.57.88,1,1.15,1.37s.42.58.42.58a6.58,6.58,0,0,1-.69-.16,8.12,8.12,0,0,1-.78-.24,5.87,5.87,0,0,1-1-.4c-.34-.17-.71-.36-1.09-.58a11.55,11.55,0,0,1-1.13-.79,14.91,14.91,0,0,1-2.18-2c-.32-.4-.64-.8-.93-1.2a12.08,12.08,0,0,1-.78-1.24,12.49,12.49,0,0,1-1.08-2.37,10.73,10.73,0,0,1-.48-1.77C272.49,486.49,272.47,486.23,272.47,486.23Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
            </g>
            <g id="R_COVER_1" data-name="R COVER 1">
              <path
                className={cls2}
                d="M268.19,487c-2.13,3.63-6.47,9.22-10.72,11.68-9.77-1.27-21.24-5.76-25.81-10.36a48.41,48.41,0,0,0,7.38-8A99.37,99.37,0,0,0,268.19,487Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls8}
                d="M268.19,487c-2.13,3.63-6.47,9.22-10.72,11.68-9.77-1.27-21.24-5.76-25.81-10.36a48.41,48.41,0,0,0,7.38-8A99.37,99.37,0,0,0,268.19,487Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls9}
                d="M260.63,492.57l-8,3.5-3.43-7.69-7.89,3.44-3.21-7.51-4,1.76c-.77.76-1.56,1.51-2.39,2.22,4.57,4.6,16,9.09,25.81,10.36a20.71,20.71,0,0,0,4.38-3.59Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls10}
                d="M260.63,492.57l-8,3.5-3.43-7.69-7.89,3.44-3.21-7.51-4,1.76c-.77.76-1.56,1.51-2.39,2.22,4.57,4.6,16,9.09,25.81,10.36a20.71,20.71,0,0,0,4.38-3.59Z"
                transform="translate(-219.07 -477.82)"
              />
              <path
                className={cls21}
                d="M246.41,482a16.08,16.08,0,0,0,3.57,16c-7-1.58-14.77-4.9-19.61-9.59a70.29,70.29,0,0,0,8.35-9.19Z"
                transform="translate(-219.07 -477.82)"
              />
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M239,480.32a27.08,27.08,0,0,1-1.4,2.41c-.48.79-1,1.52-1.54,2.28q-1.6,2.22-3.38,4.27l-.06-2c.49.39,1,.8,1.45,1.2l.75.56c.26.19.49.4.76.57.53.35,1,.73,1.57,1.07l.81.52c.27.17.53.36.81.51.56.33,1.1.68,1.67,1s1.12.68,1.7,1,1.14.68,1.73,1,1.17.68,1.75,1.07c-.7,0-1.38-.08-2.07-.15s-1.35-.22-2-.38-1.33-.33-2-.53-1.3-.47-2-.72-1.26-.59-1.88-.89a10.28,10.28,0,0,1-.91-.53l-.9-.54c-.3-.19-.57-.41-.86-.62l-.84-.63c-.55-.45-1.06-.94-1.58-1.42l-1.08-1,1-1c1.27-1.28,2.6-2.52,4-3.7.72-.57,1.4-1.17,2.15-1.71A27.32,27.32,0,0,1,239,480.32Z"
                  transform="translate(-219.07 -477.82)"
                />
              </g>
              <line
                className={cls15}
                x1="32.57"
                y1="13.29"
                x2="33.54"
                y2="12.92"
              />
              <line className={cls15} x1="21.45" y1="5.65" x2="21.8" y2="5.8" />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}
