import React from "react"
import Button from "react-bootstrap/Button"

import {
  CenteredWrapper,
  SpacedWrapper,
} from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import { CharacterIntroScene } from "@views/english-demo-1/common/character-intro-scene"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { SceneProps } from "@views/english-demo-1/types"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"

import { H_100 } from "@helpers/constants/style"

const intro = (
  <>
    <h1>Tank the symbols and techniques</h1>
    <p>
      In order to compose a short answer, you'll need to identify key symbols
      and techniques, then ponder on their figurative meaning.
    </p>
    <p>
      This will test your <b>grit</b> and who better to help us than the{" "}
      <b>Tank</b>.
    </p>
  </>
)

const description = (
  <CenteredWrapper className={H_100}>
    <div>
      <h2 className="text-center">Tank</h2>
      <p>
        Tanks are the tough ones. They undertake the smaller questions that need
        to be answered when preparing for an overall response, and they do this
        with grit.
      </p>
    </div>
  </CenteredWrapper>
)

export const Action1C = ({
  previousScene,
  nextScene,
  isActiveScene,
}: SceneProps) => {
  useScrollTop(isActiveScene)
  return (
    <>
      <SpacedWrapper>
        <>
          <Button onClick={previousScene}>Back</Button>
          <GoogleFormLinkGeneralFeedback />
        </>
      </SpacedWrapper>
      <MainQuestionReference />
      <CharacterIntroScene
        intro={intro}
        description={description}
        avatarType="tank"
        primaryButtonOnClick={nextScene}
        primaryButtonText="Begin Tanking"
      />
    </>
  )
}
