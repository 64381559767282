import React from "react"

import { NoArgVoid } from "@components/types"

import { OnboardingScreenKeyType } from "@views/onboarding/types"

import { D_NONE } from "@helpers/constants/style"

import {
  ONBOARDING_SCREEN_KEY_WHAT,
  ONBOARDING_SCREEN_KEY_WHY,
  ONBOARDING_SCREEN_KEY_HOW,
} from "./constants"
import { HowEduCatsHelps } from "./how-educats-helps"
import { WhatIsEduCats } from "./what-is-educats"
import { WhyPastPaperPractice } from "./why-past-paper-practice"

interface Props {
  onboardingScreenKey: OnboardingScreenKeyType
  onComplete: NoArgVoid
}

const createClassName = (
  activeScreenKey: OnboardingScreenKeyType,
  screenKey: OnboardingScreenKeyType
) => {
  return activeScreenKey === screenKey ? undefined : D_NONE
}

export const OnboardingScreenSwitcher = ({
  onboardingScreenKey,
  onComplete,
}: Props) => {
  const screenProps = {
    onboardingScreenKey,
    onComplete,
  }

  // Render all at once.
  // Pros: Avoids image flickers / layout shifts
  // Cons: Impact to TTI for first render.
  // Outcome: Choose this for now for the better experience and imperceivable TTI impact.
  return (
    <>
      <WhatIsEduCats
        {...screenProps}
        className={createClassName(
          onboardingScreenKey,
          ONBOARDING_SCREEN_KEY_WHAT
        )}
      />
      <WhyPastPaperPractice
        {...screenProps}
        className={createClassName(
          onboardingScreenKey,
          ONBOARDING_SCREEN_KEY_WHY
        )}
      />
      <HowEduCatsHelps
        {...screenProps}
        className={createClassName(
          onboardingScreenKey,
          ONBOARDING_SCREEN_KEY_HOW
        )}
      />
    </>
  )

  // Render 1 at a time
  // switch (onboardingScreenKey) {
  //   case ONBOARDING_SCREEN_KEY_WHAT:
  //   default:
  //     return <WhatIsEduCats {...screenProps} />
  //   case ONBOARDING_SCREEN_KEY_WHY:
  //     return <WhyPastPaperPractice {...screenProps} />
  //   case ONBOARDING_SCREEN_KEY_HOW:
  //     return <HowEduCatsHelps {...screenProps} />
  // }
}
