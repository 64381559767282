import React from "react"

import { InnerSvg } from "@components/Avatar/common/avatar-item/inner-image"
import { MultiPartHairAsset } from "@components/Avatar/types"

import { cls1, cls2, cls4, cls5, cls6, cls7, cls8 } from "./fsl2.module.scss"

export const FringeStraightLongHair2Asset = ({
  sizing,
  factor,
  hairColour,
}: MultiPartHairAsset) => {
  return (
    <InnerSvg sizing={sizing} factor={factor} viewBox="0 0 65.4 76.48">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-39.14"
          y1="34.96"
          x2="-32.53"
          y2="37.39"
          gradientTransform="matrix(-1, 0, 0, 1, 24.23, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f6ead7" />
          <stop offset="1" stopColor="#f9e3b3" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="radial-gradient"
          cx="-37.18"
          cy="33.56"
          r="42.32"
          gradientTransform="matrix(-0.04, 1, 0.54, 0.02, 41.85, 69.95)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.15" stopColor="#f5e3cf" />
          <stop offset="0.93" stopColor="#fbc292" stopOpacity="0" />
        </radialGradient>
      </defs>
      <g className={cls1}>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="HAIR_33-3" data-name="HAIR 33-3">
              <polygon
                className={cls2}
                points="62.9 3.96 62.9 73.98 60.22 
73.98 57.51 70.7 55.58 73.98 53.65 73.98 51.17 72.48 49 73.98 18.59 73.98 16.27 71.48 13.95 73.98 2.5 73.98 2.5 2.56 62.9 3.96"
              />
              <polygon
                className={hairColour}
                points="62.9 3.96 62.9 73.98 60.22 73.98 57.51 70.7 55.58 73.98 53.65 73.98 51.17 72.48 49 73.98 18.59 73.98 16.27 71.48 13.95 73.98 2.5 73.98 2.5 2.56 62.9 3.96"
              />
              <polygon
                className={cls4}
                points="58.18 71.5 57.51 70.7 55.58 73.98 53.65 73.98 51.17 72.48 49 73.98 18.59 73.98 16.27 71.48 
13.95 73.98 2.5 73.98 2.5 2.56 58.18 3.85 58.18 71.5"
              />
              <polygon
                className={cls5}
                points="58.18 71.5 57.51 70.7 
55.58 73.98 53.65 73.98 51.17 72.48 49 73.98 18.59 73.98 16.27 71.48 13.95 73.98 2.5 73.98 2.5 2.56 58.18 3.85 
58.18 71.5"
              />
              <path d="M59.49,41a54.94,54.94,0,0,1,.5,7.78,54.8,54.8,0,0,1-.5,7.77,52,52,0,0,1-.5-7.77A52.13,52.13,0,0,1,59.49,41Z" />
              <path d="M56.74,36.81a14.18,14.18,0,0,1,.5,3.91,13.5,13.5,0,0,1-.5,3.91,13.86,13.86,0,0,1-.5-3.91A14.18,14.18,0,0,1,56.74,36.81Z" />
              <polygon points="56.25 73.14 57.75 63.51 59.25 73.14 56.25 73.14" />
              <polygon points="44.25 75.11 45.75 55.3 47.25 75.11 44.25 75.11" />
              <polygon points="33.34 74.65 34.84 71.63 36.34 74.65 33.34 74.65" />
              <polygon points="20.11 74.88 21.61 61.8 23.11 74.88 20.11 74.88" />
              <polygon points="23.9 75.11 25.4 70.78 26.9 75.11 23.9 75.11" />
              <path
                className={cls6}
                d="M63.84,25.51c-2.08,5.06-3.94,5.6-5.58,1.5V41.18c2-3.42,3.94-2.11,5.83,3.33Z"
              />
              <rect
                className={cls7}
                x="41.18"
                y="2.34"
                width="23.2"
                height="72.34"
              />
              <path className={cls8} d="M54.59,58.67a5.93,5.93,0,0,1-.2.93" />
              <path
                className={cls8}
                d="M7.05,72.27a10.63,10.63,0,0,0-.12,2.52"
              />
              <line className={cls8} x1="7.4" y1="63.26" x2="7.4" y2="67.1" />
            </g>
          </g>
        </g>
      </g>
    </InnerSvg>
  )
}
