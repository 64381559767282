import { mainQuestionMarkValue } from "@views/english-demo-1/common/constants"

export const steps = [
  {
    element: "#action-1b-1",
    popover: {
      title: "🐱‍👤 Scouting Around",
      description: "First, let's read the question",
    },
  },
  {
    element: "#action-1b-2",
    popover: {
      title: "💀 A Skeleton King!",
      description: `${mainQuestionMarkValue} marks! Answering this <b>monster</b> of a
			question is not going to be easy. Let's not engage just yet, but
			continue to <b>scout</b> by reading the stimulus.`,
    },
  },
  {
    element: "#english-demo-1-stimulus",
    popover: {
      title: "📚 Gather intel",
      description: `Read the stimulus, but keep the question in mind.`,
      position: "left",
    },
  },
  {
    element: "#action-1b-3",
    popover: {
      title: "✔️ Scouting Complete!",
      description: `Finished reading? Great job <b>Scout</b>! If we want a chance at
			beating the <b>Skeleton King</b>, we need to use our grit, and take on
			his <b>minions</b> first. This is a job for the <b>Tank</b>.`,
      position: "top",
    },
  },
]
