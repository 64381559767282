import { Sizing, Dimensions, Position, TranslatedFactoredStyles } from "./types"

export const createFactoredSizing = <T extends Sizing>(
  sizing: T,
  factor?: number
): T => {
  if (factor === 1 || factor == undefined) {
    return sizing
  }

  const factoredSizing = {}

  for (const sizingKey in sizing) {
    factoredSizing[sizingKey] = sizing[sizingKey] * factor
  }

  return factoredSizing
}

interface GetTranslatedFactoredStylesProps {
  dimensions: Dimensions
  originPosition: Position
  placementPosition: Position
  rotation?: number
  factor?: number
}

export const getTranslatedFactoredStyles = ({
  dimensions,
  originPosition,
  placementPosition,
  rotation,
  factor,
}: GetTranslatedFactoredStylesProps): TranslatedFactoredStyles => {
  const factoredDimensions = createFactoredSizing(dimensions, factor)
  const originFactoredPosition = createFactoredSizing(originPosition, factor)
  const placementFactoredPosition = createFactoredSizing(
    placementPosition,
    factor
  )

  const translate = `translate(${
    placementFactoredPosition.left - originFactoredPosition.left
  }px, ${placementFactoredPosition.top - originFactoredPosition.top}px)`

  const transform = rotation ? `${translate} rotate(${rotation}deg)` : translate

  const finalPosition = {
    transformOrigin: `${originFactoredPosition.left}px ${originFactoredPosition.top}px`,
    transform,
  }

  return {
    ...factoredDimensions,
    ...finalPosition,
  }
}
