import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { EndWrapper } from "@components/Containers/positioning-wrappers"
import { Input } from "@components/Form/Input"
import { Wrapper } from "@components/Form/types"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import { ANIMATION_FADEOUT, ANIMATION_SHAKE_X } from "@helpers/constants/style"

import { BossEmpathSceneProps } from "./types"

const LabelWrapper = ({ children }: Wrapper) => <b>{children}</b>

export const BossEmpathScene = ({
  question,
  sampleAnswer,
  previousScene,
  avatar,
  minion,
  inputProps,
  questionMaxMarks,
  elementWhenAnsweredCorrectly,
  belowNav,
}: BossEmpathSceneProps) => {
  const [hasAnswered, setHasAnswered] = React.useState(false)
  const [hasAnsweredCorrectly, setHasAnsweredCorrectly] = React.useState(false)
  const [isInvalid, setIsInvalid] = React.useState(false)
  const [showSampleAnswer, setShowSampleAnswer] = React.useState(false)

  const inputExtraProps = hasAnswered ? { plaintext: true, readOnly: true } : {}

  const seeExampleResponseButton = (
    <Button className="mb-4" onClick={() => setShowSampleAnswer(true)}>
      See Sample Response
    </Button>
  )

  let bossWrapperClassName

  if (hasAnswered && hasAnsweredCorrectly) {
    bossWrapperClassName = ANIMATION_FADEOUT
  } else if (hasAnswered && !hasAnsweredCorrectly) {
    bossWrapperClassName = ANIMATION_SHAKE_X
  }

  return (
    <section>
      <SpacedWrapper>
        <Button onClick={previousScene}>Back</Button>
        <GoogleFormLinkGeneralFeedback />
      </SpacedWrapper>
      {belowNav}
      <Container>
        <Row>
          <Col xs={12} md={6}>
            {avatar}
          </Col>
          <Col xs={12} md={6}>
            <div className={bossWrapperClassName}>{minion}</div>
          </Col>
        </Row>
      </Container>
      {question}
      <Input
        LabelWrapper={LabelWrapper}
        {...inputProps}
        {...inputExtraProps}
        isInvalid={isInvalid}
      />

      {!hasAnswered && (
        <Button
          className="mb-8"
          variant="success"
          onClick={() => {
            const value = parseInt(inputProps.value)

            if (isNaN(value) || value > questionMaxMarks || value < 0) {
              setIsInvalid(true)
            } else {
              setHasAnswered(true)
              setIsInvalid(false)
            }

            if (value === questionMaxMarks) {
              setHasAnsweredCorrectly(true)
            } else {
              setHasAnsweredCorrectly(false)
            }
          }}
        >
          Mark
        </Button>
      )}

      {showSampleAnswer && (
        <div>
          <div>
            <b>Sample Response</b>
          </div>
          {typeof sampleAnswer === "string" ? (
            <p>{sampleAnswer}</p>
          ) : (
            sampleAnswer
          )}
        </div>
      )}

      {hasAnswered &&
        hasAnsweredCorrectly &&
        !showSampleAnswer &&
        seeExampleResponseButton}

      {hasAnswered && hasAnsweredCorrectly && showSampleAnswer && (
        <SpacedWrapper>
          <>
            <GoogleFormLinkGeneralFeedback />
            {elementWhenAnsweredCorrectly}
          </>
        </SpacedWrapper>
      )}

      {hasAnswered &&
        !hasAnsweredCorrectly &&
        !showSampleAnswer &&
        seeExampleResponseButton}

      {hasAnswered && !hasAnsweredCorrectly && showSampleAnswer && (
        <EndWrapper>
          <Button
            variant="success"
            onClick={() => {
              setHasAnswered(false)
            }}
          >
            Adjust your response, and remark yourself
          </Button>
        </EndWrapper>
      )}
    </section>
  )
}
