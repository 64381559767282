import { Cache } from "aws-amplify"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { LinkButton } from "@components/Buttons/link-button"
import { CenteredWrapper } from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"
import { Img } from "@components/Img"
import { AppLayout } from "@components/layouts/app-layout"
import { LogoImg } from "@components/logo"
import { RouterItem } from "@components/types"

import { LINK_PHYSICS_LIST } from "@helpers/constants/links"
import {
  TEXT_CENTER,
  D_BLOCK,
  MB_4,
  MX_AUTO,
  MX_2,
  PX_6,
} from "@helpers/constants/style"

import dungeonSrc from "@images/dungeons/skeleton-dungeon.png"

export const PhysicsIntroView = (props: RouterItem) => {
  return (
    <AppLayout>
      <PhysicsIntro />
    </AppLayout>
  )
}

const PhysicsIntro = () => {
  Cache.clear()

  return (
    <Container className={TEXT_CENTER}>
      <Row>
        <Col xs={12}>
          <CenteredWrapper>
            <div>
              <CenteredWrapper>
                <LogoImg />
              </CenteredWrapper>
              <Col xs={12}>
                <h1>Year 12 HSC Physics</h1>
              </Col>
              <Img
                src={dungeonSrc}
                className={`${D_BLOCK} ${MX_AUTO} ${MB_4}`}
              />
              <h2>Multiple Choice Questions</h2>
            </div>
          </CenteredWrapper>
          <p>
            Practice against a set of real past paper and past paper style
            questions designed to help you become more exam ready for the HSC
            exam.
          </p>
          <CenteredWrapper>
            <LinkButton to={LINK_PHYSICS_LIST} className={`${PX_6} ${MX_2}`}>
              Explore
            </LinkButton>
            <div className={MX_2}>
              <GoogleFormLinkGeneralFeedback />
            </div>
          </CenteredWrapper>
        </Col>
      </Row>
    </Container>
  )
}
