import { AirwallexEnv } from "airwallex-payment-elements"
import { API } from "aws-amplify"

import { PaymentPayloadInput } from "@src/API"

import { get } from "@helpers/get"

import { getPaymentIntent } from "@graphql/queries"

interface PaymentIntentServiceResponse {
  error: any
  value?: {
    intent_id: string
    client_secret: string
  }
}

export const fetchPaymentIntent = async (
  paymentPayload: PaymentPayloadInput,
  env: AirwallexEnv
): Promise<PaymentIntentServiceResponse> => {
  try {
    const response = await API.graphql({
      query: getPaymentIntent,
      variables: {
        paymentPayload,
        env,
      },
    })

    console.log({ response })

    const intent_id: string = get({
      object: response,
      path: ["data", "getPaymentIntent", "id"],
      defaultValue: "",
    })

    const client_secret: string = get({
      object: response,
      path: ["data", "getPaymentIntent", "client_secret"],
      defaultValue: "",
    })

    if (intent_id === "") {
      throw new Error("intent_id is empty")
    }
    if (client_secret === "") {
      throw new Error("client_secret is empty")
    }

    return {
      error: undefined,
      value: {
        intent_id,
        client_secret,
      },
    }
  } catch (error: any) {
    console.log(error)
    return {
      error,
      value: undefined,
    }
  }
}
