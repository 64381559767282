import React from "react"
import Button from "react-bootstrap/Button"

import { Input } from "@components/Form/Input"
import { Wrapper } from "@components/Form/types"

import { BossEmpathScene } from "@views/english-demo-1/common/boss-empath-scene"
import {
  mainQuestion,
  mainQuestionMarkValue,
} from "@views/english-demo-1/common/constants"
import {
  DetailedBossMonster,
  SkeletonKing,
} from "@views/english-demo-1/common/minion"
import { ProgressBar } from "@views/english-demo-1/common/progress-bar"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { ScenePropsWithInputProps } from "@views/english-demo-1/types"
import { findSceneIndexBySceneId } from "@views/english-demo-1/utils/"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import { HERO_CLASS_EMPATH } from "@helpers/constants/hero-class"

import { question, sampleAnswer } from "./constants"

const minion = (
  <DetailedBossMonster
    marks={mainQuestionMarkValue}
    question={mainQuestion}
    bossMonster={<SkeletonKing />}
    bossMonsterTitle="Skeleton King"
  />
)
const LabelWrapper = ({ children }: Wrapper) => <b>{children}</b>

export const Action8 = ({
  index,
  scenes,
  previousScene,
  nextScene,
  inputProps,
  isActiveScene,
}: ScenePropsWithInputProps) => {
  useScrollTop(isActiveScene)
  const avatar = (
    <div className={avatarContainer}>
      {useEnglishDemoAvatar({
        heroClass: HERO_CLASS_EMPATH,
      })}
    </div>
  )
  // Gather the short response scene's answer
  const shortResponseIndex = findSceneIndexBySceneId("7", scenes)
  let previousSceneInputProps =
    scenes[shortResponseIndex ? shortResponseIndex : index - 2].inputProps

  const composedQuestion = (
    <>
      {question}
      {previousSceneInputProps && (
        <Input {...previousSceneInputProps} LabelWrapper={LabelWrapper} />
      )}
    </>
  )

  const elementWhenAnsweredCorrectly = <Button onClick={nextScene}>Next</Button>

  return (
    <section>
      <ProgressBar index={10} />
      <BossEmpathScene
        question={composedQuestion}
        sampleAnswer={sampleAnswer}
        minion={minion}
        avatar={avatar}
        inputProps={inputProps}
        previousScene={previousScene}
        elementWhenAnsweredCorrectly={elementWhenAnsweredCorrectly}
        questionMaxMarks={4}
        belowNav={<MainQuestionReference />}
      />
    </section>
  )
}
