// Read from state
import { useSelector } from "react-redux"

import { useMetaStatus } from "@helpers/hooks/use-meta-status"

import { getPhysicsUserSyncedThunkCreator } from "./async"
import { selectUserSynced } from "./selectors"

// Read from state
export const useUserSynced = () => {
  return useSelector(selectUserSynced)
}

// Read from state, but fetch if it hasn't successfully fetched
export const useFetchUserSynced = () => {
  const userSynced = useUserSynced()

  console.log("useFetchUserSynced", { userSynced })

  useMetaStatus({
    metaStatusValue: userSynced.metaStatusValue,
    usernameThunkCreator: getPhysicsUserSyncedThunkCreator,
  })

  return userSynced
}
