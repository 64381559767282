import React from "react"

export interface SvgProps {
  children: React.ReactNode
  className?: string
  viewBox?: string
  style?: React.CSSProperties
  x?: string
  y?: string
}

export const Svg = (props: SvgProps) => <svg role="img" {...props} />
