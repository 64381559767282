import React from "react"

import { SkinAsset } from "@components/Avatar/types"
import { Svg } from "@components/Svg"

import {
  cls1,
  cls2,
  cls3,
  cls4,
  cls5, // cls6,
  cls7,
  cls8,
  cls9,
  cls10,
  cls11,
  cls12,
  cls13,
  cls14,
  cls15,
  cls16,
  cls17,
  cls18,
  cls19,
  cls20,
  cls21,
  cls22,
  cls23,
  cls24,
  cls25,
} from "./nb.module.scss"

const transform = "translate(-254.99 -386.16)"

export const InnerNinjaBody = ({ skinTone }: SkinAsset) => {
  return (
    <Svg viewBox="0 0 86.06 72.32">
      <g className={cls1}>
        <g id="Layer_1" data-name="Layer 1">
          <g id="S_HOLDER" data-name="S HOLDER">
            <path
              className={cls2}
              d="M300.9,432.11l-43.27-38.25a16.39,16.39,0,0,0,4-4.52l46.37,41Z"
              transform={transform}
            />
            <path
              className={cls3}
              d="M300.9,432.11l-43.27-38.25a16.39,16.39,0,0,0,4-4.52l46.37,41Z"
              transform={transform}
            />
            <polygon
              className={cls4}
              points="3.46 6.19 49.32 46.66 54.6 44.44 6.83 2.38 3.46 6.19"
            />
          </g>
          <g id="L_ARM" data-name="L ARM">
            <g id="L_HAND" data-name="L HAND">
              <path
                className={cls5}
                d="M335,425.89s4.82,6.27,3.26,9.46c-.5,1-6,3.06-9.47,3-1.08-1-2.72-3.41-2.81-4.5-1.1-2.81.76-6.15,4.15-7.47S335,425.89,335,425.89Z"
                transform={transform}
              />
              <path
                className={skinTone}
                d="M335,425.89s4.82,6.27,3.26,9.46c-.5,1-6,3.06-9.47,3-1.08-1-2.72-3.41-2.81-4.5-1.1-2.81.76-6.15,4.15-7.47S335,425.89,335,425.89Z"
                transform={transform}
              />
              <path
                className={cls4}
                d="M338.6,428.18s-9.31,4.65-9.58,5.93,3.42,4.85,3.42,4.85l-4.84.53-3.93-7.29c2.74-8,10.13-9.86,14-5.31Z"
                transform={transform}
              />
            </g>
            <g id="L_ARM-2" data-name="L ARM">
              <path
                className={cls5}
                d="M320.33,405c-6.16,3.88,1.19,23.32,1.19,23.32l13.56-5.68c-3.14-3.56-7-12.5-11-17.06A2.85,2.85,0,0,0,320.33,405Z"
                transform={transform}
              />
              <path
                className={skinTone}
                d="M320.33,405c-6.16,3.88,1.19,23.32,1.19,23.32l13.56-5.68c-3.14-3.56-7-12.5-11-17.06A2.85,2.85,0,0,0,320.33,405Z"
                transform={transform}
              />
              <path
                className={cls4}
                d="M318.24,405.63c-.19,7.45,4.43,19.8,16,15.47l2.21,1.85-15.65,6.35S314.41,415.36,318.24,405.63Z"
                transform={transform}
              />
            </g>
            <g id="L_ARM_CLOTH_2" data-name="L ARM CLOTH 2">
              <path
                className={cls7}
                d="M318.21,402.54c2,2,5.25,6,4.46,9.31a11.4,11.4,0,0,0,8.17-.6,11.25,11.25,0,0,0-4.33-8.71C323,401,320.94,401.27,318.21,402.54Z"
                transform={transform}
              />
              <path
                className={cls8}
                d="M318.21,402.54c2,2,5.25,6,4.46,9.31a11.4,11.4,0,0,0,8.17-.6,11.25,11.25,0,0,0-4.33-8.71C323,401,320.94,401.27,318.21,402.54Z"
                transform={transform}
              />
              <path
                className={cls9}
                d="M330.62,406.74a5.17,5.17,0,0,1-2.15,1.48,7,7,0,0,1-2.61.33,25.31,25.31,0,0,1-5.17-1"
                transform={transform}
              />
              <path
                className={cls9}
                d="M328.31,403.69a5.79,5.79,0,0,1-2.84.95,16.14,16.14,0,0,1-3,.05,22.57,22.57,0,0,1-5.92-1.2"
                transform={transform}
              />
              <g className={cls10}>
                <path
                  className={cls11}
                  d="M330.56,407.43c-2.16,2.63-4.72-2.81-10.39-6.42l-2.11.48a22.07,22.07,0,0,1,6.42,9.24c3.09.49,5.82.8,7.6-1.13Z"
                  transform={transform}
                />
              </g>
              <path
                className={cls12}
                d="M317.5,401.26a23.8,23.8,0,0,1,6.37,9.7c2.59.8,6.32,1.46,8.5.15l.15,2c-2.7,1.44-6.9,1.25-10.72.07a22.37,22.37,0,0,0-6.11-11.57Z"
                transform={transform}
              />
              <path
                className={cls13}
                d="M317.5,401.26a23.8,23.8,0,0,1,6.37,9.7c2.59.8,6.32,1.46,8.5.15l.15,2c-2.7,1.44-6.9,1.25-10.72.07a22.37,22.37,0,0,0-6.11-11.57Z"
                transform={transform}
              />
              <line
                className={cls9}
                x1="69.68"
                y1="24.02"
                x2="66.65"
                y2="27.45"
              />
              <path
                className={cls4}
                d="M323.14,411.65c2.61,1.63,7.94,1.56,10.05.05v2.22c-3.52,2-7.76,1.47-12.18.35a27.09,27.09,0,0,0-6.35-12.79l1-.65C318.56,402.51,321.38,407.42,323.14,411.65Z"
                transform={transform}
              />
            </g>
          </g>
          <g id="L_FINGERS" data-name="L FINGERS">
            <path
              className={cls14}
              d="M326.86,436.65c-.32.83.55,5.51,2.27,5.47s9.48-3.7,9.63-5.41-3.45-4-4.56-4.26S327.28,435.55,326.86,436.65Z"
              transform={transform}
            />
            <path
              className={skinTone}
              d="M326.86,436.65c-.32.83.55,5.51,2.27,5.47s9.48-3.7,9.63-5.41-3.45-4-4.56-4.26S327.28,435.55,326.86,436.65Z"
              transform={transform}
            />
            <path
              className={cls14}
              d="M330,431.77c-.45,1.42.52,4.22,1.55,4.23.85-1,4.31-3.19,5.42-3.18.47-1.6-.87-4.28-2.28-4.65C333.4,428.73,330.44,430.42,330,431.77Z"
              transform={transform}
            />
            <path
              className={skinTone}
              d="M330,431.77c-.45,1.42.52,4.22,1.55,4.23.85-1,4.31-3.19,5.42-3.18.47-1.6-.87-4.28-2.28-4.65C333.4,428.73,330.44,430.42,330,431.77Z"
              transform={transform}
            />
          </g>
          <g id="L_LEG" data-name="L LEG">
            <g id="L_LEG_2" data-name="L LEG 2">
              <path
                className={cls15}
                d="M308.54,444.53c2.86,1.15,8.93,1.44,12.18,1.06l.49,3.77s2.63,2.73,2.05,3.06c-2.17,1.26-11.91,1.82-14.72.6C308,452.77,308.54,444.53,308.54,444.53Z"
                transform={transform}
              />
              <path
                className={cls16}
                d="M308.54,444.53c2.86,1.15,8.93,1.44,12.18,1.06l.49,3.77s2.63,2.73,2.05,3.06c-2.17,1.26-11.91,1.82-14.72.6C308,452.77,308.54,444.53,308.54,444.53Z"
                transform={transform}
              />
              <path
                d="M321.85,450a20.57,20.57,0,0,0-7.19-.93,21.5,21.5,0,0,1,7.63-1Z"
                transform={transform}
              />
              <g className={cls10}>
                <path
                  className={cls17}
                  d="M307.5,449.86c4.63,1.95,10.48,1.93,17.18.57a2.57,2.57,0,0,1-1.88,2.78c-2.17.63-14.42.72-14.95.14S307.5,449.86,307.5,449.86Z"
                  transform={transform}
                />
              </g>
            </g>
            <g id="L_SHOW" data-name="L SHOW">
              <path
                className={cls12}
                d="M320.31,454.24l-1.78-3.3h-.46l-.09,3.52a31,31,0,0,1-10.66-1.5v1.52a31.91,31.91,0,0,0,18,0V453A24,24,0,0,1,320.31,454.24Z"
                transform={transform}
              />
              <path
                className={cls3}
                d="M320.31,454.24l-1.78-3.3h-.46l-.09,3.52a31,31,0,0,1-10.66-1.5v1.52a31.91,31.91,0,0,0,18,0V453A24,24,0,0,1,320.31,454.24Z"
                transform={transform}
              />
            </g>
            <g id="L_LEG_1" data-name="L LEG 1">
              <g id="L_LEG_1-2" data-name="L LEG 1">
                <path
                  className={cls5}
                  d="M319.51,428.55c0,4.71.3,9.4.46,14a22.57,22.57,0,0,1-12.09,1.27l-3.37-13.05C305.91,423.33,314.05,420.36,319.51,428.55Z"
                  transform={transform}
                />
                <path
                  className={skinTone}
                  d="M319.51,428.55c0,4.71.3,9.4.46,14a22.57,22.57,0,0,1-12.09,1.27l-3.37-13.05C305.91,423.33,314.05,420.36,319.51,428.55Z"
                  transform={transform}
                />
                <path
                  className={cls4}
                  d="M309.56,423.21c-1.35,6.07.8,18.23,4.64,21.89l-6.84-.23c-2.28-4.53-2.6-9.1-3.87-14.36C304.19,427.15,305.62,424.23,309.56,423.21Z"
                  transform={transform}
                />
              </g>
              <g id="L_LEG_CLOTH" data-name="L LEG CLOTH">
                <path
                  className={cls5}
                  d="M304.24,430.46l3.14,13.84c3.67,1.14,9.92.31,13.38-1.32l-1.17-14.54C315.05,419.53,304.27,423.43,304.24,430.46Z"
                  transform={transform}
                />
                <path
                  className={cls8}
                  d="M304.24,430.46l3.14,13.84c3.67,1.14,9.92.31,13.38-1.32l-1.17-14.54C315.05,419.53,304.27,423.43,304.24,430.46Z"
                  transform={transform}
                />
              </g>
            </g>
          </g>
          <g id="R_LEG" data-name="R LEG">
            <g id="R_LEG_2" data-name="R LEG 2">
              <path
                className={cls15}
                d="M284.33,443.67c2.73,1.43,8.75,2.3,12,2.23l.13,3.8s2.35,3,1.74,3.24c-2.28,1-12,.67-14.71-.81C283,451.82,284.33,443.67,284.33,443.67Z"
                transform={transform}
              />
              <path
                className={cls16}
                d="M284.33,443.67c2.73,1.43,8.75,2.3,12,2.23l.13,3.8s2.35,3,1.74,3.24c-2.28,1-12,.67-14.71-.81C283,451.82,284.33,443.67,284.33,443.67Z"
                transform={transform}
              />
              <path
                d="M297.05,450.43a20.85,20.85,0,0,0-7.06-1.61,21.65,21.65,0,0,1,7.69-.28Z"
                transform={transform}
              />
              <g className={cls10}>
                <path
                  className={cls17}
                  d="M282.53,449c4.22,2.71,10,3.69,16.83,3.5a2.56,2.56,0,0,1-2.33,2.41c-2.25.25-14.34-1.75-14.75-2.41S282.53,449,282.53,449Z"
                  transform={transform}
                />
              </g>
            </g>
            <g id="R_SHOE" data-name="R SHOE">
              <path
                className={cls12}
                d="M294.67,455l-1.38-3.41-.81,0v3.65a31.08,31.08,0,0,1-10.76-1.5v1.52a31.91,31.91,0,0,0,18,0v-1.52A23.66,23.66,0,0,1,294.67,455Z"
                transform={transform}
              />
              <path
                className={cls3}
                d="M294.67,455l-1.38-3.41-.81,0v3.65a31.08,31.08,0,0,1-10.76-1.5v1.52a31.91,31.91,0,0,0,18,0v-1.52A23.66,23.66,0,0,1,294.67,455Z"
                transform={transform}
              />
            </g>
            <g id="R_LEG_1" data-name="R LEG 1">
              <g id="R_LEG_1-2" data-name="R LEG 1">
                <path
                  className={cls5}
                  d="M300.27,430.4l-4.11,14.22A18.1,18.1,0,0,1,284.4,442l.81-13.35C289,422,298.83,424.54,300.27,430.4Z"
                  transform={transform}
                />
                <path
                  className={skinTone}
                  d="M300.27,430.4l-4.11,14.22A18.1,18.1,0,0,1,284.4,442l.81-13.35C289,422,298.83,424.54,300.27,430.4Z"
                  transform={transform}
                />
                <path
                  className={cls4}
                  d="M293.08,424.17c-3.45,5.17-5.43,16.59-3.18,21.39l-6.29-2.69c-.48-5.05.12-9.37.85-14.74A8.34,8.34,0,0,1,293.08,424.17Z"
                  transform={transform}
                />
              </g>
              <g id="R_LEG_CLOTH" data-name="R LEG CLOTH">
                <path
                  className={cls5}
                  d="M284.83,428.33l-.88,14c3.72,3.08,7.38,3.43,12.16,2.49L300.48,430C299,422.89,287,421.6,284.83,428.33Z"
                  transform={transform}
                />
                <path
                  className={cls8}
                  d="M284.83,428.33l-.88,14c3.72,3.08,7.38,3.43,12.16,2.49L300.48,430C299,422.89,287,421.6,284.83,428.33Z"
                  transform={transform}
                />
              </g>
            </g>
          </g>
          <g id="PANTS">
            <path
              className={cls15}
              d="M311.18,432.8c-.74,4.37,2.36,8.43,2.86,12.06,3.88-.06,7.25-.63,8.7-1.84-.88-4.23-2.08-7.37-2.72-11.09Z"
              transform={transform}
            />
            <path
              className={cls8}
              d="M311.18,432.8c-.74,4.37,2.36,8.43,2.86,12.06,3.88-.06,7.25-.63,8.7-1.84-.88-4.23-2.08-7.37-2.72-11.09Z"
              transform={transform}
            />
            <path
              className={cls18}
              d="M317,444.7l-4-12.07-1.73.17c-.74,4.37,2.36,8.43,2.86,12.06C315.06,444.84,316,444.79,317,444.7Z"
              transform={transform}
            />
            <path
              className={cls19}
              d="M317,444.7l-4-12.07-1.73.17c-.74,4.37,2.36,8.43,2.86,12.06C315.06,444.84,316,444.79,317,444.7Z"
              transform={transform}
            />
            <path
              className={cls15}
              d="M284.87,429.93A79.77,79.77,0,0,1,281,442.15c7,3.24,20.43,3.59,27.84,3-.58-4.28,1.78-8.56,2.33-12.69Z"
              transform={transform}
            />
            <path
              className={cls8}
              d="M284.87,429.93A79.77,79.77,0,0,1,281,442.15c7,3.24,20.43,3.59,27.84,3-.58-4.28,1.78-8.56,2.33-12.69Z"
              transform={transform}
            />
            <path
              className={cls18}
              d="M311.13,432.47l-4.25-.41c-1,4.28-1.34,9.1-2,13.3,1.43,0,2.77-.11,3.94-.2C308.22,440.88,310.58,436.6,311.13,432.47Z"
              transform={transform}
            />
            <path
              className={cls19}
              d="M311.13,432.47l-4.25-.41c-1,4.28-1.34,9.1-2,13.3,1.43,0,2.77-.11,3.94-.2C308.22,440.88,310.58,436.6,311.13,432.47Z"
              transform={transform}
            />
            <g className={cls10}>
              <path
                className={cls11}
                d="M311.5,436.85c-9.13-.61-23.12-2.17-24.29,2.33-.77,3,12.61,8.18,12.61,8.18A61.47,61.47,0,0,1,279.71,443s3.56-9.81,3.75-13.87c5.71,1.11,23.25,2.12,29.21,2.49Z"
                transform={transform}
              />
            </g>
            <g className={cls20}>
              <path
                className={cls21}
                d="M285,430q0,.84-.12,1.65c-.07.54-.13,1.08-.22,1.62s-.17,1.06-.28,1.59l-.33,1.58c-.26,1-.49,2.09-.81,3.11l-.46,1.53c-.16.52-.34,1-.51,1.52l-.72-1.6c.75.3,1.48.63,2.21,1s1.51.6,2.26.93c.39.13.77.29,1.16.44s.77.3,1.16.48c.79.28,1.58.59,2.37,1l0,.15-2.49-.4-2.47-.56-2.42-.74-2.36-.93-.11,0,0-.12c.63-2.05,1.32-4.07,2-6.1l2-6.1Z"
                transform={transform}
              />
            </g>
          </g>
          <g id="TORSO">
            <g id="TORSO-2" data-name="TORSO">
              <path
                className={cls5}
                d="M283,397.36a58.68,58.68,0,0,0,3.75,15.12L285,428a92.36,92.36,0,0,0,35,1.6l-.52-29.74C310.42,388.4,288.26,383.23,283,397.36Z"
                transform={transform}
              />
              <path
                className={skinTone}
                d="M283,397.36a58.68,58.68,0,0,0,3.75,15.12L285,428a92.36,92.36,0,0,0,35,1.6l-.52-29.74C310.42,388.4,288.26,383.23,283,397.36Z"
                transform={transform}
              />
              <path
                className={cls4}
                d="M300.6,387.74c-9.5,4.25-8.34,28.7-6.16,33.2,1,2.06,17.34,4.67,26.75,3.59l-.08,6.08c-15.54,2.62-27.5.94-36.65-2.48l1.61-15.59a55.2,55.2,0,0,1-3.81-15.74C285.49,390,291.21,386.46,300.6,387.74Z"
                transform={transform}
              />
            </g>
            <g id="TORSO_CLOTH" data-name="TORSO CLOTH">
              <path
                className={cls15}
                d="M286.06,389.16a16.55,16.55,0,0,0-6.86,7s5.11,12.74,5.65,16.37.35,14.29.35,14.29c13.95,3.62,27.64,5.12,35.2,1.43-1.26-6.76.67-18.17-.55-27.28-.08-.19-.16-.37-.26-.56l-10,15.83s-13.16-17.08-15.33-19.17c-1.49-1.42-5.66-5.5-8.16-7.95"
                transform={transform}
              />
              <path
                className={cls8}
                d="M286.06,389.16a16.55,16.55,0,0,0-6.86,7s5.11,12.74,5.65,16.37.35,14.29.35,14.29c13.95,3.62,27.64,5.12,35.2,1.43-1.26-6.76.67-18.17-.55-27.28-.08-.19-.16-.37-.26-.56l-10,15.83s-13.16-17.08-15.33-19.17c-1.49-1.42-5.66-5.5-8.16-7.95"
                transform={transform}
              />
              <g className={cls10}>
                <path
                  className={cls11}
                  d="M296.28,419.38c2,2.54,16.13,4.85,24.67,4l.51,6c-6.58,3.77-26.62,1.83-37.61-1.94l-.62-15.38-5.35-16.47,4.59-5.5,5.41,4.42s1,6.45,1.5,8.41C290.46,407.35,292.75,414.86,296.28,419.38Z"
                  transform={transform}
                />
              </g>
              <path
                className={cls9}
                d="M296.17,418.82a18.76,18.76,0,0,0,5.24,2.61"
                transform={transform}
              />
              <path
                className={cls9}
                d="M317.57,423.44a6.48,6.48,0,0,0,3.41-.09"
                transform={transform}
              />
              <path
                className={cls22}
                d="M308.87,415.29s5.14-8,6.77-10.68c.89-1.48,3.43-6,3.43-6a6.67,6.67,0,0,1,1,2.87s-1.24,2.82-1.72,3.72c-1.83,3.38-8.12,13-8.12,13Z"
                transform={transform}
              />
              <path
                className={cls19}
                d="M308.87,415.29s5.14-8,6.77-10.68c.89-1.48,3.43-6,3.43-6a6.67,6.67,0,0,1,1,2.87s-1.24,2.82-1.72,3.72c-1.83,3.38-8.12,13-8.12,13Z"
                transform={transform}
              />
              <path
                className={cls22}
                d="M297.67,403.89s7.85,9.84,9.14,13.76c1,3,.62,12.43.62,12.43l2.57.21s1.62-10.52.71-13.87-7.6-11.61-7.6-11.61a168.42,168.42,0,0,0-14.83-16.93,9.34,9.34,0,0,0-4.34,2.41A74.6,74.6,0,0,1,297.67,403.89Z"
                transform={transform}
              />
              <path
                className={cls19}
                d="M297.67,403.89s7.85,9.84,9.14,13.76c1,3,.62,12.43.62,12.43l2.57.21s1.62-10.52.71-13.87-7.6-11.61-7.6-11.61a168.42,168.42,0,0,0-14.83-16.93,9.34,9.34,0,0,0-4.34,2.41A74.6,74.6,0,0,1,297.67,403.89Z"
                transform={transform}
              />
              <g className={cls20}>
                <path
                  className={cls21}
                  d="M279.2,396.2c.55,1.19,1.09,2.4,1.61,3.61l1.54,3.65c.5,1.23,1,2.46,1.42,3.71.23.62.46,1.24.66,1.87s.44,1.26.61,1.91l.25,1,.13.5c0,.23,0,.34.05.52l.19,2c.06.66.14,1.31.14,2l.07,2c.11,2.68.08,5.3.07,7.95l-.56-.73c1.45.35,2.88.72,4.33,1.05s2.9.65,4.35.93,2.92.55,4.38.79l2.2.33,2.2.29c1.47.16,3,.3,4.43.38l2.21.06,2.22,0c.74-.06,1.48-.07,2.21-.15s1.48-.14,2.2-.3l1.1-.2,1.07-.3a18.13,18.13,0,0,0,2.12-.7,18.92,18.92,0,0,1-2,.93l-1.06.38-1.09.3c-.72.22-1.47.32-2.21.48s-1.49.22-2.23.32l-2.26.15-2.26,0c-1.5,0-3-.07-4.51-.16s-3-.29-4.49-.48-3-.43-4.45-.71-2.94-.58-4.41-.91-2.92-.68-4.37-1.06l-.56-.14v-.59c0-2.61.06-5.27,0-7.86v-2c0-.64-.05-1.31-.09-2l-.1-2,0-.46-.09-.46-.19-.94c-.14-.62-.34-1.25-.5-1.88s-.37-1.26-.57-1.88c-.38-1.26-.81-2.5-1.21-3.76C280.9,401.19,280,398.7,279.2,396.2Z"
                  transform={transform}
                />
              </g>
            </g>
          </g>
          <g id="BELT">
            <path
              className={cls5}
              d="M283.32,423.48a73,73,0,0,0,38.54,4.28c1.25-.2.46,3.76.46,3.76-12.91,3.32-26,1.89-38.88-1.54A7,7,0,0,1,283.32,423.48Z"
              transform={transform}
            />
            <path
              className={cls23}
              d="M283.32,423.48a73,73,0,0,0,38.54,4.28c1.25-.2.46,3.76.46,3.76-12.91,3.32-26,1.89-38.88-1.54A7,7,0,0,1,283.32,423.48Z"
              transform={transform}
            />
            <g className={cls10}>
              <path
                className={cls24}
                d="M281.24,424.43c9.33,4.92,30.24,7.57,42.24,4.57l-.45,3.53c-13.83,4-27.26,2.34-40.5-1.75Z"
                transform={transform}
              />
            </g>
            <path
              className={cls9}
              d="M287.92,427.3a53.51,53.51,0,0,0,13.69,2.75"
              transform={transform}
            />
            <path
              className={cls5}
              d="M312.48,432.81a20.89,20.89,0,0,0,1.85,5c.28.45,2.55-.43,3.22-1.65s-3.49-4.79-4.06-4.66A1.09,1.09,0,0,0,312.48,432.81Z"
              transform={transform}
            />
            <path
              className={cls23}
              d="M312.48,432.81a20.89,20.89,0,0,0,1.85,5c.28.45,2.55-.43,3.22-1.65s-3.49-4.79-4.06-4.66A1.09,1.09,0,0,0,312.48,432.81Z"
              transform={transform}
            />
            <g className={cls10}>
              <path
                className={cls24}
                d="M312.31,431l4.32,7.29-2.56.72S311.51,432.36,312.31,431Z"
                transform={transform}
              />
            </g>
            <path
              className={cls5}
              d="M310.15,433.06a20.78,20.78,0,0,0-2.74,4.52c-.19.51,1.91,1.74,3.29,1.51s1.59-5.71,1.14-6.08A1.1,1.1,0,0,0,310.15,433.06Z"
              transform={transform}
            />
            <path
              className={cls23}
              d="M310.15,433.06a20.78,20.78,0,0,0-2.74,4.52c-.19.51,1.91,1.74,3.29,1.51s1.59-5.71,1.14-6.08A1.1,1.1,0,0,0,310.15,433.06Z"
              transform={transform}
            />
            <g className={cls10}>
              <path
                className={cls24}
                d="M311.19,432.24l-2.25,7.45c-1.42,0-2.4-.75-2.68-2.73C306.26,437,308.62,432,311.19,432.24Z"
                transform={transform}
              />
            </g>
            <path
              className={cls9}
              d="M309.44,437.23c-.17,1.07-.65,2-.9,3.09"
              transform={transform}
            />
          </g>
          <g id="R_ARM" data-name="R ARM">
            <g id="R_ARM-2" data-name="R ARM">
              <path
                className={cls15}
                d="M280.45,404.22c-7.2-1-14.15,18.58-14.15,18.58a18.85,18.85,0,0,0,14,4.43c-.11-4.76,2.75-14.05,2.6-20.14A2.85,2.85,0,0,0,280.45,404.22Z"
                transform={transform}
              />
              <path
                className={skinTone}
                d="M280.45,404.22c-7.2-1-14.15,18.58-14.15,18.58a18.85,18.85,0,0,0,14,4.43c-.11-4.76,2.75-14.05,2.6-20.14A2.85,2.85,0,0,0,280.45,404.22Z"
                transform={transform}
              />
              <path
                className={cls4}
                d="M278.46,403.35c-4.95,5.55-12.67,18.48,2.94,22.67l-.25,2.66c-6.67,0-12.09-1.61-16.06-5.07C265.09,423.61,269.25,408.3,278.46,403.35Z"
                transform={transform}
              />
            </g>
            <g id="R_HAND" data-name="R HAND">
              <path
                className={cls25}
                d="M276.93,432.25c-.35,3.4.07,7.08-4.14,9.13-3.32.22-6.09-1.18-8.71-3.84-.17-1.44.2-4.36.84-5.24,1-2.84,4.62-4.14,8-2.91S276.93,432.25,276.93,432.25Z"
                transform={transform}
              />
              <path
                className={skinTone}
                d="M276.93,432.25c-.35,3.4.07,7.08-4.14,9.13-3.32.22-6.09-1.18-8.71-3.84-.17-1.44.2-4.36.84-5.24,1-2.84,4.62-4.14,8-2.91S276.93,432.25,276.93,432.25Z"
                transform={transform}
              />
              <path
                d="M274.18,432a7.85,7.85,0,0,1,1.35-.34c.41,0,.83-.1,1.22-.1a8,8,0,0,1,2.13.26l.42.11.78,1.66a6.88,6.88,0,0,1,.65,2.72,6.61,6.61,0,0,1-.1,1.51,6.18,6.18,0,0,1-.72,2,4.3,4.3,0,0,1-.66.86,3.1,3.1,0,0,1-1.7.87,2.72,2.72,0,0,1-1.07-.08,2.29,2.29,0,0,1-.54-.2,4.71,4.71,0,0,1-1.23-1,7,7,0,0,1-1.47-2.61,15.1,15.1,0,0,1,2.79-.64,4.44,4.44,0,0,0,1-.22c.05,0,.09-.05,0-.1s-.18-.1-.48-.06a2.2,2.2,0,0,0-1.21.57c-.21.18-.29.3-.36.36s-.18-.06-.23-.27,0-.54-.05-.84a9.61,9.61,0,0,1,.15-2l1.2,1.77a8.34,8.34,0,0,1-1.17-1.8c-.17-.34-.3-.75-.45-1.14A8.32,8.32,0,0,1,274.18,432Z"
                transform={transform}
              />
              <path
                className={skinTone}
                d="M274.18,432l3.31,2.06c.41,2.24.37,4.94-.44,5.06s-3.81-1.44-3.81-1.44"
                transform={transform}
              />
            </g>
            <g id="R_ARM_CLOTH_2" data-name="R ARM CLOTH 2">
              <path
                className={cls12}
                d="M279.34,430.5a27,27,0,0,1-5.63,1c-2.39-.79-7.07-2.52-10.75-4,0,0-1.19-4.56.4-6,3.79,1.64,9.07,3.79,11.73,4.77,1.39.1,4.17.14,6.19.08A11.52,11.52,0,0,1,279.34,430.5Z"
                transform={transform}
              />
              <path
                className={cls13}
                d="M279.34,430.5a27,27,0,0,1-5.63,1c-2.39-.79-7.07-2.52-10.75-4,0,0-1.19-4.56.4-6,3.79,1.64,9.07,3.79,11.73,4.77,1.39.1,4.17.14,6.19.08A11.52,11.52,0,0,1,279.34,430.5Z"
                transform={transform}
              />
              <path
                className={cls12}
                d="M276.91,435.3a46.27,46.27,0,0,1-4.63.88s-6.46-2.21-9.62-3.52a15.58,15.58,0,0,1-.51-5.19c3.59,1.74,11.16,4.42,11.16,4.42l5.28-.2A11.34,11.34,0,0,1,276.91,435.3Z"
                transform={transform}
              />
              <path
                className={cls13}
                d="M276.91,435.3a46.27,46.27,0,0,1-4.63.88s-6.46-2.21-9.62-3.52a15.58,15.58,0,0,1-.51-5.19c3.59,1.74,11.16,4.42,11.16,4.42l5.28-.2A11.34,11.34,0,0,1,276.91,435.3Z"
                transform={transform}
              />
              <path
                d="M265.47,424.36a.93.93,0,1,1-.92-.93A.93.93,0,0,1,265.47,424.36Z"
                transform={transform}
              />
              <path
                d="M273.36,427.4a.93.93,0,1,1-.92-.92A.93.93,0,0,1,273.36,427.4Z"
                transform={transform}
              />
              <path
                d="M271.56,433.26a.93.93,0,1,1-.93-.93A.93.93,0,0,1,271.56,433.26Z"
                transform={transform}
              />
              <path
                d="M264.28,430.85a.93.93,0,1,1-.92-.92A.92.92,0,0,1,264.28,430.85Z"
                transform={transform}
              />
              <path
                className={cls4}
                d="M261.26,426.49c3.05,3.65,12.05,7.23,12.05,7.23a34.28,34.28,0,0,0,5.61-.42l-1.44,3a46.76,46.76,0,0,0-5.3.72s-7.3-2.43-10.33-4.16Z"
                transform={transform}
              />
              <path
                className={cls4}
                d="M263.09,420.6c1.69,2.64,8.3,6.16,11.75,7.1a66.31,66.31,0,0,0,6.86.1L280.29,431c-1.88.06-6.93,0-6.93,0s-8.67-2.88-11.63-4.58C261.8,424.56,261.37,422.81,263.09,420.6Z"
                transform={transform}
              />
              <path
                className={cls9}
                d="M267.66,426.67a4.78,4.78,0,0,0,0,1.31"
                transform={transform}
              />
              <path
                className={cls9}
                d="M278.85,427.79a24.4,24.4,0,0,0,2.82-.25"
                transform={transform}
              />
              <line className={cls9} x1="21.5" y1="47.5" x2="23.5" y2="47.25" />
            </g>
            <g id="R_ARM_CLOTH" data-name="R ARM CLOTH">
              <path
                className={cls7}
                d="M284.33,403.86a49.43,49.43,0,0,1-.41,9.33s-8,.84-10.59.25a6,6,0,0,1-3.75-3.08,34.56,34.56,0,0,1,5.75-8.38A10.4,10.4,0,0,1,284.33,403.86Z"
                transform={transform}
              />
              <path
                className={cls8}
                d="M284.33,403.86a49.43,49.43,0,0,1-.41,9.33s-8,.84-10.59.25a6,6,0,0,1-3.75-3.08,34.56,34.56,0,0,1,5.75-8.38A10.4,10.4,0,0,1,284.33,403.86Z"
                transform={transform}
              />
              <path
                className={cls12}
                d="M284.12,402.59A18.87,18.87,0,0,1,283,412c-6.09.52-12.15-.11-14.17-3.92l-1.08,2.08c2.6,5.11,8.85,5.73,17.42,4,1.37-3.21,2.06-8,.62-11.27Z"
                transform={transform}
              />
              <path
                className={cls13}
                d="M284.12,402.59A18.87,18.87,0,0,1,283,412c-6.09.52-12.15-.11-14.17-3.92l-1.08,2.08c2.6,5.11,8.85,5.73,17.42,4,1.37-3.21,2.06-8,.62-11.27Z"
                transform={transform}
              />
              <path
                className={cls9}
                d="M271.51,406a5.15,5.15,0,0,0,2.34,1.88,10.77,10.77,0,0,0,3,.72,30.14,30.14,0,0,0,6.08-.07"
                transform={transform}
              />
              <path
                className={cls9}
                d="M273.34,402.79a5.07,5.07,0,0,0,2,1.38,9.23,9.23,0,0,0,2.39.55,18.23,18.23,0,0,0,4.89-.26"
                transform={transform}
              />
              <line
                className={cls9}
                x1="27.24"
                y1="25.04"
                x2="30.66"
                y2="28.35"
              />
              <path
                className={cls4}
                d="M268.79,407.42c-.17,4.62,7.13,6.62,14.31,5l3.15,2.65c-9.35,2-17.57.78-19.52-4.92Z"
                transform={transform}
              />
              <g className={cls10}>
                <path
                  className={cls11}
                  d="M270.5,406.11c3.5,4.67,11.83,2,10.91-4.63l1.92.34a17.27,17.27,0,0,1-.75,9.37c-6,.65-10.48-.33-13-3.46Z"
                  transform={transform}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}
