interface Get {
  object?: {
    [key: string]: any
  } | null
  path: string[]
  defaultValue: any
}

export const get = ({ object, path, defaultValue }: Get): any => {
  if (!object || typeof object !== "object") {
    return defaultValue
  }

  try {
    let val: any = object
    for (let i = 0; i < path.length; i++) {
      val = val[path[i]]

      if (val == null) {
        // The return value cannot be null/undefined
        // It can be 0 or "" though
        return defaultValue
      }
    }
    return val
  } catch (e) {
    return defaultValue
  }
}
