import React from "react"

import { Sizing } from "@components/Avatar/types"
import { createFactoredSizing } from "@components/Avatar/utils"
import { Svg, SvgProps } from "@components/Svg"

import { POSITION_RELATIVE } from "@helpers/constants/style"

interface InnerImageProps {
  src: string
  sizing: Sizing
  factor?: number
}

export const InnerImage = ({ src, sizing, factor }: InnerImageProps) => {
  return (
    <img
      className={POSITION_RELATIVE}
      src={src}
      alt=""
      style={createFactoredSizing(sizing, factor)}
    />
  )
}

interface InnerSvgProps extends SvgProps {
  sizing: Sizing
  factor?: number
}

export const InnerSvg = ({
  sizing,
  factor,
  ...restSvgProps
}: InnerSvgProps) => {
  return (
    <Svg
      className={POSITION_RELATIVE}
      style={createFactoredSizing(sizing, factor)}
      {...restSvgProps}
    />
  )
}
