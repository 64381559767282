import { classnames } from "classnames-joiner"
import React from "react"

import { Problem } from "@components/problem"

import {
  COL_12,
  OFFSET_MD_1,
  COL_MD_5,
  ORDER_2,
  ORDER_MD_1,
  MT_10,
} from "@helpers/constants/style"

import { onAnsweredCorrectly } from "./onAnsweredCorrectly"

type PropsType = {
  aboveProblemPartElement?: React.ReactNode
  belowSolutionElement?: React.ReactNode
}

export const EDUCATIONAL_CONTAINER_DOM_ID = "educational-container"

export const EducationalContainer = ({
  aboveProblemPartElement,
  belowSolutionElement,
}: PropsType) => {
  return (
    <div
      id={EDUCATIONAL_CONTAINER_DOM_ID}
      className={classnames([
        COL_12,
        OFFSET_MD_1,
        COL_MD_5,
        ORDER_2,
        ORDER_MD_1,
      ])}
    >
      <div className={MT_10}>
        {aboveProblemPartElement}
        <Problem
          onAnsweredCorrectly={onAnsweredCorrectly}
          belowSolutionElement={belowSolutionElement}
        />
      </div>
    </div>
  )
}
