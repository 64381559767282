import React from "react"

import { PaymentDataType } from "@redux/ducks/misc/payment"

import { ContainerRowCol } from "@components/Containers/positioning-wrappers"

import { H2 } from "@helpers/constants/style"

export const PaymentSummary = ({
  paidAmount,
  fromDate,
  toDate,
  campaign,
}: PaymentDataType) => {
  return (
    <ContainerRowCol>
      <h1 className={H2}>Payment Summary 💰</h1>
      <p>
        <strong>Membership From: </strong>
        {`${fromDate.day}/${fromDate.month}/${fromDate.year}`}
      </p>
      <p>
        <strong>Membership To: </strong>
        {`${toDate.day}/${toDate.month}/${toDate.year}`}
      </p>
      {campaign && (
        <p>
          <strong>Campaign: </strong>
          {campaign}
        </p>
      )}
      <p>
        <strong>Paid: </strong>${paidAmount}
      </p>
    </ContainerRowCol>
  )
}
