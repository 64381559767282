import React from "react"
import Button from "react-bootstrap/Button"

import {
  CenteredWrapper,
  SpacedWrapper,
} from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import { CharacterIntroScene } from "@views/english-demo-1/common/character-intro-scene"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { SceneProps } from "@views/english-demo-1/types"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"

import { HERO_CLASS_MAKER } from "@helpers/constants/hero-class"
import { H_100 } from "@helpers/constants/style"

const intro = (
  <>
    <h1>Synthesise your response</h1>
    <p>
      You've successfully answered questions about the key symbols and
      techniques. Now's the time to gather your thoughts and <b>make</b> it all
      happen.
    </p>
    <p>
      For high level composition, this is a job for the <b>Maker</b>.
    </p>
  </>
)

const description = (
  <CenteredWrapper className={H_100}>
    <div>
      <h2 className="text-center">Maker</h2>
      <p>
        <b>Makers</b> are the magical ones. They synthesise key symbols and
        techniques to form a new whole. They're the ones who you want on your
        side when taking on the likes of the <b>Skeleton King</b>.
      </p>
    </div>
  </CenteredWrapper>
)

export const Action6E = ({
  previousScene,
  nextScene,
  isActiveScene,
}: SceneProps) => {
  useScrollTop(isActiveScene)
  return (
    <>
      <SpacedWrapper>
        <Button onClick={previousScene}>Back</Button>
        <GoogleFormLinkGeneralFeedback />
      </SpacedWrapper>
      <MainQuestionReference />
      <CharacterIntroScene
        intro={intro}
        description={description}
        avatarType={HERO_CLASS_MAKER}
        primaryButtonOnClick={nextScene}
        primaryButtonText="Begin Making"
      />
    </>
  )
}
