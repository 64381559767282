import { DifficultyType } from "@views/multiple-choice-problem/types"

export const marksToDifficultyForEnglish = (marks: number): DifficultyType => {
  if (marks <= 3) {
    return 1
  } else if (marks === 4) {
    return 2
  } else if (marks === 5) {
    return 4
  } else if (marks >= 6) {
    return 5
  } else {
    return 1
  }
}

export const containerItems = [
  {
    md: 3,
    title: "Stimulus",
  },
  {
    md: 3,
    title: "Monster",
  },
  {
    md: 3,
    title: "Question",
  },
  {
    md: 1,
    title: "Marks",
  },
  {
    md: 2,
    title: "Action",
  },
]
