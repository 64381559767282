import { Cache, Analytics } from "aws-amplify"
import { classnames } from "classnames-joiner"
import React from "react"
import Button from "react-bootstrap/Button"

import { CenteredWrapper } from "@components/Containers/positioning-wrappers"
import { Triangle } from "@components/shapes/triangle"

import {
  triangle1,
  triangle2,
  triangle3,
} from "@views/english-demo-1/action-7/ac7.module.scss"
import { ENGLISH_DEMO_USER_RESPONSES } from "@views/english-demo-1/common/constants"
import {
  DetailedBossMonster,
  BossMonster,
} from "@views/english-demo-1/common/minion"
import { ShortAnswerFieldScene } from "@views/english-demo-1/common/short-answer-field-scene"
import { TechniqueBox } from "@views/english-demo-1/common/technique-box"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { goblin } from "@views/english-demo-1/practice-problem/pp.module.scss"
import { ScenePropsWithInputProps } from "@views/english-demo-1/types"
import { AvatarsContext } from "@views/english-demo-1/utils/context"
import { getUserResponses } from "@views/english-demo-1/utils/get-user-responses"
import {
  useScrollTop,
  useIfActiveScene,
} from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"
import { useEnglishProblem } from "@views/english-demo-1/utils/hooks/use-english-problem"
import { saveEnglishDataFromCacheToRemote } from "@views/english-demo-1/utils/save-load-data"

import { useUserFromState } from "@helpers/auth"
import { HERO_CLASS_MAKER } from "@helpers/constants/hero-class"
import { CACHE_KEY_ACTIVE_MONSTER_DETAILS } from "@helpers/constants/monster-class"
import {
  ANIMATION_ROTATE_IN,
  ANIMATION_DELAY_1S,
  ANIMATION_DELAY_2S,
} from "@helpers/constants/style"

export const PracticeProblem = ({
  previousScene,
  nextScene,
  inputProps,
  isActiveScene,
  setShowStimulus,
}: ScenePropsWithInputProps) => {
  useScrollTop(isActiveScene)
  const user = useUserFromState()
  // Get Data
  const englishProblem = useEnglishProblem(isActiveScene, setShowStimulus)

  const context = React.useContext(AvatarsContext)

  const avatar = (
    <section>
      <div className={avatarContainer}>
        {useEnglishDemoAvatar({
          heroClass: HERO_CLASS_MAKER,
        })}
        <Triangle
          className={classnames([triangle1, ANIMATION_ROTATE_IN])}
          fill="fill-yellow-shade-01"
        />
        <Triangle
          className={classnames([
            triangle2,
            ANIMATION_DELAY_1S,
            ANIMATION_ROTATE_IN,
          ])}
          fill="fill-green-shade-01"
        />
        <Triangle
          className={classnames([
            triangle3,
            ANIMATION_DELAY_2S,
            ANIMATION_ROTATE_IN,
          ])}
          fill="fill-teal-shade-01"
        />
      </div>
      <CenteredWrapper>
        <Button
          variant="success"
          className="mr-4"
          onClick={context.avatarToggles[HERO_CLASS_MAKER]}
        >
          👱‍♀️ 👨‍🦱 Personalise
        </Button>
      </CenteredWrapper>
    </section>
  )

  const englishProblemId = englishProblem?.id

  useIfActiveScene(
    isActiveScene,
    () => {
      if (englishProblem) {
        const userResponses = getUserResponses()
        const res = userResponses[englishProblem.id]?.res

        inputProps.setValue(res ? res : "")
      }
    },
    [englishProblemId]
  )

  if (!englishProblem) {
    return <div></div>
  }
  const markValue = englishProblem.triangles.length + 1
  const composedQuestion = (
    <section>
      <div className="mb-8">
        <h2 className="h4 mb-0">Notes</h2>
        <small className="d-block mb-2">
          Use the notes for inspiration. Feel free to deviate from them.
        </small>
        {englishProblem.triangles.map((triangle, i) => {
          return (
            <div className="mb-4" key={`triangle-${i}`}>
              <TechniqueBox techniqueNo={i + 1} {...triangle} closed={true} />
            </div>
          )
        })}
      </div>
      <h2 className="h4 mb-0">{`${englishProblem.question} (${markValue} Marks)`}</h2>
    </section>
  )

  const fromCache = Cache.getItem(CACHE_KEY_ACTIVE_MONSTER_DETAILS)

  const activeMonsterDetails = fromCache
    ? fromCache
    : {
        src: "",
        title: "",
      }

  const saveData = async () => {
    try {
      await saveEnglishDataFromCacheToRemote(user)
    } catch (e) {
      console.log(e)
    }
  }

  const onAnswer = () => {
    if (!englishProblemId) {
      return
    }
    const userResponses = getUserResponses()

    userResponses[englishProblemId] = {
      ...userResponses[englishProblemId],
      res: inputProps.value,
    }

    Cache.setItem(ENGLISH_DEMO_USER_RESPONSES, userResponses)

    saveData()

    Analytics.record({
      name: "practice-problem-button-answer",
      attributes: { id: englishProblemId },
    })
  }

  return (
    <section>
      <ShortAnswerFieldScene
        question={composedQuestion}
        minion={
          <DetailedBossMonster
            marks={markValue}
            question={englishProblem.question}
            bossMonsterTitle={activeMonsterDetails.title}
            bossMonster={
              <BossMonster
                src={activeMonsterDetails.src}
                alt={activeMonsterDetails.title}
                className={goblin}
              />
            }
          />
        }
        avatar={avatar}
        inputProps={inputProps}
        previousScene={previousScene}
        nextScene={nextScene}
        onAnswer={onAnswer}
      />
    </section>
  )
}
