import React from "react"

import { face } from "@components/Avatar/a.module.scss"
import { AvatarItem } from "@components/Avatar/common/avatar-item"
import { SkinAndHairAvatarProps } from "@components/Avatar/types"

import { dimensions, originPosition } from "./constants"
import { NeutralEdgeFaceAsset } from "./hair"

export const NeutralEdgeFace = ({
  skinTone,
  hairColour,
  ...baseAvatar
}: SkinAndHairAvatarProps) => {
  return (
    <AvatarItem
      className={face}
      dimensions={dimensions}
      originPosition={originPosition}
      baseAvatar={baseAvatar}
    >
      <NeutralEdgeFaceAsset skinTone={skinTone} hairColour={hairColour} />
    </AvatarItem>
  )
}
