import { OptionsWereSelectedType } from "@redux/ducks/ui/problem-multiple-choice"

export const getIsOptionUnclickable = ({
  optionKey,
  optionsWereSelected,
  optionsAreLocked,
}: {
  optionKey: string
  optionsWereSelected: OptionsWereSelectedType
  optionsAreLocked: boolean
}) => {
  if (optionsAreLocked) {
    return true
  }

  const optionWasSelected = optionsWereSelected?.[optionKey]

  return Boolean(optionWasSelected)
}
