export const MONSTER_CLASS_ORC = "orc"
export const MONSTER_CLASS_SKELETON = "skeleton"
export const MONSTER_CLASS_GOBLIN = "goblin"
export const MONSTER_CLASS_DEMON = "demon"
export const MONSTER_CLASS_VAMPIRE = "vampire"

export type MonsterClassType =
  | typeof MONSTER_CLASS_ORC
  | typeof MONSTER_CLASS_SKELETON
  | typeof MONSTER_CLASS_GOBLIN
  | typeof MONSTER_CLASS_DEMON
  | typeof MONSTER_CLASS_VAMPIRE

// Cache constants
export const CACHE_KEY_ACTIVE_MONSTER_DETAILS = "__active-monster-details"
