import React from "react"

import {
  ANSWER_LATER,
  ANSWER_HERE,
} from "@views/english-demo-1/common/constants"
import { Minion } from "@views/english-demo-1/common/minion"
import { ProgressBar } from "@views/english-demo-1/common/progress-bar"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { TextFieldScene } from "@views/english-demo-1/common/text-field-scene"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { ScenePropsWithInputProps } from "@views/english-demo-1/types"
import { AvatarsContext } from "@views/english-demo-1/utils/context"
import { useSetTechTriangleContent } from "@views/english-demo-1/utils/hooks"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import { HERO_CLASS_TANK } from "@helpers/constants/hero-class"

import minionSrc from "@images/monsters/skeletons/skeleton-archer-min.png"

const minionAlt = "Skeleton Archer"
const minion = <Minion src={minionSrc} alt={minionAlt} />

export const Action6B = ({
  previousScene,
  nextScene,
  inputProps,
  question,
  sampleAnswer,
  isActiveScene,
}: ScenePropsWithInputProps) => {
  const context = React.useContext(AvatarsContext)
  const techniqueNo = 2
  const avatar = (
    <div className={avatarContainer}>
      {useEnglishDemoAvatar({
        heroClass: HERO_CLASS_TANK,
      })}
    </div>
  )
  useScrollTop(isActiveScene)
  useSetTechTriangleContent({
    isActiveScene,
    techniqueNo,
    content: {
      explanation: ANSWER_HERE,
    },
    setOnlyIf: ["", ANSWER_LATER],
  })

  const techniqueBoxProps = {
    techniqueNo,
    evidence: context.techTriangles[techniqueNo].evidence,
    identification: context.techTriangles[techniqueNo].identification,
    explanation: context.techTriangles[techniqueNo].explanation,
  }

  const hasAnsweredCorrectlyCallback = (content: React.ReactNode) => {
    context.techTriangleSetters[techniqueNo]({
      ...context.techTriangles[techniqueNo],
      explanation: content,
    })
  }
  return (
    <section>
      <ProgressBar index={6} />
      <TextFieldScene
        question={question}
        sampleAnswer={sampleAnswer}
        minion={minion}
        avatar={avatar}
        inputProps={inputProps}
        previousScene={previousScene}
        nextScene={nextScene}
        techniqueBoxProps={techniqueBoxProps}
        hasAnsweredCorrectlyCallback={hasAnsweredCorrectlyCallback}
        belowNav={<MainQuestionReference />}
      />
    </section>
  )
}
