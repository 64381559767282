import { classnames } from "classnames-joiner"
import React from "react"

import { hair, part } from "@components/Avatar/a.module.scss"
import { AvatarItemInner } from "@components/Avatar/common/avatar-item"
import { InnerImage } from "@components/Avatar/common/avatar-item/inner-image"
import { SkinAndHairAvatarProps } from "@components/Avatar/types"
import { BaseAvatarProps } from "@components/Avatar/types"
import { getTranslatedFactoredStyles } from "@components/Avatar/utils"

import { dimensions, originPosition, sizing1, sizing2 } from "./constants"
import { PartedFrontPonytailHairAsset } from "./hair-1"
import src1 from "./hair-1.svg"
import { PartedFrontPonytailHair2Asset } from "./hair-2"
import src2 from "./hair-2.svg"
import { extraHair } from "./p.module.scss"

export const PartedFrontPonytailHair = ({
  skinTone,
  hairColour,
  ...baseAvatar
}: SkinAndHairAvatarProps) => {
  const { placementPosition, rotation, factor } = baseAvatar
  const translatedFactoredStyles = getTranslatedFactoredStyles({
    dimensions,
    originPosition,
    placementPosition,
    rotation,
    factor,
  })

  return (
    <>
      <AvatarItemInner
        translatedFactoredStyles={translatedFactoredStyles}
        originPosition={originPosition}
        baseAvatar={baseAvatar}
        className={hair}
      >
        <PartedFrontPonytailHairAsset
          sizing={sizing1}
          factor={baseAvatar.factor}
          skinTone={skinTone}
          hairColour={hairColour}
        />
      </AvatarItemInner>
      <AvatarItemInner
        translatedFactoredStyles={translatedFactoredStyles}
        originPosition={originPosition}
        baseAvatar={baseAvatar}
        className={classnames([part, extraHair])}
      >
        {/* <InnerImage src={src2} sizing={sizing2} factor={baseAvatar.factor} /> */}
        <PartedFrontPonytailHair2Asset
          sizing={sizing2}
          factor={baseAvatar.factor}
          skinTone={skinTone}
          hairColour={hairColour}
        />
      </AvatarItemInner>
    </>
  )
}
