export const HERO_CLASS_SCOUT = "scout"
export const HERO_CLASS_TANK = "tank"
export const HERO_CLASS_EMPATH = "empath"
export const HERO_CLASS_AIMER = "aimer"
export const HERO_CLASS_MAKER = "maker"

export type HeroClassType =
  | typeof HERO_CLASS_SCOUT
  | typeof HERO_CLASS_TANK
  | typeof HERO_CLASS_EMPATH
  | typeof HERO_CLASS_AIMER
  | typeof HERO_CLASS_MAKER
