import { MovieCameraEmoji } from "accessible-emojis"
import React from "react"
import Button from "react-bootstrap/Button"

import {
  VIDEO_MODAL,
  RESTING_VIDEO_MODAL,
  ModalIdType,
} from "@redux/ducks/ui/modal"

import Potion from "@components/Potion"
import { VIDEO_COST } from "@components/hero-stats/constants"

import {
  W_100,
  MY_2,
  FONT_WEIGHT_NORMAL,
  FONT_WEIGHT_BOLD,
} from "@helpers/constants/style"

import { useOnClick } from "./utils/use-on-click"
import { VideoModal, RestingVideoModal } from "./video-modal"

const RenderModalSwitch = ({
  modalId,
  video,
}: {
  modalId?: ModalIdType
  video: string
}) => {
  switch (modalId) {
    case VIDEO_MODAL:
      return <VideoModal show={true} video={video} />
    case RESTING_VIDEO_MODAL:
      return <RestingVideoModal show={true} video={video} />
    default:
      return null
  }
}

interface Props {
  video: string
  potions: number
  modalId?: ModalIdType
}
export const VideoButton = ({ video, potions, modalId }: Props) => {
  const onClick = useOnClick({ ifSpentCount: potions + VIDEO_COST })

  return (
    <>
      <Button
        className={`${FONT_WEIGHT_BOLD} ${W_100} ${MY_2}`}
        onClick={onClick}
      >
        <MovieCameraEmoji className={FONT_WEIGHT_NORMAL} /> Video ({" "}
        <Potion size="small" />
        <span>&nbsp;&nbsp;x {VIDEO_COST}</span> )
      </Button>
      <RenderModalSwitch modalId={modalId} video={video} />
    </>
  )
}
