import { setMiscActionCreator } from "@redux/ducks/misc"
import { PHYSICS_MC_USER_RESPONSES_KEY } from "@redux/ducks/misc/physics-mc-user-responses"
import { selectUsername } from "@redux/selectors"
import { DispatchType, GetStateType } from "@redux/types"

import { PHYSICS_MC_USER_RESPONSES } from "@views/physics/common/constants"

import {
  META_STATUS_LOADING,
  META_STATUS_SUCCESS,
  META_STATUS_ERROR,
} from "@helpers/hooks/use-meta-status/constants"
import { getUserRemoteData, saveProgress } from "@helpers/user-data"
import { getPhysicsUserTableItemId } from "@helpers/user-data/get-user-table-item-id"

import { selectPhysicsMCUserResponses } from "./selectors"

export const savePhysicsMCUserResponsesThunkCreator =
  () => async (_: DispatchType, getState: GetStateType) => {
    const rootState = getState()

    // Save to remote only for logged in users
    const username = selectUsername(rootState)
    if (!username) {
      return
    }

    await saveProgress({
      remoteId: getPhysicsUserTableItemId(username),
      remoteAttribute: PHYSICS_MC_USER_RESPONSES.remoteAttribute,
      value: selectPhysicsMCUserResponses(rootState),
    })
  }

export const getPhysicsMCUserResponsesThunkCreator =
  (username: string) =>
  async (dispatch: DispatchType, getState: GetStateType) => {
    const rootState = getState()

    const remoteId = getPhysicsUserTableItemId(username)
    const physicsMCUserResponses = selectPhysicsMCUserResponses(rootState)

    dispatch(
      setMiscActionCreator({
        [PHYSICS_MC_USER_RESPONSES_KEY]: {
          ...physicsMCUserResponses,
          status: META_STATUS_LOADING,
        },
      })
    )

    const { value: physicsMCUserResponsesDataFromRemote, error } =
      await getUserRemoteData({
        remoteId,
        remoteAttribute: PHYSICS_MC_USER_RESPONSES.remoteAttribute,
      })

    console.log("getPhysicsMCUserResponsesThunkCreator", {
      physicsMCUserResponsesDataFromRemote,
      error,
    })

    if (error === undefined) {
      dispatch(
        setMiscActionCreator({
          [PHYSICS_MC_USER_RESPONSES_KEY]: {
            data: physicsMCUserResponsesDataFromRemote,
            status: META_STATUS_SUCCESS,
          },
        })
      )
    } else {
      dispatch(
        setMiscActionCreator({
          [PHYSICS_MC_USER_RESPONSES_KEY]: {
            data: physicsMCUserResponsesDataFromRemote,
            status: META_STATUS_ERROR,
          },
        })
      )
    }
  }
