import React, { ReactNode } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import {
  EndWrapper,
  SpacedWrapper,
} from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"
import { NoArgVoid } from "@components/types"

import { MultipleChoiceOption } from "@views/english-demo-1/common/multiple-choice-option"
import { MultipleChoiceOptionType } from "@views/english-demo-1/common/multiple-choice-option/types"
import {
  TechniqueBox,
  TechniqueBoxProps,
} from "@views/english-demo-1/common/technique-box"

import { ANIMATION_FADEOUT, ANIMATION_SHAKE_X } from "@helpers/constants/style"

import { StatefulMultipleChoiceOptionType } from "../multiple-choice-option/types"

const getHasAnsweredCorrectly = (
  statefulOptions: StatefulMultipleChoiceOptionType[]
) => {
  let verdict = false
  for (let i = 0; i < statefulOptions.length; i++) {
    if (
      statefulOptions[i].selected === true &&
      statefulOptions[i].shouldBeSelected === true
    ) {
      verdict = true
      break
    }
  }

  return verdict
}

interface MultipleChoiceSceneProps {
  question: ReactNode
  options: MultipleChoiceOptionType[]
  minion: ReactNode
  avatar: ReactNode
  nextScene: NoArgVoid
  previousScene: NoArgVoid
  techniqueBoxProps?: TechniqueBoxProps
  hasAnsweredCorrectlyCallback?: (content: ReactNode) => void
  belowNav?: ReactNode
}

export const MultipleChoiceScene = ({
  previousScene,
  nextScene,
  question,
  options,
  minion,
  avatar,
  techniqueBoxProps,
  hasAnsweredCorrectlyCallback,
  belowNav,
}: MultipleChoiceSceneProps) => {
  const statefulOptions = options.map((option) => {
    const [selected, setSelected] = React.useState(false)
    return { ...option, selected, setSelected }
  })
  const [hasAnswered, setHasAnswered] = React.useState(false)
  const setHasAnsweredToTrue = () => setHasAnswered(true)
  const hasAnsweredCorrectly = getHasAnsweredCorrectly(statefulOptions)

  React.useEffect(() => {
    if (hasAnswered) {
      const timeout = setTimeout(() => {
        setHasAnswered(false)
      }, 1000)

      return () => {
        clearInterval(timeout)
      }
    }
  }, [hasAnswered])

  return (
    <section>
      <SpacedWrapper>
        <Button onClick={previousScene}>Back</Button>
        <GoogleFormLinkGeneralFeedback />
      </SpacedWrapper>
      {belowNav}
      <Container>
        <Row>
          <Col xs={12}>
            <SpacedWrapper className="flex-wrap">
              <div
                className={
                  hasAnswered && !hasAnsweredCorrectly
                    ? ANIMATION_SHAKE_X
                    : undefined
                }
              >
                {avatar}
              </div>
              <div
                className={hasAnsweredCorrectly ? ANIMATION_FADEOUT : undefined}
              >
                {minion}
              </div>
            </SpacedWrapper>
          </Col>
        </Row>
      </Container>

      {techniqueBoxProps && (
        <div className="mb-4">
          <TechniqueBox {...techniqueBoxProps} />
        </div>
      )}
      {question}
      <div className="mb-4">
        <b className="d-block mb-2">Multiple Choice</b>

        {statefulOptions.map((statefulOption) => {
          return (
            <MultipleChoiceOption
              key={statefulOption.label}
              statefulOption={statefulOption}
              onClickCallback={(statefulOption) => {
                setHasAnsweredToTrue()
                if (
                  hasAnsweredCorrectlyCallback &&
                  statefulOption.shouldBeSelected
                ) {
                  hasAnsweredCorrectlyCallback(statefulOption.content)
                }
              }}
            />
          )
        })}
      </div>

      {hasAnsweredCorrectly && (
        <EndWrapper>
          <Button onClick={nextScene} className="mb-4">
            Next
          </Button>
        </EndWrapper>
      )}
    </section>
  )
}
