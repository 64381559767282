import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import { ANIMATION_FLASH, ANIMATION_SHAKE_X } from "@helpers/constants/style"

import { HasAnsweredView } from "./has-answered-view"
import { HasntAnsweredView } from "./hasnt-answered-view"
import { ShortAnswerFieldSceneProps } from "./types"

export const ShortAnswerFieldScene = ({
  question,
  previousScene,
  nextScene,
  avatar,
  minion,
  inputProps,
  onAnswer,
  belowNav,
}: ShortAnswerFieldSceneProps) => {
  const [hasAnswered, setHasAnswered] = React.useState(false)
  const [animationClass, setAnimationClass] = React.useState(ANIMATION_SHAKE_X)

  React.useEffect(() => {
    if (hasAnswered) {
      const timeout = setTimeout(() => {
        setAnimationClass(ANIMATION_FLASH)
      }, 1000)

      return () => {
        clearInterval(timeout)
      }
    }
  }, [hasAnswered])

  return (
    <section>
      <SpacedWrapper>
        <Button onClick={previousScene}>Back</Button>
        <GoogleFormLinkGeneralFeedback />
      </SpacedWrapper>
      <Container>
        {belowNav}
        <Row>
          <Col xs={12} md={6}>
            {avatar}
          </Col>
          <Col xs={12} md={6}>
            <div className={hasAnswered ? animationClass : undefined}>
              {minion}
            </div>
          </Col>
        </Row>
      </Container>
      {question}
      {!hasAnswered ? (
        <HasntAnsweredView
          onAnswer={() => {
            onAnswer && onAnswer()
            setHasAnswered(true)
          }}
          inputProps={inputProps}
        />
      ) : (
        <HasAnsweredView
          onEdit={() => setHasAnswered(false)}
          value={inputProps.value}
          previousScene={previousScene}
          nextScene={nextScene}
        />
      )}
    </section>
  )
}
