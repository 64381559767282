import { onboardingConfig } from "@views/onboarding/constants"
import { OnboardingScreenKeyType } from "@views/onboarding/types"

export const getIsLastScreen = (activeScreen: OnboardingScreenKeyType) => {
  const currentScreenIndex = onboardingConfig.screensList.indexOf(activeScreen)

  const isLastScreen =
    currentScreenIndex === onboardingConfig.screensList.length - 1

  return isLastScreen
}
