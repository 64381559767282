import React from "react"

import { FullHeightColumnSpacedWrapper } from "@components/Containers/positioning-wrappers"
import { AnimateHelmet } from "@components/Helmets/AnimateHelmet"
import { AppNavbar } from "@components/app-navbar"
import { Footer } from "@components/footer"

import "@styles/style.scss"

import { ContentSection } from "./content-section"
import { LayoutType } from "./types"

export const AppLayout = ({
  hasNavBar = true,
  children,
  hasFooter = true,
  contentSectionClassName,
}: LayoutType) => (
  <>
    <AnimateHelmet />
    <FullHeightColumnSpacedWrapper>
      {hasNavBar && <AppNavbar />}
      <ContentSection className={contentSectionClassName}>
        {children}
      </ContentSection>
      {hasFooter && <Footer />}
    </FullHeightColumnSpacedWrapper>
  </>
)
