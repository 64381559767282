import React from "react"

import { InnerSvg } from "@components/Avatar/common/avatar-item/inner-image"
import { MultiPartHairAsset } from "@components/Avatar/types"

import {
  cls1,
  cls2,
  cls3,
  cls4,
  cls5,
  cls7,
  cls8,
  cls9,
  cls10,
  cls11,
  cls12,
  cls13,
  cls14,
} from "./pfp2.module.scss"

export const PartedFrontPonytailHair2Asset = ({
  sizing,
  factor,
  hairColour,
}: MultiPartHairAsset) => {
  return (
    <InnerSvg sizing={sizing} factor={factor} viewBox="0 0 58.13 103.64">
      <defs>
        <mask
          id="mask-pfp2"
          x="0"
          y="0"
          width="58.13"
          height="103.64"
          maskUnits="userSpaceOnUse"
        >
          <path
            className={cls1}
            d="M30.05,56.67s7.07,10.31,4.65,13C30.38,74.55,23,75.2,23,75.2c-3.14,3.32-9,5.35-9.08-2.29C10.76,69.74,1.49,61.53,15,57.7Z"
          />
          <path
            className={cls2}
            d="M30.05,56.67s7.07,10.31,4.65,13C30.38,74.55,23,75.2,23,75.2c-3.14,3.32-9,5.35-9.08-2.29C10.76,69.74,1.49,61.53,15,57.7Z"
          />
          <path
            className={cls1}
            d="M23,79.46s-2.57,2.42.47,3.3,4.26,1.41,6,2.79c3.53,2.79-1.22,9.36-7.21,9.32.35-1.37.84-2.39,0-3.86,0,0-17-3.06-9.08-12.6C17.75,78.32,23,79.6,23,79.46Z"
          />
          <path
            className={cls2}
            d="M23,79.46s-2.57,2.42.47,3.3,4.26,1.41,6,2.79c3.53,2.79-1.22,9.36-7.21,9.32.35-1.37.84-2.39,0-3.86,0,0-17-3.06-9.08-12.6C17.75,78.32,23,79.6,23,79.46Z"
          />
          <path d="M16.73,81.69a5.25,5.25,0,0,0-.2,1.48,5.11,5.11,0,0,0,.23,1.55,3.81,3.81,0,0,0,.81,1.38,4.89,4.89,0,0,0,1.39,1,5,5,0,0,1-1.7-.61,4.41,4.41,0,0,1-1.38-1.31,5.85,5.85,0,0,1-.83-1.77,7.12,7.12,0,0,1-.3-2Z" />
          <path
            className={cls3}
            d="M26,77.28c-4.86,1-10.46-1.22-14.49-3.2l.31,4.47c3.58,2.74,7.77,3,12.4,1.58Z"
          />
          <path
            className={cls4}
            d="M26,77.28c-4.86,1-10.46-1.22-14.49-3.2l.31,4.47c3.58,2.74,7.77,3,12.4,1.58Z"
          />
          <path
            className={cls3}
            d="M19.74,38.06S3,44.11,10.36,57.37c1.88,1.76,9.9,5.38,13.45,9.29,3.27-1,2.64-5.5,1-10.16C24.8,56.5,32.75,44.13,19.74,38.06Z"
          />
          <path
            className={cls2}
            d="M19.74,38.06S3,44.11,10.36,57.37c1.88,1.76,9.9,5.38,13.45,9.29,3.27-1,2.64-5.5,1-10.16C24.8,56.5,32.75,44.13,19.74,38.06Z"
          />
          <path d="M12.3,68.75a3.45,3.45,0,0,1,1.94-2A10.09,10.09,0,0,1,17,65.92a12.76,12.76,0,0,1,2.9-.2,7.59,7.59,0,0,1,3,.75l-1.07,1.69a5.84,5.84,0,0,0-2.17-1,11.87,11.87,0,0,0-2.61-.3,9.73,9.73,0,0,0-2.63.33A3.24,3.24,0,0,0,12.3,68.75Z" />
          <path d="M26.54,65.06c-.38,0-.19,0-.17,0l.17,0,.36,0a5.94,5.94,0,0,1,.73,0,5,5,0,0,1,1.44.2,3.6,3.6,0,0,1,1.27.63,3.92,3.92,0,0,1,.89,1,3.41,3.41,0,0,0-1.12-.54A3.21,3.21,0,0,0,29,66.18a3.6,3.6,0,0,0-1.08.29,4.59,4.59,0,0,0-.5.24l-.25.14-.13.07c-.08,0,0,.07-.47.14Z" />
          <path d="M23,68.2a13.31,13.31,0,0,0-.43-3.13A19.78,19.78,0,0,0,21.52,62,23.22,23.22,0,0,0,18,56.24a23.85,23.85,0,0,1,4.38,5.25A21.67,21.67,0,0,1,24,64.6a14.76,14.76,0,0,1,1,3.46Z" />
          <path
            className={cls3}
            d="M42.59,37.94S47.16,53.67,40,56.86a22.9,22.9,0,0,1-11.92,1.62s1.43-8.7-4-15.29a10.9,10.9,0,0,0-9.86-3.86S11.09,25.9,23.91,23.71C33.88,22,42.59,37.94,42.59,37.94Z"
          />
          <path
            className={cls2}
            d="M42.59,37.94S47.16,53.67,40,56.86a22.9,22.9,0,0,1-11.92,1.62s1.43-8.7-4-15.29a10.9,10.9,0,0,0-9.86-3.86S11.09,25.9,23.91,23.71C33.88,22,42.59,37.94,42.59,37.94Z"
          />
          <path
            className={cls1}
            d="M33,41.38s11.57-.88,16.3-2.69C59.49,34.77,56,20,48.54,17c.31-5.68-7.5-11.54-14.7-10.7-8.25,1-13.92,6.42-14.17,11.49C19,31.29,33,41.38,33,41.38Z"
          />
          <path
            className={cls2}
            d="M33,41.38s11.57-.88,16.3-2.69C59.49,34.77,56,20,48.54,17c.31-5.68-7.5-11.54-14.7-10.7-8.25,1-13.92,6.42-14.17,11.49C19,31.29,33,41.38,33,41.38Z"
          />
          <path d="M32.52,41.56a40.65,40.65,0,0,0,6.56-5.07,35.83,35.83,0,0,0,5.38-6.34,25.74,25.74,0,0,0,3.43-7.52,22,22,0,0,0,.58-8.24l.93-.13a23,23,0,0,1-.34,8.66,27.1,27.1,0,0,1-3.36,8,37.87,37.87,0,0,1-5.41,6.8,42.15,42.15,0,0,1-6.75,5.53Z" />
          <path d="M26.12,50.25a13.62,13.62,0,0,0,2-1.36,13.82,13.82,0,0,0,1.74-1.68,9.62,9.62,0,0,0,1.32-2,6.88,6.88,0,0,0,.6-2.27h.94a8.1,8.1,0,0,1-.42,2.72A11.23,11.23,0,0,1,31,48.11a15.71,15.71,0,0,1-1.75,2.07A15.81,15.81,0,0,1,27.17,52Z" />
          <path d="M42.37,26.33A9.67,9.67,0,0,1,42,29a6.07,6.07,0,0,1-1.25,2.43,8,8,0,0,1-4.66,2.51c.71-.54,1.43-1,2.1-1.5A11.38,11.38,0,0,0,40,30.74a8.75,8.75,0,0,0,1.28-2C41.66,27.93,42,27.12,42.37,26.33Z" />
          <path d="M26.94,56.11a21.57,21.57,0,0,0,4.81-2A22.07,22.07,0,0,0,36,51a23.58,23.58,0,0,0,1.83-1.91,21.51,21.51,0,0,0,3.74-7l.91.25A22.41,22.41,0,0,1,38.9,50a25,25,0,0,1-1.85,2.13,23.61,23.61,0,0,1-4.42,3.54,22.89,22.89,0,0,1-5.2,2.42Z" />
          <path d="M30.15,40.88c.07-1,.07-2,.05-3s-.11-2-.23-3a19.9,19.9,0,0,0-1.55-5.83A20.45,20.45,0,0,1,31,34.72c.29,1,.53,2,.74,3s.36,2,.46,3.13Z" />
          <path d="M26.42,61C25.26,58.7,24,56.46,22.7,54.28c-.67-1.09-1.35-2.16-2.08-3.2a14.61,14.61,0,0,0-2.51-2.87A14.61,14.61,0,0,1,21,50.76c.88,1,1.71,1.94,2.52,3,1.6,2,3.14,4.08,4.61,6.21Z" />
          <path d="M21.73,82.11a1,1,0,0,0,0,.51,3.45,3.45,0,0,0,.21.67,5.44,5.44,0,0,0,.79,1.31,8.82,8.82,0,0,0,1.11,1.18,14.75,14.75,0,0,0,1.33,1,14.18,14.18,0,0,1-1.6-.61,10.07,10.07,0,0,1-1.52-.87A6.92,6.92,0,0,1,20.7,84.1a5.38,5.38,0,0,1-.56-.82,3.23,3.23,0,0,1-.41-1.09Z" />
          <path d="M21,90.93a10.13,10.13,0,0,0,5.41-.81,11.11,11.11,0,0,1-5.37,2.81Z" />
        </mask>
        <linearGradient
          id="New_Gradient_Swatch_3-pfp2"
          x1="210.84"
          y1="49.09"
          x2="181.21"
          y2="53.22"
          gradientTransform="matrix(-1, 0, 0, 1, 213.31, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#7aabdc" />
          <stop offset="0.04" stopColor="#7aabdc" />
          <stop offset="1" stopColor="#7aabdc" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g className={cls5}>
        <g id="Layer_2-pfp2" data-name="Layer 2">
          <g id="Layer_1-2-pfp2" data-name="Layer 1">
            <g id="HAIR_30-2-pfp2" data-name="HAIR 30-2">
              <path
                className={cls1}
                d="M30.05,56.67s7.07,10.31,4.65,13C30.38,74.55,23,75.2,23,75.2c-3.14,3.32-9,5.35-9.08-2.29C10.76,69.74,1.49,61.53,15,57.7Z"
              />
              <path
                className={hairColour}
                d="M30.05,56.67s7.07,10.31,4.65,13C30.38,74.55,23,75.2,23,75.2c-3.14,3.32-9,5.35-9.08-2.29C10.76,69.74,1.49,61.53,15,57.7Z"
              />
              <path
                className={cls1}
                d="M23,79.46s-2.57,2.42.47,3.3,4.26,1.41,6,2.79c3.53,2.79-1.22,9.36-7.21,9.32.35-1.37.84-2.39,0-3.86,0,0-17-3.06-9.08-12.6C17.75,78.32,23,79.6,23,79.46Z"
              />
              <path
                className={hairColour}
                d="M23,79.46s-2.57,2.42.47,3.3,4.26,1.41,6,2.79c3.53,2.79-1.22,9.36-7.21,9.32.35-1.37.84-2.39,0-3.86,0,0-17-3.06-9.08-12.6C17.75,78.32,23,79.6,23,79.46Z"
              />
              <path d="M16.73,81.69a5.25,5.25,0,0,0-.2,1.48,5.11,5.11,0,0,0,.23,1.55,3.81,3.81,0,0,0,.81,1.38,4.89,4.89,0,0,0,1.39,1,5,5,0,0,1-1.7-.61,4.41,4.41,0,0,1-1.38-1.31,5.85,5.85,0,0,1-.83-1.77,7.12,7.12,0,0,1-.3-2Z" />
              <path
                className={cls3}
                d="M26,77.28c-4.86,1-10.46-1.22-14.49-3.2l.31,4.47c3.58,2.74,7.77,3,12.4,1.58Z"
              />
              <path
                className={cls4}
                d="M26,77.28c-4.86,1-10.46-1.22-14.49-3.2l.31,4.47c3.58,2.74,7.77,3,12.4,1.58Z"
              />
              <path
                className={cls3}
                d="M19.74,38.06S3,44.11,10.36,57.37c1.88,1.76,9.9,5.38,13.45,9.29,3.27-1,2.64-5.5,1-10.16C24.8,56.5,32.75,44.13,19.74,38.06Z"
              />
              <path
                className={hairColour}
                d="M19.74,38.06S3,44.11,10.36,57.37c1.88,1.76,9.9,5.38,13.45,9.29,3.27-1,2.64-5.5,1-10.16C24.8,56.5,32.75,44.13,19.74,38.06Z"
              />
              <path d="M12.3,68.75a3.45,3.45,0,0,1,1.94-2A10.09,10.09,0,0,1,17,65.92a12.76,12.76,0,0,1,2.9-.2,7.59,7.59,0,0,1,3,.75l-1.07,1.69a5.84,5.84,0,0,0-2.17-1,11.87,11.87,0,0,0-2.61-.3,9.73,9.73,0,0,0-2.63.33A3.24,3.24,0,0,0,12.3,68.75Z" />
              <path d="M26.54,65.06c-.38,0-.19,0-.17,0l.17,0,.36,0a5.94,5.94,0,0,1,.73,0,5,5,0,0,1,1.44.2,3.6,3.6,0,0,1,1.27.63,3.92,3.92,0,0,1,.89,1,3.41,3.41,0,0,0-1.12-.54A3.21,3.21,0,0,0,29,66.18a3.6,3.6,0,0,0-1.08.29,4.59,4.59,0,0,0-.5.24l-.25.14-.13.07c-.08,0,0,.07-.47.14Z" />
              <path d="M23,68.2a13.31,13.31,0,0,0-.43-3.13A19.78,19.78,0,0,0,21.52,62,23.22,23.22,0,0,0,18,56.24a23.85,23.85,0,0,1,4.38,5.25A21.67,21.67,0,0,1,24,64.6a14.76,14.76,0,0,1,1,3.46Z" />
              <path
                className={cls3}
                d="M42.59,37.94S47.16,53.67,40,56.86a22.9,22.9,0,0,1-11.92,1.62s1.43-8.7-4-15.29a10.9,10.9,0,0,0-9.86-3.86S11.09,25.9,23.91,23.71C33.88,22,42.59,37.94,42.59,37.94Z"
              />
              <path
                className={hairColour}
                d="M42.59,37.94S47.16,53.67,40,56.86a22.9,22.9,0,0,1-11.92,1.62s1.43-8.7-4-15.29a10.9,10.9,0,0,0-9.86-3.86S11.09,25.9,23.91,23.71C33.88,22,42.59,37.94,42.59,37.94Z"
              />
              <path
                className={cls1}
                d="M33,41.38s11.57-.88,16.3-2.69C59.49,34.77,56,20,48.54,17c.31-5.68-7.5-11.54-14.7-10.7-8.25,1-13.92,6.42-14.17,11.49C19,31.29,33,41.38,33,41.38Z"
              />
              <path
                className={hairColour}
                d="M33,41.38s11.57-.88,16.3-2.69C59.49,34.77,56,20,48.54,17c.31-5.68-7.5-11.54-14.7-10.7-8.25,1-13.92,6.42-14.17,11.49C19,31.29,33,41.38,33,41.38Z"
              />
              <path d="M32.52,41.56a40.65,40.65,0,0,0,6.56-5.07,35.83,35.83,0,0,0,5.38-6.34,25.74,25.74,0,0,0,3.43-7.52,22,22,0,0,0,.58-8.24l.93-.13a23,23,0,0,1-.34,8.66,27.1,27.1,0,0,1-3.36,8,37.87,37.87,0,0,1-5.41,6.8,42.15,42.15,0,0,1-6.75,5.53Z" />
              <path d="M26.12,50.25a13.62,13.62,0,0,0,2-1.36,13.82,13.82,0,0,0,1.74-1.68,9.62,9.62,0,0,0,1.32-2,6.88,6.88,0,0,0,.6-2.27h.94a8.1,8.1,0,0,1-.42,2.72A11.23,11.23,0,0,1,31,48.11a15.71,15.71,0,0,1-1.75,2.07A15.81,15.81,0,0,1,27.17,52Z" />
              <path d="M42.37,26.33A9.67,9.67,0,0,1,42,29a6.07,6.07,0,0,1-1.25,2.43,8,8,0,0,1-4.66,2.51c.71-.54,1.43-1,2.1-1.5A11.38,11.38,0,0,0,40,30.74a8.75,8.75,0,0,0,1.28-2C41.66,27.93,42,27.12,42.37,26.33Z" />
              <path d="M26.94,56.11a21.57,21.57,0,0,0,4.81-2A22.07,22.07,0,0,0,36,51a23.58,23.58,0,0,0,1.83-1.91,21.51,21.51,0,0,0,3.74-7l.91.25A22.41,22.41,0,0,1,38.9,50a25,25,0,0,1-1.85,2.13,23.61,23.61,0,0,1-4.42,3.54,22.89,22.89,0,0,1-5.2,2.42Z" />
              <path d="M30.15,40.88c.07-1,.07-2,.05-3s-.11-2-.23-3a19.9,19.9,0,0,0-1.55-5.83A20.45,20.45,0,0,1,31,34.72c.29,1,.53,2,.74,3s.36,2,.46,3.13Z" />
              <path d="M26.42,61C25.26,58.7,24,56.46,22.7,54.28c-.67-1.09-1.35-2.16-2.08-3.2a14.61,14.61,0,0,0-2.51-2.87A14.61,14.61,0,0,1,21,50.76c.88,1,1.71,1.94,2.52,3,1.6,2,3.14,4.08,4.61,6.21Z" />
              <path d="M21.73,82.11a1,1,0,0,0,0,.51,3.45,3.45,0,0,0,.21.67,5.44,5.44,0,0,0,.79,1.31,8.82,8.82,0,0,0,1.11,1.18,14.75,14.75,0,0,0,1.33,1,14.18,14.18,0,0,1-1.6-.61,10.07,10.07,0,0,1-1.52-.87A6.92,6.92,0,0,1,20.7,84.1a5.38,5.38,0,0,1-.56-.82,3.23,3.23,0,0,1-.41-1.09Z" />
              <path d="M21,90.93a10.13,10.13,0,0,0,5.41-.81,11.11,11.11,0,0,1-5.37,2.81Z" />
              <g className={cls7}>
                <path
                  className={cls8}
                  d="M18.92,24q-8,2.58-6.46,16.64C7.78,45.18,6.59,51.09,9.3,58.48,4.72,62.25,6,67,11.91,72.51l-1.65,1,.27,6.33S7.92,86,13,88.32s8.38,4.17,8.38,4.17l-1.78,3.67s12.83.84,12-9.62a21.88,21.88,0,0,0-6.38-4.87l2.73-6.53s8.2-3.92,8.44-6.06-2.5-9.27-2.5-9.27,7.08-1.44,9.15-3.92C46.7,51.49,44.91,41,44.91,41s4.49.45,7-1.91C65,26.89,49.67,15.61,49.67,15.61S46.1,4.08,34.7,4.91c-7.47.55-15,7.25-15.69,10.22S18.92,24,18.92,24Z"
                />
              </g>
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M33.84,6.29a29.61,29.61,0,0,0-8.15,4.14,11.11,11.11,0,0,0-4.24,6.94,18.8,18.8,0,0,0,.85,8.56,65.83,65.83,0,0,0,3.57,8.6,24.61,24.61,0,0,1-5.75-7.75,16.72,16.72,0,0,1-1.62-9.92,11.57,11.57,0,0,1,2-4.89A12.39,12.39,0,0,1,24.38,8.5,17.58,17.58,0,0,1,33.84,6.29Z"
                />
              </g>
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M19.19,25c-.66,1.47-1.35,2.74-1.92,4a24.93,24.93,0,0,0-1.4,3.76,25.55,25.55,0,0,0-.75,3.93c-.16,1.37-.21,2.82-.38,4.41a9.32,9.32,0,0,1-2-4.3A10.62,10.62,0,0,1,13,32a10.8,10.8,0,0,1,2.34-4.25A9.73,9.73,0,0,1,19.19,25Z"
                />
              </g>
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M13.65,40.85C13,42.53,12.31,44,11.77,45.52a22,22,0,0,0-1.18,4.41,17.75,17.75,0,0,0-.11,4.5c.14,1.56.47,3.15.78,4.92A10.3,10.3,0,0,1,8.19,55a10.8,10.8,0,0,1-.57-5.5,12.1,12.1,0,0,1,6-8.63Z"
                />
              </g>
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M10.75,59.41A6.16,6.16,0,0,0,9.56,63a8.92,8.92,0,0,0,1.11,3.28c.58,1.12,1.28,2.29,2,3.51a21.18,21.18,0,0,1,1.84,4,15.25,15.25,0,0,1-3.69-2.55A13.28,13.28,0,0,1,8,67.67a7.16,7.16,0,0,1-.76-5,7.26,7.26,0,0,1,.54-1.24,5.77,5.77,0,0,1,.84-1,7,7,0,0,1,1-.68A8.71,8.71,0,0,1,10.75,59.41Z"
                />
              </g>
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M11.91,80.28a7.66,7.66,0,0,0,.63,4.19,3,3,0,0,0,1.18,1.09c.56.32,1.24.64,1.9,1,2.69,1.25,5.5,2.51,8.19,4.06l.91.53-.37.63a24.1,24.1,0,0,1-2.1,3.13,25.61,25.61,0,0,1,.41-3.75l.54,1.16a29.79,29.79,0,0,1-8.93-3.17,14,14,0,0,1-2.09-1.41,4.62,4.62,0,0,1-1.62-2.52,5.06,5.06,0,0,1,.14-2.72A6,6,0,0,1,11.91,80.28Z"
                />
              </g>
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M11.46,73.71a4.31,4.31,0,0,1,1.24,1.53,4.08,4.08,0,0,1,.39,1.57,3.91,3.91,0,0,1-.25,1.6A4.27,4.27,0,0,1,11.73,80a4.16,4.16,0,0,1-1.25-1.53,4.27,4.27,0,0,1-.39-1.57,4,4,0,0,1,.26-1.59A4.22,4.22,0,0,1,11.46,73.71Z"
                />
              </g>
              <g className={cls11}>
                <g className={cls12}>
                  <path
                    className={cls13}
                    d="M46.73,9.61l-13,29.1L26,49l-.58,15.62L20.79,75l-2,7.17s14.62,9.17,8.74,12.61-15,8.89-15,8.89L0,88.3C1.14,52.39,5.73,15.34,27,0,27,0,51.17-.43,46.73,9.61Z"
                  />
                </g>
              </g>
              <path
                className={cls14}
                d="M13.68,57.27a5.14,5.14,0,0,1,.38,3.42"
              />
              <path className={cls14} d="M12.7,53.57a2.16,2.16,0,0,0,.43.65" />
              <path className={cls14} d="M14,79.07A7.31,7.31,0,0,0,14,80.31" />
              <path className={cls14} d="M37.44,51.36c0,1,.29,2,.3,3.06" />
              <path className={cls14} d="M52.64,34.67a3,3,0,0,1,.72,2.26" />
              <line
                className={cls14}
                x1="51.33"
                y1="33.14"
                x2="50.39"
                y2="32.71"
              />
              <path className={cls14} d="M29.66,15.12a1.81,1.81,0,0,0-.49.73" />
            </g>
          </g>
        </g>
      </g>
    </InnerSvg>
  )
}
