import { Auth } from "aws-amplify"
import { useEffect, useState } from "react"
import { useSelector, useDispatch, batch } from "react-redux"

import { setMiscActionCreator } from "@redux/ducks/misc"
import { userInitialState, UserType } from "@redux/ducks/misc/user"
import { selectUser } from "@redux/selectors"
import { DispatchType } from "@redux/types"

import { get } from "@helpers/get"

import { getIsBrowser } from "./misc"

const getUserFromRemote = () =>
  Auth.currentAuthenticatedUser()
    .then((userFromRemote: UserType) => {
      return userFromRemote
    })
    .catch(() => console.log("Not signed in"))

export const useFetchUserAndSetIntoState = () => {
  const dispatch = useDispatch()
  const userFromState = useUserFromState()

  const [complete, setComplete] = useState<boolean>(false)
  const [user, setUser] = useState<UserType>(userFromState)

  useEffect(() => {
    if (!getIsBrowser()) {
      // Not a browser
      setComplete(true)
      return
    }

    if (userFromState !== userInitialState) {
      // There is currently a user set
      setComplete(true)
      return
    }

    getUserFromRemote().then((userFromRemote) => {
      if (!userFromRemote) {
        console.log("no userFromRemote", userFromRemote)
        setComplete(true)
        return
      }

      // Set the user into the state
      // Batch is necessary to reduce the re-renders since we are not on React 18 yet.
      batch(() => {
        dispatch(
          setMiscActionCreator({
            user: userFromRemote,
          })
        )
        setComplete(true)
        setUser(userFromRemote)
      })
    })
  }, [])

  return {
    complete,
    username: getUsername(user),
  }
}

export const signOut = (dispatch: DispatchType) => {
  Auth.signOut()
    .then((data) => {
      console.log("signOut", data)
      dispatch(
        setMiscActionCreator({
          user: userInitialState,
        })
      )
    })
    .catch((err) => console.log(err))
}

export const signIn = () => {
  Auth.federatedSignIn()
}

export const getIsSignedIn = (user: UserType) => user !== userInitialState

export const useUserFromState = () => {
  return useSelector(selectUser)
}

export const getUsername = (user: UserType): string | undefined => {
  return get({
    object: user,
    path: ["username"],
    defaultValue: undefined,
  })
}

export const useUsername = () => {
  const user = useUserFromState()
  return getUsername(user)
}

export const useIsSignedIn = () => {
  const user = useUserFromState()

  console.log({ user })

  return getIsSignedIn(user)
}
