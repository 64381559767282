import { getIsBrowser } from "@helpers/misc"

import { ModeType, SetQueryStringValueType } from "../types"
import { setQueryStringWithoutPageReload } from "./set-query-string-without-page-reload"

// setQueryStringValue: updates the query string parameters only for the provided key, keeping the remaining parameters intact.
export const setQueryStringValue = ({
  key,
  value,
  mode,
}: SetQueryStringValueType) => {
  if (!getIsBrowser()) {
    return
  }

  const queryString = window.location.search
  let params = new URLSearchParams(queryString)

  params.set(key, value)

  setQueryStringWithoutPageReload(params.toString(), mode)
}
