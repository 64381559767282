import React from "react"

import { WrapperConstructor } from "@components/types"

// https://react-bootstrap.github.io/components/forms/
import { Value } from "../types"

export const TYPE_TEXT = "text"
const TYPE_NUMBER = "number"
interface Feedback {
  type?: "valid" | "invalid"
  tooltip?: boolean
  display: string
}
interface CommonProps {
  id: string
  labelDisplay: React.ReactNode
  type?: typeof TYPE_TEXT | typeof TYPE_NUMBER
  placeholder?: string
  as?: "textarea"
  rows?: number
  isInvalid?: boolean
  isValid?: boolean
  feedback?: Feedback
  belowContent?: React.ReactNode
}

export interface UseInputProps extends CommonProps {
  defaultValue?: Value
}

export interface InputProps extends CommonProps {
  value: Value
  setValue: (value: Value) => void
  LabelWrapper?: WrapperConstructor
}
