import { MageEmoji } from "accessible-emojis"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import { SceneProps } from "@views/english-demo-1/types"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"

import { signIn, useUserFromState } from "@helpers/auth"
import {
  D_NONE,
  D_MD_FLEX,
  TEXT_CENTER,
  FONT_WEIGHT_NORMAL,
} from "@helpers/constants/style"

import { dungeonMonsterList } from "./constants"
import { useEnglishProblems, useLoadUserResponses } from "./hooks"
import { PracticeRow } from "./practice-row"
import { containerItems } from "./utils"

export const PracticeList = ({
  isActiveScene,
  nextScene,
  setShowStimulus,
  setSceneById,
}: SceneProps) => {
  useScrollTop(isActiveScene)

  const user = useUserFromState()

  // Get English Problems Data
  const englishProblems = useEnglishProblems(isActiveScene, () =>
    setShowStimulus(false)
  )

  // Get User Input Data
  useLoadUserResponses(isActiveScene)

  const listOfProblemIds = Object.keys(englishProblems.problems)

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <SpacedWrapper>
              <>
                <Button
                  onClick={() => {
                    setSceneById("0")
                  }}
                >
                  Back to Tutorial
                </Button>
                <GoogleFormLinkGeneralFeedback />
              </>
            </SpacedWrapper>
          </Col>
          <Col xs={12}>
            <h1 className={TEXT_CENTER}>Dungeon</h1>
            <p>
              The dungeon presents a list of questions{" "}
              <strong>(monsters)</strong> you can practice against for your real
              HSC common module exam. All questions have sample responses for
              you to look at, and to reflect on your own response.
            </p>
            {user ? (
              <p>
                <MageEmoji className={FONT_WEIGHT_NORMAL} /> You're currently
                signed in. Your responses will be saved.
              </p>
            ) : (
              <div className="mb-4">
                <Button onClick={signIn}>Sign In</Button>
                <span>
                  {" "}
                  and we'll save your responses for you to look back on.
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className={`${D_NONE} ${D_MD_FLEX} ${TEXT_CENTER}`}>
          {containerItems.map((containerItem) => {
            return (
              <Col key={containerItem.title} xs={12} md={containerItem.md}>
                <strong>{containerItem.title}</strong>
              </Col>
            )
          })}
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        {listOfProblemIds.map((problemId, i) => {
          const dungeonMonsterIndex = i % dungeonMonsterList.length
          return (
            <PracticeRow
              key={problemId}
              problem={englishProblems.problems[problemId]}
              nextScene={nextScene}
              dungeonMonster={dungeonMonsterList[dungeonMonsterIndex]}
            />
          )
        })}
      </Container>
    </>
  )
}
