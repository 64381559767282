import React from "react"

import { Minion } from "@views/english-demo-1/common/minion"
import { ProgressBar } from "@views/english-demo-1/common/progress-bar"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { TextFieldScene } from "@views/english-demo-1/common/text-field-scene"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { ScenePropsWithInputProps } from "@views/english-demo-1/types"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import { HERO_CLASS_TANK } from "@helpers/constants/hero-class"

import minionSrc from "@images/monsters/skeletons/skeleton-fighter-min.png"

const minionAlt = "Skeleton Fighter"
const minion = <Minion src={minionSrc} alt={minionAlt} />

export const Action6D = ({
  previousScene,
  nextScene,
  inputProps,
  question,
  sampleAnswer,
  isActiveScene,
}: ScenePropsWithInputProps) => {
  const avatar = (
    <div className={avatarContainer}>
      {useEnglishDemoAvatar({
        heroClass: HERO_CLASS_TANK,
      })}
    </div>
  )
  useScrollTop(isActiveScene)
  return (
    <section>
      <ProgressBar index={8} />
      <TextFieldScene
        question={question}
        sampleAnswer={sampleAnswer}
        minion={minion}
        avatar={avatar}
        inputProps={inputProps}
        previousScene={previousScene}
        nextScene={nextScene}
        belowNav={<MainQuestionReference />}
      />
    </section>
  )
}
