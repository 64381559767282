import { ModuleCodeType } from "@views/multiple-choice-problem/types"
import { PHYSICS_QUERIES } from "@views/physics/common/constants"
import { getRemoteFetchKeyForPhysicsList } from "@views/physics/common/utils/get-remote-fetch-key"

import { createRemoteThunk } from "@helpers/create-remote-thunk"
import { useMetaState } from "@helpers/hooks/use-meta-state"

export const usePhysicsListMetaState = (moduleCode: ModuleCodeType) => {
  const key = getRemoteFetchKeyForPhysicsList(moduleCode)
  const remoteThunk = createRemoteThunk(`${PHYSICS_QUERIES}/${key}.json`)

  const metaState = useMetaState({
    key,
    remoteThunk,
  })

  return metaState
}
