import React from "react"

import { weapon } from "@components/Avatar/a.module.scss"
import { AvatarItem } from "@components/Avatar/common/avatar-item"
import { BaseAvatarProps } from "@components/Avatar/types"

import { dimensions, originPosition } from "./constants"
import src from "./weapon.svg"

export const KnightWeapon = (baseAvatar: BaseAvatarProps) => {
  return (
    <AvatarItem
      className={weapon}
      src={src}
      dimensions={dimensions}
      originPosition={originPosition}
      baseAvatar={baseAvatar}
    />
  )
}
