import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import { useDispatch } from "react-redux"

import { setAvatarTankActionCreator } from "@redux/ducks/user-synced/avatar-tank"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"

import { MB_2 } from "@helpers/constants/style"

const getNextPartIndex = ({
  activeIndex,
  listOfPartIds,
}: {
  activeIndex: number
  listOfPartIds: string[]
}) => {
  const isLastIndex = activeIndex === listOfPartIds.length - 1
  if (isLastIndex) {
    return 0
  } else {
    return activeIndex + 1
  }
}

export const AvatarToggle = ({
  payloadKey,
  listOfPartIds,
  children,
}: {
  payloadKey: string
  listOfPartIds: string[]
  children: string
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const dispatch = useDispatch()

  return (
    <SpacedWrapper className={MB_2}>
      <Button
        block
        onClick={() => {
          const nextPartIndex = getNextPartIndex({
            activeIndex,
            listOfPartIds,
          })
          const nextPartId = listOfPartIds[nextPartIndex]

          dispatch(setAvatarTankActionCreator({ [payloadKey]: nextPartId }))
          setActiveIndex(nextPartIndex)
        }}
      >
        {children}
      </Button>
    </SpacedWrapper>
  )
}
