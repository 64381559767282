import React from "react"

import { useFetchPhysicsMCUserResponses } from "@redux/ducks/misc/physics-mc-user-responses/hooks"
import { useFetchUserSynced } from "@redux/ducks/user-synced/hooks"

import { AppLayout } from "@components/layouts/app-layout"
import {
  RenderByMetaStatusValue,
  getCompoundMetaStatusValue,
} from "@components/render-by-meta-status-value"
import { RouterItem } from "@components/types"

import { useIsSignedIn } from "@helpers/auth"
import { META_STATUS_SUCCESS } from "@helpers/hooks/use-meta-status/constants"

import { PhysicsPracticeListViewContent } from "./main"

export const PhysicsPracticeListView = (props: RouterItem) => {
  return (
    <AppLayout>
      <PhysicsPracticeList />
    </AppLayout>
  )
}

const PhysicsPracticeList = () => {
  // Get User Response Data
  const { status: physicsMCUserResponsesStatus } =
    useFetchPhysicsMCUserResponses()

  // Get User Synced Data
  const userSynced = useFetchUserSynced()

  const isSignedIn = useIsSignedIn()

  // This wants the physics responses and the user synced values loaded before displaying anything.
  // However it doesn't account for users who are not logged in, which will be neutral for both fetches since there is no username.
  const compoundMetaStatusValue = getCompoundMetaStatusValue([
    physicsMCUserResponsesStatus,
    userSynced.metaStatusValue,
  ])

  // If you'r not signed in, assume success.
  const metaStatusValue = isSignedIn
    ? compoundMetaStatusValue
    : META_STATUS_SUCCESS

  return (
    <RenderByMetaStatusValue
      metaStatusValue={metaStatusValue}
      successElement={<PhysicsPracticeListViewContent />}
    />
  )
}
