import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import { TechniqueBox } from "@views/english-demo-1/common/technique-box"

import { ANIMATION_FADEOUT } from "@helpers/constants/style"

import { HasAnsweredView } from "./has-answered-view"
import { HasntAnsweredView } from "./hasnt-answered-view"
import { TextFieldSceneProps } from "./types"

export const TextFieldScene = ({
  question,
  sampleAnswer,
  previousScene,
  nextScene,
  avatar,
  minion,
  inputProps,
  techniqueBoxProps,
  hasAnsweredCorrectlyCallback,
  belowNav,
}: TextFieldSceneProps) => {
  const [hasAnswered, setHasAnswered] = React.useState(false)

  return (
    <section>
      <SpacedWrapper>
        <Button onClick={previousScene}>Back</Button>
        <GoogleFormLinkGeneralFeedback />
      </SpacedWrapper>
      {belowNav}
      <Container>
        <Row>
          <Col xs={12}>
            <SpacedWrapper className="flex-wrap">
              <>
                {avatar}
                <div className={hasAnswered ? ANIMATION_FADEOUT : undefined}>
                  {minion}
                </div>
              </>
            </SpacedWrapper>
          </Col>
        </Row>
      </Container>
      {techniqueBoxProps && (
        <div className="mb-4">
          <TechniqueBox {...techniqueBoxProps} />
        </div>
      )}
      {question}
      {!hasAnswered ? (
        <HasntAnsweredView
          setHasAnswered={setHasAnswered}
          inputProps={inputProps}
          hasAnsweredCorrectlyCallback={hasAnsweredCorrectlyCallback}
        />
      ) : (
        <HasAnsweredView
          sampleAnswer={sampleAnswer}
          value={inputProps.value}
          previousScene={previousScene}
          nextScene={nextScene}
        />
      )}
    </section>
  )
}
