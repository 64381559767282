import { CacheKeyRemoteAttributePairType } from "@components/types"

import { useUsername } from "@helpers/auth"
import { useUserDataMetaState } from "@helpers/hooks/use-user-data-meta-state"
import { getSettingsUserTableItemId } from "@helpers/user-data/get-user-table-item-id"

export { USER_SETTINGS_HAS_SEEN_ONBOARDING } from "./constants"

// Read from User table, settings remoteId.
export const useUserSettingsDataMetaState = ({
  cacheKey,
  remoteAttribute,
}: CacheKeyRemoteAttributePairType) => {
  const username = useUsername()

  const isLoggedIn = Boolean(username)
  const remoteId = username ? getSettingsUserTableItemId(username) : undefined

  console.log("useUserSettingsDataMetaState", {
    username,
    remoteId,
  })

  const metaState = useUserDataMetaState({
    remoteId,
    cacheKey,
    remoteAttribute,
    isLoggedIn,
  })

  return metaState
}
