import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeKatex from "rehype-katex"
import remarkGfm from "remark-gfm"
import remarkMath from "remark-math"

import { MarkdownHelmet } from "@components/Helmets/MarkdownHelmet"

interface Props {
  children: string
  components?: {
    [key: string]: string
  }
}

export const MarkDownRenderer = ({ children, components }: Props) => {
  return (
    <ReactMarkdown skipHtml remarkPlugins={[remarkGfm]} components={components}>
      {children}
    </ReactMarkdown>
  )
}

export const MarkDownMathRenderer = ({ children, components }: Props) => {
  return (
    <>
      <MarkdownHelmet />
      <ReactMarkdown
        skipHtml
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={components}
      >
        {children}
      </ReactMarkdown>
    </>
  )
}
