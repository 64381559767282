import React from "react"
import Form from "react-bootstrap/Form"

import { HOVER_CLICKABLE, CURSOR_POINTER } from "@helpers/constants/style"

import { UseCheckboxProps, CheckboxProps } from "./types"

export function useCheckboxProps({
  id,
  labelDisplay,
  defaultChecked = false,
  disabled = false,
}: UseCheckboxProps): CheckboxProps {
  const [checked, setChecked] = React.useState(defaultChecked)

  return {
    id,
    labelDisplay,
    disabled,
    checked,
    setChecked,
  }
}

export const Checkbox = ({
  id,
  labelDisplay,
  checked,
  setChecked,
  disabled = false,
}: CheckboxProps) => {
  const onChange = !disabled ? () => setChecked(!checked) : undefined
  const label = !disabled ? (
    <div className={`${HOVER_CLICKABLE} ${CURSOR_POINTER}`}>{labelDisplay}</div>
  ) : (
    labelDisplay
  )

  return (
    <Form.Check
      id={id}
      type="checkbox"
      label={label}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    ></Form.Check>
  )
}
