import React from "react"

const countWords = ["1st", "2nd", "3rd"]

export const Question = ({
  question,
  triangleCount,
}: {
  question: string
  triangleCount: number
}) => {
  const marks = triangleCount + 1
  const points = []
  for (let i = 0; i < triangleCount; i++) {
    points.push(
      <li key={`marking-criteria-point-${i}`}>
        {countWords[i] ? countWords[i] : `${i + 1}th`} piece of evidence clearly
        supports written thesis and includes a quote, technique and effect (1
        mark)
      </li>
    )
  }

  return (
    <div>
      <h2 className="h4">Empath: Reflect by marking your own response</h2>
      <p>
        <em>
          {question} ({marks} Marks)
        </em>
      </p>
      <h3 className="h5 mb-2">Marking criteria:</h3>
      <ul>
        <li>A clear thesis which directly addresses the question (1 mark)</li>
        {points}
      </ul>
    </div>
  )
}
