import React from "react"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { SimpleCenteredModal } from "@components/centered-modal"
import { NOTES_COST } from "@components/hero-stats/constants"

import { W_100, MB_0, H2, TEXT_PRIMARY } from "@helpers/constants/style"
import { jsxJSONArrayProcessor } from "@helpers/jsxJSONProcessor"
import { JSXJSONType } from "@helpers/jsxJSONProcessor/types"

import { HideModalFooter } from "../../common/hide-modal-footer"
import { PotionExpenseHeader } from "../../common/potion-expense-header"
import { RestingFlavourText } from "../../common/resting-flavour-text"
import { RestingModalFooter } from "../../common/resting-modal-footer"

interface Props {
  show: boolean
  notes?: JSXJSONType[]
}

const NOTES_DESCRIPTION = "Accessing Notes 📝"

export const RestingNotesModal = ({ show, notes }: Props) => {
  const headerContent = (
    <SpacedWrapper className={W_100}>
      <div className={`${H2} ${MB_0} ${TEXT_PRIMARY}`}>
        Rest to recover damage 😴
      </div>
      <div className={`${H2} ${MB_0}`}>{NOTES_DESCRIPTION}</div>
    </SpacedWrapper>
  )

  const bodyContent = (
    <article>
      <RestingFlavourText />
      {jsxJSONArrayProcessor(notes || ["No notes exist"])}
    </article>
  )

  return (
    <SimpleCenteredModal
      show={show}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={<RestingModalFooter />}
    />
  )
}

export const NotesModal = ({ show, notes }: Props) => {
  return (
    <SimpleCenteredModal
      show={show}
      headerContent={
        <PotionExpenseHeader
          cost={NOTES_COST}
          description={NOTES_DESCRIPTION}
        />
      }
      bodyContent={jsxJSONArrayProcessor(notes || ["No notes exist"])}
      footerContent={<HideModalFooter />}
      mode={"free"}
    />
  )
}
