import React from "react"
import Button from "react-bootstrap/Button"

import {
  CenteredWrapper,
  SpacedWrapper,
} from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import { CharacterIntroScene } from "@views/english-demo-1/common/character-intro-scene"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { SceneProps } from "@views/english-demo-1/types"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"

import { H_100 } from "@helpers/constants/style"

const intro = (
  <>
    <h1>Reflect on Your Response</h1>
    <p>
      Now that you've answered the question, here's your chance to reflect on
      your response with the marking criteria in mind.
    </p>
    <p>
      The best way to do this is by empathising with the question, your
      response, and the marking criteria in unison. This is a job best suited
      for the <b>Empath</b>.
    </p>
  </>
)

const description = (
  <CenteredWrapper className={H_100}>
    <div>
      <h2 className="text-center">Empath</h2>
      <p>
        <b>Empaths</b> are the thoughtful ones. They consider different
        perspectives, reflect on their actions, and make good judgement.
      </p>
    </div>
  </CenteredWrapper>
)

export const Action7B = ({
  previousScene,
  nextScene,
  isActiveScene,
}: SceneProps) => {
  useScrollTop(isActiveScene)
  return (
    <>
      <SpacedWrapper>
        <>
          <Button onClick={previousScene}>Back</Button>
          <GoogleFormLinkGeneralFeedback />
        </>
      </SpacedWrapper>
      <MainQuestionReference />
      <CharacterIntroScene
        intro={intro}
        description={description}
        avatarType="empath"
        primaryButtonOnClick={nextScene}
        primaryButtonText="Begin Empathising"
      />
    </>
  )
}
