import { QueryParamValueType } from "@components/types"

import { ModuleCodeType } from "@views/multiple-choice-problem/types"

export const createModuleSelectionHandler =
  ({
    moduleJustSelected,
    moduleValue,
    onSetModuleParamValue,
    onSetPageParamValue,
  }: {
    moduleJustSelected: ModuleCodeType
    moduleValue: ModuleCodeType
    onSetModuleParamValue: (value: QueryParamValueType) => void
    onSetPageParamValue: (value: QueryParamValueType) => void
  }) =>
  () => {
    if (moduleValue === moduleJustSelected) {
      return
    }
    onSetModuleParamValue(moduleJustSelected)
    onSetPageParamValue("1")
  }
