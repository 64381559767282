import React from "react"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { SimpleCenteredModal } from "@components/centered-modal"
import { VIDEO_COST } from "@components/hero-stats/constants"

import { W_100, MB_0, H2, TEXT_PRIMARY } from "@helpers/constants/style"
import { jsxJSONArrayProcessor } from "@helpers/jsxJSONProcessor"
import { ContentlessJSXJSONType } from "@helpers/jsxJSONProcessor/types"

import { HideModalFooter } from "../../common/hide-modal-footer"
import { PotionExpenseHeader } from "../../common/potion-expense-header"
import { RestingFlavourText } from "../../common/resting-flavour-text"
import { RestingModalFooter } from "../../common/resting-modal-footer"

const videoPlayerProps = {
  className: W_100,
  height: "400",
  frameBorder: "0",
  allow:
    "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
  allowFullScreen: false,
}

const VIDEO_DESCRIPTION = "Accessing Video 🎥"

interface Props {
  show: boolean
  video: string
}

export const RestingVideoModal = ({ show, video }: Props) => {
  const headerContent = (
    <SpacedWrapper className={W_100}>
      <div className={`${H2} ${MB_0} ${TEXT_PRIMARY}`}>
        Rest to recover damage 😴
      </div>
      <div className={`${H2} ${MB_0}`}>{VIDEO_DESCRIPTION}</div>
    </SpacedWrapper>
  )

  const videoJsxJson = [
    {
      type: "iframe",
      props: {
        ...videoPlayerProps,
        src: video,
      },
    },
  ] as ContentlessJSXJSONType[]

  const bodyContent = (
    <article>
      <RestingFlavourText />
      {jsxJSONArrayProcessor(videoJsxJson)}
    </article>
  )
  return (
    <SimpleCenteredModal
      show={show}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={<RestingModalFooter />}
    />
  )
}

export const VideoModal = ({ show, video }: Props) => {
  const headerContent = (
    <PotionExpenseHeader cost={VIDEO_COST} description={VIDEO_DESCRIPTION} />
  )

  const videoJsxJson = [
    {
      type: "iframe",
      props: {
        ...videoPlayerProps,
        src: video,
      },
    },
  ] as ContentlessJSXJSONType[]

  const bodyContent = jsxJSONArrayProcessor(videoJsxJson)

  return (
    <SimpleCenteredModal
      show={show}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={<HideModalFooter />}
      mode={"free"}
    />
  )
}
