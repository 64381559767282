/**
 * Detailed guidance here: https://github.com/airwallex/airwallex-payment-demo/blob/master/docs/dropin.md
 */
// STEP #1: At the start of your file, import airwallex-payment-elements package
import {
  createElement,
  loadAirwallex,
  ElementType,
} from "airwallex-payment-elements"
import React, { useEffect, useState } from "react"
import { batch } from "react-redux"

import { CenteredLoader } from "@components/Loader"
import { ContactEmail } from "@components/contact-email"

import { D_BLOCK, D_NONE } from "@helpers/constants/style"
import { fetchPaymentIntent } from "@helpers/services/fetch-payment-intent"

import { DropInProps } from "../types"

const DROP_IN_ELEMENT_ID = "dropIn"

export const DropIn = ({ env, paymentPayload, onSuccess }: DropInProps) => {
  const [elementShow, setElementShow] = useState<boolean>(false) // Example: set element show state
  const [errorMessage, setErrorMessage] = useState<string>("") // Example: set error state
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const displayLoader = isLoading && !elementShow

  useEffect(() => {
    const init = async (): Promise<boolean> => {
      // STEP #2: Initialize Airwallex on mount with the appropriate production environment and other configurations
      await loadAirwallex({
        env,
        origin: window.location.origin, // Setup your event target to receive the browser events message
        fonts: [
          // Customizes the font for the payment elements
          {
            src: "https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2",
            family: "AxLLCircular",
            weight: 400,
          },
        ],
        // For more detailed documentation at https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#loadAirwallex
      })

      console.time()
      const { value: paymentIntent, error } = await fetchPaymentIntent(
        paymentPayload,
        env
      )
      console.timeEnd()

      const intent_id = paymentIntent?.intent_id
      const client_secret = paymentIntent?.client_secret

      if (error !== undefined) {
        throw error
      }

      // STEP #4: Create the drop-in element
      const element = createElement(DROP_IN_ELEMENT_ID as ElementType, {
        // Required, dropIn use intent Id, client_secret and currency to prepare checkout
        intent_id,
        client_secret,
        currency: paymentPayload.currency,
        components: ["card"],
      })
      // STEP #5: Mount the drop-in element to the empty container created previously
      element?.mount(DROP_IN_ELEMENT_ID) // This 'dropIn' id MUST MATCH the id on your empty container created in Step 3
      return true
    }

    const initHandled = async () => {
      try {
        await init()
      } catch (error) {
        batch(() => {
          setElementShow(false)
          // Loading is set to false since it is no longer trying to load the async assets.
          setIsLoading(false)
          setErrorMessage(
            "Something went wrong (Error Code 1). Try reloading the browser."
          )
          console.log("init error", error)
        })
      }
    }

    initHandled()

    // STEP ##6: Add an event listener to handle events when the element is mounted
    const onReady = (event: CustomEvent): void => {
      /**
       * ... Handle events on element mount
       */
      if (isLoading) {
        batch(() => {
          setErrorMessage("")
          setElementShow(true)
          setIsLoading(false)
          console.log(`Element is mounted: ${JSON.stringify(event.detail)}`)
        })
      }
    }

    // STEP ##8: Add an event listener to handle events when the payment has failed.
    const onError = (event: CustomEvent) => {
      /**
       * ... Handle events on error
       */
      const { error } = event.detail
      // setErrorMessage(error.message ?? JSON.stringify(error)) // Example: set error message

      const ERROR_PREFIX =
        "Something went wrong (Error Code 2). Your payment failed."

      setErrorMessage(
        error.message ? `${ERROR_PREFIX} ${error.message}` : ERROR_PREFIX
      )
      console.error("There is an error", error)
    }

    window.addEventListener("onReady", onReady as EventListener)
    window.addEventListener("onSuccess", onSuccess as EventListener)
    window.addEventListener("onError", onError as EventListener)
    return () => {
      window.removeEventListener("onReady", onReady as EventListener)
      window.removeEventListener("onSuccess", onSuccess as EventListener)
      window.removeEventListener("onError", onError as EventListener)
    }
  }, []) // This effect should ONLY RUN ONCE as we do not want to reload Airwallex and remount the elements

  return (
    <div>
      {displayLoader && (
        <CenteredLoader>
          <p>Loading Secure Payment...</p>
        </CenteredLoader>
      )}
      {errorMessage.length > 0 && (
        <p>
          {errorMessage} You can contact support at <ContactEmail />
        </p>
      )}
      <div className={elementShow ? D_BLOCK : D_NONE} id={DROP_IN_ELEMENT_ID} />
    </div>
  )
}
