import {
  BackpackEmoji,
  CrossedSwordsEmoji,
  HandWithFingersSplayedEmoji,
  BackhandIndexPointingRightEmoji,
} from "accessible-emojis"
import React from "react"

import { ModalIdType } from "@redux/ducks/ui/modal"
import {
  UserStatusType,
  USER_STATUS_DANGER,
} from "@redux/ducks/user-synced/user-status"

import { TableRow } from "@components/Table"
import { CellTypes } from "@components/Table/types"
import { StatusAvatar } from "@components/status-avatar"

import { HERO_CLASS_TANK } from "@helpers/constants/hero-class"
import { ANIMATION_PULSE, ANIMATION_INFINITE } from "@helpers/constants/style"
import {
  W_50,
  PX_2,
  PL_2,
  PB_1,
  MT_4,
  FONT_WEIGHT_NORMAL,
} from "@helpers/constants/style"
import { JSXJSONType } from "@helpers/jsxJSONProcessor/types"
import { capitaliseFirstLetter } from "@helpers/strings"

import { CurrencyCounters } from "./currency-counters"
import { NotesButton } from "./notes-button"
import { VideoButton } from "./video-button"

interface Props {
  stars: number
  potions: number
  userStatus: UserStatusType
  modalId?: ModalIdType
  video?: string
  notes?: JSXJSONType[]
}

export const HeroStats = ({
  stars,
  potions,
  userStatus,
  modalId,
  video,
  notes,
}: Props) => {
  return (
    <table id="hero-stats" className={MT_4}>
      <thead>
        <TableRow
          cells={[
            {
              type: "th",
              className: "text-center",
              content: "Hero Stats",
              colSpan: 2,
            },
          ]}
        />
      </thead>
      <tbody>
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: `${W_50} ${PL_2}`,
              content: (
                <>
                  <BackpackEmoji className={FONT_WEIGHT_NORMAL} /> Bag
                </>
              ),
            },
            {
              className: `${W_50} ${PL_2}`,
              content: (
                <CurrencyCounters stars={stars} potions={potions} />
              ) as JSX.Element,
            },
          ]}
        />
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: `${W_50} ${PL_2}`,
              content: (
                <div>
                  <>
                    <CrossedSwordsEmoji className={FONT_WEIGHT_NORMAL} />{" "}
                    {capitaliseFirstLetter(HERO_CLASS_TANK)}
                  </>
                </div>
              ),
            },
            {
              className: W_50,
              content: (
                <StatusAvatar
                  status={userStatus}
                  heroClass={HERO_CLASS_TANK}
                  direction="left"
                />
              ) as JSX.Element,
            },
          ]}
        />
        <TableRow
          cells={[
            {
              type: "th" as CellTypes,
              className: `${W_50} ${PL_2}`,
              content: (
                <div
                  className={
                    userStatus === USER_STATUS_DANGER
                      ? `${ANIMATION_PULSE} ${ANIMATION_INFINITE}`
                      : ""
                  }
                >
                  <HandWithFingersSplayedEmoji className={FONT_WEIGHT_NORMAL} />{" "}
                  Use potions <i>(get help)</i>{" "}
                  {userStatus === USER_STATUS_DANGER && (
                    <BackhandIndexPointingRightEmoji
                      className={`${PB_1} ${FONT_WEIGHT_NORMAL}`}
                    />
                  )}
                </div>
              ) as JSX.Element,
            },
            {
              className: `${W_50} ${PX_2}`,
              content: (
                <section id="hero-stats-use-potions">
                  {video && (
                    <VideoButton
                      video={video}
                      potions={potions}
                      modalId={modalId}
                    />
                  )}
                  <NotesButton
                    notes={notes}
                    potions={potions}
                    modalId={modalId}
                  />
                </section>
              ) as JSX.Element,
            },
          ]}
        ></TableRow>
      </tbody>
    </table>
  )
}
