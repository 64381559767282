import { PAYMENT_KEY } from "@redux/ducks/misc/payment"

import {
  CacheKeyRemoteAttributePairType,
  InMemoryKeyRemoteAttributePairType,
} from "@components/types"

import {
  REMOTE_ATTRIBUTE_BOOLEAN_HEADS,
  REMOTE_ATTRIBUTE_OBJECT_TRIANGLE,
} from "@helpers/constants/remote-attributes"

export const USER_SETTINGS_HAS_SEEN_ONBOARDING: CacheKeyRemoteAttributePairType =
  {
    cacheKey: "user-settings-has-seen-onboarding",
    remoteAttribute: REMOTE_ATTRIBUTE_BOOLEAN_HEADS,
  }

export const USER_SETTINGS_PAYMENT: InMemoryKeyRemoteAttributePairType = {
  inMemoryKey: PAYMENT_KEY,
  remoteAttribute: REMOTE_ATTRIBUTE_OBJECT_TRIANGLE,
}
