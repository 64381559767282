import React from "react"

import { HairAsset } from "@components/Avatar/types"
import { Svg } from "@components/Svg"

import { cls1, cls2, cls5, cls6, cls7, cls8 } from "./dh.module.scss"

export const DruidHairAsset = ({ skinTone, hairColour }: HairAsset) => {
  return (
    <Svg viewBox="0 0 97.66 84.43">
      <g className={cls1}>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="HAIR">
              <path
                className={cls2}
                d="M17.61,73.42c-1.54.68-4.57-.54-5.75-1.22C9.27,57.69,2,36,15.5,18.94,26.73,4.79,40.61,2.84,50.68,3c29.48.51,42.88,26.1,38.63,38.15C88.17,38.32,86.92,34,86.92,34c-6.38-3.93-15.08-1.25-19.65,2.13C57,29.75,41,29.39,34.77,33.64c-6.66,4.53-9,15-10.06,19.49l-6.37,1.81C16.31,63.87,14.62,65.24,17.61,73.42Z"
              />
              <path
                className={hairColour}
                d="M17.61,73.42c-1.54.68-4.57-.54-5.75-1.22C9.27,57.69,2,36,15.5,18.94,26.73,4.79,40.61,2.84,50.68,3c29.48.51,42.88,26.1,38.63,38.15C88.17,38.32,86.92,34,86.92,34c-6.38-3.93-15.08-1.25-19.65,2.13C57,29.75,41,29.39,34.77,33.64c-6.66,4.53-9,15-10.06,19.49l-6.37,1.81C16.31,63.87,14.62,65.24,17.61,73.42Z"
              />
              <path d="M8.8,45.17A13.49,13.49,0,0,1,9.16,50c-.11,1.59-.32,3.13-.55,4.61a47.36,47.36,0,0,0-.8,8.23L4.7,59.93a17.2,17.2,0,0,1,2.36,0c.72,0,1.37.1,2,.17s1.28.13,2,.13a11.52,11.52,0,0,0,2.6-.34,4.43,4.43,0,0,1-.93,3,6.82,6.82,0,0,1-2.48,2,9.67,9.67,0,0,1-2.81.87,15.22,15.22,0,0,1-2.5.18l-3.11,0V63a19.24,19.24,0,0,1,.75-5.12,29,29,0,0,1,1.72-4.52c.67-1.43,1.44-2.77,2.21-4.09S8.1,46.64,8.8,45.17Z" />
              <path
                className={hairColour}
                d="M8.8,45.17c-.23,4.33-4.07,11.26-4,17.73,2.68-.12,6.58.1,8.85-3"
              />
              <path d="M11.67,26.69A27.73,27.73,0,0,1,10.89,32c-.4,1.73-.86,3.41-1.36,5.06S8.51,40.37,8,42s-1,3.17-1.41,4.67l-2.14-3.7c.23,0,.47,0,.92,0a15.45,15.45,0,0,0,1.72-.25c.68-.13,1.45-.28,2.29-.4a13.14,13.14,0,0,1,2.81-.15,9.34,9.34,0,0,1-1,2.69A8.85,8.85,0,0,1,9.45,47a7.38,7.38,0,0,1-2.66,1.58A6.55,6.55,0,0,1,3,48.71l-3-.83L.85,45a34.19,34.19,0,0,1,2-5.11,52.24,52.24,0,0,1,2.56-4.66c.92-1.5,1.92-3,3-4.36S10.52,28.1,11.67,26.69Z" />
              <path
                className={hairColour}
                d="M11.67,26.69c-1.45,4.09-6.21,12.9-7.95,19.13,2.6.65,5.39-1.29,8.46-3.66"
              />
              <path d="M11.65,58.69a13.7,13.7,0,0,1,.79,4.8c0,1.6-.06,3.15-.15,4.64a48,48,0,0,0-.08,8.27L8.85,73.75a19.91,19.91,0,0,1,2.36-.23c.71,0,1.37,0,2,0s1.28,0,2,0a12.07,12.07,0,0,0,2.56-.57,4.44,4.44,0,0,1-.67,3,6.81,6.81,0,0,1-2.29,2.21,9.76,9.76,0,0,1-2.72,1.11,14.76,14.76,0,0,1-2.48.4l-3.11.26L6.28,77a19.47,19.47,0,0,1,.3-5.16A28.53,28.53,0,0,1,7.9,67.19c.54-1.48,1.19-2.88,1.84-4.26S11.08,60.22,11.65,58.69Z" />
              <path
                className={hairColour}
                d="M11.65,58.69c.15,4.34-3.07,11.57-2.4,18,2.66-.36,6.56-.48,8.55-3.81"
              />
              <path d="M14.56,62.7a13.45,13.45,0,0,1,2.63,4A40.36,40.36,0,0,1,18.85,71c1,2.82,1.72,5.56,2.69,8l-4.31-1.51c-.25.15-.12.1,0-.14a10.74,10.74,0,0,0,.46-1c.3-.84.59-1.84.86-2.87l.85-3.28c.31-1.14.6-2.32,1.05-3.61a11.09,11.09,0,0,1,2.05,3.37,14.28,14.28,0,0,1,1,3.78,13,13,0,0,1-.16,4.1A9,9,0,0,1,22.54,80a5.87,5.87,0,0,1-2.26,2.51l-3.09,1.89L16,81a25.55,25.55,0,0,1-1.15-4.7,36.3,36.3,0,0,1-.39-4.6c0-1.51,0-3,.06-4.49S14.63,64.3,14.56,62.7Z" />
              <path
                className={hairColour}
                d="M14.56,62.7c1.85,3.93,2,11.35,4.2,17.28,2.3-1.37,2.87-8,1.7-13.48"
              />
              <path d="M22.11,53.49a12.48,12.48,0,0,0-4-2.33,3.65,3.65,0,0,0-1.7,0,3.32,3.32,0,0,0-1.25.81,7.29,7.29,0,0,0-1.81,6.66,13.59,13.59,0,0,0,1.21,3.82,9.23,9.23,0,0,0,2.22,3,3.56,3.56,0,0,1,.31.26c.1.07.2.1.3.17a3.18,3.18,0,0,0,.8.31,4.85,4.85,0,0,0,1.87.09,7.53,7.53,0,0,0,4-2.14,6.53,6.53,0,0,1-3.05,4.42,6.54,6.54,0,0,1-6.16.3,10.78,10.78,0,0,1-4.36-4.08,14.42,14.42,0,0,1-2-5.37,11.67,11.67,0,0,1,.56-6,9.25,9.25,0,0,1,4-4.73c.25-.13.49-.26.74-.37a8,8,0,0,1,.83-.26,5.64,5.64,0,0,1,1.74-.19,5.93,5.93,0,0,1,3,1.16,7.82,7.82,0,0,1,1.84,2.09A8.43,8.43,0,0,1,22.11,53.49Z" />
              <path
                className={skinTone}
                d="M22.11,53.49s-4.45-7.33-9.29-2.16c-5,5.38-.23,15.56,4.4,16.39S24,64.12,24,64.12"
              />
              <path d="M19.86,63.37a2.85,2.85,0,0,1-1,.59,1.81,1.81,0,0,1-1.31-.06,2.12,2.12,0,0,1-1-1,3.36,3.36,0,0,1-.38-1.3,3.65,3.65,0,0,1,.16-1.37,2.19,2.19,0,0,1,2.76-1.5,4.16,4.16,0,0,1,1.28.65l-1.57.74,0-1.46a12.25,12.25,0,0,0-.19-1.39,3.84,3.84,0,0,0-1-2.2,1.47,1.47,0,0,0-.88-.39,1.74,1.74,0,0,0-1.07.34,3.93,3.93,0,0,0-1.47,2.43,3.6,3.6,0,0,1,.71-3.23,2.84,2.84,0,0,1,1.68-1,2.93,2.93,0,0,1,2,.45,5,5,0,0,1,1.93,3.14,10,10,0,0,1,.26,1.69c0,.56,0,1.11,0,1.67l0,1.91L19.18,61a3,3,0,0,0-.63-.41.48.48,0,0,0-.37,0c-.21,0-.57.57-.61,1.14A1.88,1.88,0,0,0,18,63.19a1.35,1.35,0,0,0,.82.31A3.62,3.62,0,0,0,19.86,63.37Z" />
              <path
                className={cls5}
                d="M13.28,50c-4.63,6.37,1.25,19.25,10.25,15.12,0,0-2.32,3.81-6.69,3.44-2.87-.25-6.07-4.8-7.06-9.19A8.67,8.67,0,0,1,13.28,50Z"
              />
              <path d="M23.91,34.88l-.36,3.63c-.08,1.2-.18,2.38-.22,3.55a45.61,45.61,0,0,0,.2,6.85A25.71,25.71,0,0,0,25,55.19a13.65,13.65,0,0,0,3,4.89l-5.08,2a46.29,46.29,0,0,1,.43-5.9,44,44,0,0,1,1.17-5.77,32.76,32.76,0,0,1,2.06-5.6,15.57,15.57,0,0,1,3.49-4.94c-.29,2-.38,3.85-.48,5.73l-.22,5.57-.24,5.55-.25,5.49-.33,6.91-4.76-4.87a16.92,16.92,0,0,1-4.09-7.43A23.43,23.43,0,0,1,19,49a29.61,29.61,0,0,1,1.58-7.43A30.07,30.07,0,0,1,23.91,34.88Z" />
              <path
                className={hairColour}
                d="M23.91,34.88c-4.75,13.22-2.45,22.85,2,27.31.14-6.33,1.39-17.38,4.17-22.28"
              />
              <path d="M25,17.64A14.8,14.8,0,0,0,22,22.24a20.37,20.37,0,0,0-1.34,5,31.62,31.62,0,0,0-.32,5.14c0,.85.08,1.71.16,2.52,0,.41.1.81.17,1.18a9,9,0,0,0,.2.88l-4.93-1.16A19.92,19.92,0,0,1,21,32.11a31.69,31.69,0,0,1,5.44-2.18A43,43,0,0,1,32,28.65a38.52,38.52,0,0,1,5.74-.51,38.85,38.85,0,0,1-4.63,3.42c-1.57,1-3.16,2-4.71,2.87s-3.08,1.81-4.51,2.72A27.46,27.46,0,0,0,20.12,40l-3.54,3.31-1.39-4.48a9.66,9.66,0,0,1-.39-1.91,15.83,15.83,0,0,1-.12-1.65,20.94,20.94,0,0,1,.13-3.18,22.61,22.61,0,0,1,1.5-6.1,16.23,16.23,0,0,1,3.4-5.32A10.52,10.52,0,0,1,25,17.64Z" />
              <path
                className={hairColour}
                d="M25,17.64c-8.25,4.5-8,17.25-7,20.25,4.16-4.17,12.37-6.92,19.75-9.75"
              />
              <path d="M33.25,20.93a21.17,21.17,0,0,0-2.65,3,13.46,13.46,0,0,0-1.69,3.34,16.21,16.21,0,0,0-.64,7.15,29.74,29.74,0,0,0,1.82,7.11c.44,1.16,1,2.29,1.5,3.39a24.89,24.89,0,0,0,1.8,3l-5.22.63A34.38,34.38,0,0,1,31,43a40.14,40.14,0,0,1,3.57-5,35.49,35.49,0,0,1,4.3-4.37A23.26,23.26,0,0,1,44,30.15c-.9,1.88-1.81,3.63-2.7,5.38L38.6,40.69,36,45.76c-.82,1.69-1.63,3.36-2.32,5l-2.13,5.1-3.08-4.47a24.17,24.17,0,0,1-2.17-4,32.18,32.18,0,0,1-1.49-4.12,27,27,0,0,1-1.07-8.75A16.69,16.69,0,0,1,26.46,26,13.13,13.13,0,0,1,33.25,20.93Z" />
              <path
                className={hairColour}
                d="M33.25,20.93C20.74,28.26,27.53,44.81,31,49.68A64.6,64.6,0,0,1,44,30.15"
              />
              <path d="M46.11,27.43a18.44,18.44,0,0,0-4,3.49A11.64,11.64,0,0,0,39.94,35a15.87,15.87,0,0,0-.55,4.52,24.1,24.1,0,0,0,.55,4.68l-5.22-1.31a37.2,37.2,0,0,1,4.63-4.84,35.91,35.91,0,0,1,5.39-3.93,28.85,28.85,0,0,1,6.09-2.77,18.15,18.15,0,0,1,6.61-.91,62.38,62.38,0,0,0-5.18,3.68c-1.6,1.27-3.13,2.6-4.61,4s-2.92,2.77-4.31,4.2-2.74,2.9-4,4.4l-4.1,4.79L34.1,45.38a20.61,20.61,0,0,1-.22-6.18,15.65,15.65,0,0,1,2-6.05,11.82,11.82,0,0,1,4.52-4.39A11.36,11.36,0,0,1,46.11,27.43Z" />
              <path
                className={hairColour}
                d="M46.11,27.43C37,30,35.72,38.24,37,44.81c4.9-6,12.75-12.42,20.42-14.34"
              />
              <path d="M77.27,12.81c1.69.68,3.29,1.36,4.89,2.11s3.16,1.54,4.68,2.41,3,1.82,4.48,2.86a35.15,35.15,0,0,1,4.22,3.52l2.12,2.11L95.43,28A6.57,6.57,0,0,1,92,29.69a7.4,7.4,0,0,1-3.1-.11,8.67,8.67,0,0,1-2.54-1.07,9,9,0,0,1-2.17-1.88,13.55,13.55,0,0,1,2.54-1.22c.79-.29,1.54-.53,2.2-.74a13.8,13.8,0,0,0,1.63-.61,9.08,9.08,0,0,0,.8-.46l-.12,4.27c-1.07-1.13-2.26-2.27-3.47-3.42s-2.45-2.33-3.68-3.54-2.44-2.47-3.62-3.79A27.28,27.28,0,0,1,77.27,12.81Z" />
              <path
                className={hairColour}
                d="M77.27,12.81c3.23,2.89,11.62,8.35,16.14,13-2,1.82-5.35,1.45-9.18.84"
              />
              <path d="M83.43,25.14a10.24,10.24,0,0,1,6.07,1.91,12.47,12.47,0,0,1,4.15,5.36,19.5,19.5,0,0,1,1.45,6.5,26.9,26.9,0,0,1-.41,6.47l-1.65,9-4-8.33a36.33,36.33,0,0,0-2.5-4.46,27.56,27.56,0,0,0-3.16-3.94,32.64,32.64,0,0,0-4-3.48C77.89,33,76.27,32,74.58,30.88a18.35,18.35,0,0,1,6.08.43,18.89,18.89,0,0,1,5.79,2.47,19.75,19.75,0,0,1,4.78,4.34,23.8,23.8,0,0,1,3.25,5.47l-5.63.64a38.25,38.25,0,0,0,.74-5.29,22.37,22.37,0,0,0-.22-5.19A12.36,12.36,0,0,0,87.57,29,13.58,13.58,0,0,0,83.43,25.14Z" />
              <path
                className={hairColour}
                d="M83.43,25.14c9.5,2.69,9.63,13.1,8.34,19.67-3.67-8.17-9.52-12-17.19-13.93"
              />
              <path d="M22.1,12.81a27.28,27.28,0,0,1-3.24,4.31q-1.77,2-3.62,3.79c-1.23,1.21-2.47,2.39-3.68,3.54s-2.4,2.29-3.47,3.42L8,23.6a9.08,9.08,0,0,0,.8.46,13.55,13.55,0,0,0,1.62.61c.66.21,1.41.45,2.21.74a13.85,13.85,0,0,1,2.54,1.22A9,9,0,0,1,13,28.51a8.56,8.56,0,0,1-2.54,1.07,7.35,7.35,0,0,1-3.09.11A6.57,6.57,0,0,1,3.94,28L1.71,25.82l2.11-2.11a35.23,35.23,0,0,1,4.23-3.52,53,53,0,0,1,4.48-2.86c1.52-.87,3.09-1.67,4.68-2.41S20.41,13.49,22.1,12.81Z" />
              <path
                className={hairColour}
                d="M22.1,12.81C18.87,15.7,10.48,21.16,6,25.79c2,1.82,5.35,1.45,9.18.84"
              />
              <path d="M64.08,29.84c-2.18.62-4.3,1.19-6.28,1.93a14.67,14.67,0,0,0-5,2.83A5.86,5.86,0,0,0,51,38.71a20.68,20.68,0,0,0,.61,5.54l-5.14-1.39a24.08,24.08,0,0,1,4.4-3.94A24.76,24.76,0,0,1,56,36.15a19.92,19.92,0,0,1,5.64-1.39,12.76,12.76,0,0,1,5.73.65,35.51,35.51,0,0,0-4.83,2.33c-1.48.85-2.88,1.78-4.23,2.74s-2.64,2-3.88,3.05a45.79,45.79,0,0,0-3.49,3.3l-4.08,4.33-1.06-5.72a20.53,20.53,0,0,1-.25-7.51,10.81,10.81,0,0,1,1.55-4,9.59,9.59,0,0,1,3-3,14.54,14.54,0,0,1,7.15-1.93A24.71,24.71,0,0,1,64.08,29.84Z" />
              <path
                className={hairColour}
                d="M64.08,29.84c-15.75.18-17,6.85-15.38,15,4.13-4.63,11.77-9.44,18.66-9.44"
              />
              <path d="M69.43,33.81a6.09,6.09,0,0,1,2.94-.34,8.52,8.52,0,0,1,2.77.81,11.57,11.57,0,0,1,4.19,3.56l-5,.26a10,10,0,0,0,1-2.14,5.71,5.71,0,0,0-.53-4.68,10.66,10.66,0,0,0-2.23-2.64,6.19,6.19,0,0,1,4,.38A7.18,7.18,0,0,1,80,32.09a8.3,8.3,0,0,1,1,4.71,10.23,10.23,0,0,1-1.44,4.38L77.28,45l-2.74-3.54c-.89-1.14-1.63-2.26-2.41-3.46l-1.21-1.91Z" />
              <path
                className={hairColour}
                d="M69.43,33.81a15.6,15.6,0,0,1,7.5,5.83c3-5,.5-9.83-4.33-11"
              />
              <g className={cls6}>
                <path
                  className={cls7}
                  d="M58,2.59C30.27,7.27,11.27,23.14,17.27,39.77a9.48,9.48,0,0,1,5.12-2.5,37.59,37.59,0,0,0-2.62,12.62c-6-3.87-11,2.59-10.07,7.69.81,6.62,5.19,13.31,11.37,10.25,1.22,3.5.84,7.09.07,10.69l-2.87,4-2.88-6.13A17.74,17.74,0,0,1,7.89,78,42.32,42.32,0,0,1,9.27,64.83L3.52,64A62.86,62.86,0,0,1,6.89,47.64l-5.12-1L8.89,28.89,3.64,25.77s6.88-6,11.25-8.5C23.24,6,35.31-.32,58,2.59Z"
                />
              </g>
              <g className={cls6}>
                <path
                  className={cls7}
                  d="M33.28,20.88c-.54-1.07-3.35-1.41-5.26-.41-8,5.91-9.69,11.87-10.5,18.75l6.91-4C24.76,28.28,26.08,24.24,33.28,20.88Z"
                />
              </g>
              <path
                className={cls8}
                d="M66.14,35.39c-11.75-4.75-17.27,6-18,11.13,4.37-3.51,7.37-7.31,15.63-10l3.87,1.62Z"
              />
              <path
                className={cls8}
                d="M75.93,29.22c10.25-1.16,15.72,8.52,16.42,17.92-2.65-6.41-6-12-13.17-13.5Z"
              />
              <path
                className={cls8}
                d="M80.85,20.72a41.55,41.55,0,0,0,13.08,6,5.73,5.73,0,0,1-3.58,1.75,10.5,10.5,0,0,0-6.83-3.58C81.28,23.23,80.59,21.88,80.85,20.72Z"
              />
              <path
                className={cls8}
                d="M53.6,29.81c-13.31,2.63-16.49,9.48-17.67,16.75l10.67-8.5C47.28,35.05,48.27,32.1,53.6,29.81Z"
              />
              <path
                className={cls8}
                d="M46,27.41c-1.85-1.92-4.78-2.38-8.63-1.6-9.17,6.75-8.58,13.83-6.25,25.41l4.17-5.91C35,37.51,35.72,29.85,46,27.41Z"
              />
              <path
                className={cls8}
                d="M21.89,40.08A28.36,28.36,0,0,0,24.83,50c-1.36,5.15-.21,9.71,1.56,14.12L28.7,48.83,25,35.64,22.33,36.7Z"
              />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}
