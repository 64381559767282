import { classnames } from "classnames-joiner"
import React, { ReactNode } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { Img } from "@components/Img"

import { QuestionReference } from "@views/english-demo-1/common/question-reference"
import { minion, skeletonKing } from "@views/english-demo-1/ed1.module.scss"

import skeletonKingSrc from "@images/monsters/skeletons/skeleton-king.png"

import { markBox } from "./common-minion.module.scss"

interface MinionProps {
  alt: string
  src: string
  className?: string
}

export const Minion = ({ src, alt, className }: MinionProps) => (
  <div className={classnames([className, "d-flex align-items-center mb-5"])}>
    <Img className={minion} src={src} alt={alt} />
  </div>
)

interface BossMonsterProps {
  imageID?: string
  src: string
  alt: string
  className?: string
}

export const BossMonster = ({
  imageID,
  src,
  alt,
  className,
}: BossMonsterProps) => (
  <div className="d-flex align-items-center justify-content-center flex-column">
    <Img id={imageID} className={className} src={src} alt={alt} />
  </div>
)

interface SkeletonKingProps {
  imageID?: string
}

export const SkeletonKing = ({ imageID }: SkeletonKingProps) => (
  <BossMonster
    imageID={imageID}
    src={skeletonKingSrc}
    alt="Skeleton King"
    className={skeletonKing}
  />
)

export const DetailedBossMonster = ({
  bossMonsterTitle,
  containerId,
  questionId,
  marks,
  question,
  bossMonster,
}: {
  bossMonsterTitle: string
  containerId?: string
  questionId?: string
  marks: number
  question: ReactNode
  bossMonster: ReactNode
}) => (
  <div id={containerId}>
    <Row>
      <Col
        xs={12}
        md={{
          span: 8,
          offset: 2,
        }}
      >
        <div
          className={classnames([
            markBox,
            "text-center p-2 mb-2 rounded bg-yellow-shade-03",
          ])}
        >
          <strong>
            <div>{bossMonsterTitle}</div>
            <div>({marks} Marks)</div>
          </strong>
        </div>
      </Col>
    </Row>
    {bossMonster}
    <Row>
      <Col
        xs={12}
        md={{
          span: 8,
          offset: 2,
        }}
      >
        <QuestionReference id={questionId}>{question}</QuestionReference>
      </Col>
    </Row>
  </div>
)
