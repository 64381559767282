import React from "react"

import { body } from "@components/Avatar/a.module.scss"
import { AvatarItem } from "@components/Avatar/common/avatar-item"
import { BaseAvatarProps } from "@components/Avatar/types"

import src from "./body.svg"
import { dimensions, originPosition } from "./constants"

export const KnightBody = (baseAvatar: BaseAvatarProps) => {
  return (
    <AvatarItem
      className={body}
      src={src}
      dimensions={dimensions}
      originPosition={originPosition}
      baseAvatar={baseAvatar}
    />
  )
}
