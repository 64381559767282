import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { Avatar, configCreator } from "@components/Avatar"
import { Marker } from "@components/Avatar/common/marker"
import {
  MEDIUM_AVATAR_FACTOR,
  LARGE_AVATAR_FACTOR,
} from "@components/Avatar/constants"
import {
  BodyId,
  KNIGHT_BODY,
  NINJA_BODY,
  CRYSTAL_WIZARD_BODY,
  SCHOLAR_BODY,
} from "@components/Avatar/constants/body"
import {
  FaceId,
  NEUTRAL_EDGE_FACE,
  NEUTRAL_JAGGED_FACE,
} from "@components/Avatar/constants/face"
import {
  HAIR_COLOUR_BROWN_BANDIT,
  HAIR_COLOUR_BEIGE_DRUID,
  HAIR_COLOUR_ORANGE_DWARF,
  HAIR_COLOUR_GREEN_SHOULDER_LENGTH_WAVY,
  HAIR_COLOUR_PURPLE_FRINGE_STRAIGHT_LONG,
  HAIR_COLOUR_YELLOW_PARTED_FRONT_PONYTAIL,
  HairColourId,
} from "@components/Avatar/constants/hair-colour"
import {
  HairStyleId,
  DRUID_HAIR_STYLE,
  DWARF_HAIR_STYLE,
  BANDIT_HAIR_STYLE,
  SHOULDER_LENGTH_WAVY_HAIR_STYLE,
  FRINGE_STRAIGHT_LONG_HAIR_STYLE,
  PARTED_FRONT_PONYTAIL_HAIR_STYLE,
} from "@components/Avatar/constants/hair-style"
import {
  SkinToneId,
  SKIN_TONE_1,
  SKIN_TONE_2,
  SKIN_TONE_3,
  SKIN_TONE_4,
  SKIN_TONE_5,
} from "@components/Avatar/constants/skin-tone"
import { StanceType } from "@components/Avatar/constants/stance"
import {
  WeaponId,
  KNIGHT_WEAPON,
  CRYSTAL_WIZARD_WEAPON,
  BANDIT_WEAPON,
  SCHOLAR_WEAPON,
} from "@components/Avatar/constants/weapon"
import ContentContainer from "@components/ContentContainer"
import { RouterItem } from "@components/types"

import {
  HeroClassType,
  HERO_CLASS_TANK,
  HERO_CLASS_SCOUT,
  HERO_CLASS_MAKER,
  HERO_CLASS_EMPATH,
} from "@helpers/constants/hero-class"
import { BORDER } from "@helpers/constants/style"

import { AvatarButton } from "./utils/avatar-button"

const createContainerMarker = ({
  factor,
  stance,
}: {
  factor: number
  stance: StanceType
}) => (
  <>
    <Marker factor={factor} {...stance.hair.position} />
    <Marker factor={factor} {...stance.face.position} />
    <Marker factor={factor} {...stance.neck.position} />
    <Marker factor={factor} {...stance.weapon.position} />
    <Marker factor={factor} {...stance.rightHand.position} />
  </>
)

// http://localhost:9000/app/avatarJig
export const ShopView = (props: RouterItem) => {
  const [heroClass, setHeroClass] = useState<HeroClassType>(HERO_CLASS_TANK)
  const [body, setBody] = useState<BodyId>(KNIGHT_BODY)
  const [weaponId, setWeaponId] = useState<WeaponId>(KNIGHT_WEAPON)

  const [skinTone, setSkinTone] = useState<SkinToneId>(SKIN_TONE_2)
  const [hairColour, setHairColour] = useState<HairColourId>(
    HAIR_COLOUR_YELLOW_PARTED_FRONT_PONYTAIL
  )
  const [hairStyle, setHairStyle] = useState<HairStyleId>(
    PARTED_FRONT_PONYTAIL_HAIR_STYLE
  )
  const [faceId, setFaceId] = useState<FaceId>(NEUTRAL_EDGE_FACE)

  const { config, stance } = configCreator({
    heroClass,
    hairColourId: hairColour,
    hairStyleId: hairStyle,
    skinToneId: skinTone,
    bodyId: body,
    faceId,
    weaponId,
  })

  return (
    <>
      <ContentContainer rowConstrained={false} sectionClassesExtra="section">
        <Col xs={12}>
          <h1>Avatar Jig</h1>
        </Col>
        <Col xs={4}>
          <h2>Hero Class</h2>
          <div className="mb-3">
            <AvatarButton onClick={() => setHeroClass(HERO_CLASS_TANK)}>
              Tank
            </AvatarButton>
            <AvatarButton onClick={() => setHeroClass(HERO_CLASS_SCOUT)}>
              Scout
            </AvatarButton>
            <AvatarButton onClick={() => setHeroClass(HERO_CLASS_MAKER)}>
              Maker
            </AvatarButton>
            <AvatarButton onClick={() => setHeroClass(HERO_CLASS_EMPATH)}>
              Empath
            </AvatarButton>
          </div>
        </Col>
        <Col xs={4}>
          <h2>Body</h2>
          <div className="mb-3">
            <AvatarButton onClick={() => setBody(KNIGHT_BODY)}>
              Knight
            </AvatarButton>
            <AvatarButton onClick={() => setBody(NINJA_BODY)}>
              Ninja
            </AvatarButton>
            <AvatarButton onClick={() => setBody(CRYSTAL_WIZARD_BODY)}>
              Crys. Wizard
            </AvatarButton>
            <AvatarButton onClick={() => setBody(SCHOLAR_BODY)}>
              Scholar
            </AvatarButton>
          </div>
        </Col>
        <Col xs={4}>
          <h2>Weapon</h2>
          <div className="mb-3">
            <AvatarButton onClick={() => setWeaponId(KNIGHT_WEAPON)}>
              Sword
            </AvatarButton>
            <AvatarButton onClick={() => setWeaponId(CRYSTAL_WIZARD_WEAPON)}>
              Staff
            </AvatarButton>
            <AvatarButton onClick={() => setWeaponId(BANDIT_WEAPON)}>
              Dagger
            </AvatarButton>
            <AvatarButton onClick={() => setWeaponId(SCHOLAR_WEAPON)}>
              Book
            </AvatarButton>
          </div>
        </Col>
        <Col xs={4}>
          <h2>Hair Colour</h2>
          <div className="mb-3">
            <AvatarButton
              onClick={() => setHairColour(HAIR_COLOUR_BROWN_BANDIT)}
            >
              Brown
            </AvatarButton>
            <AvatarButton
              onClick={() => setHairColour(HAIR_COLOUR_BEIGE_DRUID)}
            >
              Beige
            </AvatarButton>
            <AvatarButton
              onClick={() => setHairColour(HAIR_COLOUR_ORANGE_DWARF)}
            >
              Orange
            </AvatarButton>
            <AvatarButton
              onClick={() =>
                setHairColour(HAIR_COLOUR_GREEN_SHOULDER_LENGTH_WAVY)
              }
            >
              Green
            </AvatarButton>
            <AvatarButton
              onClick={() =>
                setHairColour(HAIR_COLOUR_PURPLE_FRINGE_STRAIGHT_LONG)
              }
            >
              Purple
            </AvatarButton>
            <AvatarButton
              onClick={() =>
                setHairColour(HAIR_COLOUR_YELLOW_PARTED_FRONT_PONYTAIL)
              }
            >
              Yellow
            </AvatarButton>
          </div>
        </Col>
        <Col xs={4}>
          <h2>Hair Style</h2>
          <div className="mb-3">
            <AvatarButton onClick={() => setHairStyle(DRUID_HAIR_STYLE)}>
              Druid
            </AvatarButton>
            <AvatarButton onClick={() => setHairStyle(DWARF_HAIR_STYLE)}>
              Dwarf
            </AvatarButton>
            <AvatarButton onClick={() => setHairStyle(BANDIT_HAIR_STYLE)}>
              Bandit
            </AvatarButton>
            <AvatarButton
              onClick={() => setHairStyle(SHOULDER_LENGTH_WAVY_HAIR_STYLE)}
            >
              Wavy
            </AvatarButton>
            <AvatarButton
              onClick={() => setHairStyle(FRINGE_STRAIGHT_LONG_HAIR_STYLE)}
            >
              Fringe
            </AvatarButton>
            <AvatarButton
              onClick={() => setHairStyle(PARTED_FRONT_PONYTAIL_HAIR_STYLE)}
            >
              Ponytail
            </AvatarButton>
          </div>
        </Col>
        <Col xs={4}>
          <h2>Face</h2>
          <div className="mb-3">
            <AvatarButton onClick={() => setFaceId(NEUTRAL_JAGGED_FACE)}>
              Neutral 1
            </AvatarButton>
            <AvatarButton onClick={() => setFaceId(NEUTRAL_EDGE_FACE)}>
              Neutral 2
            </AvatarButton>
          </div>
        </Col>
        <Col xs={4}>
          <h2>Skin</h2>
          <div className="mb-3">
            <AvatarButton onClick={() => setSkinTone(SKIN_TONE_1)}>
              1
            </AvatarButton>
            <AvatarButton onClick={() => setSkinTone(SKIN_TONE_2)}>
              2
            </AvatarButton>
            <AvatarButton onClick={() => setSkinTone(SKIN_TONE_3)}>
              3
            </AvatarButton>
            <AvatarButton onClick={() => setSkinTone(SKIN_TONE_4)}>
              4
            </AvatarButton>
            <AvatarButton onClick={() => setSkinTone(SKIN_TONE_5)}>
              5
            </AvatarButton>
          </div>
        </Col>
      </ContentContainer>
      <Row>
        <Col xs={6}>
          <h2>Large size</h2>
          <Avatar
            factor={LARGE_AVATAR_FACTOR}
            marker={createContainerMarker({
              factor: LARGE_AVATAR_FACTOR,
              stance,
            })}
            avatarContainerClassName={BORDER}
            {...config}
          />
        </Col>
        <Col xs={6}>
          <h2>Medium size</h2>
          <Avatar
            factor={MEDIUM_AVATAR_FACTOR}
            marker={createContainerMarker({
              factor: MEDIUM_AVATAR_FACTOR,
              stance,
            })}
            avatarContainerClassName={BORDER}
            {...config}
          />
        </Col>
      </Row>
    </>
  )
}
