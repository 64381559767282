import { classnames } from "classnames-joiner"
import React from "react"

import PotionSrc from "@images/potion.png"

import { potion, small } from "./p.module.scss"

interface Props {
  className?: string
  size?: "small"
}

const Potion = (props: Props) => {
  let classNamesList = [potion, props.className]
  if (props.size === "small") {
    classNamesList.push(small)
  }

  return (
    <img
      className={classnames(classNamesList)}
      src={PotionSrc}
      alt="potion"
    ></img>
  )
}

export default Potion
