import React from "react"
import Button from "react-bootstrap/Button"

import { useHideModal } from "@components/centered-modal/hooks"

export const HideModalFooter = () => {
  const hideModal = useHideModal()

  return <Button onClick={hideModal}>Try answering again</Button>
}
