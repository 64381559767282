import { ENGLISH_BATCHED_PROBLEMS } from "@views/english-demo-1/common/constants"
import { BatchedEnglishProblems } from "@views/english-demo-1/utils/types"

import { getFromCacheOrRemote } from "@helpers/cache"

export const createEmptyBatchedProblems = (): BatchedEnglishProblems => {
  return {
    problems: {},
  }
}

export const getBatchedProblems = (): Promise<BatchedEnglishProblems> => {
  const remoteThunk = async () => {
    try {
      const response = await fetch(
        `/data/english-queries/batched-problems.json`
      )
      return await response.json()
    } catch {
      console.log("catch error")
      return undefined
    }
  }
  return getFromCacheOrRemote({
    key: ENGLISH_BATCHED_PROBLEMS,
    remoteThunk,
  })
}
