import React, { ReactNode } from "react"

import {
  ANSWER_LATER,
  ANSWER_HERE,
} from "@views/english-demo-1/common/constants"
import { Minion } from "@views/english-demo-1/common/minion"
import { MultipleChoiceScene } from "@views/english-demo-1/common/multiple-choice-scene"
import { ProgressBar } from "@views/english-demo-1/common/progress-bar"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { SceneProps } from "@views/english-demo-1/types"
import { AvatarsContext } from "@views/english-demo-1/utils/context"
import { useSetTechTriangleContent } from "@views/english-demo-1/utils/hooks"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import { HERO_CLASS_TANK } from "@helpers/constants/hero-class"

import minionSrc from "@images/monsters/skeletons/skeleton-mage-min.png"

const minionAlt = "Skeleton Mage"
const minion = <Minion src={minionSrc} alt={minionAlt} />

export const Action4 = ({
  previousScene,
  nextScene,
  question,
  multipleChoiceOptions = [],
  isActiveScene,
}: SceneProps) => {
  const context = React.useContext(AvatarsContext)

  const avatar = useEnglishDemoAvatar({
    heroClass: HERO_CLASS_TANK,
  })

  const techniqueNo = 2

  useScrollTop(isActiveScene)

  useSetTechTriangleContent({
    isActiveScene,
    techniqueNo,
    content: {
      evidence: `"Stanza 1: a garden; or a magic city. Stanza 2: or an eye, or the picture. Stanza 3: there’s a fog. Stanza 4: if there’s only; the hollow wind; even if; nothing"`,
      identification: ANSWER_HERE,
      explanation: ANSWER_LATER,
    },
    setOnlyIf: ["", ANSWER_HERE, ANSWER_LATER],
  })

  const hasAnsweredCorrectlyCallback = (content: ReactNode) => {
    const techTriangle = context.techTriangles[techniqueNo]
    context.techTriangleSetters[techniqueNo]({
      ...techTriangle,
      identification: content,
    })
  }

  return (
    <section>
      <ProgressBar index={3} />
      <MultipleChoiceScene
        previousScene={previousScene}
        nextScene={nextScene}
        avatar={<div className={avatarContainer}>{avatar}</div>}
        minion={minion}
        question={question}
        options={multipleChoiceOptions}
        techniqueBoxProps={{
          techniqueNo,
          evidence: context.techTriangles[techniqueNo].evidence,
          identification: context.techTriangles[techniqueNo].identification,
          explanation: context.techTriangles[techniqueNo].explanation,
        }}
        hasAnsweredCorrectlyCallback={hasAnsweredCorrectlyCallback}
        belowNav={<MainQuestionReference />}
      />
    </section>
  )
}
