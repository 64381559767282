import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { PaymentPayloadInput } from "@src/API"

import { savePaymentThunkCreator } from "@redux/ducks/misc/payment/async"
import { useFetchPayment } from "@redux/ducks/misc/payment/hooks"

import { CenteredLoader } from "@components/Loader"
import { RenderByMetaStatusValue } from "@components/render-by-meta-status-value"

import {
  META_STATUS_NEUTRAL,
  META_STATUS_LOADING,
  META_STATUS_SUCCESS,
} from "@helpers/hooks/use-meta-status/constants"

import { PaymentSummary } from "./payment-summary"
import { ReadyView } from "./ready-view"
import { SuccessView } from "./success-view"

export const CheckoutView = ({ username }: { username: string }) => {
  const { data, status } = useFetchPayment()

  const successElement =
    data === undefined ? (
      <CheckoutExperience username={username} />
    ) : (
      <PaymentSummary {...data} />
    )

  return (
    <RenderByMetaStatusValue
      metaStatusValue={status}
      successElement={successElement}
    />
  )
}

const CheckoutExperience = ({ username }: { username: string }) => {
  const [hasSucceeded, setHasSucceeded] = useState<boolean>(false)
  const dispatch = useDispatch()

  const paymentPayload: PaymentPayloadInput = {
    currency: "AUD",
    amount: 10,
    merchant_order_id: username,
    descriptor: "EduCats 2022 Membership",
  }

  const onSuccess = (event: CustomEvent): void => {
    console.log(`Confirm success with ${JSON.stringify(event.detail)}`)
    // Show Success view
    setHasSucceeded(true)

    // Set remote state that the user has signed up to their settings.
    const dateNow = new Date()

    dispatch(
      savePaymentThunkCreator({
        hasPaid: true,
        paidAmount: paymentPayload.amount,
        fromDate: {
          day: dateNow.getDate(),
          month: dateNow.getMonth() + 1, // counts from 0
          year: 2022,
        },
        toDate: {
          day: 31,
          month: 12,
          year: 2022,
        },
        campaign: paymentPayload.descriptor,
      })
    )

    // TODO: Send an email with SES
  }

  return hasSucceeded ? (
    <SuccessView descriptor={paymentPayload.descriptor} />
  ) : (
    <ReadyView paymentPayload={paymentPayload} onSuccess={onSuccess} />
  )
}
