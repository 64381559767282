import React from "react"

import { Avatar, configCreator } from "@components/Avatar"
import {
  KNIGHT_BODY,
  CRYSTAL_WIZARD_BODY,
  NINJA_BODY,
  SCHOLAR_BODY,
} from "@components/Avatar/constants/body"
import {
  NEUTRAL_EDGE_FACE,
  NEUTRAL_JAGGED_FACE,
} from "@components/Avatar/constants/face"
import {
  HAIR_COLOUR_BEIGE_DRUID,
  HAIR_COLOUR_ORANGE_DWARF,
  HAIR_COLOUR_PURPLE_FRINGE_STRAIGHT_LONG,
  HAIR_COLOUR_YELLOW_PARTED_FRONT_PONYTAIL,
} from "@components/Avatar/constants/hair-colour"
import {
  KNIGHT_WEAPON,
  CRYSTAL_WIZARD_WEAPON,
  BANDIT_WEAPON,
  SCHOLAR_WEAPON,
} from "@components/Avatar/constants/weapon"

import { AvatarsContext } from "@views/english-demo-1/utils/context"

import {
  HERO_CLASS_SCOUT,
  HERO_CLASS_TANK,
  HERO_CLASS_EMPATH,
  HERO_CLASS_MAKER,
} from "@helpers/constants/hero-class"
import { HeroClassType } from "@helpers/constants/hero-class"

const mapHeroClassToConfig = (heroClass: HeroClassType) => {
  switch (heroClass) {
    case HERO_CLASS_SCOUT:
      return {
        hairColourId: HAIR_COLOUR_PURPLE_FRINGE_STRAIGHT_LONG,
        bodyId: NINJA_BODY,
        weaponId: BANDIT_WEAPON,
        faceId: NEUTRAL_JAGGED_FACE,
      }
    case HERO_CLASS_TANK:
    default:
      return {
        hairColourId: HAIR_COLOUR_ORANGE_DWARF,
        bodyId: KNIGHT_BODY,
        weaponId: KNIGHT_WEAPON,
        faceId: NEUTRAL_EDGE_FACE,
      }
    case HERO_CLASS_EMPATH:
      return {
        hairColourId: HAIR_COLOUR_BEIGE_DRUID,
        bodyId: SCHOLAR_BODY,
        weaponId: SCHOLAR_WEAPON,
        faceId: NEUTRAL_EDGE_FACE,
      }
    case HERO_CLASS_MAKER:
      return {
        hairColourId: HAIR_COLOUR_YELLOW_PARTED_FRONT_PONYTAIL,
        bodyId: CRYSTAL_WIZARD_BODY,
        weaponId: CRYSTAL_WIZARD_WEAPON,
        faceId: NEUTRAL_JAGGED_FACE,
      }
  }
}

export const useEnglishDemoAvatar = ({
  heroClass,
  avatarContainerClassName,
}: {
  heroClass: HeroClassType
  avatarContainerClassName?: string
}) => {
  const context = React.useContext(AvatarsContext)
  const hairIndex = context.avatarHairs[heroClass]
  const hairStyleId = context.avatarHairsList[hairIndex]

  const { config } = configCreator({
    ...mapHeroClassToConfig(heroClass),
    heroClass,
    hairStyleId,
  })

  return (
    <Avatar {...config} avatarContainerClassName={avatarContainerClassName} />
  )
}
