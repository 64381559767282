import React from "react"

import { MonsterPropsType } from "@components/types"

import { DifficultyType } from "@views/multiple-choice-problem/types"

import vampireAssassin from "@images/monsters/vampires/vampire-assassin.png"
import vampireBat from "@images/monsters/vampires/vampire-bat.png"
import vampireBruiser from "@images/monsters/vampires/vampire-bruiser.png"

const commonImgProps = {
  className: "d-block w-100",
}
export function vampireCases(difficulty: DifficultyType): MonsterPropsType {
  if (difficulty >= 2 && difficulty <= 3) {
    return {
      src: vampireAssassin,
      imgEl: (
        <img
          {...commonImgProps}
          style={{
            maxWidth: "150px",
          }}
          src={vampireAssassin}
          alt="vampire assassin"
        />
      ),
      monsterTitle: "Vampire Assassin",
      containerClassName: "containerGreater",
    }
  } else if (difficulty >= 4) {
    return {
      src: vampireBruiser,
      imgEl: (
        <img
          {...commonImgProps}
          style={{
            maxWidth: "250px",
          }}
          src={vampireBruiser}
          alt="vampire bruiser"
        />
      ),
      monsterTitle: "Vampire Bruiser",
      containerClassName: "containerMajor",
    }
  }

  return {
    src: vampireBat,
    imgEl: (
      <img
        {...commonImgProps}
        style={{
          maxWidth: "100px",
        }}
        src={vampireBat}
        alt="vampire bat"
      />
    ),
    monsterTitle: "Vampire Bat",
    containerClassName: "containerMinor",
  }
}
