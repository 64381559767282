import React, { ReactNode } from "react"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import Potion from "@components/Potion"

import { W_100, MB_0, PB_2, H2, TEXT_PRIMARY } from "@helpers/constants/style"

interface Props {
  cost: number
  description: ReactNode
}

export const PotionExpenseHeader = ({ cost, description }: Props) => (
  <SpacedWrapper className={W_100}>
    <div className={`${H2} ${MB_0} ${TEXT_PRIMARY}`}>
      Drank {`${cost} x `}
      <Potion className={PB_2} size="small" />
    </div>
    <div className={`${H2} ${MB_0}`}>{description}</div>
  </SpacedWrapper>
)
