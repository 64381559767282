export const chunkList = (list: any[], chunkSize: number) => {
  let chunkedList = []

  const maxLimit = Math.ceil(list.length / chunkSize)

  for (let i = 0; i < maxLimit; i++) {
    const start = i * chunkSize
    // End doesn't need to -1 since "end" does not include the index specified.
    const end = start + chunkSize

    const chunk = list.slice(start, end)
    chunkedList.push(chunk)
  }

  return chunkedList
}
