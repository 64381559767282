import { CheckMarkEmoji } from "accessible-emojis"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { useFetchPhysicsMCUserResponses } from "@redux/ducks/misc/physics-mc-user-responses/hooks"

import { FullHeightColumnCenteredWrapper } from "@components/Containers/positioning-wrappers"
import { createMonster } from "@components/Monster"
import { DifficultyRatingWithFallback } from "@components/difficulty-rating"

import { PHYSICS_LIST_COLUMNS } from "@views/physics/common/constants"
import { getMonsterTypeByPhysicsModule } from "@views/physics/common/utils/get-monster-type-by-module"

import { BREAKPOINT_MOBILE_KEY } from "@helpers/constants/breakpoints"
import {
  D_MD_NONE,
  H_100,
  FONT_WEIGHT_NORMAL,
  MY_2,
  TEXT_CENTER,
  ANIMATION_FADEIN,
} from "@helpers/constants/style"
import { getBreakpoint } from "@helpers/getBreakpoint"

import { monsterContainerSizing } from "./pr.module.scss"
import { PracticeRowProps } from "./types"
import { renderSyllabusCode, OriginContainer } from "./utils"

export const PracticeRow = ({ problem, onBegin }: PracticeRowProps) => {
  const physicsMCUserResponses = useFetchPhysicsMCUserResponses()

  const { marks, difficulty, syllabusCodes, origin } = problem

  const { monster } = createMonster({
    className: `${H_100} ${monsterContainerSizing}`,
    difficulty: typeof difficulty === "number" ? difficulty : 0,
    dungeonMonster: getMonsterTypeByPhysicsModule(syllabusCodes[0].moduleCode),
  })

  const userMark = physicsMCUserResponses.data.responses?.[problem.id]?.marks

  const breakpoint = getBreakpoint()

  return (
    <Container className={ANIMATION_FADEIN}>
      <Row className={TEXT_CENTER}>
        <Col xs={12} md={PHYSICS_LIST_COLUMNS[0].md}>
          <FullHeightColumnCenteredWrapper>
            <div>
              {userMark && <CheckMarkEmoji className={FONT_WEIGHT_NORMAL} />}
              <OriginContainer {...origin} />
            </div>
          </FullHeightColumnCenteredWrapper>
        </Col>
        <Col xs={12} md={PHYSICS_LIST_COLUMNS[1].md}>
          {monster}
        </Col>
        <Col xs={12} md={PHYSICS_LIST_COLUMNS[2].md}>
          <FullHeightColumnCenteredWrapper>
            <>
              {Array.isArray(syllabusCodes) &&
                syllabusCodes.map(renderSyllabusCode)}
            </>
          </FullHeightColumnCenteredWrapper>
        </Col>
        <Col xs={12} md={PHYSICS_LIST_COLUMNS[3].md}>
          <FullHeightColumnCenteredWrapper>
            <div>
              {breakpoint === BREAKPOINT_MOBILE_KEY && (
                <strong className={D_MD_NONE}>
                  {PHYSICS_LIST_COLUMNS[3].title}:{" "}
                </strong>
              )}
              <DifficultyRatingWithFallback difficulty={difficulty} />
            </div>
          </FullHeightColumnCenteredWrapper>
        </Col>
        <Col xs={12} md={PHYSICS_LIST_COLUMNS[4].md}>
          <FullHeightColumnCenteredWrapper>
            <div>
              {breakpoint === BREAKPOINT_MOBILE_KEY && (
                <strong className={D_MD_NONE}>
                  {PHYSICS_LIST_COLUMNS[4].title}:{" "}
                </strong>
              )}
              <>{userMark ? `${userMark}/${marks}` : marks}</>
            </div>
          </FullHeightColumnCenteredWrapper>
        </Col>
        <Col xs={12} md={PHYSICS_LIST_COLUMNS[5].md}>
          <FullHeightColumnCenteredWrapper>
            <Button onClick={onBegin}>Begin</Button>
          </FullHeightColumnCenteredWrapper>
        </Col>
        <Col xs={12}>
          <hr className={MY_2} />
        </Col>
      </Row>
    </Container>
  )
}
