export const caughtAwait = async (remoteThunk: () => Promise<any>) => {
  try {
    const data = await remoteThunk()
    return {
      data,
      error: undefined,
    }
  } catch (error) {
    console.log(error)
    return {
      data: undefined,
      error,
    }
  }
}
