// extracted by mini-css-extract-plugin
export var cls1 = "pfp1-module--cls-1--z9Kbb";
export var cls10 = "pfp1-module--cls-10--EGrSm";
export var cls11 = "pfp1-module--cls-11--XJ30N";
export var cls12 = "pfp1-module--cls-12--zUYGC";
export var cls13 = "pfp1-module--cls-13--k79e9";
export var cls14 = "pfp1-module--cls-14--w2wq8";
export var cls15 = "pfp1-module--cls-15--dj2JA";
export var cls16 = "pfp1-module--cls-16--RF8Cb";
export var cls17 = "pfp1-module--cls-17--oTEPu";
export var cls18 = "pfp1-module--cls-18--NUZ8L";
export var cls2 = "pfp1-module--cls-2--Lg445";
export var cls3 = "pfp1-module--cls-3--DpbnQ";
export var cls5 = "pfp1-module--cls-5--g5VNe";
export var cls6 = "pfp1-module--cls-6--F5ILv";
export var cls7 = "pfp1-module--cls-7--pbt2j";
export var cls8 = "pfp1-module--cls-8--XAzdS";
export var cls9 = "pfp1-module--cls-9--i4GzO";