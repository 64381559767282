import { useDispatch } from "react-redux"

import { setModalActionCreator } from "@redux/ducks/ui/modal"

export const useHideModal = () => {
  const dispatch = useDispatch()

  return () =>
    dispatch(
      setModalActionCreator({
        modalId: undefined,
      })
    )
}
