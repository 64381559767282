// extracted by mini-css-extract-plugin
export var cls1 = "cwb-module--cls1--6n1qP";
export var cls10 = "cwb-module--cls10--ZtIcD";
export var cls11 = "cwb-module--cls11--uMBSJ";
export var cls12 = "cwb-module--cls12--pvnGD";
export var cls13 = "cwb-module--cls13--uMCVE";
export var cls14 = "cwb-module--cls14--GMkLb";
export var cls15 = "cwb-module--cls15--X6urM";
export var cls16 = "cwb-module--cls16--l9JcX";
export var cls17 = "cwb-module--cls17--V1RHI";
export var cls18 = "cwb-module--cls18--u-YpC";
export var cls19 = "cwb-module--cls19--edY3a";
export var cls2 = "cwb-module--cls2--zBGIb";
export var cls20 = "cwb-module--cls20--6zH10";
export var cls21 = "cwb-module--cls21--3JWw9";
export var cls22 = "cwb-module--cls22--5TIPO";
export var cls3 = "cwb-module--cls3--j-YwU";
export var cls4 = "cwb-module--cls4--K4+Nt";
export var cls5 = "cwb-module--cls5--kfdkf";
export var cls6 = "cwb-module--cls6--AK77f";
export var cls7 = "cwb-module--cls7--+E9Hk";
export var cls8 = "cwb-module--cls8--xItZ5";
export var cls9 = "cwb-module--cls9---zeb0";