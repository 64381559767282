import React, { useState } from "react"
import Button from "react-bootstrap/Button"

import { EndWrapper } from "@components/Containers/positioning-wrappers"
import { NoArgVoid } from "@components/types"

import { getIsLastScreen } from "@views/onboarding/common/utils"
import { OnboardingScreenKeyType } from "@views/onboarding/types"

interface Props {
  onboardingScreenKey: OnboardingScreenKeyType
  onComplete: NoArgVoid
}

export const NavigationButtonArea = ({
  onboardingScreenKey,
  onComplete,
}: Props) => {
  const isLastScreen = getIsLastScreen(onboardingScreenKey)
  return (
    <EndWrapper>
      <Button
        onClick={() => {
          onComplete()
        }}
        variant="success"
      >
        {isLastScreen ? "Try It" : "Next"}
      </Button>
    </EndWrapper>
  )
}
