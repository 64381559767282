export const dimensions = {
  width: 42,
  height: 142,
}

export const originPosition = {
  left: 0.3 * dimensions.width,
  top: 0.83
	 * dimensions.height,
}
