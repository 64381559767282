// extracted by mini-css-extract-plugin
export var cls1 = "pfp2-module--cls-1--jZHeB";
export var cls10 = "pfp2-module--cls-10--jyI+R";
export var cls11 = "pfp2-module--cls-11--ZJNNO";
export var cls12 = "pfp2-module--cls-12--hkxkL";
export var cls13 = "pfp2-module--cls-13--sE0Dn";
export var cls14 = "pfp2-module--cls-14--a0Zzz";
export var cls2 = "pfp2-module--cls-2--N1cxy";
export var cls3 = "pfp2-module--cls-3--cB7Hi";
export var cls4 = "pfp2-module--cls-4--1aIIG";
export var cls5 = "pfp2-module--cls-5--a5XD0";
export var cls7 = "pfp2-module--cls-7--e4o2W";
export var cls8 = "pfp2-module--cls-8--eoc3P";
export var cls9 = "pfp2-module--cls-9--turic";