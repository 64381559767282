// extracted by mini-css-extract-plugin
export var cls1 = "nb-module--cls1--k4emx";
export var cls10 = "nb-module--cls10--4+QJW";
export var cls11 = "nb-module--cls11--opEbI";
export var cls12 = "nb-module--cls12--yDhmq";
export var cls13 = "nb-module--cls13--3Y6hR";
export var cls14 = "nb-module--cls14--RqdGK";
export var cls15 = "nb-module--cls15--KBK9v";
export var cls16 = "nb-module--cls16--S7UTT";
export var cls17 = "nb-module--cls17--Hi1DU";
export var cls18 = "nb-module--cls18---8G-I";
export var cls19 = "nb-module--cls19--5C6q1";
export var cls2 = "nb-module--cls2--HTCqX";
export var cls20 = "nb-module--cls20--ZlRWd";
export var cls21 = "nb-module--cls21--uihDv";
export var cls22 = "nb-module--cls22--ge1G4";
export var cls23 = "nb-module--cls23--jlzOz";
export var cls24 = "nb-module--cls24--YKwYd";
export var cls25 = "nb-module--cls25--EvWs5";
export var cls3 = "nb-module--cls3--ddGLk";
export var cls4 = "nb-module--cls4--+nPiu";
export var cls5 = "nb-module--cls5--JY7iE";
export var cls7 = "nb-module--cls7--+sJ3-";
export var cls8 = "nb-module--cls8--i48fR";
export var cls9 = "nb-module--cls9--3DLEQ";