import { WarningEmoji } from "accessible-emojis"
import React from "react"

import Potion from "@components/Potion"

import { FONT_WEIGHT_NORMAL } from "@helpers/constants/style"
import { toastNotification } from "@helpers/toastNotification"

const AlreadyDamagedToast = () => {
  return (
    <div>
      <WarningEmoji className={FONT_WEIGHT_NORMAL} />
      <span>
        Heal your <span className="text-danger">damage</span> to answer again.
        Drink a potion <Potion size="small"></Potion>.
      </span>
    </div>
  )
}

export const alreadyDamagedActions = () => {
  toastNotification(<AlreadyDamagedToast />, "warn")
}
