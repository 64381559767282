import {
  setModalActionCreator,
  L1_TO_L2_MODAL,
  L2_TO_L3_MODAL,
} from "@redux/ducks/ui/modal"
import { USER_STATUS_DANGER } from "@redux/ducks/user-synced/user-status"
import {
  selectUserCategory,
  selectUserStatus,
  selectProblem,
  selectPhysicsQuestionsAnsweredCount,
} from "@redux/selectors"

import {
  USER_CATEGORY_L1,
  USER_CATEGORY_L2,
  USER_CATEGORY_L3,
} from "@helpers/constants/user-category"

import { CreateOnClickHandlerType } from "../types"
import { alreadyDamagedActions } from "./already-damaged-actions"
import { answeredCorrectlyActions } from "./answered-correctly-actions"
import { answeredIncorrectlyActions } from "./answered-incorrectly-actions"

export const createOnClickHandler = ({
  optionKey,
  answer,
  onAnsweredCorrectly,
  rootState,
  dispatch,
}: CreateOnClickHandlerType) => {
  const userCategory = selectUserCategory(rootState)
  const physicsQuestionsAnsweredCount =
    selectPhysicsQuestionsAnsweredCount(rootState)

  // User category
  if (userCategory === USER_CATEGORY_L1 && physicsQuestionsAnsweredCount > 3) {
    // L1 (unsigned users) can't answer questions, after having answered 3 questions.
    // Open a modal to convince them to create an account
    return () => {
      dispatch(
        setModalActionCreator({
          modalId: L1_TO_L2_MODAL,
        })
      )
    }
  }
  // else if (userCategory === USER_CATEGORY_L2) {
  // L2 (free signed users) can only answer questions from the most current year.
  // Open a modal to convince them to convince them to create a paid account.
  // const currentYear = new Date().getFullYear()
  // const problem = selectProblem(rootState)
  // if (problem?.origin.year !== currentYear) {
  //   return () => {
  //     dispatch(
  //       setModalActionCreator({
  //         modalId: L2_TO_L3_MODAL,
  //       })
  //     )
  //   }
  // }
  // }

  if (selectUserStatus(rootState) === USER_STATUS_DANGER) {
    // User is already damaged
    return alreadyDamagedActions
  }

  // User answered:
  if (answer === optionKey) {
    // correctly
    return answeredCorrectlyActions({
      optionKey,
      rootState,
      dispatch,
      onAnsweredCorrectly,
    })
  } else {
    // incorrectly
    return answeredIncorrectlyActions({
      optionKey,
      dispatch,
      rootState,
    })
  }
}
