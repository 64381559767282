import { Cache } from "aws-amplify"
import { useState } from "react"

import { ENGLISH_DEMO_ACTIVE_PROBLEM_ID } from "@views/english-demo-1/common/constants"
import { SetShowStimulus } from "@views/english-demo-1/types"
import { getBatchedProblems } from "@views/english-demo-1/utils/helpers"
import { useIfActiveScene } from "@views/english-demo-1/utils/hooks"
import { EnglishProblem } from "@views/english-demo-1/utils/types"

export const useEnglishProblem = (
  isActiveScene: boolean,
  setShowStimulus: SetShowStimulus
) => {
  const [englishProblem, setEnglishProblem] = useState<
    undefined | EnglishProblem
  >(undefined)

  const fromCache = Cache.getItem(ENGLISH_DEMO_ACTIVE_PROBLEM_ID)
  const activeProblemId = fromCache ? fromCache : "q1"

  useIfActiveScene(isActiveScene, () => {
    const getData = async () => {
      const batchedProblems = await getBatchedProblems()
      const newEnglishProblem = batchedProblems.problems[activeProblemId]
      setEnglishProblem(newEnglishProblem)

      // Set the stimulus view
      setShowStimulus({
        src: newEnglishProblem.stimulus.src,
        title: newEnglishProblem.stimulus.title
          ? newEnglishProblem.stimulus.title
          : "Stimulus",
      })
    }

    getData()
  })

  return englishProblem
}
