import React from "react"

import { EducationalContainer } from "./educational-container"
import { StatsPanel } from "./stats-panel"

interface Props {
  aboveProblemPartElement?: React.ReactNode
  belowSolutionElement?: React.ReactNode
  monster?: React.ReactElement
  monsterTitle?: string
}

export const MultipleChoiceProblemInner = ({
  aboveProblemPartElement,
  belowSolutionElement,
  monster,
  monsterTitle,
}: Props) => {
  return (
    <>
      <EducationalContainer
        aboveProblemPartElement={aboveProblemPartElement}
        belowSolutionElement={belowSolutionElement}
      />
      <StatsPanel monster={monster} monsterTitle={monsterTitle} />
    </>
  )
}
