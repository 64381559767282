import { CacheKeyRemoteAttributePairType } from "@components/types"

import {
  REMOTE_ATTRIBUTE_OBJECT_TRIANGLE,
  REMOTE_ATTRIBUTE_OBJECT_PENTAGON,
} from "@helpers/constants/remote-attributes"

export const PHYSICS_QUERIES = "/data/physics-queries"
export const PHYSICS_PROBLEMS = "/data/physics-problems"
export const PROBLEMS_PER_PAGE = 10

export const QUERY_PARAM_KEY_PAGE = "page"
export const QUERY_PARAM_KEY_MODULE = "module"
export const QUERY_PARAM_KEY_PROBLEM_ID = "pid"

export const PHYSICS_MC_USER_RESPONSES: CacheKeyRemoteAttributePairType = {
  cacheKey: "physics-mc-user-responses",
  remoteAttribute: REMOTE_ATTRIBUTE_OBJECT_TRIANGLE,
}

export const PHYSICS_USER_SYNCED: CacheKeyRemoteAttributePairType = {
  cacheKey: "physics-user-synced",
  remoteAttribute: REMOTE_ATTRIBUTE_OBJECT_PENTAGON,
}

export const PHYSICS_LIST_COLUMNS = [
  {
    md: 3,
    title: "Question",
  },
  {
    md: 3,
    title: "Monster",
  },
  {
    md: 2,
    title: "Syllabus",
  },
  {
    md: 2,
    title: "Difficulty",
  },
  {
    md: 1,
    title: "Marks",
  },
  {
    md: 1,
    title: "Action",
  },
]
