import { classnames } from "classnames-joiner"
import React from "react"

import { BanditHair } from "@components/Avatar/bandit/hair"
import { BanditWeapon } from "@components/Avatar/bandit/weapon"
import { AvatarContainer } from "@components/Avatar/common/container"
import { NeutralJaggedFace } from "@components/Avatar/common/face/neutral-female"
import { NeutralEdgeFace } from "@components/Avatar/common/face/neutral-male"
import { NakedHead } from "@components/Avatar/common/naked-head"
import {
  KNIGHT_BODY,
  CRYSTAL_WIZARD_BODY,
  NINJA_BODY,
  SCHOLAR_BODY,
} from "@components/Avatar/constants/body"
import {
  NEUTRAL_EDGE_FACE,
  NEUTRAL_JAGGED_FACE,
} from "@components/Avatar/constants/face"
import { HairColourId } from "@components/Avatar/constants/hair-colour"
import {
  DRUID_HAIR_STYLE,
  DWARF_HAIR_STYLE,
  BANDIT_HAIR_STYLE,
  SHOULDER_LENGTH_WAVY_HAIR_STYLE,
  FRINGE_STRAIGHT_LONG_HAIR_STYLE,
  PARTED_FRONT_PONYTAIL_HAIR_STYLE,
} from "@components/Avatar/constants/hair-style"
import { SkinToneId } from "@components/Avatar/constants/skin-tone"
import {
  KNIGHT_WEAPON,
  CRYSTAL_WIZARD_WEAPON,
  BANDIT_WEAPON,
  SCHOLAR_WEAPON,
} from "@components/Avatar/constants/weapon"
import { CrystalWizardBody } from "@components/Avatar/crystal-wizard/body"
// import { CrystalWizardHat } from "@components/Avatar/crystal-wizard/hat"
import { CrystalWizardWeapon } from "@components/Avatar/crystal-wizard/weapon"
// import IceMageHair from "@components/Avatar/ice-mage/hair"
import { DruidHair } from "@components/Avatar/druid/hair"
import { DwarfHair } from "@components/Avatar/dwarf/hair"
// import PonytailSideHair from "@components/Avatar/hair-box/25-ponytail-side"
// import PonytailDischeveled from "@components/Avatar/hair-box/6-ponytail-discheveled"
import { ShoulderLengthWavyHair } from "@components/Avatar/hair-box/26-shoulder-length-wavy"
import { PartedFrontPonytailHair } from "@components/Avatar/hair-box/30-parted-front-ponytail"
import { FringeStraightLongHair } from "@components/Avatar/hair-box/33-fringe-straight-long"
import { KnightBody } from "@components/Avatar/knight/body"
// import { KnightHat } from "@components/Avatar/knight/hat"
import { KnightWeapon } from "@components/Avatar/knight/weapon"
import { NinjaBody } from "@components/Avatar/ninja/body"
// import { NinjaWeapon } from "@components/Avatar/ninja/weapon"
import { ScholarBody } from "@components/Avatar/scholar/body"
import { ScholarWeapon } from "@components/Avatar/scholar/weapon"
import { AvatarItemSelection } from "@components/Avatar/types"
import { CenteredWrapper } from "@components/Containers/positioning-wrappers"

import { FLEX_COLUMN, MB_2 } from "@helpers/constants/style"

import "./hair-colours.scss"
import "./skin-tones.scss"

export { configCreator } from "./constants/config"

interface AvatarProps {
  skinTone: SkinToneId
  hairColour: HairColourId
  hair: AvatarItemSelection
  head: AvatarItemSelection
  face: AvatarItemSelection
  body: AvatarItemSelection
  weapon: AvatarItemSelection
  factor?: number
  marker?: React.ReactNode
  className?: string
  avatarContainerClassName?: string
}

interface SkinHairSwitchProps {
  avatarItemSelection: AvatarItemSelection
  skinTone: SkinToneId
  hairColour: HairColourId
  factor?: number
}

const hairSwitch = ({
  avatarItemSelection,
  ...restProps
}: SkinHairSwitchProps) => {
  const props = {
    key: avatarItemSelection.id,
    ...avatarItemSelection.props,
    ...restProps,
  }

  switch (avatarItemSelection.id) {
    case DRUID_HAIR_STYLE:
      return <DruidHair {...props} />
    case DWARF_HAIR_STYLE:
      return <DwarfHair {...props} />
    case BANDIT_HAIR_STYLE:
      return <BanditHair {...props} />
    case SHOULDER_LENGTH_WAVY_HAIR_STYLE:
      return <ShoulderLengthWavyHair {...props} />
    case FRINGE_STRAIGHT_LONG_HAIR_STYLE:
      return <FringeStraightLongHair {...props} />
    case PARTED_FRONT_PONYTAIL_HAIR_STYLE:
      return <PartedFrontPonytailHair {...props} />
    default:
      return null
  }
}

const weaponSwitch = (
  avatarItemSelection: AvatarItemSelection,
  factor?: number
) => {
  const props = {
    key: avatarItemSelection.id,
    ...avatarItemSelection.props,
    factor,
  }

  switch (avatarItemSelection.id) {
    case KNIGHT_WEAPON:
      return <KnightWeapon {...props} />
    case CRYSTAL_WIZARD_WEAPON:
      return <CrystalWizardWeapon {...props} />
    case BANDIT_WEAPON:
      return <BanditWeapon {...props} />
    case SCHOLAR_WEAPON:
      return <ScholarWeapon {...props} />
    default:
      return null
  }
}

const faceSwitch = ({
  avatarItemSelection,
  ...restProps
}: SkinHairSwitchProps) => {
  const props = {
    key: avatarItemSelection.id,
    ...avatarItemSelection.props,
    ...restProps,
  }

  switch (avatarItemSelection.id) {
    case NEUTRAL_EDGE_FACE:
      return <NeutralEdgeFace {...props} />
    case NEUTRAL_JAGGED_FACE:
      return <NeutralJaggedFace {...props} />
    default:
      return null
  }
}

const bodySwitch = ({
  avatarItemSelection,
  skinTone,
  factor,
}: {
  avatarItemSelection: AvatarItemSelection
  skinTone: SkinToneId
  factor?: number
}) => {
  const props = {
    key: avatarItemSelection.id,
    ...avatarItemSelection.props,
    factor,
  }

  switch (avatarItemSelection.id) {
    case KNIGHT_BODY:
      return <KnightBody {...props} />
    case CRYSTAL_WIZARD_BODY:
      return <CrystalWizardBody {...props} />
    case NINJA_BODY:
      return <NinjaBody {...props} skinTone={skinTone} />
    case SCHOLAR_BODY:
      return <ScholarBody {...props} />
    default:
      return null
  }
}

export const Avatar = ({
  skinTone,
  hairColour,
  hair,
  head,
  face,
  body,
  weapon,
  className,
  avatarContainerClassName,
  factor,
  marker,
}: AvatarProps) => {
  const bodyChoice = bodySwitch({ avatarItemSelection: body, factor, skinTone })
  const weaponChoice = weaponSwitch(weapon, factor)

  const hairChoice = hairSwitch({
    avatarItemSelection: hair,
    skinTone,
    hairColour,
    factor,
  })

  const faceChoice = faceSwitch({
    avatarItemSelection: face,
    skinTone,
    hairColour,
    factor,
  })

  return (
    <CenteredWrapper className={classnames([FLEX_COLUMN, className])}>
      <AvatarContainer
        className={classnames([MB_2, avatarContainerClassName])}
        factor={factor}
      >
        <>
          {hairChoice}
          {faceChoice}
          <NakedHead factor={factor} {...head.props} skinTone={skinTone} />
          {bodyChoice}
          {weaponChoice}
          {marker}
        </>
      </AvatarContainer>
    </CenteredWrapper>
  )
}
