import { SkullAndCrossbonesEmoji } from "accessible-emojis"
import React from "react"

import { setUserSyncedActionCreator } from "@redux/ducks/user-synced"
import { savePhysicsUserSyncedThunkCreator } from "@redux/ducks/user-synced/async"
import { USER_STATUS_DANGER } from "@redux/ducks/user-synced/user-status"

import { DropOfBloodIcon } from "@components/Emojis/Icons"

import { FONT_WEIGHT_NORMAL } from "@helpers/constants/style"
import { toastNotification } from "@helpers/toastNotification"

import { AnsweredIncorrectlyActions } from "../types"
import { setOptionWasSelected } from "./set-option-was-selected"

const AnsweredIncorrectlyToast = ({ optionKey }: { optionKey: string }) => {
  return (
    <div>
      <p className="mb-0">
        <strong>{optionKey}: </strong>
        Incorrect answer.
        <SkullAndCrossbonesEmoji className={FONT_WEIGHT_NORMAL} />
      </p>
      <p className="mb-0">
        You've taken damage.
        <DropOfBloodIcon className="h-18px" />
      </p>
    </div>
  )
}

export const answeredIncorrectlyActions = ({
  optionKey,
  dispatch,
  rootState,
}: AnsweredIncorrectlyActions) => {
  return () => {
    toastNotification(
      <AnsweredIncorrectlyToast optionKey={optionKey} />,
      "error"
    )

    setOptionWasSelected({ dispatch, rootState, optionKey })

    dispatch(
      setUserSyncedActionCreator({
        userStatus: USER_STATUS_DANGER,
      })
    )

    dispatch(savePhysicsUserSyncedThunkCreator())
  }
}
