import { CheckMarkEmoji, CrossMarkEmoji } from "accessible-emojis"
import { classnames } from "classnames-joiner"
import React from "react"
import { ReactNode } from "react-markdown/lib/react-markdown"

import { FONT_WEIGHT_NORMAL, D_FLEX } from "@helpers/constants/style"

type PropsType = {
  className?: string
  optionKey: string
  wasSelected: boolean
  answer: string
}

export const BaseOptionLabel = ({
  optionKey,
  children,
}: {
  optionKey: string
  children?: ReactNode
}) => {
  return (
    <strong className={D_FLEX}>
      <span>{optionKey}</span>
      <span>.</span>
      {children && children}
    </strong>
  )
}

export const OptionLabel = ({
  className,
  optionKey,
  wasSelected,
  answer,
}: PropsType) => {
  if (wasSelected && answer !== optionKey) {
    return (
      <div className={className}>
        <s>
          <BaseOptionLabel optionKey={optionKey}>
            <CrossMarkEmoji className={FONT_WEIGHT_NORMAL} />
          </BaseOptionLabel>
        </s>
      </div>
    )
  } else if (wasSelected && answer === optionKey) {
    return (
      <div className={className}>
        <BaseOptionLabel optionKey={optionKey}>
          <CheckMarkEmoji className={FONT_WEIGHT_NORMAL} />
        </BaseOptionLabel>
      </div>
    )
  }

  return (
    <div className={className}>
      <BaseOptionLabel optionKey={optionKey} />
    </div>
  )
}
