import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { useDispatch } from "react-redux"

import { savePhysicsUserSyncedThunkCreator } from "@redux/ducks/user-synced/async"
import { HAIR_ID_KEY } from "@redux/ducks/user-synced/avatar-tank"

import { MEDIUM_LARGE_AVATAR_FACTOR } from "@components/Avatar/constants"
import {
  NEUTRAL_EDGE_FACE,
  NEUTRAL_JAGGED_FACE,
} from "@components/Avatar/constants/face"
import {
  HAIR_COLOUR_BROWN_BANDIT,
  HAIR_COLOUR_BEIGE_DRUID,
  HAIR_COLOUR_ORANGE_DWARF,
  HAIR_COLOUR_YELLOW_PARTED_FRONT_PONYTAIL,
} from "@components/Avatar/constants/hair-colour"
import {
  DRUID_HAIR_STYLE,
  BANDIT_HAIR_STYLE,
  SHOULDER_LENGTH_WAVY_HAIR_STYLE,
  PARTED_FRONT_PONYTAIL_HAIR_STYLE,
} from "@components/Avatar/constants/hair-style"
import {
  SKIN_TONE_1,
  SKIN_TONE_2,
  SKIN_TONE_3,
  SKIN_TONE_4,
  SKIN_TONE_5,
} from "@components/Avatar/constants/skin-tone"
import { StatusAvatar } from "@components/status-avatar"

import { ScreenPropsType } from "@views/onboarding/common/types"
import { NavigationButtonArea } from "@views/onboarding/common/ui/navigation-button-area"

import { AvatarToggle } from "./avatar-toggle"

export const WhatIsEduCats = ({
  onboardingScreenKey,
  onComplete,
  className,
}: ScreenPropsType) => {
  const dispatch = useDispatch()

  return (
    <Container className={className}>
      <Row>
        <Col xs={12}>
          <h1>What Is EduCats?</h1>
          <p>
            At EduCats, you're the <strong>hero</strong> of your learning
            journey, and the <strong>monsters</strong> are the past paper
            questions in your way. Defeat them to work those mental muscles, so
            that your real final exam is the easiest boss battle you've every
            encountered.
          </p>
          <p>Be the hero we know you can be.</p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <StatusAvatar heroClass="tank" factor={MEDIUM_LARGE_AVATAR_FACTOR} />
        </Col>
        <Col xs={12} md={6}>
          <h2>Customise Your Hero</h2>
          <AvatarToggle
            payloadKey={HAIR_ID_KEY}
            listOfPartIds={[
              DRUID_HAIR_STYLE,
              BANDIT_HAIR_STYLE,
              SHOULDER_LENGTH_WAVY_HAIR_STYLE,
              PARTED_FRONT_PONYTAIL_HAIR_STYLE,
            ]}
          >
            👨‍🦱 Hair Style
          </AvatarToggle>
          <AvatarToggle
            payloadKey="hairColour"
            listOfPartIds={[
              HAIR_COLOUR_BROWN_BANDIT,
              HAIR_COLOUR_BEIGE_DRUID,
              HAIR_COLOUR_ORANGE_DWARF,
              HAIR_COLOUR_YELLOW_PARTED_FRONT_PONYTAIL,
            ]}
          >
            👩‍🦳 Hair Colour
          </AvatarToggle>
          <AvatarToggle
            payloadKey="skinTone"
            listOfPartIds={[
              SKIN_TONE_1,
              SKIN_TONE_2,
              SKIN_TONE_3,
              SKIN_TONE_4,
              SKIN_TONE_5,
            ]}
          >
            Skin Tone
          </AvatarToggle>
          <AvatarToggle
            payloadKey="faceId"
            listOfPartIds={[NEUTRAL_EDGE_FACE, NEUTRAL_JAGGED_FACE]}
          >
            Face
          </AvatarToggle>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <NavigationButtonArea
            onComplete={() => {
              dispatch(savePhysicsUserSyncedThunkCreator())
              onComplete()
            }}
            onboardingScreenKey={onboardingScreenKey}
          />
        </Col>
      </Row>
    </Container>
  )
}
