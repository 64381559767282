import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { setMiscActionCreator } from "@redux/ducks/misc"
import { setUIActionCreator } from "@redux/ducks/ui"

import { ProblemV2Type } from "@views/multiple-choice-problem/types"

export const useSetProblemIntoStore = (problem: ProblemV2Type) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setMiscActionCreator({
        problem,
      })
    )
  }, [])
}

export const useSetInProductOnboardingShow = (
  inProductOnboardingShow: boolean
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setUIActionCreator({
        inProductOnboardingShow,
      })
    )
  }, [inProductOnboardingShow])
}
