import { classnames } from "classnames-joiner"
import React from "react"

import { Marker } from "@components/Avatar/common/marker"
import { getTranslatedFactoredStyles } from "@components/Avatar/utils"
import { Img } from "@components/Img"

import { BORDER } from "@helpers/constants/style"

import { AvatarItemProps, AvatarItemInnerProps } from "./types"

export const AvatarItemInner = ({
  originPosition,
  baseAvatar,
  className,
  src,
  children,
  translatedFactoredStyles,
}: AvatarItemInnerProps) => {
  return (
    <div
      style={{
        opacity: baseAvatar.opacity,
        ...translatedFactoredStyles,
      }}
      className={classnames([
        className,
        baseAvatar.jigMode ? BORDER : undefined,
      ])}
    >
      {src && !children && <Img src={src} />}
      {children}
      {baseAvatar.jigMode && (
        <Marker factor={baseAvatar.factor} {...originPosition} />
      )}
    </div>
  )
}

export const AvatarItem = ({
  dimensions,
  originPosition,
  baseAvatar,
  className,
  src,
  children,
}: AvatarItemProps) => {
  const { placementPosition, rotation, factor } = baseAvatar

  const translatedFactoredStyles = getTranslatedFactoredStyles({
    dimensions,
    originPosition,
    placementPosition,
    rotation,
    factor,
  })

  return (
    <AvatarItemInner
      translatedFactoredStyles={translatedFactoredStyles}
      originPosition={originPosition}
      baseAvatar={baseAvatar}
      className={className}
      src={src}
    >
      {children}
    </AvatarItemInner>
  )
}
