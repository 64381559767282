import { DownwardsButtonEmoji } from "accessible-emojis"
import { classnames } from "classnames-joiner"
import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"

import { FONT_WEIGHT_NORMAL } from "@helpers/constants/style"
import { CURSOR_POINTER } from "@helpers/constants/style"

interface Props {
  headingWrapperClassName?: string
  heading: React.ReactNode
  children: React.ReactNode
  closed?: boolean
}

export const ECAccordion = ({
  headingWrapperClassName,
  heading,
  children,
  closed,
}: Props) => {
  return (
    <Accordion defaultActiveKey={closed === true ? undefined : "0"}>
      <Accordion.Toggle as={Card.Header} eventKey="0">
        <SpacedWrapper
          className={classnames([CURSOR_POINTER, headingWrapperClassName])}
        >
          <>
            {heading}
            <DownwardsButtonEmoji className={FONT_WEIGHT_NORMAL} />
          </>
        </SpacedWrapper>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </Accordion>
  )
}
