import React, { ReactNode } from "react"

import {
  ANSWER_LATER,
  ANSWER_HERE,
} from "@views/english-demo-1/common/constants"
import { Minion } from "@views/english-demo-1/common/minion"
import { MultipleChoiceScene } from "@views/english-demo-1/common/multiple-choice-scene"
import { ProgressBar } from "@views/english-demo-1/common/progress-bar"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { SceneProps } from "@views/english-demo-1/types"
import { AvatarsContext } from "@views/english-demo-1/utils/context"
import { useSetTechTriangleContent } from "@views/english-demo-1/utils/hooks"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import { HERO_CLASS_TANK } from "@helpers/constants/hero-class"

import minionSrc from "@images/monsters/skeletons/skeleton-archer-min.png"

const minionAlt = "Skeleton Archer"
const minion = <Minion src={minionSrc} alt={minionAlt} />

export const Action5 = ({
  previousScene,
  nextScene,
  question,
  multipleChoiceOptions = [],
  isActiveScene,
}: SceneProps) => {
  const techniqueNo = 3
  const context = React.useContext(AvatarsContext)

  const avatar = useEnglishDemoAvatar({
    heroClass: HERO_CLASS_TANK,
  })

  useScrollTop(isActiveScene)
  useSetTechTriangleContent({
    isActiveScene,
    techniqueNo,
    content: {
      evidence: `Final stanza: "At least there’ll be a draught."`,
      identification: ANSWER_HERE,
      explanation: ANSWER_LATER,
    },
    setOnlyIf: ["", ANSWER_HERE, ANSWER_LATER],
  })

  const hasAnsweredCorrectlyCallback = (content: ReactNode) => {
    context.techTriangleSetters[techniqueNo]({
      ...context.techTriangles[techniqueNo],
      identification: content,
    })
  }

  return (
    <section>
      <ProgressBar index={4} />
      <MultipleChoiceScene
        previousScene={previousScene}
        nextScene={nextScene}
        avatar={<div className={avatarContainer}>{avatar}</div>}
        minion={minion}
        question={question}
        options={multipleChoiceOptions}
        techniqueBoxProps={{
          techniqueNo,
          evidence: context.techTriangles[techniqueNo].evidence,
          identification: context.techTriangles[techniqueNo].identification,
          explanation: context.techTriangles[techniqueNo].explanation,
        }}
        hasAnsweredCorrectlyCallback={hasAnsweredCorrectlyCallback}
        belowNav={<MainQuestionReference />}
      />
    </section>
  )
}
