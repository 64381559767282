import { Cache } from "aws-amplify"
import React, { useState, useEffect, useCallback } from "react"

import { CenteredLoader } from "@components/Loader"
import { AppLayout } from "@components/layouts/app-layout"
import { RouterItem } from "@components/types"

import { getIsLastScreen } from "@views/onboarding/common/utils"

import {
  QUERY_PARAM_SCREEN,
  QUERY_PARAM_SUBJECT,
} from "@helpers/constants/query-params"
import { useQueryString } from "@helpers/hooks/use-query-string"
import {
  useUserSettingsDataMetaState,
  USER_SETTINGS_HAS_SEEN_ONBOARDING,
} from "@helpers/hooks/use-user-settings-data-meta-state"

import { onboardingConfig } from "./constants"
import { OnboardingScreenSwitcher } from "./main"
import {
  castScreenKeyWithDefault,
  useRedirectToProduct,
  useRedirectToProductWithSave,
  progressToNextScreen,
} from "./utils"

export const OnboardingView = (props: RouterItem) => {
  return (
    <AppLayout>
      <Onboarding />
    </AppLayout>
  )
}

const Onboarding = () => {
  console.log("renderOnboardingView")

  Cache.clear()

  const { data, isFetching, hasErrored } = useUserSettingsDataMetaState(
    USER_SETTINGS_HAS_SEEN_ONBOARDING
  )

  console.log({ data, isFetching, hasErrored })

  if (hasErrored) {
    return <TransitionAway />
  }

  if (isFetching) {
    return <CenteredLoader children={"Loading..."} />
  }

  const shouldRender = !data
  if (shouldRender) {
    return <OnboardingManager />
  }

  return <TransitionAway />
}

const TransitionAway = () => {
  const redirectToProduct = useRedirectToProduct()

  useEffect(() => {
    redirectToProduct()
  }, [])

  return null
}

const OnboardingManager = () => {
  const {
    paramValue: screenParamValue,
    onSetParamValue: onSetScreenParamValue,
  } = useQueryString(QUERY_PARAM_SCREEN)

  const { paramValue: subjectParamValue } = useQueryString(QUERY_PARAM_SUBJECT)

  console.log({ subjectParamValue })

  const activeScreen = castScreenKeyWithDefault(
    screenParamValue,
    onboardingConfig.screensList[0]
  )

  useEffect(() => {
    const isFirstScreen = activeScreen === onboardingConfig.screensList[0]
    if (isFirstScreen) {
      onSetScreenParamValue(activeScreen, "replace")
    }
  }, [activeScreen])

  const redirectToProductWithSave = useRedirectToProductWithSave()

  const onComplete = useCallback(() => {
    if (getIsLastScreen(activeScreen)) {
      redirectToProductWithSave()
      return
    }

    progressToNextScreen({ activeScreen, onSetScreenParamValue })
  }, [activeScreen])

  return (
    <OnboardingScreenSwitcher
      onboardingScreenKey={activeScreen}
      onComplete={onComplete}
    />
  )
}
