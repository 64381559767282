import { useCallback } from "react"
import { useDispatch } from "react-redux"

import {
  setModalActionCreator,
  VIDEO_MODAL,
  RESTING_VIDEO_MODAL,
} from "@redux/ducks/ui/modal"
import { setUserSyncedActionCreator } from "@redux/ducks/user-synced"
import { savePhysicsUserSyncedThunkCreator } from "@redux/ducks/user-synced/async"
import { USER_STATUS_OK } from "@redux/ducks/user-synced/user-status"

import { useInteractionStructure } from "@components/hero-stats/common/use-interaction-logic"

interface Props {
  ifSpentCount: number
}

export const useOnClick = ({ ifSpentCount }: Props) => {
  const dispatch = useDispatch()

  const onCanAfford = () => {
    dispatch(
      setUserSyncedActionCreator({
        potionsTank: ifSpentCount,
        userStatus: USER_STATUS_OK,
      })
    )

    dispatch(savePhysicsUserSyncedThunkCreator())

    dispatch(
      setModalActionCreator({
        modalId: VIDEO_MODAL,
      })
    )
  }

  const onCantAfford = () => {
    dispatch(
      setModalActionCreator({
        modalId: RESTING_VIDEO_MODAL,
      })
    )
  }

  const onClick = useInteractionStructure({
    ifSpentCount,
    onCanAfford,
    onCantAfford,
  })

  return useCallback(onClick, [])
}
