import { classnames } from "classnames-joiner"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { AvatarsContext } from "@views/english-demo-1/utils/context"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import {
  HERO_CLASS_SCOUT,
  HERO_CLASS_TANK,
  HERO_CLASS_EMPATH, // HERO_CLASS_AIMER,
  HERO_CLASS_MAKER,
  HeroClassType,
} from "@helpers/constants/hero-class"
import { D_BLOCK, MB_4, PL_10 } from "@helpers/constants/style"
import { capitaliseFirstLetter } from "@helpers/strings"

const heroClassList: HeroClassType[] = [
  HERO_CLASS_SCOUT,
  HERO_CLASS_TANK,
  HERO_CLASS_MAKER,
  HERO_CLASS_EMPATH,
]

const PartyMember = ({ heroClass }: { heroClass: HeroClassType }) => {
  return useEnglishDemoAvatar({
    heroClass,
  })
}

export const Party = () => {
  const context = React.useContext(AvatarsContext)

  const content = heroClassList.map((heroClass) => (
    <Col xs={12} md={3} key={`party-${heroClass}`}>
      <div className={avatarContainer}>
        <PartyMember heroClass={heroClass} />
      </div>
      <strong className={classnames([D_BLOCK, MB_4, PL_10])}>
        {capitaliseFirstLetter(heroClass)}
      </strong>
    </Col>
  ))

  return (
    <Container fluid>
      <Row>{content}</Row>
    </Container>
  )
}
