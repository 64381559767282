import React from "react"

import { cls2 } from "@components/Avatar/common/face/common/cf.module.scss"
import { HairAsset } from "@components/Avatar/types"
import { Svg } from "@components/Svg"

import { cls1, cls3, cls5, cls6, cls7, cls8 } from "./nmh.module.scss"

export const NeutralEdgeFaceAsset = ({ hairColour }: HairAsset) => {
  return (
    <Svg viewBox="0 0 57.74 40.21">
      <g className={cls1}>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="FACE">
              <path
                className={cls2}
                d="M29.33,23.64c-12.75,8-24.1,5.28-22.07-9.52,1.28-3.73,6.88-2.52,13.33-.29S30.62,19.91,29.33,23.64Z"
              />
              <path
                className={cls3}
                d="M29.33,23.64c-12.75,8-24.1,5.28-22.07-9.52,1.28-3.73,6.88-2.52,13.33-.29S30.62,19.91,29.33,23.64Z"
              />
              <circle cx="23.96" cy="22.26" r="3.87" />
              <path
                className={cls2}
                d="M33.26,15.08A19,19,0,0,1,31,23.34c-8.66-3.09-15.74-6.19-25-8.58l-3.51.78c0-3.89.45-7.68,4-10.91C15.54,7.73,22.16,13,30.77,16A3.59,3.59,0,0,0,33.26,15.08Z"
              />
              <path
                className={hairColour}
                d="M33.26,15.08A19,19,0,0,1,31,23.34c-8.66-3.09-15.74-6.19-25-8.58l-3.51.78c0-3.89.45-7.68,4-10.91C15.54,7.73,22.16,13,30.77,16A3.59,3.59,0,0,0,33.26,15.08Z"
              />
              <path
                className={cls5}
                d="M3.46,9.64A10.8,10.8,0,0,1,4.49,7,7.77,7.77,0,0,1,6.38,4.75l.21-.17.34.11a49.28,49.28,0,0,1,6.22,2.62c2,1,4,2,5.92,3.08s3.9,2.06,5.88,3S29,15.23,31,16h-.19c.73-.23,1.46-.45,2.2-.66-.65.4-1.31.79-2,1.17l-.06,0-.13,0a42.22,42.22,0,0,1-12.72-4.44c-2-1-3.93-2.11-5.87-3.2S8.44,6.77,6.48,5.75L7,5.7A8.32,8.32,0,0,0,5,7.39,14.6,14.6,0,0,0,3.46,9.64Z"
              />
              <path
                className={cls6}
                d="M29.33,23.64,8.25,16.71a5.46,5.46,0,0,0-2-.29C4.9,24.48,9.78,32.79,22,27.64,13.7,28.23,10.87,26,9.37,19.89l18.84,5.83Z"
              />
              <g className={cls7}>
                <path
                  className={cls8}
                  d="M32.82,21.51A170.85,170.85,0,0,1,7.2,11.89l-6.38,1v3.87L6.45,16,31.7,24.76Z"
                />
              </g>
              <path
                className={cls2}
                d="M41.44,24.48c6.79,5.38,11.84,1.73,12.29-10.08C50.86,13.47,41.43,20.72,41.44,24.48Z"
              />
              <path
                className={cls3}
                d="M41.44,24.48c6.79,5.38,11.84,1.73,12.29-10.08C50.86,13.47,41.43,20.72,41.44,24.48Z"
              />
              <circle cx="50.33" cy="20.14" r="3.87" />
              <path
                className={cls2}
                d="M40.23,15.64a22.35,22.35,0,0,0,.71,7.56,103.59,103.59,0,0,1,12.57-8.33l1.65.78c.3-3-.21-6.49-1.22-7.74-4.24,3.11-7.2,6.16-11.25,9.15A5,5,0,0,1,40.23,15.64Z"
              />
              <path
                className={hairColour}
                d="M40.23,15.64a22.35,22.35,0,0,0,.71,7.56,103.59,103.59,0,0,1,12.57-8.33l1.65.78c.3-3-.21-6.49-1.22-7.74-4.24,3.11-7.2,6.16-11.25,9.15A5,5,0,0,1,40.23,15.64Z"
              />
              <path
                className={cls5}
                d="M40.23,15.64A29.23,29.23,0,0,1,42.92,17l-.56,0c1.91-1.43,3.8-2.81,5.63-4.24.92-.71,1.8-1.46,2.71-2.2S52.46,9,53.34,8.19l.74-.67.26.8A20.71,20.71,0,0,1,55,11.19a20.74,20.74,0,0,1-1.78-2.35l1,.13a20.41,20.41,0,0,1-2.31,2.85,22,22,0,0,1-2.73,2.46,26.27,26.27,0,0,1-6.34,3.57l-.38.15-.19-.19A28.43,28.43,0,0,1,40.23,15.64Z"
              />
              <path
                className={cls6}
                d="M53.5,17.62l-8.78,7.09c1.56,1.4,3.93,1.59,6.83,1q-4.71,3.63-10.48-.56L53.76,16Z"
              />
              <g className={cls7}>
                <polygon
                  className={cls8}
                  points="39.32 21.77 53.57 13.64 56.45 15.02 56.32 17.77 53.68 16.03 39.82 24.52 39.32 21.77"
                />
              </g>
              <path d="M35.64,7.61c-.52-.8-.92-1.53-1.38-2.18A20.21,20.21,0,0,0,32.84,3.6c-.51-.57-1.05-1.13-1.63-1.7S30,.74,29.39,0a6,6,0,0,1,2.7.6,6.71,6.71,0,0,1,2.25,1.69A5.57,5.57,0,0,1,35.64,7.61Z" />
              <path d="M39.44,7.58a2.91,2.91,0,0,1-.32-2,4.71,4.71,0,0,1,.76-1.83,4.9,4.9,0,0,1,1.4-1.36,3.53,3.53,0,0,1,1.93-.57,12.15,12.15,0,0,1-.82,1.69c-.28.49-.56.93-.87,1.37s-.61.85-.94,1.29Z" />
              <path d="M26.83,39.44a4.15,4.15,0,0,1-1.76.71,5.38,5.38,0,0,1-1.79,0,5.2,5.2,0,0,1-1.69-.61,4.07,4.07,0,0,1-1.39-1.3A6.43,6.43,0,0,1,22,38a9.35,9.35,0,0,1,1.61.16,9.17,9.17,0,0,1,1.57.43A6.34,6.34,0,0,1,26.83,39.44Z" />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}
