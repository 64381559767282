import { getIsBrowser } from "@helpers/misc"

import { ModeType } from "../types"

// setQueryStringWhithoutPageReload: replaces the current query string value with the new provided value without triggering a page refresh.
export const setQueryStringWithoutPageReload = (
  paramsToString: string,
  mode: ModeType = "push"
) => {
  if (!getIsBrowser()) {
    return
  }

  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    paramsToString

  if (mode === "push") {
    window.history.pushState({ path: newurl }, "", newurl)
  } else if (mode === "replace") {
    window.history.replaceState({ path: newurl }, "", newurl)
  }
}
