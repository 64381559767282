import React from "react"
import { v4 as uuid } from "uuid"

import {
  MarkDownRenderer,
  MarkDownMathRenderer,
} from "@components/markdown-renderer"

import {
  MathScientificNotationDataType,
  MathCharDataType,
  ContentType,
  JSXJSONType,
} from "@helpers/jsxJSONProcessor/types"

const ScientificNotation = ({
  digits,
  power10,
}: MathScientificNotationDataType) => (
  <span>
    {`${digits} x 10`}
    <sup>{power10}</sup>
  </span>
)

const MathChar = ({ char, sup, sub }: MathCharDataType) => (
  <span>
    {char}
    {sup && <sup>{sup}</sup>}
    {sub && <sub>{sub}</sub>}
  </span>
)

function getContentfulTypeContent(content: ContentType) {
  // There are more content "string" types than array types, so on average I thought it would be more performant to check this first
  if (typeof content === "string") {
    return content
  } else if (Array.isArray(content)) {
    return jsxJSONArrayProcessor(content)
  }
  return content
}

// Process JSXJSONType to JSX
export function jsxJSONProcessor(jsxJSON: JSXJSONType): JSX.Element {
  if (typeof jsxJSON === "string") {
    return <>{jsxJSON}</>
  }

  const { props } = jsxJSON

  // Childless types
  switch (jsxJSON.type) {
    case "img":
      return <img {...props} />
    case "br":
      return <br {...props} />
    case "iframe":
      return <iframe {...props} />
  }

  // Special types
  switch (jsxJSON.type) {
    case "math-sn":
      const { digits, power10 } = jsxJSON.data as MathScientificNotationDataType
      // Scientific notation
      // https://www.mathsisfun.com/numbers/scientific-notation.html
      return <ScientificNotation {...props} digits={digits} power10={power10} />
    case "math-char":
      const { char, sup, sub } = jsxJSON.data as MathCharDataType
      return <MathChar {...props} char={char} sup={sup} sub={sub} />
  }

  // Parent types
  const content = getContentfulTypeContent(jsxJSON.content)
  // Optimisation: order based on the most common types
  switch (jsxJSON.type) {
    case "md":
      return <MarkDownRenderer {...props}>{content as string}</MarkDownRenderer>
    case "md-math":
      return (
        <MarkDownMathRenderer {...props}>
          {content as string}
        </MarkDownMathRenderer>
      )
    case "p":
      return <p {...props}>{content}</p>
    case "div":
    default:
      return <div {...props}>{content}</div>
    case "span":
      return <span {...props}>{content}</span>
    case "strong":
      return <strong {...props}>{content}</strong>
    case "i":
    case "em":
      return <em {...props}>{content}</em>
    case "ul":
      return <ul {...props}>{content}</ul>
    case "ol":
      return <ol {...props}>{content}</ol>
    case "li":
      return <li {...props}>{content}</li>
    case "h1":
      return <h1 {...props}>{content}</h1>
    case "h2":
      return <h2 {...props}>{content}</h2>
    case "h3":
      return <h3 {...props}>{content}</h3>
  }
}

export function jsxJSONArrayProcessor(
  jsxJSONArray: JSXJSONType[]
): JSX.Element[] {
  return jsxJSONArray.map((jsxJSON) => {
    if (typeof jsxJSON === "string") {
      return jsxJSONProcessor(jsxJSON)
    }

    // Insert key
    const props = { ...jsxJSON.props, key: uuid() }
    const keyedJsxJSON = { ...jsxJSON, props }
    return jsxJSONProcessor(keyedJsxJSON)
  })
}
