import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import {
  CenteredWrapper,
  EndWrapper,
} from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"
import { Img } from "@components/Img"

import { ScenePropsWithInputProps } from "@views/english-demo-1/types"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"

import { scroll } from "./ac1.module.scss"
import introImgSrc from "./action-1-intro.jpg"
import scrollSrc from "./scroll.jpg"
import triangleSrc from "./technique-triangle.svg"

export const Action1 = ({
  previousScene,
  nextScene,
  isActiveScene,
}: ScenePropsWithInputProps) => {
  useScrollTop(isActiveScene)
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <SpacedWrapper>
              <>
                <Button onClick={previousScene}>Back</Button>
                <GoogleFormLinkGeneralFeedback />
              </>
            </SpacedWrapper>
          </Col>
          <Col xs={12}>
            <Img className="w-100 mb-4" src={introImgSrc} />
            <p>
              You've arrived at <strong>the door</strong> of the dungeon. Inside
              are <strong>past paper style questions</strong> disguised as{" "}
              <strong>monsters</strong>. However before you can even enter, a{" "}
              monster and their minions block your way.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <a
              href="https://docs.google.com/document/d/1qyhwlG4dY80eD6mdAJHBjvFyD1iyxi5EHsRqw3u97jk/edit?usp=sharing"
              target="_blank"
            >
              <Img
                className={scroll + " d-block w-100 mx-auto"}
                src={scrollSrc}
                alt="short answers guide"
              />
              <small className="d-block text-center">
                Click to access revision notes
              </small>
            </a>
          </Col>
          <Col xs={12} md={9}>
            <p>
              This is a 20 minutes tutorial before you enter the dungeon. You
              will learn how to approach short answer questions through your
              party of heroes.
            </p>
            <p>
              The objective is to defeat the <strong>Skeleton King</strong>{" "}
              blocking your way by collecting{" "}
              <strong>Technique Triangles</strong> to power up your{" "}
              <strong>Maker</strong>. You'll do this with the symbolic poem{" "}
              <strong>"The Door"</strong> by Miroslav Holub.
            </p>
          </Col>
          <Col xs={12}>
            <p>
              Technique Triangles are a trio of technique identification,
              evidence, and explanation (similar to{" "}
              <a
                href="https://www.literacysolutions.com.au/blog/2021/07/22/writing-teel-peel-paragraphs/"
                target="_blank"
              >
                TEEL or PEEL
              </a>
              , which you may have learnt in school).
            </p>
          </Col>
          <Col xs={12}>
            <CenteredWrapper>
              <div>
                <Img
                  className={"d-block w-100"}
                  src={triangleSrc}
                  style={{ maxWidth: 500 }}
                />
                <em className="d-block text-center">Technique Triangle</em>
              </div>
            </CenteredWrapper>
          </Col>
          <Col xs={12}>
            <EndWrapper>
              <Button className="mb-4" onClick={nextScene}>
                Begin
              </Button>
            </EndWrapper>
          </Col>
        </Row>
      </Container>
    </>
  )
}
