import { useDispatch } from "react-redux"

import { setMiscActionCreator } from "@redux/ducks/misc"
import { setUIActionCreator, uiInitialState } from "@redux/ducks/ui"
import { DispatchType } from "@redux/types"

import { ProblemV2Type } from "@views/multiple-choice-problem/types"

export function resetUIState(dispatch: DispatchType, problem?: ProblemV2Type) {
  dispatch(setUIActionCreator(uiInitialState))
  if (problem) {
    dispatch(
      setMiscActionCreator({
        problem,
      })
    )
  }
}
