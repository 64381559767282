import React from "react"

import {
  SyllabusCodeType,
  OriginType,
} from "@views/multiple-choice-problem/types"
import {
  SyllabusBadge,
  QuestionBadge,
} from "@views/physics/common/ui/content-badges"

import { D_BLOCK, MX_1, MB_1 } from "@helpers/constants/style"

export const renderSyllabusCode = (
  { dotPointCode, moduleCode, topicCode }: SyllabusCodeType,
  i: number
) => {
  return (
    <div key={`${dotPointCode}-${moduleCode}-${topicCode}-${i}`}>
      <SyllabusBadge className={MX_1}>{moduleCode}</SyllabusBadge>
      <SyllabusBadge className={MX_1}>{topicCode}</SyllabusBadge>
      <SyllabusBadge className={MX_1}>{dotPointCode}</SyllabusBadge>
    </div>
  )
}
export const OriginContainer = ({
  author,
  year,
  id,
  similarityId,
}: OriginType) => {
  const isASimilarityProblem = similarityId !== undefined
  return (
    <div key={`${year}-${id}`}>
      <strong className={`${D_BLOCK} ${MB_1}`}>{`Q${id}, ${year}, ${author}${
        isASimilarityProblem ? `, v${similarityId}` : ""
      }`}</strong>
      <QuestionBadge>
        {isASimilarityProblem ? "similar" : "exact"}
      </QuestionBadge>
    </div>
  )
}
