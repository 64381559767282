import React from "react"

import { HairAsset } from "@components/Avatar/types"
import { Svg } from "@components/Svg"

import {
  cls1,
  cls2,
  cls3,
  cls5,
  cls6,
  cls7,
  cls8,
  cls9,
  cls10,
  cls11,
  cls12,
  cls13,
  cls14,
  cls15,
  cls16,
  cls17,
  cls18,
  cls19,
  cls20,
  cls21,
  cls22,
  cls23,
  cls24,
  cls25,
  cls26,
  cls27,
  cls28,
} from "./slw.module.scss"

export const ShoulderLengthWavyHairAsset = ({ hairColour }: HairAsset) => {
  return (
    <Svg viewBox="0 0 117.68 109.85">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="1.92"
          y1="57.42"
          x2="8.34"
          y2="59.17"
          gradientTransform="matrix(-1, 0, 0, 1, 116.77, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f7ebd8" />
          <stop offset="1" stopColor="#fae3b3" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="15.56"
          y1="93.76"
          x2="19.75"
          y2="94.9"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="23.4"
          y1="98.42"
          x2="28.67"
          y2="99.85"
        />
        <radialGradient
          id="radial-gradient"
          cx="4.93"
          cy="59.88"
          r="30.39"
          gradientTransform="matrix(-1, 0, 0, 1, 116.77, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.15" stopColor="#f7e4cf" />
          <stop offset="0.93" stopColor="#ffc78e" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="13.42"
          y1="41.76"
          x2="23.26"
          y2="49.18"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="49.84"
          y1="51.27"
          x2="59.71"
          y2="50.02"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="52.34"
          y1="24.21"
          x2="66.84"
          y2="22.38"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="29.86"
          y1="18.54"
          x2="35.16"
          y2="17.87"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="15.48"
          y1="25.32"
          x2="27.64"
          y2="23.79"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="5.76"
          y1="43.5"
          x2="10.61"
          y2="42.89"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="75.26"
          y1="68.34"
          x2="84.08"
          y2="67.23"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="72.69"
          y1="96.13"
          x2="81.23"
          y2="95.05"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="44.76"
          y1="60.86"
          x2="52.86"
          y2="59.83"
        />
        <mask
          id="mask"
          x="0"
          y="0"
          width="114.64"
          height="109.85"
          maskUnits="userSpaceOnUse"
        >
          <path
            className={cls1}
            d="M27.45,91.27s1.34,7.8-2.15,7.8S15,94.37,14.38,91.49s.15-8.1,3.12-8.62,9.66.89,10.77,2.68S27.45,91.27,27.45,91.27Z"
          />
          <path
            className={cls2}
            d="M27.45,91.27s1.34,7.8-2.15,7.8S15,94.37,14.38,91.49s.15-8.1,3.12-8.62,9.66.89,10.77,2.68S27.45,91.27,27.45,91.27Z"
          />
          <path d="M27,92.42a15.3,15.3,0,0,0-3.79-1.29,2.84,2.84,0,0,0-1.59.05.7.7,0,0,0-.36.34,2.33,2.33,0,0,0-.17.78,2.13,2.13,0,0,0,.45,1.56A9.4,9.4,0,0,0,23,95.24c.47.32.93.33,1-.34A4.07,4.07,0,0,0,23.29,93a3.61,3.61,0,0,1,1.21,1.88,1.16,1.16,0,0,1-.57,1.27A1.57,1.57,0,0,1,22.52,96a7.86,7.86,0,0,1-1.89-1.36,3.81,3.81,0,0,1-.83-3.72,2.3,2.3,0,0,1,1-1.24,4.52,4.52,0,0,1,2.63-.38,15.19,15.19,0,0,1,4.41,1.29Z" />
          <path
            className={cls1}
            d="M81.13,50.39c7.59-3.86,16.87-5.64,23.61-4.53A29.89,29.89,0,0,0,91.66,24.78C84.53,20,79.3,14.4,72.41,14c-14.28-.9-28.2,9.08-30.9,11.68C26.6,40,28,50.34,31.16,75.3L38,72.55l-.32-6.8s7.28-12.29,17.79-16.33S75,47.16,81.13,50.39Z"
          />
          <path
            className={cls2}
            d="M81.13,50.39c7.59-3.86,16.87-5.64,23.61-4.53A29.89,29.89,0,0,0,91.66,24.78C84.53,20,79.3,14.4,72.41,14c-14.28-.9-28.2,9.08-30.9,11.68C26.6,40,28,50.34,31.16,75.3L38,72.55l-.32-6.8s7.28-12.29,17.79-16.33S75,47.16,81.13,50.39Z"
          />
          <path d="M27.55,22.55a8.24,8.24,0,0,1-1.77,3c-.69.72-1.49,1.25-2.17,1.9a17.39,17.39,0,0,0-1.83,1.77c-.09.13-.2.26-.26.36s-.08.1-.17.32-.32.73-.48,1.11a18.83,18.83,0,0,0-1.12,5.18,11.71,11.71,0,0,0,.05,2.59,4.94,4.94,0,0,0,.5,1.81l-4.67.35a5.38,5.38,0,0,1,2.06-2.44,8.34,8.34,0,0,1,2.14-1,13.31,13.31,0,0,1,2-.37,15.55,15.55,0,0,1,2-.06l-1.48.52a22,22,0,0,1,3.23-1.8,10.72,10.72,0,0,1,1.87-.56,8.28,8.28,0,0,1,2.17-.19,8.17,8.17,0,0,1-.7,2.06,10.31,10.31,0,0,1-1,1.69,22.3,22.3,0,0,1-2.52,2.7l-.36.36L23.85,42a11.8,11.8,0,0,0-1.27.25,7.46,7.46,0,0,0-1.2.32,3.53,3.53,0,0,0-.82.35c-.17.1-.14.11-.08-.07l-4.68.35a9.94,9.94,0,0,1-1.07-4,25.8,25.8,0,0,1,1.39-10.07,12.52,12.52,0,0,1,.74-1.65,9,9,0,0,1,.61-1c.24-.3.48-.54.72-.79A14.19,14.19,0,0,1,21,23.69,15.26,15.26,0,0,1,24,22.36a5.69,5.69,0,0,1,1.68-.21A4.85,4.85,0,0,1,27.55,22.55Z" />
          <path
            className={cls2}
            d="M27.55,22.55c-.25,0-6.94,3.4-8.33,5.92-2,3.71-2.75,10.75-1.17,13.41.92-2.25,5.75-2.33,5.75-2.33l5.75-4.5"
          />
          <path d="M26.85,30.34c-1.1,1.49-2.2,2.67-3.19,3.86A38.35,38.35,0,0,0,21,37.7a18.38,18.38,0,0,0-2.64,8.18l-1,10.44-3.89-9.91a8,8,0,0,0-1.3-2.26,1.31,1.31,0,0,0-.38-.34.76.76,0,0,0,.17,0H12l-.29.09a5.21,5.21,0,0,0-3.32,3.44,13,13,0,0,0-.31,6,27.08,27.08,0,0,0,1.74,6.33A10,10,0,0,0,13,64.15a3.66,3.66,0,0,0,2,.64,6.22,6.22,0,0,0,2.5-.49,18.5,18.5,0,0,0,2.83-1.39,23.76,23.76,0,0,0,2.6-1.81A18.76,18.76,0,0,0,27.47,56a7.63,7.63,0,0,1-.06,3.94,11.31,11.31,0,0,1-1.68,3.69,14.91,14.91,0,0,1-2.79,3,23.18,23.18,0,0,1-3.44,2.2A11,11,0,0,1,15,70a8.75,8.75,0,0,1-4.87-1.35,11.63,11.63,0,0,1-3.28-3.28A19.44,19.44,0,0,1,5,61.63,33.32,33.32,0,0,1,3.07,54a18.93,18.93,0,0,1,.57-8.29,10.59,10.59,0,0,1,2.38-4,10.3,10.3,0,0,1,4-2.57l.57-.19a4.66,4.66,0,0,1,2.89.07A6.07,6.07,0,0,1,16,40.78a10.72,10.72,0,0,1,1.33,1.95,13.64,13.64,0,0,1,.9,2l-4.86.53A21.74,21.74,0,0,1,14.7,40a18,18,0,0,1,2.81-4.8,13.39,13.39,0,0,1,4.15-3.54A10.51,10.51,0,0,1,26.85,30.34Z" />
          <path
            className={cls2}
            d="M26.85,30.34c-7,3.36-10.24,8.57-11,15.25,0,0-1.76-5.07-4.56-4.21C5.89,43,4.13,48.8,6.24,57.05,6.91,59.69,9.91,70.86,19,66.3,27,62.3,27.47,56,27.47,56"
          />
          <path d="M8.63,40.61a3.94,3.94,0,0,1,2.5.45,3.27,3.27,0,0,1,1.48,2.06,4.81,4.81,0,0,1-1.13,4.23,2.72,2.72,0,0,1-2,1,1,1,0,0,1-.65-.27,1.11,1.11,0,0,1-.31-.58,3.49,3.49,0,0,1,0-1.08,4.39,4.39,0,0,1,.74-1.88,2.11,2.11,0,0,1,.35-.38c.12-.09.33-.25.53-.1.21.35,0,.68-.05,1,.06-.3.15-.7,0-.9s-.24,0-.34.14a2.51,2.51,0,0,0-.27.37A4,4,0,0,0,9,46.47a2.4,2.4,0,0,0,.07.87c.1.25.23.32.43.3a2,2,0,0,0,1.24-.92,3.61,3.61,0,0,0,.47-3.17,1.66,1.66,0,0,0-2.19-1Z" />
          <path d="M6.9,58.87a7.59,7.59,0,0,0,1.44,1.66,7.34,7.34,0,0,0,1.92,1.24,6.1,6.1,0,0,0,2.28.54A7.43,7.43,0,0,0,15,62.05a8.11,8.11,0,0,1-2.4.76,6.72,6.72,0,0,1-2.6-.09,8.51,8.51,0,0,1-2.49-1,9.71,9.71,0,0,1-2.18-1.69Z" />
          <path d="M30.05,41.72a53.49,53.49,0,0,0-5.32,5.35,24.92,24.92,0,0,0-2.09,2.78,7.71,7.71,0,0,0-1,2.81,25.58,25.58,0,0,0,0,7c.15,1.21.33,2.44.53,3.68s.44,2.48.63,3.88l-4.28,2.17a1,1,0,0,0-.16-.12,2.4,2.4,0,0,0-.26-.18,4.85,4.85,0,0,0-.54-.3,3,3,0,0,0-1-.23,2.06,2.06,0,0,0-.88.21,3.38,3.38,0,0,0-.94.7C13.57,70.65,13,73,13.26,75.14a11.48,11.48,0,0,0,.84,3.28l.16.39.17.33c.1.21.25.4.37.61a6.26,6.26,0,0,0,1,1.08,9,9,0,0,0,5.91,2,18.84,18.84,0,0,0,3.48-.35,10.58,10.58,0,0,0,3-1.1A8.83,8.83,0,0,0,32,76.47,44.12,44.12,0,0,0,33.52,69c.2-1.32.39-2.67.62-4s.46-2.74.83-4.15a26.38,26.38,0,0,1,1.33,4.06,29.42,29.42,0,0,1,.76,4.19,24.25,24.25,0,0,1-.53,8.8,14.48,14.48,0,0,1-2,4.46,6.84,6.84,0,0,1-.76,1c-.29.33-.55.68-.86,1a12.13,12.13,0,0,1-2.07,1.59,14.34,14.34,0,0,1-4.65,1.82,19.7,19.7,0,0,1-4.69.41,14.08,14.08,0,0,1-9.21-3.51,11.06,11.06,0,0,1-1.73-2,11.29,11.29,0,0,1-1.19-2.31,17.71,17.71,0,0,1-1.14-4.68,14.6,14.6,0,0,1,.33-4.95A10.32,10.32,0,0,1,11.14,66a8.45,8.45,0,0,1,2.34-1.74,7.09,7.09,0,0,1,3.09-.73,7.51,7.51,0,0,1,3,.62,9,9,0,0,1,1.27.67c.2.12.4.26.59.4s.35.27.64.53l-4.29,2.17c-.36-2.37-.86-5.09-1.06-7.77a26.8,26.8,0,0,1,.48-8.45,12.48,12.48,0,0,1,2-4.25,15.81,15.81,0,0,1,3-3,13.72,13.72,0,0,1,3.7-2A10.24,10.24,0,0,1,30.05,41.72Z" />
          <path
            className={cls2}
            d="M30.05,41.72A19.28,19.28,0,0,0,20.8,48.8c-3.41,4.92-1,15-.5,18.75,0,0-2.63-2.53-5.66-1.08C10.8,68.3,9.47,74.3,12,79.88c1.73,3.87,6.58,6.67,13.67,5.25C35.24,83.21,36,73.72,35,60.8"
          />
          <path d="M19.5,62.94c.32,2.2,1.25,4.49,1.69,7a10.42,10.42,0,0,1,.12,3.91A5.26,5.26,0,0,1,19,77.23,4.18,4.18,0,0,1,17,78a3.18,3.18,0,0,1-2.11-.58A5.08,5.08,0,0,1,13,74a5.44,5.44,0,0,1,0-1.93,5,5,0,0,1,.28-.94,4.81,4.81,0,0,1,.49-.82,3.36,3.36,0,0,1,.67-.69,1.3,1.3,0,0,1,.94-.3c.73.15,1,.84,1.18,1.37-.28-.5-.66-1.1-1.2-1.13s-.91.54-1.21,1a4.17,4.17,0,0,0-.31,3.25,4.34,4.34,0,0,0,1.74,2.6,2.19,2.19,0,0,0,2.61-.34,3.71,3.71,0,0,0,1.48-2.54,9,9,0,0,0-.25-3.26c-.52-2.25-1.45-4.46-1.91-7Z" />
          <path
            className={cls1}
            d="M103.16,36.9s6.75,1.82,8.25,5.19A7.35,7.35,0,0,1,110,50.53c-1.56,1.56-5.06,4.44-7.31,3.37C101.7,53.45,96.78,39.9,103.16,36.9Z"
          />
          <path
            className={cls2}
            d="M103.16,36.9s6.75,1.82,8.25,5.19A7.35,7.35,0,0,1,110,50.53c-1.56,1.56-5.06,4.44-7.31,3.37C101.7,53.45,96.78,39.9,103.16,36.9Z"
          />
          <path d="M64.53,12.51A9.15,9.15,0,0,1,68,10a15.12,15.12,0,0,1,4.09-1.15,22.14,22.14,0,0,1,4.32-.12A14,14,0,0,1,78.74,9a17.84,17.84,0,0,1,2.28.8,15.08,15.08,0,0,1,3.89,2.56,27.51,27.51,0,0,1,2.92,3.19,22.61,22.61,0,0,0,4.9,5.16l-3,.18,1.63-1.37-.18,3.65C90.09,22.09,89,21,88,19.82c-.53-.57-1.06-1.16-1.58-1.76s-1-1.2-1.58-1.93l-2.2-3,3.54-1A5.71,5.71,0,0,1,88,12a10.17,10.17,0,0,1,1.32.13,18.87,18.87,0,0,1,2.27.52,31.27,31.27,0,0,1,4.13,1.53,24.05,24.05,0,0,1,7.38,5,11.65,11.65,0,0,1,2.69,4.32,8,8,0,0,1,.34,2.88,7.58,7.58,0,0,1-.68,2.62,6.93,6.93,0,0,1-3.68,3.54,7.05,7.05,0,0,1-4.54.26,16.33,16.33,0,0,0,2.91-2.65,5.85,5.85,0,0,0,1.26-2.64,4.72,4.72,0,0,0,.09-1.3,3,3,0,0,0-.28-1.08,7.23,7.23,0,0,0-.7-1.15,10.35,10.35,0,0,0-1.08-1.14A24.56,24.56,0,0,0,93.48,19a26.74,26.74,0,0,0-3.38-1.35,12.78,12.78,0,0,0-1.64-.42,5.12,5.12,0,0,0-.68-.09c-.19,0-.32,0-.17,0L89,13.14c.36.51.83,1.08,1.29,1.63s1,1.11,1.46,1.67c1,1.11,2,2.22,3.07,3.28l1.86,1.92-2,1.74L93,24.76,91.54,26l-1.58-1a27.63,27.63,0,0,1-6.09-6.16,17.48,17.48,0,0,0-5-4.67,8.38,8.38,0,0,0-3-1,24.46,24.46,0,0,0-3.59-.31C69.84,12.68,67.4,12.53,64.53,12.51Z" />
          <path
            className={cls2}
            d="M64.53,12.51c3.67-1.83,10.84-2.32,14.2-1.07,6.28,2.34,7.64,8.12,12.61,11.4L93,21.47s-4.46-4.59-6.08-6.84c3-.87,17,4.8,16.92,11.54-.07,3.45-2.67,5.92-6.59,6.63"
          />
          <path d="M98.22,22.3a17.89,17.89,0,0,1,4.65,2.11,19.05,19.05,0,0,1,4,3.34,11.17,11.17,0,0,1,2.81,5.13,7,7,0,0,1-.2,3.6,7.21,7.21,0,0,1-2,3,10,10,0,0,1-1.28,1,14,14,0,0,1-1.25.72,12.54,12.54,0,0,1-2.71,1,15,15,0,0,1-5.55.39,20.48,20.48,0,0,1-5.06-1.24,25.85,25.85,0,0,1-4.59-2.22c3.42-.44,6.72-.3,9.68-.65a18.23,18.23,0,0,0,4.1-.92,12.8,12.8,0,0,0,1.81-.81c.3-.14.57-.35.86-.5a4.86,4.86,0,0,0,.67-.49,2.42,2.42,0,0,0,1-2.19A9.42,9.42,0,0,0,103.91,30,48.49,48.49,0,0,0,98.22,22.3Z" />
          <path
            className={cls2}
            d="M98.22,22.3c5.08,3.17,13.56,11.51,6.81,16-5.22,3.46-11.06,2.31-17.94.87"
          />
          <path d="M104.22,34.15a4.71,4.71,0,0,1,2,2.65,7.45,7.45,0,0,1,.3,3.49,7.32,7.32,0,0,1-1.46,3.49,6.48,6.48,0,0,1-1.63,1.48,7.56,7.56,0,0,1-1.07.52,10.3,10.3,0,0,1-1.19.29,11.86,11.86,0,0,1-3.39-.15,18.94,18.94,0,0,1-2.94-.77,16.39,16.39,0,0,1-5.35-3,25.31,25.31,0,0,1,5.94-.89c1,0,1.87,0,2.76-.06a22.13,22.13,0,0,0,2.34-.09,1.82,1.82,0,0,0,1-.47,6.18,6.18,0,0,0,1.26-1.51,9.71,9.71,0,0,0,1-2.18A7.52,7.52,0,0,0,104.22,34.15Z" />
          <path
            className={cls2}
            d="M104.22,34.15c2,3.13.24,9-3.31,9.44-3.14.41-11.38-1.44-11.38-1.44"
          />
          <path d="M79.39,38.3a7.59,7.59,0,0,1,3.14-3.61,13.34,13.34,0,0,1,4.53-1.79,17.26,17.26,0,0,1,4.87-.25,19.9,19.9,0,0,1,4.85,1,18.36,18.36,0,0,1,4.52,2.3,17.16,17.16,0,0,1,3.67,3.6,20.22,20.22,0,0,1,3.64,9.28,21.59,21.59,0,0,1-.82,9.9,14.47,14.47,0,0,1-2.55,4.65,15.27,15.27,0,0,1-4.08,3.37,13.66,13.66,0,0,1-10.48,1.51,9.56,9.56,0,0,1-5-3.29,9.16,9.16,0,0,1-1.8-5.82,2.64,2.64,0,0,1,3.52-2.42l.15,0c.14.05.47.17.71.24s.55.15.82.21a10.67,10.67,0,0,0,1.64.21A2.92,2.92,0,0,0,92.9,57a3.11,3.11,0,0,0,1.07-2,5.51,5.51,0,0,0-.22-2.81,4.78,4.78,0,0,0-1.6-2.2,5.67,5.67,0,0,0-2.87-1,30.56,30.56,0,0,0-8,.61,14.56,14.56,0,0,1,3.54-2.58,13.73,13.73,0,0,1,4.35-1.48,8.41,8.41,0,0,1,5.16.78,8.63,8.63,0,0,1,3.73,4,10,10,0,0,1,.85,5.36A8.17,8.17,0,0,1,96.2,61a7.15,7.15,0,0,1-2.91,1.46,10.88,10.88,0,0,1-2.79.24A14.61,14.61,0,0,1,88,62.4q-.6-.12-1.2-.3a11.23,11.23,0,0,1-1.27-.43l3.68-2.36a3.94,3.94,0,0,0,.66,2.49,4.28,4.28,0,0,0,2.27,1.5,8.64,8.64,0,0,0,6.54-.91,10.19,10.19,0,0,0,2.7-2.24A9.57,9.57,0,0,0,103,57.09a17.45,17.45,0,0,0-2.11-14.6,13.19,13.19,0,0,0-5.76-4.55,16.56,16.56,0,0,0-3.72-1.06,22.54,22.54,0,0,0-4-.25,24.9,24.9,0,0,0-4,.36A13.32,13.32,0,0,0,79.39,38.3Z" />
          <path
            className={cls2}
            d="M79.39,38.3c4.12-4.87,17.73-5.85,23.83,3.08,4.06,5.95,4.31,16-.38,20.9-6.16,6.41-16.54,4.5-16.29-3.06a13,13,0,0,0,5.42.83c5.44-.48,6.07-8.69,1.5-11.71s-12.19,1.31-12.19,1.31"
          />
          <path d="M71,14.38c-1.12-.38-2.16-.71-3.18-1s-2-.48-3-.67a29,29,0,0,0-5.92-.46,23.82,23.82,0,0,0-5.78.8,26.88,26.88,0,0,0-10.71,5.31,38,38,0,0,0-4.22,4.19,44.16,44.16,0,0,0-3.6,4.85,34.37,34.37,0,0,1-4.17,5.22,12.52,12.52,0,0,1,.37-7.06,24.69,24.69,0,0,1,3.33-6.24,26,26,0,0,1,5-5.08,33.78,33.78,0,0,1,6.1-3.66,36.91,36.91,0,0,1,6.63-2.37,23.24,23.24,0,0,1,3.55-.68,19.25,19.25,0,0,1,3.64,0,18.46,18.46,0,0,1,6.92,2A11.72,11.72,0,0,1,71,14.38Z" />
          <path
            className={cls2}
            d="M71,14.38c-4.22-3.81-11.16-5.44-17.53-4C42.62,12.83,33.75,20,30.39,32.63"
          />
          <path d="M72.47,19.63a20.55,20.55,0,0,0-3-1.14c-1-.33-2-.62-3.06-.89a36.24,36.24,0,0,0-6.1-1.11,16.6,16.6,0,0,0-3,0,16.45,16.45,0,0,0-2.87.66A11.32,11.32,0,0,0,49.77,20a20,20,0,0,0-1.88,2.21l-1,1.29a4.68,4.68,0,0,1-.9,1,3.52,3.52,0,0,1-.38.26l-.35.16a4.49,4.49,0,0,1-.47.13A2.44,2.44,0,0,1,43,24.59a2.13,2.13,0,0,1-.59-.55,2.36,2.36,0,0,1-.3-.49,2.88,2.88,0,0,1-.2-.59c0-.14,0-.29-.06-.36s0-.33,0-.46a14.75,14.75,0,0,1,.17-2l.2-1.59.22-1.43a4,4,0,0,0,0-1,1.59,1.59,0,0,0-.59-1.12,3.46,3.46,0,0,0-1.79-.55,10.14,10.14,0,0,0-2.61.13,12.45,12.45,0,0,0-2.66.71,12.37,12.37,0,0,0-7.5,7.41,20.13,20.13,0,0,0-1,5.7,25.2,25.2,0,0,0,.47,5.89,16.57,16.57,0,0,0,5.79,9.8,24,24,0,0,0,5.14,3.11,62.88,62.88,0,0,0,6,2.22,19.58,19.58,0,0,1-6.65-.16,17.12,17.12,0,0,1-6.37-2.59,18.23,18.23,0,0,1-5-5,21,21,0,0,1-2.84-6.45,26.08,26.08,0,0,1-.82-6.91,24.14,24.14,0,0,1,1-7A15.43,15.43,0,0,1,27,15a18.17,18.17,0,0,1,6.15-4,18.65,18.65,0,0,1,3.54-1,14.88,14.88,0,0,1,3.81-.25,8.12,8.12,0,0,1,4.27,1.39,6.48,6.48,0,0,1,2.61,4.28,9.49,9.49,0,0,1,.07,2.24,13.39,13.39,0,0,1-.23,1.76L46.83,21c-.1.48-.21,1.05-.23,1.22,0,0,0,0,0-.06l0-.16a1.72,1.72,0,0,0-.14-.4,2.3,2.3,0,0,0-.25-.41,2.42,2.42,0,0,0-.51-.48A2.25,2.25,0,0,0,44,20.35a1.81,1.81,0,0,0-.37.1,1.73,1.73,0,0,0-.26.12,1.08,1.08,0,0,0-.21.14c-.2.19,0,0,.19-.2l1.08-1.2A23,23,0,0,1,47,16.83a13.81,13.81,0,0,1,6.67-3,18.13,18.13,0,0,1,3.51-.18,19.67,19.67,0,0,1,3.45.26A24.27,24.27,0,0,1,67,16a26.29,26.29,0,0,1,2.89,1.61A11.62,11.62,0,0,1,72.47,19.63Z" />
          <path
            className={cls2}
            d="M72.47,19.63c-.08-.33-7.83-4.83-15-4.58-7.53.26-10,4.24-12.52,7.18-1.5,1.78-.25-2.27,0-5,.2-2.31-.78-5.42-6.22-5.11S26.82,16,25,22.72C23,29.84,23.22,47.84,43.72,49.47"
          />
          <path
            className={cls1}
            d="M42.3,81.88s-2.75,6.75-.91,8.92,6.8,2.81,7.5,6.42c1.08,5.58-3.41,9-7,9.75-8.59,1.91-14.17-3.84-5.09-8.75,0,0-6.16-1.09-9-5.09s-4-7.91-2.25-11.5S40.89,76.22,42.3,81.88Z"
          />
          <path
            className={cls2}
            d="M42.3,81.88s-2.75,6.75-.91,8.92,6.8,2.81,7.5,6.42c1.08,5.58-3.41,9-7,9.75-8.59,1.91-14.17-3.84-5.09-8.75,0,0-6.16-1.09-9-5.09s-4-7.91-2.25-11.5S40.89,76.22,42.3,81.88Z"
          />
          <path d="M41.72,53.55a29.92,29.92,0,0,0-4.41,1.91,6.14,6.14,0,0,0-2.59,2.63c-1,2-.36,5,.88,7.43A37.78,37.78,0,0,0,38,69.38c.45.67.93,1.35,1.4,2.1a14.72,14.72,0,0,1,1.35,2.58,7.09,7.09,0,0,1,.47,1.82,5.79,5.79,0,0,1-.19,2.18,4.53,4.53,0,0,1-1.49,2.28,4.32,4.32,0,0,1-2.78.93,5.86,5.86,0,0,1-3.84-1.79,10.62,10.62,0,0,1-1.06-1.22A12.09,12.09,0,0,1,31,76.94l4.34.15a2.6,2.6,0,0,0-.07,3.19,5.19,5.19,0,0,0,1.6,1.47l.47.29a4.55,4.55,0,0,0,.51.21,4.16,4.16,0,0,0,1,.31,5.21,5.21,0,0,0,4.1-.94,3.19,3.19,0,0,0,1.14-1.43,2.44,2.44,0,0,0,.13-1.63c-.1-.91-1.75-3.15-2.45-5.6a10.26,10.26,0,0,1-.46-4.21A8.37,8.37,0,0,1,43,64.57a12.32,12.32,0,0,1,6.41-4.65,11.72,11.72,0,0,1,4-.43,14.09,14.09,0,0,1,2,.28c.32.07.64.16,1,.26s.6.2,1,.39l-.65-.17c1.49.25,3,.5,4.48.77l-2.42,2.81c-.57-2.41-1-4.84-1.4-7.31a52.2,52.2,0,0,1-.6-7.55,51.84,51.84,0,0,1,3.54,6.7c1,2.28,1.91,4.59,2.72,6.93l1.07,3.09-3.49-.27c-1.51-.12-3-.27-4.52-.4l-.65-.18L55,64.73a5.59,5.59,0,0,0-.59-.11,8.11,8.11,0,0,0-1.2-.11,6.57,6.57,0,0,0-2.28.37,7.52,7.52,0,0,0-3.65,2.84,3,3,0,0,0-.69,1.65,5,5,0,0,0,.22,2.09,14.24,14.24,0,0,0,1,2.49,17.08,17.08,0,0,1,.76,1.54c.15.33.3.65.42,1s.18.74.26,1.11a4.86,4.86,0,0,1,.11.55l0,.59a7.75,7.75,0,0,1,0,1.18q-.09.59-.21,1.17a7.35,7.35,0,0,1-.4,1.11A8.29,8.29,0,0,1,46,85.67a9.71,9.71,0,0,1-3.84,1.72A10.21,10.21,0,0,1,36,86.92c-.32-.14-.65-.25-1-.41L34.16,86a9.87,9.87,0,0,1-3-2.85,7.62,7.62,0,0,1-1.41-4.44,8.22,8.22,0,0,1,1.38-4.34l2.22-3.49,2.12,3.65a6.43,6.43,0,0,0,1,1.33c.34.36.64.38.44.35a.85.85,0,0,0-.52.19.53.53,0,0,0-.19.25.93.93,0,0,0,0-.24,2,2,0,0,0-.15-.54,23.94,23.94,0,0,0-2.13-3.77,34.5,34.5,0,0,1-2.54-4.57A16.34,16.34,0,0,1,30,62a9.25,9.25,0,0,1,1.4-5.91,6.14,6.14,0,0,1,1.06-1.21A5.28,5.28,0,0,1,33.75,54a5.65,5.65,0,0,1,1.35-.62A6.44,6.44,0,0,1,36.49,53,10.79,10.79,0,0,1,41.72,53.55Z" />
          <path
            className={cls2}
            d="M41.72,53.55c-11.33,0-10.44,8.22-8.25,12.92,1.74,3.73,5,7.16,5.25,9.66.22,2.19-1.47,3.33-3.33,2.09a8.37,8.37,0,0,1-2.17-2.5c-2,3.08-.75,6,1.92,7.91,5.92,4.26,14.55-1,10.91-7.58-1.66-3-3.25-6.58-1-9.75a9.51,9.51,0,0,1,11.42-3.67l4.5.59L56.84,49"
          />
          <path d="M80.39,49.63l-4.52-.35a19.75,19.75,0,0,0-4,.09c-.29.06-.56.13-.79.2a4.43,4.43,0,0,0-.65.33,6.59,6.59,0,0,0-1.42,1,5.39,5.39,0,0,0-1.69,2.7,6.82,6.82,0,0,0,0,3.27,2.47,2.47,0,0,0,1.36,1.8,4.47,4.47,0,0,0,2.77.23,7.47,7.47,0,0,0,3-1.34l0,0a2.52,2.52,0,0,1,4,2,7.38,7.38,0,0,1,0,.86c0,.25-.05.5-.08.74A11.8,11.8,0,0,1,78,62.49a8.94,8.94,0,0,1-3.5,4.87,10.69,10.69,0,0,1-2.62,1.27,18.67,18.67,0,0,1-5,.85A22.15,22.15,0,0,1,62,69.11a16.92,16.92,0,0,1-9.09-4.66A14.35,14.35,0,0,1,50,59.93a16.83,16.83,0,0,1-1.1-5.06l.17.78a27.33,27.33,0,0,1-1-3.12,29.78,29.78,0,0,1-.59-3.13,21.63,21.63,0,0,1,0-6.57,15.64,15.64,0,0,1,1-3.34,14.28,14.28,0,0,1,1.75-3.07A16.5,16.5,0,0,1,55.41,32a16,16,0,0,1,13-1.26,16.72,16.72,0,0,1,5.48,3.11,18.33,18.33,0,0,1,4,4.58c-3.51-1.9-6.94-3.48-10.38-4a13.46,13.46,0,0,0-9.48,1.81,12.21,12.21,0,0,0-3.44,3.27,10.16,10.16,0,0,0-1.69,4.24,18.66,18.66,0,0,0-.07,5,25.75,25.75,0,0,0,.44,2.58,21.28,21.28,0,0,0,.7,2.45l0,.11a2.65,2.65,0,0,1,.13.67A9.89,9.89,0,0,0,56.53,61a11.39,11.39,0,0,0,6.34,3.2,15.84,15.84,0,0,0,7.46-.34,6,6,0,0,0,1.32-.64,4.27,4.27,0,0,0,.93-.92A4.42,4.42,0,0,0,73.16,61a6.2,6.2,0,0,0,.16-.72c0-.12,0-.24,0-.36s0-.27,0-.23l4,1.93a12.66,12.66,0,0,1-5.12,2.17A9.25,9.25,0,0,1,66.33,63a6.91,6.91,0,0,1-2.49-2.23,7.78,7.78,0,0,1-1.19-3,10.89,10.89,0,0,1,.41-5.56,9.81,9.81,0,0,1,5.72-5.88,6.35,6.35,0,0,1,1.45-.42,7.82,7.82,0,0,1,1.43-.08,12.41,12.41,0,0,1,4.79,1.26A15.94,15.94,0,0,1,80.39,49.63Z" />
          <path
            className={cls2}
            d="M80.39,49.63s-7-3.11-10.5-1.66A8,8,0,0,0,65,57.38c1,4.66,6.69,5.27,10.94,2.21a6.78,6.78,0,0,1-4.27,6.46c-5.71,2.24-19.42,1.17-20.17-11.33,0,0-3.58-9.25.25-15.75s14.75-11.34,26.08-.59"
          />
          <path d="M21.87,60.84a9.67,9.67,0,0,1-.51-4.18,9.46,9.46,0,0,1,1.42-4,12.18,12.18,0,0,1,2.88-3.14,15.94,15.94,0,0,1,3.62-2.13c-1.07.9-2.14,1.76-3.1,2.71a15.94,15.94,0,0,0-2.55,3.09,11.1,11.1,0,0,0-1.51,3.64A16.05,16.05,0,0,0,21.87,60.84Z" />
          <path d="M46.73,19.57A11.08,11.08,0,0,1,44.38,23a9.63,9.63,0,0,1-3.47,2.25,10,10,0,0,1-2,.51,8.39,8.39,0,0,1-1,.09,3.92,3.92,0,0,1-3.13-1.4,4,4,0,0,1-.89-2.1,7.52,7.52,0,0,1,3.82-6.85,5.28,5.28,0,0,1,1.91-.74,2.67,2.67,0,0,1,2,.47,1.94,1.94,0,0,1,.86,1.84,1.87,1.87,0,0,1-1.09,1.55,1.72,1.72,0,0,0,.74-1.56,1.49,1.49,0,0,0-.84-1.31,3,3,0,0,0-3.09.57,8.08,8.08,0,0,0-2.3,2.61,5.52,5.52,0,0,0-.62,3.21,2.32,2.32,0,0,0,1.82,2,2.12,2.12,0,0,0,.71.06,5.16,5.16,0,0,0,.82-.11,7.55,7.55,0,0,0,1.62-.48A8.37,8.37,0,0,0,43,21.64a9.25,9.25,0,0,0,1.85-2.78Z" />
          <path d="M27.42,54.66c-.45,1.66-1,3.19-1.39,4.76a45.75,45.75,0,0,0-.91,4.85,9.18,9.18,0,0,1-.05-5.09A10.83,10.83,0,0,1,27.42,54.66Z" />
          <path d="M38.67,56.38a8,8,0,0,0-2.74,5.1,11.51,11.51,0,0,0,.15,2.92c.1.49.23,1,.34,1.48s.31,1,.42,1.5c-.31-.41-.59-.86-.88-1.31a14.77,14.77,0,0,1-.63-1.47,8,8,0,0,1-.39-3.23,6.38,6.38,0,0,1,1.2-3.06A5.44,5.44,0,0,1,38.67,56.38Z" />
          <path d="M51.59,22.88a10.07,10.07,0,0,0-3.47,3.91c-.8,1.55-1.36,3.24-2.12,4.94a15.26,15.26,0,0,1-1.4,2.48,7.87,7.87,0,0,1-1,1.11l-.54.51c-.19.15-.42.27-.62.4a8.14,8.14,0,0,1-5.5,1,10.93,10.93,0,0,0,4.82-1.94,10.88,10.88,0,0,0,2.85-4.17,32.51,32.51,0,0,1,2.48-4.93A7.76,7.76,0,0,1,51.59,22.88Z" />
          <path d="M46,39.53a9.12,9.12,0,0,1-4,2.15,9.71,9.71,0,0,1-4.64,0,11.78,11.78,0,0,1-4.16-2,11.9,11.9,0,0,1-3.07-3.3,41,41,0,0,0,3.7,2.31,16.2,16.2,0,0,0,3.88,1.52,12.26,12.26,0,0,0,4.1.32A18,18,0,0,0,46,39.53Z" />
          <path d="M38.24,74.59A20.3,20.3,0,0,1,39.69,78a12.1,12.1,0,0,1,.68,3.75,4.88,4.88,0,0,1-.39,2,2.91,2.91,0,0,1-1.6,1.54,3.86,3.86,0,0,1-2,.17A6.73,6.73,0,0,1,34.66,85a6.76,6.76,0,0,0,3.18-.72,2.21,2.21,0,0,0,.72-2.39,12.51,12.51,0,0,0-1-3.06,31.61,31.61,0,0,0-1.51-3Z" />
          <path d="M40,68.63a3.75,3.75,0,0,1-1.15-1,4.63,4.63,0,0,1-.74-1.32,3.56,3.56,0,0,1-.21-1.56,2.06,2.06,0,0,1,.72-1.39,5,5,0,0,0,.06,1.37A8,8,0,0,0,39,66C39.3,66.83,39.69,67.64,40,68.63Z" />
          <path
            className={cls2}
            d="M39.37,27.34c-5.78.29-8.69-4.41-4.63-10.72-1.73,1.51-4.56,4.31-3.35,8.06a5.18,5.18,0,0,1-2.3-1.84c-.49,3.35,1.82,9,5.38,9.42,5,.55,10.56-5.74,10.56-5.74a12.63,12.63,0,0,1-7.85,2.11A8.53,8.53,0,0,0,39.37,27.34Z"
          />
          <path
            className={cls2}
            d="M45.55,42.93c-5.11,1-10.07.21-15.37-3.84,1.26,3,5.83,6.84,9.5,7.29l-2.13-2A9.8,9.8,0,0,0,45.55,42.93Z"
          />
          <path d="M16.55,41.55c-.34,2.06-.57,4.13-1,6.27a10.54,10.54,0,0,1-1.21,3.24A4.77,4.77,0,0,1,13,52.48a5,5,0,0,1-1.72.79,4.61,4.61,0,0,1-1.93.08,3.43,3.43,0,0,1-1.78-.94,5.5,5.5,0,0,1-1.39-3.24,9.85,9.85,0,0,1,1.37-6.26A12.7,12.7,0,0,0,7.35,49,4.37,4.37,0,0,0,8.59,51.3a2.14,2.14,0,0,0,2.19.23,3,3,0,0,0,1.85-1.47,9.4,9.4,0,0,0,.84-2.67c.35-2,.57-4.08.85-6.16Z" />
        </mask>
        <linearGradient
          id="New_Gradient_Swatch_3"
          x1="115.23"
          y1="45.35"
          x2="81.98"
          y2="52.1"
          gradientTransform="matrix(-1, 0, 0, 1, 116.77, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#6eadf8" />
          <stop offset="0.04" stopColor="#6eadf8" />
          <stop offset="1" stopColor="#6eadf8" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="radial-gradient-2"
          cx="27.52"
          cy="28.31"
          r="54.11"
        />
        <radialGradient id="radial-gradient-3" cx="78.51" cy="65.78" r="21.2" />
        <radialGradient
          id="radial-gradient-4"
          cx="74.99"
          cy="95.09"
          r="13.38"
        />
      </defs>
      <g className={cls3}>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="HAIR_26-2" data-name="HAIR 26-2">
              <path
                className={cls1}
                d="M105.34,78.53s3.07,10-1.31,13.62c-5.64,4.68-13.61-.33-15.74-2.58C88.1,89.25,98.33,77.05,105.34,78.53Z"
              />
              <path
                className={hairColour}
                d="M105.34,78.53s3.07,10-1.31,13.62c-5.64,4.68-13.61-.33-15.74-2.58C88.1,89.25,98.33,77.05,105.34,78.53Z"
              />
              <path
                className={cls1}
                d="M99.78,84.9s4.93,9.85-1.69,14.38c-5.66,3.88-18.22,1.51-17.25-5.5C80.84,93.78,97.84,81.53,99.78,84.9Z"
              />
              <path
                className={hairColour}
                d="M99.78,84.9s4.93,9.85-1.69,14.38c-5.66,3.88-18.22,1.51-17.25-5.5C80.84,93.78,97.84,81.53,99.78,84.9Z"
              />
              <path
                className={cls1}
                d="M91.06,88.61s5.94,13.07-.43,16.79C86.18,108,79,101.47,78.09,98.09S87.64,84.87,91.06,88.61Z"
              />
              <path
                className={hairColour}
                d="M91.06,88.61s5.94,13.07-.43,16.79C86.18,108,79,101.47,78.09,98.09S87.64,84.87,91.06,88.61Z"
              />
              <path
                className={cls1}
                d="M108.46,74.58s-1.13,8.63-8.25,11.44a14.91,14.91,0,0,1-10.12.19l5-8.63Z"
              />
              <path
                className={hairColour}
                d="M108.46,74.58s-1.13,8.63-8.25,11.44a14.91,14.91,0,0,1-10.12.19l5-8.63Z"
              />
              <path
                className={cls1}
                d="M110.78,70s1.29,9-6.34,11.08c-7.37,2.06-12,.54-12,.54l5-8.62Z"
              />
              <path
                className={hairColour}
                d="M110.78,70s1.29,9-6.34,11.08c-7.37,2.06-12,.54-12,.54l5-8.62Z"
              />
              <path d="M90.68,101.48a2.06,2.06,0,0,0,.16.45l0-.05a.72.72,0,0,0-.46-.17.71.71,0,0,0-.48.17.18.18,0,0,0-.06.06,2,2,0,0,0,.18-.43,6,6,0,0,0,.15-.64,13.19,13.19,0,0,0,.24-2.8,4.23,4.23,0,0,0-.19-1.19.47.47,0,0,0-.12-.19s0,0-.12,0a1.73,1.73,0,0,0-.8.59,4.58,4.58,0,0,0-.7,1.13,11.4,11.4,0,0,0-.78,5.64,2.82,2.82,0,0,0,1.21,2.3,3.42,3.42,0,0,0,2.8-.3,4.48,4.48,0,0,1-1.36.74,2.59,2.59,0,0,1-1.63,0,2.41,2.41,0,0,1-1.3-1.09,5,5,0,0,1-.59-1.53,11.73,11.73,0,0,1,.36-6.34,6.3,6.3,0,0,1,.79-1.52,3.17,3.17,0,0,1,1.57-1.22,1.77,1.77,0,0,1,1.36.12,2,2,0,0,1,.86.94A5.57,5.57,0,0,1,92.14,98a12.79,12.79,0,0,1-.13,3.27c-.05.27-.1.55-.18.83a3.43,3.43,0,0,1-.41,1,1.52,1.52,0,0,1-.31.35,1.21,1.21,0,0,1-.82.3,1.27,1.27,0,0,1-.8-.31,2.09,2.09,0,0,1-.3-.35,4.61,4.61,0,0,1-.43-.94Z" />
              <path d="M100.72,95.91a4.2,4.2,0,0,0,.52-2.48,1.18,1.18,0,0,0-.35-.78,1.06,1.06,0,0,0-.94,0,4.91,4.91,0,0,0-2.27,1.71c-.26.37-.42.84-.24,1.13s.82.35,1.34.22a2.27,2.27,0,0,1-.77.36,1.09,1.09,0,0,1-1-.2,1.31,1.31,0,0,1-.4-1,2.45,2.45,0,0,1,.21-1,5.58,5.58,0,0,1,2.53-2.55,2.59,2.59,0,0,1,2.22-.2A2.68,2.68,0,0,1,103,93.07a5.46,5.46,0,0,1-.53,3.84Z" />
              <path
                className={cls1}
                d="M104.34,45.09s12.44,5.63,10.38,14.69c3.06,10.25-9.88,16.81-13.94,17.06-3.33.21-6.19-2.56-6.25-3.31S98.53,46.28,104.34,45.09Z"
              />
              <path
                className={hairColour}
                d="M104.34,45.09s12.44,5.63,10.38,14.69c3.06,10.25-9.88,16.81-13.94,17.06-3.33.21-6.19-2.56-6.25-3.31S98.53,46.28,104.34,45.09Z"
              />
              <path d="M116.88,58.89A8.63,8.63,0,0,1,114,63.83a10.74,10.74,0,0,1-4.88,2.36,10.27,10.27,0,0,0,4.16-3.06,7.08,7.08,0,0,0,1.66-4.44Z" />
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M102.22,45.26c6.31,5.81,12.7,14.24,2.71,23.35a5.28,5.28,0,0,0,3.76-1.41s-.39,7.15-3.52,9.15c-4.16,2.67-10,.72-11.83-2C93.34,74.38,95,51.13,102.22,45.26Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M106.22,77.26l-3.06,5.37L97.53,87.7a14,14,0,0,1-8.94-.82L94.22,76C94.28,76,100.08,80.2,106.22,77.26Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M93,91.2s-8.81,10.31-3.62,10.87c0,0,2.93,9.31-4.19,4.69S76.47,99,77,97,83.53,87,87.66,86.7c1.24-.1,4.87,1.81,4.87,1.81Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M99.53,88.32S94.34,100.13,99,99.2l-4.75,2.87a17.87,17.87,0,0,0,.19-6c-.5-2.87-1.94-7.56-1.94-7.56Z"
                />
              </g>
              <path
                className={cls7}
                d="M114.05,51.38c.42,5,.55,6.07-1,3.13-.5,5.18-2.61,3.57-4.56-1.63,2.39,3.94,2.12,7.82.17,11.67,0,0,5.66,1.25,6.58-3.58S115.47,52.22,114.05,51.38Z"
              />
              <path
                className={cls8}
                d="M101.47,90l-3.13,1.75a8.72,8.72,0,0,0-.87,6.69S103.47,97.32,101.47,90Z"
              />
              <path
                className={cls9}
                d="M93.34,92.82c-1,3.45-2.47,5.29-4.25,5.75L88,101.76l1.75.37L90,105,94.09,101S94.28,94,93.34,92.82Z"
              />
              <path
                className={cls10}
                d="M107.22,45.38c5.82,2,9.15,8.21,9.15,14.29,0,2,.4,1.4.4,3.38,0,4.22-5,10-5,10.42,0,4.46-4.79,7.76-4.79,9.44,0,1,.7,2.22.7,3.42,0,1-5.26,15.24-12,15.24-3.91,0-8.83-4.53-8.83-10.36,0-5.41,5.09-9.91,5.9-12.58.94-3.09,4-35,11.52-35A3.47,3.47,0,0,1,107.22,45.38Z"
              />
              <path
                className={cls11}
                d="M108.4,69.1a4.55,4.55,0,0,1-.47,2.47"
              />
            </g>
            <g id="HAIR_26-1" data-name="HAIR 26-1">
              <path
                className={cls1}
                d="M27.45,91.27s1.34,7.8-2.15,7.8S15,94.37,14.38,91.49s.15-8.1,3.12-8.62,9.66.89,10.77,2.68S27.45,91.27,27.45,91.27Z"
              />
              <path
                className={hairColour}
                d="M27.45,91.27s1.34,7.8-2.15,7.8S15,94.37,14.38,91.49s.15-8.1,3.12-8.62,9.66.89,10.77,2.68S27.45,91.27,27.45,91.27Z"
              />
              <path d="M27,92.42a15.3,15.3,0,0,0-3.79-1.29,2.84,2.84,0,0,0-1.59.05.7.7,0,0,0-.36.34,2.33,2.33,0,0,0-.17.78,2.13,2.13,0,0,0,.45,1.56A9.4,9.4,0,0,0,23,95.24c.47.32.93.33,1-.34A4.07,4.07,0,0,0,23.29,93a3.61,3.61,0,0,1,1.21,1.88,1.16,1.16,0,0,1-.57,1.27A1.57,1.57,0,0,1,22.52,96a7.86,7.86,0,0,1-1.89-1.36,3.81,3.81,0,0,1-.83-3.72,2.3,2.3,0,0,1,1-1.24,4.52,4.52,0,0,1,2.63-.38,15.19,15.19,0,0,1,4.41,1.29Z" />
              <path
                className={cls1}
                d="M81.13,50.39c7.59-3.86,16.87-5.64,23.61-4.53A29.89,29.89,0,0,0,91.66,24.78C84.53,20,79.3,14.4,72.41,14c-14.28-.9-28.2,9.08-30.9,11.68C26.6,40,28,50.34,31.16,75.3L38,72.55l-.32-6.8s7.28-12.29,17.79-16.33S75,47.16,81.13,50.39Z"
              />
              <path
                className={hairColour}
                d="M81.13,50.39c7.59-3.86,16.87-5.64,23.61-4.53A29.89,29.89,0,0,0,91.66,24.78C84.53,20,79.3,14.4,72.41,14c-14.28-.9-28.2,9.08-30.9,11.68C26.6,40,28,50.34,31.16,75.3L38,72.55l-.32-6.8s7.28-12.29,17.79-16.33S75,47.16,81.13,50.39Z"
              />
              <path d="M27.55,22.55a8.24,8.24,0,0,1-1.77,3c-.69.72-1.49,1.25-2.17,1.9a17.39,17.39,0,0,0-1.83,1.77c-.09.13-.2.26-.26.36s-.08.1-.17.32-.32.73-.48,1.11a18.83,18.83,0,0,0-1.12,5.18,11.71,11.71,0,0,0,.05,2.59,4.94,4.94,0,0,0,.5,1.81l-4.67.35a5.38,5.38,0,0,1,2.06-2.44,8.34,8.34,0,0,1,2.14-1,13.31,13.31,0,0,1,2-.37,15.55,15.55,0,0,1,2-.06l-1.48.52a22,22,0,0,1,3.23-1.8,10.72,10.72,0,0,1,1.87-.56,8.28,8.28,0,0,1,2.17-.19,8.17,8.17,0,0,1-.7,2.06,10.31,10.31,0,0,1-1,1.69,22.3,22.3,0,0,1-2.52,2.7l-.36.36L23.85,42a11.8,11.8,0,0,0-1.27.25,7.46,7.46,0,0,0-1.2.32,3.53,3.53,0,0,0-.82.35c-.17.1-.14.11-.08-.07l-4.68.35a9.94,9.94,0,0,1-1.07-4,25.8,25.8,0,0,1,1.39-10.07,12.52,12.52,0,0,1,.74-1.65,9,9,0,0,1,.61-1c.24-.3.48-.54.72-.79A14.19,14.19,0,0,1,21,23.69,15.26,15.26,0,0,1,24,22.36a5.69,5.69,0,0,1,1.68-.21A4.85,4.85,0,0,1,27.55,22.55Z" />
              <path
                className={hairColour}
                d="M27.55,22.55c-.25,0-6.94,3.4-8.33,5.92-2,3.71-2.75,10.75-1.17,13.41.92-2.25,5.75-2.33,5.75-2.33l5.75-4.5"
              />
              <path d="M26.85,30.34c-1.1,1.49-2.2,2.67-3.19,3.86A38.35,38.35,0,0,0,21,37.7a18.38,18.38,0,0,0-2.64,8.18l-1,10.44-3.89-9.91a8,8,0,0,0-1.3-2.26,1.31,1.31,0,0,0-.38-.34.76.76,0,0,0,.17,0H12l-.29.09a5.21,5.21,0,0,0-3.32,3.44,13,13,0,0,0-.31,6,27.08,27.08,0,0,0,1.74,6.33A10,10,0,0,0,13,64.15a3.66,3.66,0,0,0,2,.64,6.22,6.22,0,0,0,2.5-.49,18.5,18.5,0,0,0,2.83-1.39,23.76,23.76,0,0,0,2.6-1.81A18.76,18.76,0,0,0,27.47,56a7.63,7.63,0,0,1-.06,3.94,11.31,11.31,0,0,1-1.68,3.69,14.91,14.91,0,0,1-2.79,3,23.18,23.18,0,0,1-3.44,2.2A11,11,0,0,1,15,70a8.75,8.75,0,0,1-4.87-1.35,11.63,11.63,0,0,1-3.28-3.28A19.44,19.44,0,0,1,5,61.63,33.32,33.32,0,0,1,3.07,54a18.93,18.93,0,0,1,.57-8.29,10.59,10.59,0,0,1,2.38-4,10.3,10.3,0,0,1,4-2.57l.57-.19a4.66,4.66,0,0,1,2.89.07A6.07,6.07,0,0,1,16,40.78a10.72,10.72,0,0,1,1.33,1.95,13.64,13.64,0,0,1,.9,2l-4.86.53A21.74,21.74,0,0,1,14.7,40a18,18,0,0,1,2.81-4.8,13.39,13.39,0,0,1,4.15-3.54A10.51,10.51,0,0,1,26.85,30.34Z" />
              <path
                className={hairColour}
                d="M26.85,30.34c-7,3.36-10.24,8.57-11,15.25,0,0-1.76-5.07-4.56-4.21C5.89,43,4.13,48.8,6.24,57.05,6.91,59.69,9.91,70.86,19,66.3,27,62.3,27.47,56,27.47,56"
              />
              <path d="M8.63,40.61a3.94,3.94,0,0,1,2.5.45,3.27,3.27,0,0,1,1.48,2.06,4.81,4.81,0,0,1-1.13,4.23,2.72,2.72,0,0,1-2,1,1,1,0,0,1-.65-.27,1.11,1.11,0,0,1-.31-.58,3.49,3.49,0,0,1,0-1.08,4.39,4.39,0,0,1,.74-1.88,2.11,2.11,0,0,1,.35-.38c.12-.09.33-.25.53-.1.21.35,0,.68-.05,1,.06-.3.15-.7,0-.9s-.24,0-.34.14a2.51,2.51,0,0,0-.27.37A4,4,0,0,0,9,46.47a2.4,2.4,0,0,0,.07.87c.1.25.23.32.43.3a2,2,0,0,0,1.24-.92,3.61,3.61,0,0,0,.47-3.17,1.66,1.66,0,0,0-2.19-1Z" />
              <path d="M16.55,41.55c-.34,2.06-.57,4.13-1,6.27a10.54,10.54,0,0,1-1.21,3.24A4.77,4.77,0,0,1,13,52.48a5,5,0,0,1-1.72.79,4.61,4.61,0,0,1-1.93.08,3.43,3.43,0,0,1-1.78-.94,5.5,5.5,0,0,1-1.39-3.24,9.85,9.85,0,0,1,1.37-6.26A12.7,12.7,0,0,0,7.35,49,4.37,4.37,0,0,0,8.59,51.3a2.14,2.14,0,0,0,2.19.23,3,3,0,0,0,1.85-1.47,9.4,9.4,0,0,0,.84-2.67c.35-2,.57-4.08.85-6.16Z" />
              <path d="M6.9,58.87a7.59,7.59,0,0,0,1.44,1.66,7.34,7.34,0,0,0,1.92,1.24,6.1,6.1,0,0,0,2.28.54A7.43,7.43,0,0,0,15,62.05a8.11,8.11,0,0,1-2.4.76,6.72,6.72,0,0,1-2.6-.09,8.51,8.51,0,0,1-2.49-1,9.71,9.71,0,0,1-2.18-1.69Z" />
              <path d="M30.05,41.72a53.49,53.49,0,0,0-5.32,5.35,24.92,24.92,0,0,0-2.09,2.78,7.71,7.71,0,0,0-1,2.81,25.58,25.58,0,0,0,0,7c.15,1.21.33,2.44.53,3.68s.44,2.48.63,3.88l-4.28,2.17a1,1,0,0,0-.16-.12,2.4,2.4,0,0,0-.26-.18,4.85,4.85,0,0,0-.54-.3,3,3,0,0,0-1-.23,2.06,2.06,0,0,0-.88.21,3.38,3.38,0,0,0-.94.7C13.57,70.65,13,73,13.26,75.14a11.48,11.48,0,0,0,.84,3.28l.16.39.17.33c.1.21.25.4.37.61a6.26,6.26,0,0,0,1,1.08,9,9,0,0,0,5.91,2,18.84,18.84,0,0,0,3.48-.35,10.58,10.58,0,0,0,3-1.1A8.83,8.83,0,0,0,32,76.47,44.12,44.12,0,0,0,33.52,69c.2-1.32.39-2.67.62-4s.46-2.74.83-4.15a26.38,26.38,0,0,1,1.33,4.06,29.42,29.42,0,0,1,.76,4.19,24.25,24.25,0,0,1-.53,8.8,14.48,14.48,0,0,1-2,4.46,6.84,6.84,0,0,1-.76,1c-.29.33-.55.68-.86,1a12.13,12.13,0,0,1-2.07,1.59,14.34,14.34,0,0,1-4.65,1.82,19.7,19.7,0,0,1-4.69.41,14.08,14.08,0,0,1-9.21-3.51,11.06,11.06,0,0,1-1.73-2,11.29,11.29,0,0,1-1.19-2.31,17.71,17.71,0,0,1-1.14-4.68,14.6,14.6,0,0,1,.33-4.95A10.32,10.32,0,0,1,11.14,66a8.45,8.45,0,0,1,2.34-1.74,7.09,7.09,0,0,1,3.09-.73,7.51,7.51,0,0,1,3,.62,9,9,0,0,1,1.27.67c.2.12.4.26.59.4s.35.27.64.53l-4.29,2.17c-.36-2.37-.86-5.09-1.06-7.77a26.8,26.8,0,0,1,.48-8.45,12.48,12.48,0,0,1,2-4.25,15.81,15.81,0,0,1,3-3,13.72,13.72,0,0,1,3.7-2A10.24,10.24,0,0,1,30.05,41.72Z" />
              <path
                className={hairColour}
                d="M30.05,41.72A19.28,19.28,0,0,0,20.8,48.8c-3.41,4.92-1,15-.5,18.75,0,0-2.63-2.53-5.66-1.08C10.8,68.3,9.47,74.3,12,79.88c1.73,3.87,6.58,6.67,13.67,5.25C35.24,83.21,36,73.72,35,60.8"
              />
              <path d="M19.5,62.94c.32,2.2,1.25,4.49,1.69,7a10.42,10.42,0,0,1,.12,3.91A5.26,5.26,0,0,1,19,77.23,4.18,4.18,0,0,1,17,78a3.18,3.18,0,0,1-2.11-.58A5.08,5.08,0,0,1,13,74a5.44,5.44,0,0,1,0-1.93,5,5,0,0,1,.28-.94,4.81,4.81,0,0,1,.49-.82,3.36,3.36,0,0,1,.67-.69,1.3,1.3,0,0,1,.94-.3c.73.15,1,.84,1.18,1.37-.28-.5-.66-1.1-1.2-1.13s-.91.54-1.21,1a4.17,4.17,0,0,0-.31,3.25,4.34,4.34,0,0,0,1.74,2.6,2.19,2.19,0,0,0,2.61-.34,3.71,3.71,0,0,0,1.48-2.54,9,9,0,0,0-.25-3.26c-.52-2.25-1.45-4.46-1.91-7Z" />
              <path
                className={cls1}
                d="M103.16,36.9s6.75,1.82,8.25,5.19A7.35,7.35,0,0,1,110,50.53c-1.56,1.56-5.06,4.44-7.31,3.37C101.7,53.45,96.78,39.9,103.16,36.9Z"
              />
              <path
                className={hairColour}
                d="M103.16,36.9s6.75,1.82,8.25,5.19A7.35,7.35,0,0,1,110,50.53c-1.56,1.56-5.06,4.44-7.31,3.37C101.7,53.45,96.78,39.9,103.16,36.9Z"
              />
              <path d="M64.53,12.51A9.15,9.15,0,0,1,68,10a15.12,15.12,0,0,1,4.09-1.15,22.14,22.14,0,0,1,4.32-.12A14,14,0,0,1,78.74,9a17.84,17.84,0,0,1,2.28.8,15.08,15.08,0,0,1,3.89,2.56,27.51,27.51,0,0,1,2.92,3.19,22.61,22.61,0,0,0,4.9,5.16l-3,.18,1.63-1.37-.18,3.65C90.09,22.09,89,21,88,19.82c-.53-.57-1.06-1.16-1.58-1.76s-1-1.2-1.58-1.93l-2.2-3,3.54-1A5.71,5.71,0,0,1,88,12a10.17,10.17,0,0,1,1.32.13,18.87,18.87,0,0,1,2.27.52,31.27,31.27,0,0,1,4.13,1.53,24.05,24.05,0,0,1,7.38,5,11.65,11.65,0,0,1,2.69,4.32,8,8,0,0,1,.34,2.88,7.58,7.58,0,0,1-.68,2.62,6.93,6.93,0,0,1-3.68,3.54,7.05,7.05,0,0,1-4.54.26,16.33,16.33,0,0,0,2.91-2.65,5.85,5.85,0,0,0,1.26-2.64,4.72,4.72,0,0,0,.09-1.3,3,3,0,0,0-.28-1.08,7.23,7.23,0,0,0-.7-1.15,10.35,10.35,0,0,0-1.08-1.14A24.56,24.56,0,0,0,93.48,19a26.74,26.74,0,0,0-3.38-1.35,12.78,12.78,0,0,0-1.64-.42,5.12,5.12,0,0,0-.68-.09c-.19,0-.32,0-.17,0L89,13.14c.36.51.83,1.08,1.29,1.63s1,1.11,1.46,1.67c1,1.11,2,2.22,3.07,3.28l1.86,1.92-2,1.74L93,24.76,91.54,26l-1.58-1a27.63,27.63,0,0,1-6.09-6.16,17.48,17.48,0,0,0-5-4.67,8.38,8.38,0,0,0-3-1,24.46,24.46,0,0,0-3.59-.31C69.84,12.68,67.4,12.53,64.53,12.51Z" />
              <path
                className={hairColour}
                d="M64.53,12.51c3.67-1.83,10.84-2.32,14.2-1.07,6.28,2.34,7.64,8.12,12.61,11.4L93,21.47s-4.46-4.59-6.08-6.84c3-.87,17,4.8,16.92,11.54-.07,3.45-2.67,5.92-6.59,6.63"
              />
              <path d="M98.22,22.3a17.89,17.89,0,0,1,4.65,2.11,19.05,19.05,0,0,1,4,3.34,11.17,11.17,0,0,1,2.81,5.13,7,7,0,0,1-.2,3.6,7.21,7.21,0,0,1-2,3,10,10,0,0,1-1.28,1,14,14,0,0,1-1.25.72,12.54,12.54,0,0,1-2.71,1,15,15,0,0,1-5.55.39,20.48,20.48,0,0,1-5.06-1.24,25.85,25.85,0,0,1-4.59-2.22c3.42-.44,6.72-.3,9.68-.65a18.23,18.23,0,0,0,4.1-.92,12.8,12.8,0,0,0,1.81-.81c.3-.14.57-.35.86-.5a4.86,4.86,0,0,0,.67-.49,2.42,2.42,0,0,0,1-2.19A9.42,9.42,0,0,0,103.91,30,48.49,48.49,0,0,0,98.22,22.3Z" />
              <path
                className={hairColour}
                d="M98.22,22.3c5.08,3.17,13.56,11.51,6.81,16-5.22,3.46-11.06,2.31-17.94.87"
              />
              <path d="M104.22,34.15a4.71,4.71,0,0,1,2,2.65,7.45,7.45,0,0,1,.3,3.49,7.32,7.32,0,0,1-1.46,3.49,6.48,6.48,0,0,1-1.63,1.48,7.56,7.56,0,0,1-1.07.52,10.3,10.3,0,0,1-1.19.29,11.86,11.86,0,0,1-3.39-.15,18.94,18.94,0,0,1-2.94-.77,16.39,16.39,0,0,1-5.35-3,25.31,25.31,0,0,1,5.94-.89c1,0,1.87,0,2.76-.06a22.13,22.13,0,0,0,2.34-.09,1.82,1.82,0,0,0,1-.47,6.18,6.18,0,0,0,1.26-1.51,9.71,9.71,0,0,0,1-2.18A7.52,7.52,0,0,0,104.22,34.15Z" />
              <path
                className={hairColour}
                d="M104.22,34.15c2,3.13.24,9-3.31,9.44-3.14.41-11.38-1.44-11.38-1.44"
              />
              <path d="M79.39,38.3a7.59,7.59,0,0,1,3.14-3.61,13.34,13.34,0,0,1,4.53-1.79,17.26,17.26,0,0,1,4.87-.25,19.9,19.9,0,0,1,4.85,1,18.36,18.36,0,0,1,4.52,2.3,17.16,17.16,0,0,1,3.67,3.6,20.22,20.22,0,0,1,3.64,9.28,21.59,21.59,0,0,1-.82,9.9,14.47,14.47,0,0,1-2.55,4.65,15.27,15.27,0,0,1-4.08,3.37,13.66,13.66,0,0,1-10.48,1.51,9.56,9.56,0,0,1-5-3.29,9.16,9.16,0,0,1-1.8-5.82,2.64,2.64,0,0,1,3.52-2.42l.15,0c.14.05.47.17.71.24s.55.15.82.21a10.67,10.67,0,0,0,1.64.21A2.92,2.92,0,0,0,92.9,57a3.11,3.11,0,0,0,1.07-2,5.51,5.51,0,0,0-.22-2.81,4.78,4.78,0,0,0-1.6-2.2,5.67,5.67,0,0,0-2.87-1,30.56,30.56,0,0,0-8,.61,14.56,14.56,0,0,1,3.54-2.58,13.73,13.73,0,0,1,4.35-1.48,8.41,8.41,0,0,1,5.16.78,8.63,8.63,0,0,1,3.73,4,10,10,0,0,1,.85,5.36A8.17,8.17,0,0,1,96.2,61a7.15,7.15,0,0,1-2.91,1.46,10.88,10.88,0,0,1-2.79.24A14.61,14.61,0,0,1,88,62.4q-.6-.12-1.2-.3a11.23,11.23,0,0,1-1.27-.43l3.68-2.36a3.94,3.94,0,0,0,.66,2.49,4.28,4.28,0,0,0,2.27,1.5,8.64,8.64,0,0,0,6.54-.91,10.19,10.19,0,0,0,2.7-2.24A9.57,9.57,0,0,0,103,57.09a17.45,17.45,0,0,0-2.11-14.6,13.19,13.19,0,0,0-5.76-4.55,16.56,16.56,0,0,0-3.72-1.06,22.54,22.54,0,0,0-4-.25,24.9,24.9,0,0,0-4,.36A13.32,13.32,0,0,0,79.39,38.3Z" />
              <path
                className={hairColour}
                d="M79.39,38.3c4.12-4.87,17.73-5.85,23.83,3.08,4.06,5.95,4.31,16-.38,20.9-6.16,6.41-16.54,4.5-16.29-3.06a13,13,0,0,0,5.42.83c5.44-.48,6.07-8.69,1.5-11.71s-12.19,1.31-12.19,1.31"
              />
              <path d="M104.08,61.31a12,12,0,0,0,.75-4.35,12.86,12.86,0,0,0-1-4.48,13.28,13.28,0,0,1,1.95,4.35,13.69,13.69,0,0,1,.21,5Z" />
              <path d="M89.1,65.38a15.86,15.86,0,0,0,4-.41,8.36,8.36,0,0,0,3.63-1.84,8.91,8.91,0,0,1-3.27,2.77,17.35,17.35,0,0,1-4.15,1.46Z" />
              <path d="M79.12,44.25A24.76,24.76,0,0,1,88.22,42a23.84,23.84,0,0,0-8,3.91Z" />
              <path d="M79.18,42.05a24.89,24.89,0,0,1,2.63-1.6,7.35,7.35,0,0,1,2.93-.87,6.89,6.89,0,0,0-2.51,1.5,22.71,22.71,0,0,0-2.06,2.1Z" />
              <path d="M77.66,34.06a22,22,0,0,1,8.43-3.84A21.16,21.16,0,0,0,79,35.5Z" />
              <path d="M75.17,26.6a26.21,26.21,0,0,1,2.51-2.11,15.77,15.77,0,0,1,2.87-1.74c2.28-1,4.54-.87,6.58-.86a9,9,0,0,0,5.56-1.21,2.38,2.38,0,0,0,.84-.9,1.3,1.3,0,0,0-.08-1.14,5.37,5.37,0,0,0-2.11-2.09,4.91,4.91,0,0,1,2.73,1.71,2.45,2.45,0,0,1,.42.82,1.92,1.92,0,0,1,0,1,3.23,3.23,0,0,1-1,1.54,7.5,7.5,0,0,1-3,1.6,17,17,0,0,1-3.24.5c-2.07.18-4.14.27-5.65,1.08a22.18,22.18,0,0,0-4.62,3.57Z" />
              <path d="M74,21.55c.4-.49.79-.91,1.2-1.35a16.35,16.35,0,0,1,1.3-1.2A9.86,9.86,0,0,1,78,18a4.28,4.28,0,0,1,1.64-.57,4,4,0,0,0-1.34,1,9.05,9.05,0,0,0-1,1.3,13.57,13.57,0,0,0-.84,1.42c-.25.48-.49,1-.68,1.47Z" />
              <path d="M71,14.38c-1.12-.38-2.16-.71-3.18-1s-2-.48-3-.67a29,29,0,0,0-5.92-.46,23.82,23.82,0,0,0-5.78.8,26.88,26.88,0,0,0-10.71,5.31,38,38,0,0,0-4.22,4.19,44.16,44.16,0,0,0-3.6,4.85,34.37,34.37,0,0,1-4.17,5.22,12.52,12.52,0,0,1,.37-7.06,24.69,24.69,0,0,1,3.33-6.24,26,26,0,0,1,5-5.08,33.78,33.78,0,0,1,6.1-3.66,36.91,36.91,0,0,1,6.63-2.37,23.24,23.24,0,0,1,3.55-.68,19.25,19.25,0,0,1,3.64,0,18.46,18.46,0,0,1,6.92,2A11.72,11.72,0,0,1,71,14.38Z" />
              <path
                className={hairColour}
                d="M71,14.38c-4.22-3.81-11.16-5.44-17.53-4C42.62,12.83,33.75,20,30.39,32.63"
              />
              <path d="M72.47,19.63a20.55,20.55,0,0,0-3-1.14c-1-.33-2-.62-3.06-.89a36.24,36.24,0,0,0-6.1-1.11,16.6,16.6,0,0,0-3,0,16.45,16.45,0,0,0-2.87.66A11.32,11.32,0,0,0,49.77,20a20,20,0,0,0-1.88,2.21l-1,1.29a4.68,4.68,0,0,1-.9,1,3.52,3.52,0,0,1-.38.26l-.35.16a4.49,4.49,0,0,1-.47.13A2.44,2.44,0,0,1,43,24.59a2.13,2.13,0,0,1-.59-.55,2.36,2.36,0,0,1-.3-.49,2.88,2.88,0,0,1-.2-.59c0-.14,0-.29-.06-.36s0-.33,0-.46a14.75,14.75,0,0,1,.17-2l.2-1.59.22-1.43a4,4,0,0,0,0-1,1.59,1.59,0,0,0-.59-1.12,3.46,3.46,0,0,0-1.79-.55,10.14,10.14,0,0,0-2.61.13,12.45,12.45,0,0,0-2.66.71,12.37,12.37,0,0,0-7.5,7.41,20.13,20.13,0,0,0-1,5.7,25.2,25.2,0,0,0,.47,5.89,16.57,16.57,0,0,0,5.79,9.8,24,24,0,0,0,5.14,3.11,62.88,62.88,0,0,0,6,2.22,19.58,19.58,0,0,1-6.65-.16,17.12,17.12,0,0,1-6.37-2.59,18.23,18.23,0,0,1-5-5,21,21,0,0,1-2.84-6.45,26.08,26.08,0,0,1-.82-6.91,24.14,24.14,0,0,1,1-7A15.43,15.43,0,0,1,27,15a18.17,18.17,0,0,1,6.15-4,18.65,18.65,0,0,1,3.54-1,14.88,14.88,0,0,1,3.81-.25,8.12,8.12,0,0,1,4.27,1.39,6.48,6.48,0,0,1,2.61,4.28,9.49,9.49,0,0,1,.07,2.24,13.39,13.39,0,0,1-.23,1.76L46.83,21c-.1.48-.21,1.05-.23,1.22,0,0,0,0,0-.06l0-.16a1.72,1.72,0,0,0-.14-.4,2.3,2.3,0,0,0-.25-.41,2.42,2.42,0,0,0-.51-.48A2.25,2.25,0,0,0,44,20.35a1.81,1.81,0,0,0-.37.1,1.73,1.73,0,0,0-.26.12,1.08,1.08,0,0,0-.21.14c-.2.19,0,0,.19-.2l1.08-1.2A23,23,0,0,1,47,16.83a13.81,13.81,0,0,1,6.67-3,18.13,18.13,0,0,1,3.51-.18,19.67,19.67,0,0,1,3.45.26A24.27,24.27,0,0,1,67,16a26.29,26.29,0,0,1,2.89,1.61A11.62,11.62,0,0,1,72.47,19.63Z" />
              <path
                className={hairColour}
                d="M72.47,19.63c-.08-.33-7.83-4.83-15-4.58-7.53.26-10,4.24-12.52,7.18-1.5,1.78-.25-2.27,0-5,.2-2.31-.78-5.42-6.22-5.11S26.82,16,25,22.72C23,29.84,23.22,47.84,43.72,49.47"
              />
              <path
                className={cls1}
                d="M42.3,81.88s-2.75,6.75-.91,8.92,6.8,2.81,7.5,6.42c1.08,5.58-3.41,9-7,9.75-8.59,1.91-14.17-3.84-5.09-8.75,0,0-6.16-1.09-9-5.09s-4-7.91-2.25-11.5S40.89,76.22,42.3,81.88Z"
              />
              <path
                className={hairColour}
                d="M42.3,81.88s-2.75,6.75-.91,8.92,6.8,2.81,7.5,6.42c1.08,5.58-3.41,9-7,9.75-8.59,1.91-14.17-3.84-5.09-8.75,0,0-6.16-1.09-9-5.09s-4-7.91-2.25-11.5S40.89,76.22,42.3,81.88Z"
              />
              <path d="M32.61,96.9c1.52.53,3,1,4.62,1.43a46.2,46.2,0,0,1,4.8,1.5,10.65,10.65,0,0,1,2.34,1.27,4.85,4.85,0,0,1,1.81,2.18,2.49,2.49,0,0,1,0,1.55,3.07,3.07,0,0,1-.75,1.19,6.62,6.62,0,0,1-2.14,1.39,10.13,10.13,0,0,0,1.57-1.82,1.81,1.81,0,0,0,.18-1.85,4.18,4.18,0,0,0-1.53-1.36,9.19,9.19,0,0,0-2.09-.86c-1.5-.4-3.12-.66-4.74-1a42.61,42.61,0,0,1-4.88-1.25Z" />
              <path d="M38.47,109.53a6.5,6.5,0,0,1-2.39-1,4.86,4.86,0,0,1-1.78-2,3.15,3.15,0,0,1,.1-2.88,4,4,0,0,1,.91-1,2.72,2.72,0,0,1,1.37-.54,5.68,5.68,0,0,1,2.46.32,4.91,4.91,0,0,1,2.07,1.29,1.66,1.66,0,0,1,.37.64,2.22,2.22,0,0,1,.07.68,1.22,1.22,0,0,1-.83,1.12,2.54,2.54,0,0,1-2.21-.59,2.56,2.56,0,0,0,2,.07.54.54,0,0,0,.27-.61,1.8,1.8,0,0,0-.1-.4,1.65,1.65,0,0,0-.23-.26,5,5,0,0,0-3.63-.86,1.24,1.24,0,0,0-.62.3,2.69,2.69,0,0,0-.45.65,1.4,1.4,0,0,0,.07,1.33,4.18,4.18,0,0,0,2.88,1.77Z" />
              <path d="M41.72,53.55a29.92,29.92,0,0,0-4.41,1.91,6.14,6.14,0,0,0-2.59,2.63c-1,2-.36,5,.88,7.43A37.78,37.78,0,0,0,38,69.38c.45.67.93,1.35,1.4,2.1a14.72,14.72,0,0,1,1.35,2.58,7.09,7.09,0,0,1,.47,1.82,5.79,5.79,0,0,1-.19,2.18,4.53,4.53,0,0,1-1.49,2.28,4.32,4.32,0,0,1-2.78.93,5.86,5.86,0,0,1-3.84-1.79,10.62,10.62,0,0,1-1.06-1.22A12.09,12.09,0,0,1,31,76.94l4.34.15a2.6,2.6,0,0,0-.07,3.19,5.19,5.19,0,0,0,1.6,1.47l.47.29a4.55,4.55,0,0,0,.51.21,4.16,4.16,0,0,0,1,.31,5.21,5.21,0,0,0,4.1-.94,3.19,3.19,0,0,0,1.14-1.43,2.44,2.44,0,0,0,.13-1.63c-.1-.91-1.75-3.15-2.45-5.6a10.26,10.26,0,0,1-.46-4.21A8.37,8.37,0,0,1,43,64.57a12.32,12.32,0,0,1,6.41-4.65,11.72,11.72,0,0,1,4-.43,14.09,14.09,0,0,1,2,.28c.32.07.64.16,1,.26s.6.2,1,.39l-.65-.17c1.49.25,3,.5,4.48.77l-2.42,2.81c-.57-2.41-1-4.84-1.4-7.31a52.2,52.2,0,0,1-.6-7.55,51.84,51.84,0,0,1,3.54,6.7c1,2.28,1.91,4.59,2.72,6.93l1.07,3.09-3.49-.27c-1.51-.12-3-.27-4.52-.4l-.65-.18L55,64.73a5.59,5.59,0,0,0-.59-.11,8.11,8.11,0,0,0-1.2-.11,6.57,6.57,0,0,0-2.28.37,7.52,7.52,0,0,0-3.65,2.84,3,3,0,0,0-.69,1.65,5,5,0,0,0,.22,2.09,14.24,14.24,0,0,0,1,2.49,17.08,17.08,0,0,1,.76,1.54c.15.33.3.65.42,1s.18.74.26,1.11a4.86,4.86,0,0,1,.11.55l0,.59a7.75,7.75,0,0,1,0,1.18q-.09.59-.21,1.17a7.35,7.35,0,0,1-.4,1.11A8.29,8.29,0,0,1,46,85.67a9.71,9.71,0,0,1-3.84,1.72A10.21,10.21,0,0,1,36,86.92c-.32-.14-.65-.25-1-.41L34.16,86a9.87,9.87,0,0,1-3-2.85,7.62,7.62,0,0,1-1.41-4.44,8.22,8.22,0,0,1,1.38-4.34l2.22-3.49,2.12,3.65a6.43,6.43,0,0,0,1,1.33c.34.36.64.38.44.35a.85.85,0,0,0-.52.19.53.53,0,0,0-.19.25.93.93,0,0,0,0-.24,2,2,0,0,0-.15-.54,23.94,23.94,0,0,0-2.13-3.77,34.5,34.5,0,0,1-2.54-4.57A16.34,16.34,0,0,1,30,62a9.25,9.25,0,0,1,1.4-5.91,6.14,6.14,0,0,1,1.06-1.21A5.28,5.28,0,0,1,33.75,54a5.65,5.65,0,0,1,1.35-.62A6.44,6.44,0,0,1,36.49,53,10.79,10.79,0,0,1,41.72,53.55Z" />
              <path
                className={hairColour}
                d="M41.72,53.55c-11.33,0-10.44,8.22-8.25,12.92,1.74,3.73,5,7.16,5.25,9.66.22,2.19-1.47,3.33-3.33,2.09a8.37,8.37,0,0,1-2.17-2.5c-2,3.08-.75,6,1.92,7.91,5.92,4.26,14.55-1,10.91-7.58-1.66-3-3.25-6.58-1-9.75a9.51,9.51,0,0,1,11.42-3.67l4.5.59L56.84,49"
              />
              <path d="M80.39,49.63l-4.52-.35a19.75,19.75,0,0,0-4,.09c-.29.06-.56.13-.79.2a4.43,4.43,0,0,0-.65.33,6.59,6.59,0,0,0-1.42,1,5.39,5.39,0,0,0-1.69,2.7,6.82,6.82,0,0,0,0,3.27,2.47,2.47,0,0,0,1.36,1.8,4.47,4.47,0,0,0,2.77.23,7.47,7.47,0,0,0,3-1.34l0,0a2.52,2.52,0,0,1,4,2,7.38,7.38,0,0,1,0,.86c0,.25-.05.5-.08.74A11.8,11.8,0,0,1,78,62.49a8.94,8.94,0,0,1-3.5,4.87,10.69,10.69,0,0,1-2.62,1.27,18.67,18.67,0,0,1-5,.85A22.15,22.15,0,0,1,62,69.11a16.92,16.92,0,0,1-9.09-4.66A14.35,14.35,0,0,1,50,59.93a16.83,16.83,0,0,1-1.1-5.06l.17.78a27.33,27.33,0,0,1-1-3.12,29.78,29.78,0,0,1-.59-3.13,21.63,21.63,0,0,1,0-6.57,15.64,15.64,0,0,1,1-3.34,14.28,14.28,0,0,1,1.75-3.07A16.5,16.5,0,0,1,55.41,32a16,16,0,0,1,13-1.26,16.72,16.72,0,0,1,5.48,3.11,18.33,18.33,0,0,1,4,4.58c-3.51-1.9-6.94-3.48-10.38-4a13.46,13.46,0,0,0-9.48,1.81,12.21,12.21,0,0,0-3.44,3.27,10.16,10.16,0,0,0-1.69,4.24,18.66,18.66,0,0,0-.07,5,25.75,25.75,0,0,0,.44,2.58,21.28,21.28,0,0,0,.7,2.45l0,.11a2.65,2.65,0,0,1,.13.67A9.89,9.89,0,0,0,56.53,61a11.39,11.39,0,0,0,6.34,3.2,15.84,15.84,0,0,0,7.46-.34,6,6,0,0,0,1.32-.64,4.27,4.27,0,0,0,.93-.92A4.42,4.42,0,0,0,73.16,61a6.2,6.2,0,0,0,.16-.72c0-.12,0-.24,0-.36s0-.27,0-.23l4,1.93a12.66,12.66,0,0,1-5.12,2.17A9.25,9.25,0,0,1,66.33,63a6.91,6.91,0,0,1-2.49-2.23,7.78,7.78,0,0,1-1.19-3,10.89,10.89,0,0,1,.41-5.56,9.81,9.81,0,0,1,5.72-5.88,6.35,6.35,0,0,1,1.45-.42,7.82,7.82,0,0,1,1.43-.08,12.41,12.41,0,0,1,4.79,1.26A15.94,15.94,0,0,1,80.39,49.63Z" />
              <path
                className={hairColour}
                d="M80.39,49.63s-7-3.11-10.5-1.66A8,8,0,0,0,65,57.38c1,4.66,6.69,5.27,10.94,2.21a6.78,6.78,0,0,1-4.27,6.46c-5.71,2.24-19.42,1.17-20.17-11.33,0,0-3.58-9.25.25-15.75s14.75-11.34,26.08-.59"
              />
              <path d="M49.39,55.47a26.53,26.53,0,0,1,.66-5.53,15.9,15.9,0,0,1,2.28-5.14,10.37,10.37,0,0,1,4.23-3.68A8.9,8.9,0,0,1,62,40.47,11,11,0,0,0,57,42a10,10,0,0,0-3.47,3.61,15.89,15.89,0,0,0-1.73,4.76,29.19,29.19,0,0,0-.42,5.13Z" />
              <path d="M21.87,60.84a9.67,9.67,0,0,1-.51-4.18,9.46,9.46,0,0,1,1.42-4,12.18,12.18,0,0,1,2.88-3.14,15.94,15.94,0,0,1,3.62-2.13c-1.07.9-2.14,1.76-3.1,2.71a15.94,15.94,0,0,0-2.55,3.09,11.1,11.1,0,0,0-1.51,3.64A16.05,16.05,0,0,0,21.87,60.84Z" />
              <path d="M75.75,30.89a14.39,14.39,0,0,0-4.49-4.29,16.23,16.23,0,0,0-6.07-2.09,16.6,16.6,0,0,1,6.52,1.2,15.85,15.85,0,0,1,5.59,3.92Z" />
              <path d="M78.87,43.78a6.85,6.85,0,0,0-2.45-2.63,10.35,10.35,0,0,0-3.64-1.36,10.9,10.9,0,0,1,4.06.45,8.45,8.45,0,0,1,3.64,2.35Z" />
              <path d="M63.17,68.88a10.72,10.72,0,0,1-4.65-3.49,27.32,27.32,0,0,1-3-4.61,26.43,26.43,0,0,0,3.7,3.91,9.19,9.19,0,0,0,4.42,2.24Z" />
              <path d="M70.05,61.28a7.69,7.69,0,0,1-2.49-.6,6.32,6.32,0,0,1-2.06-1.44,9.93,9.93,0,0,1-2.16-4.15,9.39,9.39,0,0,0,2.81,3.39,5.37,5.37,0,0,0,3.86.8Z" />
              <path d="M75,25.61A11,11,0,0,0,72,23.56a10.45,10.45,0,0,0-3.56-1.09,10.55,10.55,0,0,1,3.82.39,11.82,11.82,0,0,1,3.65,1.61Z" />
              <path d="M46.73,19.57A11.08,11.08,0,0,1,44.38,23a9.63,9.63,0,0,1-3.47,2.25,10,10,0,0,1-2,.51,8.39,8.39,0,0,1-1,.09,3.92,3.92,0,0,1-3.13-1.4,4,4,0,0,1-.89-2.1,7.52,7.52,0,0,1,3.82-6.85,5.28,5.28,0,0,1,1.91-.74,2.67,2.67,0,0,1,2,.47,1.94,1.94,0,0,1,.86,1.84,1.87,1.87,0,0,1-1.09,1.55,1.72,1.72,0,0,0,.74-1.56,1.49,1.49,0,0,0-.84-1.31,3,3,0,0,0-3.09.57,8.08,8.08,0,0,0-2.3,2.61,5.52,5.52,0,0,0-.62,3.21,2.32,2.32,0,0,0,1.82,2,2.12,2.12,0,0,0,.71.06,5.16,5.16,0,0,0,.82-.11,7.55,7.55,0,0,0,1.62-.48A8.37,8.37,0,0,0,43,21.64a9.25,9.25,0,0,0,1.85-2.78Z" />
              <path d="M27.42,54.66c-.45,1.66-1,3.19-1.39,4.76a45.75,45.75,0,0,0-.91,4.85,9.18,9.18,0,0,1-.05-5.09A10.83,10.83,0,0,1,27.42,54.66Z" />
              <path d="M38.67,56.38a8,8,0,0,0-2.74,5.1,11.51,11.51,0,0,0,.15,2.92c.1.49.23,1,.34,1.48s.31,1,.42,1.5c-.31-.41-.59-.86-.88-1.31a14.77,14.77,0,0,1-.63-1.47,8,8,0,0,1-.39-3.23,6.38,6.38,0,0,1,1.2-3.06A5.44,5.44,0,0,1,38.67,56.38Z" />
              <path d="M51.59,22.88a10.07,10.07,0,0,0-3.47,3.91c-.8,1.55-1.36,3.24-2.12,4.94a15.26,15.26,0,0,1-1.4,2.48,7.87,7.87,0,0,1-1,1.11l-.54.51c-.19.15-.42.27-.62.4a8.14,8.14,0,0,1-5.5,1,10.93,10.93,0,0,0,4.82-1.94,10.88,10.88,0,0,0,2.85-4.17,32.51,32.51,0,0,1,2.48-4.93A7.76,7.76,0,0,1,51.59,22.88Z" />
              <path d="M46,39.53a9.12,9.12,0,0,1-4,2.15,9.71,9.71,0,0,1-4.64,0,11.78,11.78,0,0,1-4.16-2,11.9,11.9,0,0,1-3.07-3.3,41,41,0,0,0,3.7,2.31,16.2,16.2,0,0,0,3.88,1.52,12.26,12.26,0,0,0,4.1.32A18,18,0,0,0,46,39.53Z" />
              <path d="M38.24,74.59A20.3,20.3,0,0,1,39.69,78a12.1,12.1,0,0,1,.68,3.75,4.88,4.88,0,0,1-.39,2,2.91,2.91,0,0,1-1.6,1.54,3.86,3.86,0,0,1-2,.17A6.73,6.73,0,0,1,34.66,85a6.76,6.76,0,0,0,3.18-.72,2.21,2.21,0,0,0,.72-2.39,12.51,12.51,0,0,0-1-3.06,31.61,31.61,0,0,0-1.51-3Z" />
              <path d="M40,68.63a3.75,3.75,0,0,1-1.15-1,4.63,4.63,0,0,1-.74-1.32,3.56,3.56,0,0,1-.21-1.56,2.06,2.06,0,0,1,.72-1.39,5,5,0,0,0,.06,1.37A8,8,0,0,0,39,66C39.3,66.83,39.69,67.64,40,68.63Z" />
              <path d="M81,50.7a42.07,42.07,0,0,0-1.44-5.34c-.29-.84-.65-1.69-.94-2.61A15.55,15.55,0,0,1,78,39.88c-.14-1-.21-1.93-.35-2.75a2.94,2.94,0,0,0-.11-.45L77.2,36l-.56-1.31a22,22,0,0,1-1.51-5.63c-.14-1-.21-1.9-.28-2.85l0-.71,0-.35a.21.21,0,0,1,0-.06l0-.11a11.8,11.8,0,0,0-.41-1.23l-1-2.6a13,13,0,0,1-.46-1.37c-.14-.4-.3-.8-.48-1.19a20.89,20.89,0,0,0-7.23-7.87l.52-.88a21.1,21.1,0,0,1,4.64,3.39,18.66,18.66,0,0,1,3.6,4.59,12.32,12.32,0,0,1,.62,1.38A11.63,11.63,0,0,0,75,20.51l1.08,2.6a13.76,13.76,0,0,1,.5,1.42l.06.23,0,.29,0,.34.07.69c.09.92.22,1.82.35,2.72A22.53,22.53,0,0,0,78.57,34l.52,1.28.27.64a3.79,3.79,0,0,1,.24.9c.16,1,.21,1.92.34,2.81a21.09,21.09,0,0,0,.55,2.6c.24.87.5,1.79.68,2.75A13,13,0,0,1,81,50.7Z" />
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M73.05,66.47c-6.07.44-17.77-.62-15-12a7.38,7.38,0,0,0-2.83,1.75c1.2-5.08,2.36-9.31,6.19-13.27a11.65,11.65,0,0,0-5.07.87c1.34-3.67,6-6.37,10.57-8-2.13-.81-6.79-.74-8.52.31.66-3.08,1.78-3.81,4.25-5.75,0,0-9.42,2-11.92,7.42C49.63,40.17,44.3,67,66.39,68.13A16.66,16.66,0,0,0,73.05,66.47Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M57.72,8.38l-4.33,3.17a25.44,25.44,0,0,1,6.91.83L56,14.55c-3.92,2.5-5.58,4.42-7,7.5A11.65,11.65,0,0,1,55.3,20.3S47,26.72,46.14,29.47s3.16.5,3.16.5-3.5,8.08-6,8.83a16.24,16.24,0,0,0,7.42-1s-2.58,6.67-2.33,9.25S52.72,63.3,52.72,63.3L46.47,66a21.11,21.11,0,0,1,1-6.5,14.79,14.79,0,0,0-4.22,1.09,14.12,14.12,0,0,1,.52-4.93c-2.95,2.14-5.11,3.65-5.94,7-.62-3.62-1-4.83,1.25-7.18,0,0-5.22-.81-6.64,1.43L33,52.13a8.57,8.57,0,0,0-4.63,3.38,13.76,13.76,0,0,1,2-7.46c-5.58,3.75-8,11.25-7.58,16.67a12.36,12.36,0,0,1,4.17-3.84c-2.84,7.09,2.15,14-.75,17.84-3.9,5.19-2.42,3.16-2.5,4.66a19.87,19.87,0,0,1-.59,3.34s-4.41.83-6.91-.75S9.55,80.8,9.55,76.3a27.92,27.92,0,0,1,1.17-8S3.3,59.13,4.05,52.47,5.3,39.22,14.8,40.88c0,0,.42-10.83,3.09-13.25a45.48,45.48,0,0,1,5.41-4.16s2.17-10,11.75-11.67,9.75.67,9.75.67Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M81,51.42a12.5,12.5,0,0,0-.16-2.85c0-1.23-3-13.31-3.25-14.31-1.31-4.75,0-7.72-3-13.38-.71-1.34-6.43-8.12-6.43-9.62s3-1.61,4.48-1.61a23.43,23.43,0,0,1,9,2.27l-3.45.71L83,15.72a8.63,8.63,0,0,0-2.69.66,19.72,19.72,0,0,1,3.27,2c-1.68.82-1.68,2-2.64,2.94l5.87,1C84.29,23.94,82,25.65,80.59,28a20,20,0,0,1,3.88-.19A5.66,5.66,0,0,0,82,30.26,12.08,12.08,0,0,1,93,31.2l-5.56,2.18-3.32,4.07a10.17,10.17,0,0,1,5.82-.38c-2.48,1-4.52,2.19-5.63,4,2.44-.11,5.75-.37,7.25.75A29.63,29.63,0,0,0,84.66,46a13.37,13.37,0,0,1,4.81,1.93Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M93.32,15.28c1,1.06,3,2.89,3.52,4.13a4.64,4.64,0,0,0-2.79-.28,4.14,4.14,0,0,1-2.58,2.94l-6.63-8.19A11,11,0,0,1,93.32,15.28Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M93.22,58.88a12.27,12.27,0,0,0,6.94-2.06,8,8,0,0,1-2.32,4.38c4.22-.49,7.14-3.42,9.32-7.75,0,0,.31,12.09-9.07,13.37C83,68.88,85.53,58.51,85.53,58.51Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M39.34,78.2a10.19,10.19,0,0,0,2.57-1.88,14.13,14.13,0,0,1,0,6.25,5.87,5.87,0,0,0,2.75-1.06,8.12,8.12,0,0,1-.5,3.56s-6.82,3.25-10.25-.87S33.41,73,33.41,73,36.47,79,39.34,78.2Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M30.66,77.7A14.32,14.32,0,0,0,36.59,86l.32,2.19-4-1.63a9.85,9.85,0,0,0,3.5,5.5c-3-.77-5.1-1.23-7.5-3.31a21.62,21.62,0,0,0,11.74,11.41L28.78,95.88s-.62,4.38-2.87,4.32-8.32-2.13-9.57-3.82-3.12-4.31-3.25-4.68.25-6.94.25-6.94l10.25,2.06S21.47,78.76,30.66,77.7Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M44.91,101.57,45,99a8.54,8.54,0,0,1,2.69,2.62l2.5-2.43s-1.63,9.31-11.63,9.87c-5.3.3-8-2.37-7.93-4.75s2.25-5.19,2.25-5.19C37.84,99.2,41.78,99.82,44.91,101.57Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M80.81,49.24a22.55,22.55,0,0,0-6.61-4.33l3.14-.52c-.71-1.8-3.27-3.54-6.46-5.08,2.47-.35,5.18-1,7.5-.19Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M74.16,21.64c-2-.76-5.38,0-7.89.67A12.89,12.89,0,0,1,72,24.82c-2.13-.56-4.24-1.13-9.4-.52,6.12,1.78,9.83,4.39,12.2,7.46a7.16,7.16,0,0,0-3.66.61l7.46,6.27Z"
                />
              </g>
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M73.44,20.41l-9.07-5.18,3.9-.76L65.13,12.9l2.24-1s5.18,3.59,6.53,8.19"
                />
              </g>
              <path
                className={hairColour}
                d="M39.37,27.34c-5.78.29-8.69-4.41-4.63-10.72-1.73,1.51-4.56,4.31-3.35,8.06a5.18,5.18,0,0,1-2.3-1.84c-.49,3.35,1.82,9,5.38,9.42,5,.55,10.56-5.74,10.56-5.74a12.63,12.63,0,0,1-7.85,2.11A8.53,8.53,0,0,0,39.37,27.34Z"
              />
              <path
                className={hairColour}
                d="M45.55,42.93c-5.11,1-10.07.21-15.37-3.84,1.26,3,5.83,6.84,9.5,7.29l-2.13-2A9.8,9.8,0,0,0,45.55,42.93Z"
              />
              <g className={cls5}>
                <path
                  className={cls6}
                  d="M104.42,40.61,98.76,35l4.59.67a5.19,5.19,0,0,0-.56-3.53,6.68,6.68,0,0,1,5,4.65L106.16,39S107.28,40.17,104.42,40.61Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M42,12.3a15.9,15.9,0,0,0-9.81,3.09,16.11,16.11,0,0,0-5.45,8.17,22.11,22.11,0,0,0-.52,10.09,35.72,35.72,0,0,0,3.53,10.07,21.85,21.85,0,0,1-5.81-9.53,19.76,19.76,0,0,1-.07-11.5,15.9,15.9,0,0,1,7-9.24A13.19,13.19,0,0,1,42,12.3Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M23.14,25.05c-.8,1.14-1.47,2.1-2,3.08a30.6,30.6,0,0,0-1.52,3c-.46,1-.89,2.06-1.36,3.16a26.38,26.38,0,0,1-1.68,3.44,9.42,9.42,0,0,1-.62-3.94,10.56,10.56,0,0,1,.93-3.91,9.2,9.2,0,0,1,2.5-3.28A6.45,6.45,0,0,1,23.14,25.05Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M14.86,42.53a7.29,7.29,0,0,0-3.85.15,3.88,3.88,0,0,0-1.44.89,5.4,5.4,0,0,0-1,1.34,14.31,14.31,0,0,0-1.33,7.14,30.2,30.2,0,0,0,1.26,7.6,40.25,40.25,0,0,0,3.08,7.44,17.73,17.73,0,0,1-5.28-6.58,21,21,0,0,1-2.06-8.38,16.94,16.94,0,0,1,.44-4.41,10.92,10.92,0,0,1,2-4.15,6,6,0,0,1,4.1-2.33A6.16,6.16,0,0,1,14.86,42.53Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M17.39,66.18A6.13,6.13,0,0,0,13,70a11.38,11.38,0,0,0-.79,5.68,11,11,0,0,0,2.06,5.35,12.88,12.88,0,0,0,2.09,2.23,25.91,25.91,0,0,0,2.71,1.9,10,10,0,0,1-3.44-.62A10.1,10.1,0,0,1,9.23,76,10.64,10.64,0,0,1,11,68.85a7.36,7.36,0,0,1,2.86-2.45A4.88,4.88,0,0,1,17.39,66.18Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M14.79,85.05A18.61,18.61,0,0,0,15.48,90a8.78,8.78,0,0,0,2.38,3.58,21.34,21.34,0,0,0,3.83,2.75c1.42.87,2.94,1.68,4.47,2.71a14.36,14.36,0,0,1-5.41-.54,12.1,12.1,0,0,1-4.95-2.74,7.86,7.86,0,0,1-1-10.69Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M30.09,78.25A16.93,16.93,0,0,0,27,81.85a6.67,6.67,0,0,0-.8,3.8,19.25,19.25,0,0,0,1,4.29c.51,1.5,1.12,3,1.59,4.73A13.46,13.46,0,0,1,25.13,91a11,11,0,0,1-1.9-5.12,7,7,0,0,1,1.95-5.49A7.6,7.6,0,0,1,30.09,78.25Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M35.77,99.16c-.6,1.18-1.22,2.2-1.62,3.14a3.36,3.36,0,0,0-.32,1.2.91.91,0,0,0,.18.63,3.66,3.66,0,0,0,.77.78c.38.27.81.56,1.29.84a32.28,32.28,0,0,1,3.07,2,8,8,0,0,1-3.86.25,7.24,7.24,0,0,1-2-.62,4.86,4.86,0,0,1-1.84-1.62,3.7,3.7,0,0,1-.51-1.37,3.52,3.52,0,0,1,.08-1.42,4.49,4.49,0,0,1,1.2-2A6.28,6.28,0,0,1,35.77,99.16Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M58,33.26a19.49,19.49,0,0,0-4.89,6.83A20.44,20.44,0,0,0,51.53,48a27.87,27.87,0,0,0,1.23,8.09A45.85,45.85,0,0,0,56.05,64a20.11,20.11,0,0,1-5.49-7.09,20.81,20.81,0,0,1-2-9A17.38,17.38,0,0,1,51,39,13.42,13.42,0,0,1,58,33.26Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M26.44,43.72a14,14,0,0,0-4.7,5.79,19.47,19.47,0,0,0-1.27,7.13,68.3,68.3,0,0,0,.43,7.62,71,71,0,0,1,.56,7.95,45.21,45.21,0,0,1-2.87-7.51,28.85,28.85,0,0,1-1.12-8.12,15.75,15.75,0,0,1,2.2-8.17,10.81,10.81,0,0,1,3-3.1A10.09,10.09,0,0,1,26.44,43.72Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M22.65,33.26A37.36,37.36,0,0,0,19.48,38a15,15,0,0,0-1,2.38A23.59,23.59,0,0,0,17.81,43a32.56,32.56,0,0,1-1.47,5.72,8.86,8.86,0,0,1-1.62,2.75A4.53,4.53,0,0,1,12,53c1.47-1.44,1.79-3.14,2.08-4.94s.4-3.7.82-5.68a14.42,14.42,0,0,1,.93-3,10.87,10.87,0,0,1,1.77-2.7A11.81,11.81,0,0,1,22.65,33.26Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M51.47,10.34a5.37,5.37,0,0,1-1,1.9,6,6,0,0,1-1.28,1.2,6.38,6.38,0,0,1-1.52.77,9.35,9.35,0,0,1-2,.43,3.4,3.4,0,0,1,.48-2.21A4.57,4.57,0,0,1,47.49,11a4.81,4.81,0,0,1,1.79-.75A4.38,4.38,0,0,1,51.47,10.34Z"
                />
              </g>
              <g className={cls12}>
                <path
                  className={cls13}
                  d="M33.5,74.82a8.85,8.85,0,0,0,0,3.6,7.44,7.44,0,0,0,1.17,2.75,9,9,0,0,0,2.25,2.12c.92.7,2,1.21,3.25,2a7.07,7.07,0,0,1-4.2.15,6.67,6.67,0,0,1-3.75-2.55,6.26,6.26,0,0,1-1.07-4.49A4.72,4.72,0,0,1,33.5,74.82Z"
                />
              </g>
              <path
                className={cls14}
                d="M99.13,36c3.32,3.47,4.52,8.18,1.67,7-.71,1.49-1.94,2.14-4.38.81a28.44,28.44,0,0,1-1.2,7.27c.84-2.11,2-3.75,3.68-4.32,1.33-1.14,3-.13,4.91,2.31-.15-1.92,1-2.12,3-1C106.81,48.08,103.12,37.74,99.13,36Z"
              />
              <path
                className={cls15}
                d="M73.19,48.41C71,48.26,68.93,48,67.69,46c-1.35,1.67-2.82,2.91-4.74,2.43-2,1.26-3.73,1.62-4.4-1.65L56.31,52c3.18-2.32,4.67-1.34,4.88,2.13,1.37-2.89,3.09-3.89,5.39-1.69l-.84,4.07S66.91,47.35,73.19,48.41Z"
              />
              <path
                className={cls16}
                d="M73.05,20.24l.4,1.17a18.82,18.82,0,0,0-7.18.9A13.77,13.77,0,0,1,72,24.82a23.27,23.27,0,0,0-9.4-.52A25.89,25.89,0,0,1,72,29c-4.88-2.48-7.39-3.47-11.36-2.91-2.6-.93-6.95-.76-6.85,2.32-1.32-1.42-3.2-1.29-5.29-.58,2.31-1.17,4-2.73,4.63-5,3,.51,4.94-.09,5.5-2.17,2.68,1.92,3.79,1.9,3.54.21,2.61.37,3.66-.38,3.41-2A12.12,12.12,0,0,0,73.05,20.24Z"
              />
              <path
                className={cls17}
                d="M85,14.76v2.62a4.21,4.21,0,0,1-3.19-.25l1.77,1.25-2.64,2.94c3.06-1.77,6-2.22,8.93-.94Z"
              />
              <path
                className={cls18}
                d="M101.22,20c.38,1.5-1,2.1-4.92,1.37,4.59,3.43,2.12,3.93.13,3.05-1.27,1.51-5,2-8.34,2.64,4.61-.21,9.33-.79,12.88,2.13.27-1.23,1.13-.72,2.33.81-2-5.12-.28-4.34,4.48,1.44C107.78,31.45,104.34,23.51,101.22,20Z"
              />
              <path
                className={cls19}
                d="M109.47,38.63c1.55,2.87,1.66,4.23-1.21,2.46.47,1.74-.45,2.23-2.35,1.86l1.56,4.18c.06-.73,2-6.5,2.75-1,1.53-3.14,2-1.54,2.44,2.2C112.66,48.38,116.41,43.26,109.47,38.63Z"
              />
              <path
                className={cls20}
                d="M45.39,67.72c-2.6,1.41-3.56.8-1.28-3.88-2.17,2-4,2.26-5.45,1.19-1.81,2-3.26,1.09-4.44-2l-1.75,2.42,2.17,4.41c3.3-2,5.64-1.19,6.69,3.27,1.09-3.06,2.8-2.11,4.77.54Z"
              />
              <path
                className={cls21}
                d="M42.8,90.72c.36,1.71-.46,2.38-3.38,1.2,4.33,4.32,4,5.34-.76,3.44,0,1.36-.81,2.16-3.77,1.44l7.91,4.42c-.79-2.58.54-3,3.11-2-1-4,.07-4.58,3-2C48.89,97.22,51.05,94.8,42.8,90.72Z"
              />
              <path
                className={cls22}
                d="M75.94,62.7,71,64l-2.64-1.67-5.16.12,5.16-2.15a9,9,0,0,0,7.8-2.11S77.89,61,75.94,62.7Z"
              />
              <g className={cls23}>
                <g className={cls24}>
                  <path
                    className={cls25}
                    d="M59.8,3.72c-10,9.57-6.74-2.48-17.83,26.16,9.93-6.2-1.34-.61-3,12.67a3.37,3.37,0,0,0,1.52.35c1.39,0,2.77-.7,4.16-.7l.32,0c-1.22,1.48-2.78,3.31-2.78,5.27,0,2.68,1.52,1.93,6.28,5.89.13.26.66.62.66.77,0,.34-11.43-1.19-17.16-4.77C19.47,56,23.21,69.92,23.21,75.62c0,9.64,3.77,3.16,7.43,1.6a0,0,0,0,1,0,0c.05,0,.08.14.08.31a1,1,0,0,1-.12.54c-2.16,2.66-2.36,12.26-2.36,13.26,0,3.11,1.4,6,1.4,8.4,0,3.44-12.16,5-15.47,5C4.5,104.66,5.47,91.22,1,66.88-.48,59-.47,50.28,2.14,42.88c10.5-29.83,18-38.32,43.16-40.66,4.51-.42,9-2.58,13.5-2.17"
                  />
                </g>
                <path
                  className={cls26}
                  d="M86.34,13c16.37.93,18.68,12.75,19.13,13.75.86,1.94,3,0,3,7.11s.08,1.59,4.59,6.51a4.45,4.45,0,0,1,1,2.9c0,2.18-2.53,8-6.28,9.35a8.91,8.91,0,0,0-.37,3.13c0,5.26-4.72,11.74-14,11.74-4,0-5.31-1.1-7.34-1.1-.1,0-7.89,2.68-15.28,2.68-29.21,0-16.88-21.24-26.26-23.7-8.72-2.28-23-3.09-23-16.86,0-6.85,5.74-18.6,17.13-18.6,2.12,0,4.2.39,6.33.39,5,0,9.3-2.44,15.33-2.44a16.91,16.91,0,0,1,8.7,2.54,22.38,22.38,0,0,1,5.46-.78,13.67,13.67,0,0,1,7.15,1.88A9.85,9.85,0,0,0,86.34,13Z"
                />
                <path
                  className={cls27}
                  d="M49.34,50.76a10.2,10.2,0,0,1,2.77,7c0,7-6.37,6.95-6.37,12.41,0,2.2,2.37,4.94,2.37,7.95,0,3.23-3.56,8.6-9.42,8.6-4.65,0-6-2.86-10.76-2.86-1.73,0-3.42.18-5.13.18-9.69,0-11.94-8.81-11.94-18.52,0-13.79,8.9-22.45,18.91-22.45,8.9,0,13.12,3.26,19.32,7.05C49.22,50.26,49.22,50.63,49.34,50.76Z"
                />
                <path
                  className={cls28}
                  d="M42,80.88a19.76,19.76,0,0,0-.51,4c0,9,9.79,5.38,9.79,14.31,0,5.8-6.52,8.75-11.75,8.75-4.11,0-5.65-1.91-5.65-5.36,0-6.11-7.84-6-7.84-13.09,0-4.83,7.29-10.45,11.86-10.45A5.32,5.32,0,0,1,42,80.88Z"
                />
              </g>
              <line
                className={cls11}
                x1="87.55"
                y1="44.02"
                x2="87.03"
                y2="44.3"
              />
              <line
                className={cls11}
                x1="100.79"
                y1="42.89"
                x2="99.78"
                y2="43.18"
              />
              <path
                className={cls11}
                d="M38.69,31.09a4.57,4.57,0,0,1-2.44.79"
              />
              <path
                className={cls11}
                d="M59.58,25.63a2.21,2.21,0,0,0-.91-.25"
              />
              <path className={cls11} d="M67.34,45.64a2.26,2.26,0,0,1-.67.43" />
              <path
                className={cls11}
                d="M64.5,65.69c-.71-.09-1.37-.39-2.07-.55"
              />
              <path
                className={cls11}
                d="M26.66,70.53c-.06.65.26,1.25.25,1.89"
              />
              <line
                className={cls11}
                x1="12.41"
                y1="58.2"
                x2="12.35"
                y2="58.2"
              />
              <line
                className={cls11}
                x1="95.94"
                y1="26.3"
                x2="93.5"
                y2="26.3"
              />
              <path className={cls11} d="M81.35,11.55a7,7,0,0,0-2.76.49" />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}
