import React from "react"
import Button from "react-bootstrap/Button"

import { NoArgVoid } from "@components/types"

import { MR_2, MB_1 } from "@helpers/constants/style"

interface Props {
  onClick: NoArgVoid
  children: string
}

export const AvatarButton = ({ onClick, children }: Props) => {
  return (
    <Button className={`${MR_2} ${MB_1}`} onClick={onClick}>
      {children}
    </Button>
  )
}
