import { classnames } from "classnames-joiner"
import React from "react"

import { Img } from "@components/Img"

import { W_100, D_BLOCK } from "@helpers/constants/style"

import logoSrc from "@images/educats-logo-sm.png"

import { logo } from "./l.module.scss"

export const LogoImg = () => {
  return (
    <Img
      alt="EduCats"
      src={logoSrc}
      className={classnames([logo, W_100, D_BLOCK])}
    />
  )
}
