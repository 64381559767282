import React from "react"

import { mainQuestion } from "@views/english-demo-1/common/constants"

export const question = (
  <div>
    <h2 className="h4">Mark your own response</h2>
    <p>
      <i>{mainQuestion} (4 Marks)</i>
    </p>
    <h3 className="h5 mb-2">Marking criteria:</h3>
    <ul>
      <li>
        A clear thesis which directly addresses the question, and interprets the
        idea of ‘new experiences’ (1 mark)
      </li>
      <li>
        First piece of evidence clearly supports written thesis and includes a
        quote, technique and effect (1 mark)
      </li>
      <li>
        Second piece of evidence clearly supports written thesis and includes a
        quote, technique and effect (1 mark)
      </li>
      <li>
        Third piece of evidence clearly supports written thesis and includes a
        quote, technique and effect (1 mark)
      </li>
    </ul>
  </div>
)

export const sampleAnswer =
  "The poem portrays the endless possibilities of new experiences. The structure of the poem darkens with each stanza. Stanza 1 starts positively as seen in the line, “or a magic city”. Stanza 2 shows neutral ‘or the picture of the picture’. Stanza 3 shows the imagery beginning to darken, with ‘a fog’. Stanza 4 climaxes the darkness with ‘the darkness ticking’. Because each stanza begins with ‘go and open the door’, which is symbolic of taking an opportunity, the poem goes through all of the possibilities that may occur from taking an opportunity. From positive to negative. The conclusion encourages the reader to take a risk. The line ‘At least there will be a draught’ uses the positive metaphor of ‘the draught’ to convey that despite the full spectrum of possibilities from taking an opportunity, it is still worthwhile to take them."
