import { setUIActionCreator } from "@redux/ducks/ui"
import { selectOptionsWereSelected } from "@redux/selectors"

import { SetOptionWasSelectedType } from "../types"

export const setOptionWasSelected = ({
  dispatch,
  rootState,
  optionKey,
}: SetOptionWasSelectedType) => {
  dispatch(
    setUIActionCreator({
      problemMultipleChoice: {
        optionsWereSelected: {
          ...selectOptionsWereSelected(rootState),
          [optionKey]: true,
        },
        lastSelectedKey: optionKey,
      },
    })
  )
}
