import React from "react"

import { BaseNavbar } from "@components/navbar"

import { useFetchUserAndSetIntoState } from "@helpers/auth"

import { SignInOrOutButton } from "./sign-in-or-out-button"

export const AppNavbar = () => {
  useFetchUserAndSetIntoState()

  return (
    <BaseNavbar>
      {/* <NavLink to={LINK_BLOG} label="Blog" /> */}
      {/* <NavLink to={LINK_APP} label="App" /> */}
      <SignInOrOutButton />
    </BaseNavbar>
  )
}
