import React, { useState, useEffect } from "react"
import Button from "react-bootstrap/Button"
import { useDispatch } from "react-redux"

import { setModalActionCreator } from "@redux/ducks/ui/modal"
import { setUserSyncedActionCreator } from "@redux/ducks/user-synced"
import { savePhysicsUserSyncedThunkCreator } from "@redux/ducks/user-synced/async"
import { USER_STATUS_OK } from "@redux/ducks/user-synced/user-status"

export const RestingModalFooter = () => {
  const dispatch = useDispatch()
  const [hasRecovered, setHasRecovered] = useState(false)
  const [time, setTime] = useState(20)

  useEffect(() => {
    if (!hasRecovered) {
      const intervalId = setInterval(() => {
        if (time > 0) {
          setTime(time - 1)
        } else {
          setHasRecovered(true)
          clearInterval(intervalId)
        }
      }, 1000)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [hasRecovered, time])

  const onRecover = () => {
    dispatch(
      setUserSyncedActionCreator({
        userStatus: USER_STATUS_OK,
      })
    )

    dispatch(savePhysicsUserSyncedThunkCreator())

    dispatch(
      setModalActionCreator({
        modalId: undefined,
      })
    )
  }

  if (hasRecovered) {
    return <Button onClick={onRecover}>Try answering again</Button>
  }

  return (
    <div>
      <strong>Countdown: </strong>
      {`⏰...${time}s`}
    </div>
  )
}
