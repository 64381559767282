import { setMiscActionCreator } from "@redux/ducks/misc"
import { PAYMENT_KEY, PaymentDataType } from "@redux/ducks/misc/payment"
import { selectPayment } from "@redux/ducks/misc/payment"
import { selectUsername } from "@redux/selectors"
import { DispatchType, GetStateType } from "@redux/types"

import {
  META_STATUS_LOADING,
  META_STATUS_SUCCESS,
  META_STATUS_ERROR,
} from "@helpers/hooks/use-meta-status/constants"
import { USER_SETTINGS_PAYMENT } from "@helpers/hooks/use-user-settings-data-meta-state/constants"
import { getUserRemoteData, saveProgress } from "@helpers/user-data"
import { getSettingsUserTableItemId } from "@helpers/user-data/get-user-table-item-id"

export const getPaymentThunkCreator =
  (username: string) =>
  async (dispatch: DispatchType, getState: GetStateType) => {
    const rootState = getState()

    const remoteId = getSettingsUserTableItemId(username)
    const payment = selectPayment(rootState)

    dispatch(
      setMiscActionCreator({
        [PAYMENT_KEY]: {
          ...payment,
          status: META_STATUS_LOADING,
        },
      })
    )

    const { value: paymentDataFromRemote, error } = await getUserRemoteData({
      remoteId,
      remoteAttribute: USER_SETTINGS_PAYMENT.remoteAttribute,
    })

    console.log("getPaymentThunkCreator", { paymentDataFromRemote, error })

    if (error === undefined) {
      dispatch(
        setMiscActionCreator({
          [PAYMENT_KEY]: {
            data: paymentDataFromRemote,
            status: META_STATUS_SUCCESS,
          },
        })
      )
    } else {
      dispatch(
        setMiscActionCreator({
          [PAYMENT_KEY]: {
            data: paymentDataFromRemote,
            status: META_STATUS_ERROR,
          },
        })
      )
    }
  }

export const savePaymentThunkCreator =
  (paymentData: PaymentDataType) =>
  async (_: DispatchType, getState: GetStateType) => {
    const rootState = getState()
    const username = selectUsername(rootState)

    if (!username) {
      console.log("Not logged in")
      return
    }

    await saveProgress({
      remoteId: getSettingsUserTableItemId(username),
      remoteAttribute: USER_SETTINGS_PAYMENT.remoteAttribute,
      value: paymentData,
    })

    // Intentionally don't dispatch into state so that the user can see the success state of the checkout.
  }
