import React from "react"
import Col from "react-bootstrap/Col"

import ContentContainer from "@components/ContentContainer"
import { AppLayout } from "@components/layouts/app-layout"
import { RouterItem } from "@components/types"

export const SignOut = () => {
  return (
    <ContentContainer rowConstrained={true} sectionClassesExtra="section">
      <Col xs={12} className="mb-10">
        <h1>Thanks for visiting, you've been signed out</h1>
      </Col>
    </ContentContainer>
  )
}

export const SignOutView = (props: RouterItem) => {
  return (
    <AppLayout>
      <SignOut />
    </AppLayout>
  )
}
