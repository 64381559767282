export function capitaliseFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function isMinorWord(word: string) {
  if (word === "of" || word === "the" || word === "to") {
    return true
  }
  return false
}

export function capitaliseWords(
  sentence: string,
  capitaliseMinorWords: boolean = true
) {
  const wordsArr = sentence.split(" ")
  return wordsArr
    .map((word, index) => {
      if (!capitaliseMinorWords && index !== 0 && isMinorWord(word)) {
        return word
      } else {
        return capitaliseFirstLetter(word)
      }
    })
    .join(" ")
}

export function getDefaultAltFromSrc(src: string) {
  if (src[0] === "/") {
    src = src.slice(1)
  }

  const srcSplitByDot = src.split(".")
  srcSplitByDot.pop()

  if (srcSplitByDot.length === 1) {
    const filePath = srcSplitByDot[0].split("-").join(" ")

    const filePathSplitBySlash = filePath.split("/")
    const fileName = filePathSplitBySlash[filePathSplitBySlash.length - 1]
    return fileName
  }

  return src
}

export function simplePluralisation({
  singleName,
  isPlural,
}: {
  singleName: string
  isPlural: boolean
}) {
  if (isPlural) {
    return singleName + "s"
  }
  return singleName
}

export type SubjectList = string[]

export const toScreamingSnakeCase = (subjectList: SubjectList) => {
  let str = ""

  for (let i = 0; i < subjectList.length; i++) {
    str += subjectList[i].toUpperCase()

    if (i !== subjectList.length - 1) {
      str += "_"
    }
  }

  return str
}

export const toCamelCase = (
  subjectList: SubjectList,
  capitalCamelCase: boolean = false
) => {
  let str = ""

  for (let i = 0; i < subjectList.length; i++) {
    if (i === 0 && capitalCamelCase === false) {
      str += subjectList[i]
    } else {
      str += capitaliseFirstLetter(subjectList[i])
    }
  }

  return str
}
