import { classnames } from "classnames-joiner"
import React from "react"

import { OptionsWereSelectedType } from "@redux/ducks/ui/problem-multiple-choice"

import { NoArgVoid } from "@components/types"

import { HOVER_CLICKABLE } from "@helpers/constants/style"
import { D_FLEX, MB_3, MR_2, D_INLINE_BLOCK } from "@helpers/constants/style"
import { jsxJSONArrayProcessor } from "@helpers/jsxJSONProcessor"
import { JSXJSONType } from "@helpers/jsxJSONProcessor/types"

import { OptionLabel } from "./option-label"

interface Props {
  optionKey: string
  optionContent: JSXJSONType[]
  optionsWereSelected: OptionsWereSelectedType
  answer: string
  isUnclickable: boolean
  onClick?: NoArgVoid
}

export const Option = ({
  optionKey,
  optionContent,
  optionsWereSelected,
  answer,
  isUnclickable,
  onClick,
}: Props) => {
  const wasSelected = Boolean(optionsWereSelected?.[optionKey])

  return (
    <div>
      <div className={D_INLINE_BLOCK}>
        <div
          className={classnames([
            D_FLEX,
            MB_3,
            isUnclickable ? undefined : HOVER_CLICKABLE,
          ])}
          onClick={isUnclickable ? undefined : onClick}
        >
          <OptionLabel
            className={MR_2}
            optionKey={optionKey}
            wasSelected={wasSelected}
            answer={answer}
          />
          <div>{jsxJSONArrayProcessor(optionContent)}</div>
        </div>
      </div>
    </div>
  )
}
