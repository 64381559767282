import React from "react"

import { AppLayout } from "@components/layouts/app-layout"
import { SignedInRouterItem } from "@components/types"

import { signIn } from "@helpers/auth"
import { useFetchUserAndSetIntoState } from "@helpers/auth"

export const SignedInView = (props: SignedInRouterItem) => {
  return (
    <AppLayout>
      <SignedIn {...props} />
    </AppLayout>
  )
}

const RedirectToSignIn = () => {
  signIn()
  return null
}

export const SignedIn = ({ onRenderElement }: SignedInRouterItem) => {
  const { complete, username } = useFetchUserAndSetIntoState()

  console.log({ complete, username })

  if (!complete) {
    return null
  }

  if (username) {
    return onRenderElement({ username })
  }

  return <RedirectToSignIn />
}
