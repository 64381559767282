import React, { useState } from "react"
import Button from "react-bootstrap/Button"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { Input } from "@components/Form/Input"
import { Wrapper } from "@components/Form/types"

import { HasntAnsweredViewProps } from "./types"

const LabelWrapper = ({ children }: Wrapper) => <b>{children}</b>

export const HasntAnsweredView = ({
  onAnswer,
  inputProps,
}: HasntAnsweredViewProps) => {
  const [hasClicked, setHasClicked] = useState<boolean>(false)

  const hasAnswered = inputProps.value !== ""

  return (
    <>
      <Input LabelWrapper={LabelWrapper} {...inputProps} />
      <SpacedWrapper>
        <Button
          variant="success"
          className="mb-4"
          onClick={() => {
            setHasClicked(true)
            if (hasAnswered) {
              onAnswer()
            }
          }}
        >
          Answer
        </Button>
        {hasClicked && !hasAnswered ? (
          <small>A response is required</small>
        ) : null}
      </SpacedWrapper>
    </>
  )
}
