import React from "react"

import {
  DRUID_HAIR_STYLE,
  DWARF_HAIR_STYLE,
  BANDIT_HAIR_STYLE,
  SHOULDER_LENGTH_WAVY_HAIR_STYLE,
  FRINGE_STRAIGHT_LONG_HAIR_STYLE,
  PARTED_FRONT_PONYTAIL_HAIR_STYLE,
} from "@components/Avatar/constants/hair-style"
import { Wrapper } from "@components/Form/types"
import { Setter } from "@components/types"

import {
  HERO_CLASS_SCOUT,
  HERO_CLASS_TANK,
  HERO_CLASS_EMPATH,
  HERO_CLASS_MAKER,
} from "@helpers/constants/hero-class"

const defaultScoutHair = 4
const defaultTankHair = 1
const defaultMakerHair = 5
const defaultEmpathHair = 0

const defaultAvatarHairs = {
  [HERO_CLASS_SCOUT]: defaultScoutHair,
  [HERO_CLASS_TANK]: defaultTankHair,
  [HERO_CLASS_EMPATH]: defaultEmpathHair,
  [HERO_CLASS_MAKER]: defaultMakerHair,
}

const noop = () => {}
const setter = (val: any) => {}

const defaultAvatarToggles = {
  [HERO_CLASS_SCOUT]: noop,
  [HERO_CLASS_TANK]: noop,
  [HERO_CLASS_EMPATH]: noop,
  [HERO_CLASS_MAKER]: noop,
}

const defaultTechTriangleSetters = {
  "1": setter,
  "2": setter,
  "3": setter,
}

const defaultTechTriangle = {
  evidence: "",
  identification: "",
  explanation: "",
}

const defaultTechTriangles = {
  "1": defaultTechTriangle,
  "2": defaultTechTriangle,
  "3": defaultTechTriangle,
}

const avatarHairsList = [
  DRUID_HAIR_STYLE,
  DWARF_HAIR_STYLE,
  BANDIT_HAIR_STYLE,
  SHOULDER_LENGTH_WAVY_HAIR_STYLE,
  FRINGE_STRAIGHT_LONG_HAIR_STYLE,
  PARTED_FRONT_PONYTAIL_HAIR_STYLE,
]

const defaultContextValue = {
  avatarHairsList,
  avatarHairs: defaultAvatarHairs,
  avatarToggles: defaultAvatarToggles,
  techTriangles: defaultTechTriangles,
  techTriangleSetters: defaultTechTriangleSetters,
}

const toggleHairIndex = (currentHairIndex: number, setter: Setter) => () => {
  let nextHairIndex = currentHairIndex + 1
  if (nextHairIndex > avatarHairsList.length - 1) {
    nextHairIndex = 0
  }
  setter(nextHairIndex)
}

export const AvatarsContext = React.createContext(defaultContextValue)

export const AvatarsProvider = ({ children }: Wrapper) => {
  // Hair
  const [scoutHairIndex, setScoutHairIndex] = React.useState(defaultScoutHair)
  const [tankHairIndex, setTankHairIndex] = React.useState(defaultTankHair)
  const [makerHairIndex, setMakerHairIndex] = React.useState(defaultMakerHair)
  const [empathHairIndex, setEmpathHairIndex] =
    React.useState(defaultEmpathHair)

  const avatarHairs = {
    [HERO_CLASS_SCOUT]: scoutHairIndex,
    [HERO_CLASS_TANK]: tankHairIndex,
    [HERO_CLASS_EMPATH]: empathHairIndex,
    [HERO_CLASS_MAKER]: makerHairIndex,
  }

  // Triangles
  const [techTriangle1, setTechTriangle1] = React.useState(defaultTechTriangle)
  const [techTriangle2, setTechTriangle2] = React.useState(defaultTechTriangle)
  const [techTriangle3, setTechTriangle3] = React.useState(defaultTechTriangle)
  const techTriangles = {
    "1": techTriangle1,
    "2": techTriangle2,
    "3": techTriangle3,
  }

  const contextValues = {
    avatarHairsList,
    avatarHairs,
    avatarToggles: {
      [HERO_CLASS_SCOUT]: toggleHairIndex(scoutHairIndex, setScoutHairIndex),
      [HERO_CLASS_TANK]: toggleHairIndex(tankHairIndex, setTankHairIndex),
      [HERO_CLASS_EMPATH]: toggleHairIndex(empathHairIndex, setEmpathHairIndex),
      [HERO_CLASS_MAKER]: toggleHairIndex(makerHairIndex, setMakerHairIndex),
    },
    techTriangles,
    techTriangleSetters: {
      "1": setTechTriangle1,
      "2": setTechTriangle2,
      "3": setTechTriangle3,
    },
  }

  return (
    <AvatarsContext.Provider value={contextValues}>
      {children}
    </AvatarsContext.Provider>
  )
}
