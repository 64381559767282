export const getActivePage = (
  pageParamValueAsInt: number,
  physicsPageList: string[][]
) => {
  const activePage =
    !isNaN(pageParamValueAsInt) && physicsPageList[pageParamValueAsInt - 1]
      ? pageParamValueAsInt
      : 1

  const activeProblemList = physicsPageList[activePage - 1]

  return { activePage, activeProblemList }
}
