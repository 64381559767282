import React from "react"

import { Position } from "@components/Avatar/types"
import { createFactoredSizing } from "@components/Avatar/utils"
import { InlineStyle } from "@components/types"

import { marker } from "./m.module.scss"

interface Props extends Position {
  factor?: number
  style?: InlineStyle
}

const sizingMarkerCommon = {
  width: 8,
  height: 8,
}

export const Marker = ({ factor, top, left, style }: Props) => {
  const sizingMarker = {
    ...sizingMarkerCommon,
    top,
    left,
  }

  const sizingStyles = createFactoredSizing(sizingMarker, factor)

  return (
    <div
      className={marker}
      style={{
        ...sizingStyles,
        ...style,
      }}
    />
  )
}
