import { classnames } from "classnames-joiner"
import React from "react"

import { Triangle } from "@components/shapes/triangle"

import {
  triangle1,
  triangle2,
  triangle3,
} from "@views/english-demo-1/action-7/ac7.module.scss"
import {
  mainQuestion,
  mainQuestionMarkValue,
  mainQuestionWithMarkValue,
} from "@views/english-demo-1/common/constants"
import {
  DetailedBossMonster,
  SkeletonKing,
} from "@views/english-demo-1/common/minion"
import { ProgressBar } from "@views/english-demo-1/common/progress-bar"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { ShortAnswerFieldScene } from "@views/english-demo-1/common/short-answer-field-scene"
import { TechniqueBox } from "@views/english-demo-1/common/technique-box"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { ScenePropsWithInputProps } from "@views/english-demo-1/types"
import { findSceneIndexBySceneId } from "@views/english-demo-1/utils/"
import { AvatarsContext } from "@views/english-demo-1/utils/context"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import { HERO_CLASS_MAKER } from "@helpers/constants/hero-class"
import {
  ANIMATION_ROTATE_IN,
  ANIMATION_DELAY_1S,
  ANIMATION_DELAY_2S,
} from "@helpers/constants/style"

export const Action7 = ({
  previousScene,
  nextScene,
  inputProps,
  scenes,
  isActiveScene,
}: ScenePropsWithInputProps) => {
  useScrollTop(isActiveScene)
  const context = React.useContext(AvatarsContext)

  const avatar = (
    <div className={avatarContainer}>
      {useEnglishDemoAvatar({
        heroClass: HERO_CLASS_MAKER,
      })}
      <Triangle
        className={classnames([triangle1, ANIMATION_ROTATE_IN])}
        fill="fill-yellow-shade-01"
      />
      <Triangle
        className={classnames([
          triangle2,
          ANIMATION_DELAY_1S,
          ANIMATION_ROTATE_IN,
        ])}
        fill="fill-green-shade-01"
      />
      <Triangle
        className={classnames([
          triangle3,
          ANIMATION_DELAY_2S,
          ANIMATION_ROTATE_IN,
        ])}
        fill="fill-teal-shade-01"
      />
    </div>
  )

  const figurativeMeaningSceneIndex = findSceneIndexBySceneId("6d", scenes)

  const techTriangle1SampleAnswerIndex = findSceneIndexBySceneId("6a", scenes)
  const techTriangle2SampleAnswerIndex = findSceneIndexBySceneId("6b", scenes)
  const techTriangle3SampleAnswerIndex = findSceneIndexBySceneId("6c", scenes)
  const composedQuestion = (
    <section>
      <div>
        <h2 className="h4">Your Notes</h2>
        <h3 className="h5 mb-0">Figurative meaning</h3>
        {figurativeMeaningSceneIndex && (
          <>
            <p className="mb-0">
              {scenes[figurativeMeaningSceneIndex].inputProps?.value}
            </p>
            <p>
              <strong>Sample: </strong>
              {scenes[figurativeMeaningSceneIndex].sampleAnswer}
            </p>
          </>
        )}
        <div className="mb-4">
          <TechniqueBox
            techniqueNo={1}
            {...context.techTriangles[1]}
            sampleExplanation={
              techTriangle1SampleAnswerIndex &&
              scenes[techTriangle1SampleAnswerIndex].sampleAnswer
            }
          />
        </div>
        <div className="mb-4">
          <TechniqueBox
            techniqueNo={2}
            {...context.techTriangles[2]}
            sampleExplanation={
              techTriangle2SampleAnswerIndex &&
              scenes[techTriangle2SampleAnswerIndex].sampleAnswer
            }
          />
        </div>
        <div className="mb-4">
          <TechniqueBox
            techniqueNo={3}
            {...context.techTriangles[3]}
            sampleExplanation={
              techTriangle3SampleAnswerIndex &&
              scenes[techTriangle3SampleAnswerIndex].sampleAnswer
            }
          />
        </div>
      </div>
      <h2 className="h4">{mainQuestionWithMarkValue}</h2>
    </section>
  )

  return (
    <section>
      <ProgressBar index={9} />
      <ShortAnswerFieldScene
        question={composedQuestion}
        minion={
          <DetailedBossMonster
            marks={mainQuestionMarkValue}
            question={mainQuestion}
            bossMonster={<SkeletonKing />}
            bossMonsterTitle="Skeleton King"
          />
        }
        avatar={avatar}
        inputProps={inputProps}
        previousScene={previousScene}
        nextScene={nextScene}
        belowNav={<MainQuestionReference />}
      />
    </section>
  )
}
