import { Auth } from "aws-amplify"
import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"

import { CenteredWrapper } from "@components/Containers/positioning-wrappers"

import { W_100, VH_100, FLEX_COLUMN, MB_4 } from "@helpers/constants/style"

import { loader } from "./l.module.scss"

const WaitMessage = () => {
  return (
    <div className="text-center">
      <p>Loading...</p>
      <h4>Waiting too long?</h4>
      <Link to="/" className="d-block">
        Return to homepage
      </Link>
      <Button variant="link" onClick={() => Auth.federatedSignIn()}>
        Sign in again
      </Button>
    </div>
  )
}

export const Loader = () => {
  return <div className={loader + " " + MB_4} />
}

export const CenteredLoader = ({
  children = "Loading...",
}: {
  children?: React.ReactChild
}) => {
  return (
    <CenteredWrapper className={`${W_100} ${VH_100}`}>
      <CenteredWrapper className={FLEX_COLUMN}>
        <Loader />
        {children}
      </CenteredWrapper>
    </CenteredWrapper>
  )
}
