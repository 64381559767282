import { classnames } from "classnames-joiner"
import React from "react"
import { useSelector } from "react-redux"

import { initialState, StateType } from "@redux/ducks"
import { AvatarTankType } from "@redux/ducks/user-synced/avatar-tank"
import {
  USER_STATUS_DANGER,
  UserStatusType,
} from "@redux/ducks/user-synced/user-status"

import { Avatar } from "@components/Avatar"
import { MEDIUM_AVATAR_FACTOR } from "@components/Avatar/constants"
import { TANK_STANCE } from "@components/Avatar/constants/stance"
import { CenteredWrapper } from "@components/Containers/positioning-wrappers"

import { HeroClassType, HERO_CLASS_TANK } from "@helpers/constants/hero-class"

import { statusOk, statusDanger, invertDirection } from "./q.module.scss"

interface Props {
  heroClass: HeroClassType
  direction?: "left" | "right"
  status?: UserStatusType
  className?: string
  factor?: number
}

const generateAvatarPropsFromHeroClass = (
  heroClass: HeroClassType,
  avatar: AvatarTankType
) => {
  switch (heroClass) {
    case HERO_CLASS_TANK:
    default:
      return {
        skinTone: avatar.skinTone,
        hairColour: avatar.hairColour,
        hair: {
          id: avatar.hairId,
          props: {
            placementPosition: TANK_STANCE.hair.position,
          },
        },
        face: {
          id: avatar.faceId,
          props: {
            placementPosition: TANK_STANCE.face.position,
          },
        },
        head: {
          id: avatar.headId,
          props: {
            placementPosition: TANK_STANCE.neck.position,
          },
        },
        body: {
          id: avatar.bodyId,
          props: {
            placementPosition: TANK_STANCE.neck.position,
          },
        },
        weapon: {
          id: avatar.weaponId,
          props: {
            placementPosition: TANK_STANCE.weapon.position,
            rotation: TANK_STANCE.weapon.rotation,
          },
        },
      }
  }
}

export const StatusAvatar = ({
  heroClass,
  className,
  status,
  factor = MEDIUM_AVATAR_FACTOR,
  direction = "right",
}: Props) => {
  const avatar = useSelector((rootState: StateType = initialState) => {
    switch (heroClass) {
      case HERO_CLASS_TANK:
      default:
        return rootState.userSynced.avatarTank
    }
  })

  const statusClass = status === USER_STATUS_DANGER ? statusDanger : statusOk
  const avatarProps = generateAvatarPropsFromHeroClass(heroClass, avatar)

  return (
    <CenteredWrapper className={classnames(["p-2", className, statusClass])}>
      <Avatar
        factor={factor}
        className={direction === "left" ? invertDirection : undefined}
        {...avatarProps}
      />
    </CenteredWrapper>
  )
}
