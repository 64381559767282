export const dimensions = {
  width: 182,
  height: 230,
}

export const originPosition = {
  left: 0.45 * dimensions.width,
  top: 0.05 * dimensions.height,
}

export const sizing1 = {
  top: 0,
  left: 0,
  ...dimensions,
}

export const sizing2 = {
  top: 73,
  left: 46,
  width: 136,
  height: 159,
}
