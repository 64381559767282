import React from "react"

import { MB_4 } from "@helpers/constants/style"
import { jsxJSONArrayProcessor } from "@helpers/jsxJSONProcessor"
import { JSXJSONType } from "@helpers/jsxJSONProcessor/types"

interface Props {
  content: JSXJSONType[]
}

export const Question = ({ content }: Props) => {
  return <div className={MB_4}>{jsxJSONArrayProcessor(content)}</div>
}
