import { BodyId, KNIGHT_BODY } from "@components/Avatar/constants/body"
import { FaceId, NEUTRAL_EDGE_FACE } from "@components/Avatar/constants/face"
import {
  HairColourId,
  HAIR_COLOUR_BROWN_BANDIT,
} from "@components/Avatar/constants/hair-colour"
import {
  HairStyleId,
  DRUID_HAIR_STYLE,
} from "@components/Avatar/constants/hair-style"
import { HEAD_1 } from "@components/Avatar/constants/head"
import { SkinToneId, SKIN_TONE_3 } from "@components/Avatar/constants/skin-tone"
import {
  TANK_STANCE,
  MAKER_STANCE,
  EMPATH_STANCE,
  SCOUT_STANCE,
} from "@components/Avatar/constants/stance"
import { KNIGHT_WEAPON, WeaponId } from "@components/Avatar/constants/weapon"

import {
  HeroClassType,
  HERO_CLASS_SCOUT,
  HERO_CLASS_TANK,
  HERO_CLASS_EMPATH,
  HERO_CLASS_MAKER,
} from "@helpers/constants/hero-class"

export const config = {
  skinTone: SKIN_TONE_3,
  hairColour: HAIR_COLOUR_BROWN_BANDIT,
  hair: {
    id: DRUID_HAIR_STYLE,
    props: {
      placementPosition: TANK_STANCE.hair.position,
    },
  },
  face: {
    id: NEUTRAL_EDGE_FACE,
    props: {
      placementPosition: TANK_STANCE.face.position,
    },
  },
  head: {
    id: HEAD_1,
    props: {
      placementPosition: TANK_STANCE.neck.position,
    },
  },
  body: {
    id: KNIGHT_BODY,
    props: {
      placementPosition: TANK_STANCE.neck.position,
    },
  },
  weapon: {
    id: KNIGHT_WEAPON,
    props: {
      placementPosition: TANK_STANCE.weapon.position,
      rotation: TANK_STANCE.weapon.rotation,
    },
  },
}

interface Props {
  heroClass: HeroClassType
  hairColourId?: HairColourId
  hairStyleId?: HairStyleId
  skinToneId?: SkinToneId
  bodyId?: BodyId
  faceId?: FaceId
  weaponId?: WeaponId
}

export const configCreator = ({
  heroClass,
  hairColourId,
  hairStyleId,
  skinToneId,
  bodyId,
  faceId,
  weaponId,
}: Props) => {
  let stance
  switch (heroClass) {
    case HERO_CLASS_SCOUT:
      stance = SCOUT_STANCE
      break
    case HERO_CLASS_TANK:
    default:
      stance = TANK_STANCE
      break
    case HERO_CLASS_EMPATH:
      stance = EMPATH_STANCE
      break
    case HERO_CLASS_MAKER:
      stance = MAKER_STANCE
      break
  }

  const newConfig = {
    ...config,
    hair: {
      ...config.hair,
      props: {
        ...config.hair.props,
        placementPosition: stance.hair.position,
      },
    },
    face: {
      ...config.face,
      props: {
        ...config.face.props,
        placementPosition: stance.face.position,
      },
    },
    head: {
      ...config.head,
      props: {
        ...config.head.props,
        placementPosition: stance.neck.position,
      },
    },
    body: {
      ...config.body,
      props: {
        ...config.body.props,
        placementPosition: stance.neck.position,
      },
    },
    weapon: {
      ...config.weapon,
      props: {
        ...config.weapon.props,
        placementPosition: stance.weapon.position,
        rotation: stance.weapon.rotation,
      },
    },
  }

  // Only replace if defined. Use defaults if not.
  if (hairColourId) {
    newConfig.hairColour = hairColourId
  }
  if (skinToneId) {
    newConfig.skinTone = skinToneId
  }
  if (hairStyleId) {
    newConfig.hair.id = hairStyleId
  }
  if (bodyId) {
    newConfig.body.id = bodyId
  }
  if (faceId) {
    newConfig.face.id = faceId
  }
  if (weaponId) {
    newConfig.weapon.id = weaponId
  }

  return {
    config: newConfig,
    stance,
  }
}
