import React from "react"

import { AvatarsContext } from "@views/english-demo-1/utils/context"

interface TechTriangleContent {
  evidence?: string
  identification?: string
  explanation?: string
}

interface UseSetTechTriangleContentProps {
  isActiveScene: boolean
  techniqueNo: 1 | 2 | 3
  content: TechTriangleContent
  setOnlyIf?: string[]
}

const getVal = ({
  setOnlyIf,
  existingValue,
  newValue,
}: {
  setOnlyIf?: string[]
  existingValue: string
  newValue?: string
}) => {
  if (setOnlyIf && setOnlyIf.includes(existingValue) && newValue) {
    return newValue
  } else if (!setOnlyIf && newValue) {
    return newValue
  }
  return existingValue
}

export const useIfActiveScene = (
  isActiveScene: boolean,
  callback: () => void,
  extraDependencies: any[] = []
) => {
  React.useEffect(() => {
    if (isActiveScene) {
      callback()
    }
  }, [isActiveScene].concat(extraDependencies))
}

export const useSetTechTriangleContent = ({
  isActiveScene,
  techniqueNo,
  content,
  setOnlyIf,
}: UseSetTechTriangleContentProps) => {
  const context = React.useContext(AvatarsContext)
  const techTriangle = context.techTriangles[techniqueNo]

  useIfActiveScene(isActiveScene, () => {
    if (isActiveScene) {
      // Default the values to the current values

      const evidence = getVal({
        setOnlyIf,
        existingValue: techTriangle.evidence,
        newValue: content.evidence,
      })
      const identification = getVal({
        setOnlyIf,
        existingValue: techTriangle.identification,
        newValue: content.identification,
      })
      const explanation = getVal({
        setOnlyIf,
        existingValue: techTriangle.explanation,
        newValue: content.explanation,
      })

      context.techTriangleSetters[techniqueNo]({
        evidence,
        identification,
        explanation,
      })
    }
  })
}

export const useScrollTop = (isActiveScene: boolean) => {
  useIfActiveScene(isActiveScene, () => {
    window.scrollTo(0, 0)
  })
}
