import React, { ReactNode } from "react"

import { mainQuestion } from "@views/english-demo-1/common/constants"

import { MT_2, D_BLOCK } from "@helpers/constants/style"

interface Props {
  id?: string
  children: ReactNode
}

export const QuestionReference = ({ id, children }: Props) => {
  return (
    <div id={id} className={MT_2}>
      <small className={D_BLOCK}>Refer to stimulus</small>
      <em>{children}</em>
    </div>
  )
}

export const MainQuestionReference = () => {
  return <QuestionReference>{mainQuestion}</QuestionReference>
}
