import React from "react"

import { InnerSvg } from "@components/Avatar/common/avatar-item/inner-image"
import { MultiPartHairAsset } from "@components/Avatar/types"

import {
  cls2,
  cls5,
  cls6,
  cls7,
  cls8,
  cls9,
  cls10,
  cls11,
  cls12,
  cls13,
  cls14,
  cls15,
  cls16,
  cls18,
} from "./fsl.module.scss"

// Refactoring into these constants is not necessary however it does highlight a moderate amount of duplication that can exist in these SVG assets. It would be interesting to see if these refactors can be automated if we are considering performance.

const pathDef1 =
  "M79.46,23.17A45.68,45.68,0,0,0,63.21,9.48,49.16,49.16,0,0,0,43,4.39,45.19,45.19,0,0,0,22.68,8a38.79,38.79,0,0,0-4.49,2.43,32.48,32.48,0,0,0-4,3.07A26.8,26.8,0,0,0,6.33,26.1,30.42,30.42,0,0,0,5.39,31a48.3,48.3,0,0,0-.18,5.2l0,5.47L5.14,63.6l-.06,43.78-2.54-2.54H7l-2.42,1.76L7.13,98.8l2-6.21,2.75,5.93,3.62,7.78-2.34-1.5H16l-2.22,1.26,2-3.36,1.71-2.86,2.36,2.36,3.36,3.34-1.84-.76,4.46,0-2.62,2.63,0-17,0-17c0-5.66.09-11.33.14-17l.19-17v-.86l.54-.62c2.6-3,5.27-5.95,7.9-8.92,1.32-1.49,2.68-2.94,4-4.41s2.67-3,4-4.39l.66-.7.78.14,6,1,6,1.09,6,1.16c2,.41,4,.83,5.93,1.28-2,0-4-.1-6.07-.19l-6.06-.3-6-.38-6-.45,1.44-.56C41.29,23.9,40,25.44,38.8,27s-2.46,3.12-3.72,4.66c-2.52,3.07-5,6.18-7.55,9.22l.55-1.48.19,17c.05,5.66.14,11.33.14,17l0,17,0,17V110H20.24l-.76-.77-3.35-3.36,4.07-.5-2,3.35L17.42,110h-5.9l-.69-1.49-3.59-7.79,4.76-.28-2.54,7.78-.58,1.76H7l-4.49,0H0v-2.54L.1,63.59l0-21.89V36.23a56.6,56.6,0,0,1,.2-5.73A32.73,32.73,0,0,1,3.67,19.18a32,32,0,0,1,7.21-9.33,36.09,36.09,0,0,1,9.87-6.23A49.7,49.7,0,0,1,43.22.11,52,52,0,0,1,54.43,2,42.28,42.28,0,0,1,64.84,6.57a35.17,35.17,0,0,1,4.61,3.31,36.66,36.66,0,0,1,4.06,3.92A31.25,31.25,0,0,1,79.46,23.17Z"

const pathDef2 =
  "M79.46,23.17S69.85,3.42,42.79,2.23C19.42,1.2,2.67,14.5,2.67,34.31c0,13.8-.13,73.07-.13,73.07H7l2.54-7.79,3.6,7.79H16L18,104l3.36,3.36h4.46v-68L41.25,21.26l24,2.94"

const pathDef3 =
  "M15.87,47.21c.08,20.34,1,37.94,2.19,56.9L18,104l-2,3.36H13.17l-3.06-6.61C11.35,82.49,13.39,65.59,15.87,47.21Z"

const pathDef4 =
  "M47,7.47a42.57,42.57,0,0,0-9.36,6.29,28.82,28.82,0,0,0-6.42,8.55,34.77,34.77,0,0,0-3,10.38,51.3,51.3,0,0,0-.3,11l-2.5-2.33c2.41,0,4.82-.06,7.23,0l-1.81.75,3.52-3.55,2.27-2.28L38.31,39l2.22,3.56-2.15-1.19h1.43l-2.34,1.56,2.29-5.47,1.65-3.92,2.65,3.32,4.35,5.47-2-.94H57.12L55,42.61l2.53-4.34,2.37-4.06,2.07,4.24,2.12,4.32-2.29-1.44,8.91,0-2.4,1.62,3-7.57,2.31-5.76,2.53,5.69,3.37,7.55L77.1,41.22l7.34,0-2.61,2.42a47.85,47.85,0,0,0-.31-10.78A28.66,28.66,0,0,0,78.1,23a31.5,31.5,0,0,0-6.86-8.18A65.81,65.81,0,0,0,62,8.36a32,32,0,0,1,11,4,27.1,27.1,0,0,1,8.84,8.29A31.25,31.25,0,0,1,86.43,32a45.72,45.72,0,0,1,.62,12l-.18,2.42H84.44l-7.34,0H75.36L74.67,45l-3.32-7.57,4.84-.07-3.1,7.55-.67,1.63H70.68l-8.91,0H60.16L59.47,45,57.4,40.66l4.45.18-2.57,4.31-.73,1.23H45.24l-.76-.94L40.12,40l4.29-.6-2.27,5.47-.64,1.56H37l-.75-1.19L34,41.66l4,.45-3.52,3.56-.74.74H32.64c-2.41,0-4.82,0-7.23,0H23.05l-.14-2.32A53.41,53.41,0,0,1,23.46,32a37.2,37.2,0,0,1,3.93-11.63,25,25,0,0,1,8.22-9.19A22,22,0,0,1,47,7.47Z"

const pathDef5 =
  "M47,7.47C30.27,11.81,24.24,26.94,25.41,43.88h7.23l3.52-3.56,2.22,3.56h1.43l2.28-5.47,4.35,5.47H57.12l2.55-4.33,2.1,4.33h8.91l3.08-7.56,3.34,7.56h7.34C85.65,27.63,79.91,14.9,62,8.36"

const pathDef6 =
  "M50,8.29S42.47,14.45,41.86,39l-2,4.91H38.38l-2.22-3.56-.42.43C36.11,31.35,38.33,12.35,50,8.29Z"

const pathDef7 =
  "M72.88,40.79a49.16,49.16,0,0,0-.28-6.62,50.3,50.3,0,0,0-1.15-6.58,38.17,38.17,0,0,0-2.21-6.32,27,27,0,0,0-3.64-5.65A26.74,26.74,0,0,1,69.68,21a38.64,38.64,0,0,1,2.73,6.26A51,51,0,0,1,74.09,34a51.61,51.61,0,0,1,.79,6.86Z"

const pathDef8 =
  "M62.93,45a52,52,0,0,0-4.5-21.31c-.72,6.22-1.75,12.12-5.13,17.06l-2.06-4.56-4,6.68a60.28,60.28,0,0,1-1.75-26.37c-5,7.76-7.13,23.5-7.13,23.5a23.94,23.94,0,0,1,.19-10.81,26.17,26.17,0,0,0-5.44,11.68,12.27,12.27,0,0,1-2.31-7.37,8.67,8.67,0,0,0-3,3.31,34.78,34.78,0,0,1,7.88-24.12c-8.94,5.6-12.37,17-11.94,32.56l9.19-.13L35.8,42.9,37.43,45l3,.44,2.5-3.19,2.75,3.06,12.25-.12,1.81-2.82,1.44,3.13Z"

const pathDef9 =
  "M1.49,108.58h7l1.61-5,1.39,5H17L18.5,107,20,108.58h3.33V39c2.27-13.3,5.26-26.13,20.38-30.88-10.86,1.48-17.37,6-20.38,13,.17-4,1.72-7.17,4.25-10.56a22.05,22.05,0,0,0-7.74,5.11A22.76,22.76,0,0,1,28.8,4.69c-4,.5-10.26,4.75-10.26,4.75.65-2.53,3-4,5.25-5.81-9.16,2.72-22.22,14-22.3,26.7Z"

const pathDef10 =
  "M82.18,45.33a57.29,57.29,0,0,0-4.57-12.87c-.15,2.6-.13,4.19-1.18,6.06a44.35,44.35,0,0,0-5.82-15.37A38.1,38.1,0,0,1,68.68,43a31.62,31.62,0,0,0-2.63-8.38,20.75,20.75,0,0,1-2.5,10.88l7.63.12L73.93,41l2.75,4.75Z"

const pathDef11 =
  "M19,6.73a51.37,51.37,0,0,0-9.24,9.58A28.67,28.67,0,0,0,4.53,28.14c-.1.53-.16,1.06-.22,1.59l-.07,1.61,0,3.32.07,6.7c.06,4.48.07,9,.06,13.43L4.22,81.66,4.1,95.1l-.2,13.44h-2c-.28-9-.42-17.92-.54-26.88s-.14-17.92-.11-26.89c0-4.48.07-9,.25-13.45l.28-6.73L2,31.21l.17-1.75c.1-.58.2-1.16.33-1.72A29,29,0,0,1,8.8,15.56,45,45,0,0,1,19,6.73Z"

const pathDef12 =
  "M35.24,42.22A30,30,0,0,1,36,37a38.68,38.68,0,0,1,1.69-4.93,36.91,36.91,0,0,0-.7,5.08,28.43,28.43,0,0,0,.22,5Z"

const pathDef13 =
  "M41,42.65c.22-3.16.48-6.31.82-9.46.16-1.57.35-3.15.57-4.71s.45-3.14.81-4.68c-.19,1.57-.26,3.15-.32,4.72s-.07,3.15-.06,4.72q0,4.73.18,9.44Z"

const pathDef14 =
  "M77.61,44.53c.2-1.46.37-3,.49-4.43a28.86,28.86,0,0,0,.09-4.46,28.77,28.77,0,0,1,.91,4.43c.21,1.5.38,3,.51,4.51Z"

const pathDef15 =
  "M58.11,43.42c.26-2.63.47-5.29.61-8,.07-1.33.13-2.66.14-4a35.45,35.45,0,0,0-.11-4,34.82,34.82,0,0,1,.86,3.93c.23,1.33.43,2.66.61,4,.36,2.67.65,5.33.89,8Z"

const pathDef16 =
  "M14.57,24.71c-.25,1.12-.49,2.23-.68,3.36s-.41,2.23-.56,3.36c-.31,2.24-.61,4.49-.8,6.75s-.36,4.52-.44,6.78-.1,4.55-.15,6.84c-.18-1.13-.32-2.27-.43-3.41s-.17-2.29-.19-3.43a56.26,56.26,0,0,1,.21-6.87,55.55,55.55,0,0,1,1.05-6.8A37.8,37.8,0,0,1,14.57,24.71Z"

const pathDef17 =
  "M16.24,43.24c.06,3.29.06,6.54.05,9.81s0,6.52-.29,9.81A59.85,59.85,0,0,1,15.29,53,47.47,47.47,0,0,1,16.24,43.24Z"

export const FringeStraightLongHairAsset = ({
  sizing,
  factor,
  hairColour,
}: MultiPartHairAsset) => {
  return (
    <InnerSvg sizing={sizing} factor={factor} viewBox="0 0 87.19 110.01">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="80.32"
          y1="25.27"
          x2="88.57"
          y2="28.3"
          gradientTransform="matrix(-1, 0, 0, 1, 161.52, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f6ead7" />
          <stop offset="1" stopColor="#f9e3b3" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="91.97"
          y1="25.87"
          x2="100.63"
          y2="29.05"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="104.85"
          y1="27.11"
          x2="116.04"
          y2="31.22"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="120.76"
          y1="22.28"
          x2="131.35"
          y2="26.18"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="103.88"
          y1="3.04"
          x2="131.35"
          y2="13.13"
        />
        <radialGradient
          id="radial-gradient"
          cx="95.81"
          cy="15.42"
          r="42.66"
          gradientTransform="matrix(-1, 0, 0, 1, 161.52, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.15" stopColor="#f5e3cf" />
          <stop offset="0.93" stopColor="#fbc292" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="135.09"
          y1="71.82"
          x2="140.31"
          y2="73.74"
        />
      </defs>
      <g className={cls2}>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="HAIR_33-2" data-name="HAIR 33-2">
              <path d={pathDef1} />
              <path className={hairColour} d={pathDef2} />
              <path className={cls5} d={pathDef3} />
              <path d={pathDef4} />
              <path className={hairColour} d={pathDef5} />
              <path className={cls5} d={pathDef6} />
              <path d={pathDef7} />
              <path d={pathDef12} />
              <path d={pathDef13} />
              <path d={pathDef14} />
              <path d={pathDef15} />
              <path d={pathDef16} />
              <path d={pathDef17} />
              <polygon points="8.29 103.87 10.04 87.07 11.29 103.91 8.29 103.87" />
              <polygon points="16.78 106.44 18.36 99.46 19.78 106.48 16.78 106.44" />
              <polygon points="65.41 44.64 66.91 38.6 68.41 44.64 65.41 44.64" />
              <path className={cls6} d={pathDef9} />
              <path className={cls7} d={pathDef9} />
              <path className={cls6} d={pathDef10} />
              <path className={cls7} d={pathDef10} />
              <path className={cls6} d={pathDef8} />
              <path className={cls7} d={pathDef8} />
              <g className={cls8}>
                <path className={cls9} d={pathDef11} />
              </g>
              <path
                className={cls10}
                d="M80.55,22.09c.13,3-1.66.72-3.08-1.61-.89,3.27-2.79,5.13-7.48,1.23a65.9,65.9,0,0,1,5.09,11.35c1.22-6.14,4.46-6.39,9.49-1.35C83.71,27.76,84.31,27.1,80.55,22.09Z"
              />
              <path
                className={cls11}
                d="M69.46,21.55c0,3.82-1.13,3.72-2.75,1-.21,4.27-1.92,6.46-7,4L61.77,33c0-2.89,1.24-2.63,3.28-1.78,1.66-3.53,4-4.25,6.06-1.53C71.11,29.65,71.52,24.12,69.46,21.55Z"
              />
              <path
                className={cls12}
                d="M58.21,25a55.77,55.77,0,0,1-1.66,8.94c-.92-2.7-2.56-3.39-6.5-1.38-.51-3.24-2.24-3.2-5-.43l-.25-7.44C51.41,29.48,55.41,28.56,58.21,25Z"
              />
              <path
                className={cls13}
                d="M42.82,21.92,41.2,27.08c-3.5-1.63-5.83,0-7.88,2.38q.41-6.83-4.25-4.42L30.86,21c2.32,3.14,4.6,3.59,6.84.75C37.87,25.73,39.53,25.92,42.82,21.92Z"
              />
              <path
                className={cls14}
                d="M65.22,8.21c-1.89-1.19-10.48-4.39-9-1C50.8,5.42,45.51,4,41.75,7.07c-5.43-2.16-9-2-10.71.45l-4.56.4L32.24,5.7l1.93-2C44.38,4.78,48.12,4,47.28,1.94,47.28,1.94,54.85,0,65.22,8.21Z"
              />
              <path
                className={cls15}
                d="M76.81,22.39a7.59,7.59,0,0,1-.89,1.14"
              />
              <path className={cls15} d="M44.74,11.73a3.29,3.29,0,0,1-.82,1" />
              <path className={cls15} d="M38,6a15.19,15.19,0,0,0-2.68-.25" />
              <line
                className={cls15}
                x1="55.99"
                y1="35.64"
                x2="55.82"
                y2="36.28"
              />
              <path
                className={cls16}
                d="M62,5.54a17.36,17.36,0,0,1,4.16,2.75c2.42,2.23,7.72,4,13,10.34a27.47,27.47,0,0,1,6.59,17.75c0,9.21,0,9.07-3.15,9.07-9.88,0-6.18-4.29-8.33-4.29-.71,0-2.18,3.57-3.44,4a10.67,10.67,0,0,1-3.18.44c-5.61,0-5.82-1.22-7.24-2.19a1.43,1.43,0,0,0-.81-.31c-1,0-1.49,1.69-2.44,1.89a31.35,31.35,0,0,1-4.71.37c-8.26,0-9.58-3.18-9.58-3.18-1,0-2,3.27-3.75,3.27C38,45.42,37,44.1,36.4,43.13a14.51,14.51,0,0,1-7.53,2.41c-10.78,0-17.64-17.42-17.64-25.95C11.23,4.84,29.59.31,38.62.31a30.85,30.85,0,0,1,6.78.82C51.15,2.4,56.9,2.71,62,5.54Z"
              />
            </g>

            <g id="HAIR_33-1" data-name="HAIR 33-1">
              <path d={pathDef1} />
              <path className={hairColour} d={pathDef2} />
              <path className={cls5} d={pathDef3} />
              <path d={pathDef4} />
              <path className={hairColour} d={pathDef5} />
              <path className={cls5} d={pathDef6} />
              <path d={pathDef7} />
              <path d={pathDef12} />
              <path d={pathDef13} />
              <path d={pathDef14} />
              <path d={pathDef15} />
              <path d={pathDef16} />
              <path d={pathDef17} />
              <polygon points="8.29 103.87 10.04 87.07 11.29 103.91 8.29 103.87" />
              <polygon points="16.78 106.44 18.36 99.46 19.78 106.48 16.78 106.44" />
              <polygon points="65.41 44.64 66.91 38.6 68.41 44.64 65.41 44.64" />
              <path className={cls6} d={pathDef9} />
              <path className={cls7} d={pathDef9} />
              <path className={cls6} d={pathDef10} />
              <path className={cls7} d={pathDef10} />
              <path className={cls6} d={pathDef8} />
              <path className={cls7} d={pathDef8} />
              <g className={cls8}>
                <path className={cls9} d={pathDef11} />
              </g>
              <path
                className={cls18}
                d="M26.57,63.71c-1.21,4.35-2.31,5.08-3.25.67l.08,15.08c1.25-4.58,2.32-3,3.34.83Z"
              />
              <line
                className={cls15}
                x1="17.01"
                y1="85.04"
                x2="17.13"
                y2="87.28"
              />
              <path
                className={cls15}
                d="M26.69,60.35a6.27,6.27,0,0,1-1.41,1.36"
              />
            </g>
          </g>
        </g>
      </g>
    </InnerSvg>
  )
}
