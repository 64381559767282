import React from "react"
import { Helmet } from "react-helmet"
import { ToastContainer } from "react-toastify"

export const ReactToastifyHelmet = () => {
  return (
    <Helmet>
      <link rel="stylesheet" href="/styles/ReactToastify.min.css" />
    </Helmet>
  )
}

export const ToastHelmetContainer = () => {
  return (
    <>
      <ReactToastifyHelmet />
      <ToastContainer />
    </>
  )
}
