import React from "react"

import EmptyStarSrc from "@images/icons/star-empty.svg"
import HalfStarSrc from "@images/icons/star-half-alt.svg"
import StarSrc from "@images/icons/star.svg"

import { star } from "./s.module.scss"

export const Star = () => {
  return <img className={star} src={StarSrc} alt="full star"></img>
}

export const HalfStar = () => {
  return <img className={star} src={HalfStarSrc} alt="half star"></img>
}

export const EmptyStar = () => {
  return <img className={star} src={EmptyStarSrc} alt="empty star"></img>
}
