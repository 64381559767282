/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      triangle
      square
      pentagon
      hexagon
      heptagon
      heads
      tails
      one
      two
      three
      a
      b
      c
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      triangle
      square
      pentagon
      hexagon
      heptagon
      heads
      tails
      one
      two
      three
      a
      b
      c
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      triangle
      square
      pentagon
      hexagon
      heptagon
      heads
      tails
      one
      two
      three
      a
      b
      c
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCreatorSharerBlob = /* GraphQL */ `
  mutation CreateCreatorSharerBlob(
    $input: CreateCreatorSharerBlobInput!
    $condition: ModelCreatorSharerBlobConditionInput
  ) {
    createCreatorSharerBlob(input: $input, condition: $condition) {
      id
      triangle
      square
      pentagon
      one
      two
      three
      a
      b
      c
      createdAt
      updatedAt
    }
  }
`;
export const updateCreatorSharerBlob = /* GraphQL */ `
  mutation UpdateCreatorSharerBlob(
    $input: UpdateCreatorSharerBlobInput!
    $condition: ModelCreatorSharerBlobConditionInput
  ) {
    updateCreatorSharerBlob(input: $input, condition: $condition) {
      id
      triangle
      square
      pentagon
      one
      two
      three
      a
      b
      c
      createdAt
      updatedAt
    }
  }
`;
export const deleteCreatorSharerBlob = /* GraphQL */ `
  mutation DeleteCreatorSharerBlob(
    $input: DeleteCreatorSharerBlobInput!
    $condition: ModelCreatorSharerBlobConditionInput
  ) {
    deleteCreatorSharerBlob(input: $input, condition: $condition) {
      id
      triangle
      square
      pentagon
      one
      two
      three
      a
      b
      c
      createdAt
      updatedAt
    }
  }
`;
