import { classnames } from "classnames-joiner"
import React from "react"
import { v4 as uuid } from "uuid"

import { Row, TableProps } from "./types"

type PropsType = {
  id?: string
  cells: Row
}

export const TableRow = ({ id, cells }: PropsType) => {
  return (
    <tr id={id}>
      {cells.map((cell) => {
        const cellAttrs = {
          key: uuid(),
          id: cell.id,
          className: cell.className,
          colSpan: cell.colSpan,
        }
        if (cell.type === "th") {
          return <th {...cellAttrs}>{cell.content}</th>
        }
        return <td {...cellAttrs}>{cell.content}</td>
      })}
    </tr>
  )
}

export const Table = ({
  className,
  headingRow,
  bodyRows,
  children,
}: TableProps) => {
  return (
    <table className={classnames([className])}>
      <thead>
        <TableRow cells={headingRow}></TableRow>
      </thead>
      <tbody>
        {bodyRows &&
          bodyRows.map((bodyRow) => {
            return <TableRow key={uuid()} cells={bodyRow}></TableRow>
          })}
        {children}
      </tbody>
    </table>
  )
}
