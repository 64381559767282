import { PartyPopperEmoji } from "accessible-emojis"
import React from "react"

import { setMiscActionCreator } from "@redux/ducks/misc"
import { setUserSyncedActionCreator } from "@redux/ducks/user-synced"
import { savePhysicsUserSyncedThunkCreator } from "@redux/ducks/user-synced/async"
import { USER_STATUS_OK } from "@redux/ducks/user-synced/user-status"
import { selectPhysicsQuestionsAnsweredCount } from "@redux/selectors"

import { FONT_WEIGHT_NORMAL } from "@helpers/constants/style"
import { toastNotification } from "@helpers/toastNotification"

import { AnsweredCorrectlyActionsType } from "../types"
import { setOptionWasSelected } from "./set-option-was-selected"

const AnsweredCorrectlyToast = ({ optionKey }: { optionKey: string }) => {
  return (
    <div>
      <p className="mb-0">
        <strong>{optionKey}: </strong>
        Correct answer! Onwards!
        <PartyPopperEmoji className={FONT_WEIGHT_NORMAL} />
      </p>
    </div>
  )
}

export const answeredCorrectlyActions = ({
  optionKey,
  rootState,
  dispatch,
  onAnsweredCorrectly,
}: AnsweredCorrectlyActionsType) => {
  return () => {
    toastNotification(
      <AnsweredCorrectlyToast optionKey={optionKey} />,
      "success"
    )

    setOptionWasSelected({ dispatch, rootState, optionKey })

    dispatch(
      setUserSyncedActionCreator({
        userStatus: USER_STATUS_OK,
      })
    )

    // TODO: This is physics specific, so it should be moved to onAnsweredCorrectly
    dispatch(savePhysicsUserSyncedThunkCreator())
    // Increments the count of questions. Note this is emphemeral.
    const physicsQuestionsAnsweredCount =
      selectPhysicsQuestionsAnsweredCount(rootState)
    dispatch(
      setMiscActionCreator({
        physicsQuestionsAnsweredCount: physicsQuestionsAnsweredCount + 1,
      })
    )

    // This is for subject specific things to happen.
    onAnsweredCorrectly({
      dispatch,
      rootState,
    })
  }
}
