import { CheckMarkEmoji, CrossMarkEmoji } from "accessible-emojis"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { EndWrapper } from "@components/Containers/positioning-wrappers"
import { Checkbox, useCheckboxProps } from "@components/Form/Checkbox"
import { CheckboxProps, CheckboxOption } from "@components/Form/Checkbox/types"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"

import {
  ANSWER_LATER,
  ANSWER_HERE,
} from "@views/english-demo-1/common/constants"
import { Minion } from "@views/english-demo-1/common/minion"
import { ProgressBar } from "@views/english-demo-1/common/progress-bar"
import { MainQuestionReference } from "@views/english-demo-1/common/question-reference"
import { TechniqueBox } from "@views/english-demo-1/common/technique-box"
import { avatarContainer } from "@views/english-demo-1/ed1.module.scss"
import { SceneProps } from "@views/english-demo-1/types"
import { AvatarsContext } from "@views/english-demo-1/utils/context"
import { useSetTechTriangleContent } from "@views/english-demo-1/utils/hooks"
import { useScrollTop } from "@views/english-demo-1/utils/hooks"
import { useEnglishDemoAvatar } from "@views/english-demo-1/utils/hooks/use-english-demo-avatar"

import { HERO_CLASS_TANK } from "@helpers/constants/hero-class"
import {
  FONT_WEIGHT_NORMAL,
  ANIMATION_FADEOUT,
  ANIMATION_SHAKE_X,
} from "@helpers/constants/style"

import minionSrc from "@images/monsters/skeletons/skeleton-archer-min.png"

const getHasAnsweredAllCorrectly = (
  checkboxPropsList: CheckboxProps[],
  checkboxOptions: CheckboxOption[]
) => {
  let verdict = true
  for (let i = 0; i < checkboxPropsList.length; i++) {
    const checkboxProps = checkboxPropsList[i]
    const checkboxOption = checkboxOptions[i]
    if (checkboxProps.checked !== checkboxOption.shouldBeChecked) {
      verdict = false
      break
    }
  }

  return verdict
}

const concatenateCorrectOptions = (checkboxOptions: CheckboxOption[]) => {
  let correctOptionsList = []
  for (let i = 0; i < checkboxOptions.length; i++) {
    if (checkboxOptions[i].shouldBeChecked) {
      correctOptionsList.push(checkboxOptions[i].label)
    }
  }

  return correctOptionsList.join(", ")
}

const getLabelDisplay = (
  checkboxProps: CheckboxProps,
  checkboxOption: CheckboxOption
) => {
  if (checkboxProps.checked === checkboxOption.shouldBeChecked) {
    return (
      <span>
        {checkboxProps.labelDisplay}
        {checkboxOption.shouldBeChecked === true && (
          <CheckMarkEmoji className={FONT_WEIGHT_NORMAL} />
        )}
      </span>
    )
  }

  return (
    <span>
      {checkboxProps.labelDisplay}
      <CrossMarkEmoji className={FONT_WEIGHT_NORMAL} />
    </span>
  )
}

export const Action3 = ({
  previousScene,
  nextScene,
  question,
  checkboxOptions = [],
  isActiveScene,
}: SceneProps) => {
  useScrollTop(isActiveScene)
  const context = React.useContext(AvatarsContext)

  const techniqueNo = 1
  useSetTechTriangleContent({
    isActiveScene,
    techniqueNo,
    content: {
      evidence: `"Go and open the door"`,
      identification: ANSWER_HERE,
      explanation: ANSWER_LATER,
    },
    setOnlyIf: ["", ANSWER_HERE, ANSWER_LATER],
  })

  const checkboxPropsList = checkboxOptions.map((option, index) =>
    useCheckboxProps({
      id: `action3-option-${index}`,
      labelDisplay: option.label,
    })
  )

  const [hasAnswered, setHasAnswered] = React.useState(false)
  const [hasAnsweredAllCorrectly, setHasAnsweredAllCorrectly] =
    React.useState(false)

  const checkboxes = checkboxPropsList.map((checkboxProps, index) => {
    if (!hasAnswered) {
      return <Checkbox key={checkboxProps.id} {...checkboxProps}></Checkbox>
    }

    const labelDisplay = getLabelDisplay(checkboxProps, checkboxOptions[index])

    return (
      <Checkbox
        key={checkboxProps.id}
        {...checkboxProps}
        labelDisplay={labelDisplay}
        disabled={true}
      />
    )
  })

  const answerButtonOnClick = () => {
    setHasAnswered(true)
    if (getHasAnsweredAllCorrectly(checkboxPropsList, checkboxOptions)) {
      setHasAnsweredAllCorrectly(true)
      const techTriangle1 = context.techTriangles["1"]
      context.techTriangleSetters["1"]({
        ...techTriangle1,
        identification: concatenateCorrectOptions(checkboxOptions),
      })
    } else {
      setHasAnsweredAllCorrectly(false)
    }
  }

  const avatar = useEnglishDemoAvatar({
    heroClass: HERO_CLASS_TANK,
    avatarContainerClassName:
      hasAnswered && !hasAnsweredAllCorrectly ? ANIMATION_SHAKE_X : undefined,
  })

  return (
    <section>
      <ProgressBar index={2} />
      <SpacedWrapper>
        <Button onClick={previousScene}>Back</Button>
        <GoogleFormLinkGeneralFeedback />
      </SpacedWrapper>
      <MainQuestionReference />
      <Container>
        <Row>
          <Col xs={12}>
            <SpacedWrapper className="flex-wrap">
              <div className={avatarContainer}>{avatar}</div>
              <Minion
                className={
                  hasAnsweredAllCorrectly ? ANIMATION_FADEOUT : undefined
                }
                src={minionSrc}
                alt="Skeleton Archer"
              />
            </SpacedWrapper>
          </Col>
        </Row>
      </Container>

      <div className="mb-4">
        <TechniqueBox
          techniqueNo={1}
          evidence={context.techTriangles["1"].evidence}
          identification={context.techTriangles["1"].identification}
          explanation={context.techTriangles["1"].explanation}
        />
      </div>

      <h2 className="h4">{question}</h2>
      <div className="mb-4">{checkboxes}</div>
      {!hasAnswered && (
        <Button
          variant="success"
          onClick={answerButtonOnClick}
          className="mb-4"
        >
          Answer
        </Button>
      )}
      {hasAnswered && !hasAnsweredAllCorrectly && (
        <Button
          variant="success"
          onClick={() => {
            setHasAnswered(false)
          }}
          className="mb-4"
        >
          Try Again
        </Button>
      )}

      {hasAnswered && hasAnsweredAllCorrectly && (
        <EndWrapper>
          <Button onClick={nextScene} className="mb-4">
            Next
          </Button>
        </EndWrapper>
      )}
    </section>
  )
}
