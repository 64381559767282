import React from "react"

import { hair } from "@components/Avatar/a.module.scss"
import { AvatarItem } from "@components/Avatar/common/avatar-item"
import { SkinAndHairAvatarProps } from "@components/Avatar/types"

import { BanditHairAsset } from "./asset"
import { dimensions, originPosition } from "./constants"

export const BanditHair = ({
  skinTone,
  hairColour,
  ...baseAvatar
}: SkinAndHairAvatarProps) => {
  return (
    <AvatarItem
      className={hair}
      dimensions={dimensions}
      originPosition={originPosition}
      baseAvatar={baseAvatar}
    >
      <BanditHairAsset skinTone={skinTone} hairColour={hairColour} />
    </AvatarItem>
  )
}
