import { MemoEmoji } from "accessible-emojis"
import React from "react"
import Button from "react-bootstrap/Button"

import {
  NOTES_MODAL,
  RESTING_NOTES_MODAL,
  ModalIdType,
} from "@redux/ducks/ui/modal"

import Potion from "@components/Potion"
import { NOTES_COST } from "@components/hero-stats/constants"

import {
  MY_2,
  FONT_WEIGHT_BOLD,
  W_100,
  FONT_WEIGHT_NORMAL,
} from "@helpers/constants/style"
import { JSXJSONType } from "@helpers/jsxJSONProcessor/types"

import { NotesModal, RestingNotesModal } from "./notes-modal"
import { useOnClick } from "./utils/use-on-click"

interface Props {
  potions: number
  modalId?: ModalIdType
  notes?: JSXJSONType[]
}

const RenderModalSwitch = ({
  modalId,
  notes,
}: {
  modalId?: ModalIdType
  notes?: JSXJSONType[]
}) => {
  switch (modalId) {
    case NOTES_MODAL:
      return <NotesModal show={true} notes={notes} />
    case RESTING_NOTES_MODAL:
      return <RestingNotesModal show={true} notes={notes} />
    default:
      return null
  }
}

export const NotesButton = ({ notes, modalId, potions }: Props) => {
  const onClick = useOnClick({ ifSpentCount: potions + NOTES_COST })

  return (
    <>
      <Button
        className={`${FONT_WEIGHT_BOLD} ${W_100} ${MY_2}`}
        onClick={onClick}
      >
        <MemoEmoji className={FONT_WEIGHT_NORMAL} /> Notes ({" "}
        <Potion size="small" />
        <span>&nbsp;&nbsp;x {NOTES_COST}</span> )
      </Button>
      <RenderModalSwitch modalId={modalId} notes={notes} />
    </>
  )
}
