import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { selectUsername } from "@redux/selectors"

import {
  META_STATUS_NEUTRAL,
  META_STATUS_LOADING,
  META_STATUS_ERROR,
} from "./constants"
import { UseMetaStatusProps } from "./types"

// A helper hook that will take care of the prioritisation logic of meta status

export const useMetaStatus = ({
  metaStatusValue,
  usernameThunkCreator,
}: UseMetaStatusProps) => {
  const dispatch = useDispatch()
  const username = useSelector(selectUsername)

  useEffect(() => {
    // Already loading
    if (metaStatusValue === META_STATUS_LOADING) {
      return
    }

    // No username
    if (!username) {
      return
    }

    if (
      metaStatusValue === META_STATUS_NEUTRAL ||
      metaStatusValue === META_STATUS_ERROR
    ) {
      // The thunk is responsible for setting loading / error / success states
      dispatch(usernameThunkCreator(username))
    }
  }, [username])
}
