import { useSelector } from "react-redux"

import { selectPayment } from "@redux/ducks/misc/payment"
import { getPaymentThunkCreator } from "@redux/ducks/misc/payment/async"

import { useMetaStatus } from "@helpers/hooks/use-meta-status"

// Read from state
export const usePayment = () => {
  return useSelector(selectPayment)
}

// Read from state, but fetch if it hasn't successfully fetched
export const useFetchPayment = () => {
  const payment = usePayment()

  console.log("useFetchPayment", { payment })

  useMetaStatus({
    metaStatusValue: payment.status,
    usernameThunkCreator: getPaymentThunkCreator,
  })

  return payment
}
