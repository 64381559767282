import React from "react"

import { cls2 } from "@components/Avatar/common/face/common/cf.module.scss"
import { HairAsset } from "@components/Avatar/types"
import { Svg } from "@components/Svg"

import { cls1, cls3, cls5, cls6, cls7, cls8 } from "./fnf.module.scss"

export const NeutralFemaleFaceAsset = ({ hairColour }: HairAsset) => {
  return (
    <Svg viewBox="0 0 67.35 40.27">
      <g className={cls1}>
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="FACE">
            <path d="M32.34,39.49a4.07,4.07,0,0,1-1.76.71,5.31,5.31,0,0,1-3.49-.64,3.87,3.87,0,0,1-1.38-1.3,6.74,6.74,0,0,1,1.82-.21,9.35,9.35,0,0,1,1.61.16,10.19,10.19,0,0,1,1.57.43A7.1,7.1,0,0,1,32.34,39.49Z" />
            <path
              className={cls2}
              d="M34.17,23A.85.85,0,0,1,34,24.24c-11.86,7.39-23.21,4.38-21.23-10.08C16.4,11.18,26.28,14.33,34.17,23Z"
            />
            <path
              className={cls3}
              d="M34.17,23A.85.85,0,0,1,34,24.24c-11.86,7.39-23.21,4.38-21.23-10.08C16.4,11.18,26.28,14.33,34.17,23Z"
            />
            <circle cx="29.45" cy="22.31" r="3.87" />
            <path
              className={cls2}
              d="M34.84,21.43C29.58,12.27,23.09,8.85,12.92,4.77l.14,2.94a29.21,29.21,0,0,0-9.47,0,27.3,27.3,0,0,0,6.08,8.59c-.23-1.54.42-2.84.83-4.42C21.5,11.6,35.3,22.24,34.84,21.43Z"
            />
            <path
              className={hairColour}
              d="M34.84,21.43C29.58,12.27,23.09,8.85,12.92,4.77l.14,2.94a29.21,29.21,0,0,0-9.47,0,27.3,27.3,0,0,0,6.08,8.59c-.23-1.54.42-2.84.83-4.42C21.5,11.6,35.3,22.24,34.84,21.43Z"
            />
            <g className={cls5}>
              <path
                className={cls6}
                d="M35.56,21.93C28.38,15.11,19.93,10.5,9.25,9.74L5,12.26l5.66,6.28L11.58,13a67.3,67.3,0,0,1,24,10.13Z"
              />
            </g>
            <path
              className={cls7}
              d="M32.94,18.71l-4.61-3.49C26.78,14.09,25.22,13,23.62,12S20.39,10,18.7,9.2s-3.4-1.63-5.15-2.34L14.79,6,14.63,8.2l-.07,1L13.65,9c-1.64-.33-3.3-.57-5-.77L3.59,7.68a36.55,36.55,0,0,1,5.13-.53,36.08,36.08,0,0,1,5.19.18l-1,.81L13,6V4.72l1.22.41a38.11,38.11,0,0,1,5.43,2.3,38,38,0,0,1,5,3.1,38.67,38.67,0,0,1,4.47,3.8A40,40,0,0,1,32.94,18.71Z"
            />
            <path
              className={cls8}
              d="M35.11,25.24c-6.3-3.3-12.37-6.3-19.56-7.3-.52,5.75,5,9,9.69,11C16.06,30.62,9.5,24,11.58,13a86.38,86.38,0,0,1,24,10.13Z"
            />
            <path
              className={cls2}
              d="M46.94,24.52c6.78,5.38,11.83,1.73,12.29-10.07C56.36,13.52,46.93,20.76,46.94,24.52Z"
            />
            <path
              className={cls3}
              d="M46.94,24.52c6.78,5.38,11.83,1.73,12.29-10.07C56.36,13.52,46.93,20.76,46.94,24.52Z"
            />
            <circle cx="55.83" cy="20.18" r="3.87" />
            <path
              className={cls8}
              d="M59.16,15.84a42.83,42.83,0,0,0-9.93,8.36c1.57,1.4,5.64,1.75,8.54,1.13-3.23,3.41-8.65,3-12.43-.58A31,31,0,0,1,59,13.05Z"
            />
            <path
              className={cls2}
              d="M45.5,21.43c3.17-9.16,7.07-12.58,13.19-16.66L58.6,7.71a10.71,10.71,0,0,1,5.7,0,31.44,31.44,0,0,1-3.66,8.59,17.29,17.29,0,0,0-.5-4.42C53.52,11.6,45.22,22.24,45.5,21.43Z"
            />
            <path
              className={hairColour}
              d="M45.5,21.43c3.17-9.16,7.07-12.58,13.19-16.66L58.6,7.71a10.71,10.71,0,0,1,5.7,0,31.44,31.44,0,0,1-3.66,8.59,17.29,17.29,0,0,0-.5-4.42C53.52,11.6,45.22,22.24,45.5,21.43Z"
            />
            <g className={cls5}>
              <path
                className={cls6}
                d="M45,22.46c1.4-5.23,8.48-11.62,16.9-12.9l2.27,1.92-3.84,6.45-.69-4.88c-7.32,2-10.36,6.14-14,9.93Z"
              />
            </g>
            <path
              className={cls7}
              d="M47,18.1a26,26,0,0,1,1.75-3.69,22.94,22.94,0,0,1,8.65-8.76l1.34-.75.06,1.44c0,.58,0,1.17,0,1.75l-.89-.68a22.44,22.44,0,0,1,6.39.27c-2.1.39-4.13.8-6.15,1.25l-.7.16-.19-.84C57.13,7.67,57,7.1,57,6.52l1.39.68c-1.06.76-2.11,1.52-3.1,2.36s-2,1.7-2.87,2.62-1.8,1.91-2.69,2.91S47.93,17.12,47,18.1Z"
            />
            <path d="M39.59,13c-.52-.8-.93-1.52-1.39-2.18A18.48,18.48,0,0,0,36.79,9c-.51-.57-1-1.13-1.63-1.7S34,6.11,33.33,5.38A5.86,5.86,0,0,1,36,6a6.71,6.71,0,0,1,2.25,1.69A5.59,5.59,0,0,1,39.59,13Z" />
            <path d="M43.38,13a3,3,0,0,1-.31-2,4.7,4.7,0,0,1,.75-1.83,4.81,4.81,0,0,1,1.41-1.36,3.67,3.67,0,0,1,1.92-.57,11.15,11.15,0,0,1-.81,1.69c-.28.49-.57.94-.87,1.37s-.61.86-.95,1.29Z" />
          </g>
        </g>
      </g>
    </Svg>
  )
}
