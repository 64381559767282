import { Link, navigate } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"

import { useFetchPayment } from "@redux/ducks/misc/payment/hooks"
import { useFetchUserSynced } from "@redux/ducks/user-synced/hooks"

import { SpacedWrapper } from "@components/Containers/positioning-wrappers"
import { ContainerRowCol } from "@components/Containers/positioning-wrappers"
import { GoogleFormLinkGeneralFeedback } from "@components/Forms/GoogleFormLink"
import { CenteredLoader } from "@components/Loader"
import { createMonster } from "@components/Monster"
import { ContactEmail } from "@components/contact-email"
import { ErrorMessage } from "@components/error-message"
import { AppLayout } from "@components/layouts/app-layout"
import { RenderByMetaStatusValue } from "@components/render-by-meta-status-value"
import { RouterItem } from "@components/types"

import { MultipleChoiceProblemView } from "@views/multiple-choice-problem"
import {
  ProblemV2MultipleChoiceType,
  UNSCORED_DIFFICULTY,
} from "@views/multiple-choice-problem/types"
import { QUERY_PARAM_KEY_PROBLEM_ID } from "@views/physics/common/constants"
import { usePhysicsProblemMetaState } from "@views/physics/common/hooks"
import { getMonsterTypeByPhysicsModule } from "@views/physics/common/utils/get-monster-type-by-module"

import { useIsSignedIn } from "@helpers/auth"
import { LINK_PHYSICS_LIST } from "@helpers/constants/links"
import { D_BLOCK, MB_3, MT_3 } from "@helpers/constants/style"
import {
  META_STATUS_LOADING,
  META_STATUS_SUCCESS,
} from "@helpers/hooks/use-meta-status/constants"
import { useQueryString } from "@helpers/hooks/use-query-string"
import { isString } from "@helpers/validation"

import { useSetProblemIntoStore, useSetInProductOnboardingShow } from "./hooks"

export const PhysicsPracticeProblemView = (props: RouterItem) => {
  return (
    <AppLayout>
      <PhysicsPracticeProblemContainer />
    </AppLayout>
  )
}

const PhysicsPracticeProblemContainer = () => {
  const { paramValue: problemIdParamValue } = useQueryString(
    QUERY_PARAM_KEY_PROBLEM_ID
  )

  const {
    data: problem,
    isFetching,
    hasErrored,
  } = usePhysicsProblemMetaState(
    typeof problemIdParamValue === "string" ? problemIdParamValue : ""
  )

  const { status: paymentStatus } = useFetchPayment()

  if (hasErrored) {
    return (
      <ContainerRowCol>
        <ErrorMessage />
      </ContainerRowCol>
    )
  }

  if (isFetching || paymentStatus === META_STATUS_LOADING) {
    return (
      <ContainerRowCol>
        <CenteredLoader />
      </ContainerRowCol>
    )
  }

  if (!isString(problemIdParamValue)) {
    // No param value is set
    return (
      <ContainerRowCol>
        <section className="section">
          <h1>No problem was selected</h1>
          <Link to={LINK_PHYSICS_LIST} className={D_BLOCK}>
            Head back and select a problem to challenge.
          </Link>
        </section>
      </ContainerRowCol>
    )
  }

  if (problem === undefined) {
    // No data could be found for the problem being requested
    return (
      <ContainerRowCol>
        <section className="section">
          <h1>Problem couldn't be found</h1>
          <p>
            Problem <strong>{problemIdParamValue}</strong> couldn't be found.
          </p>
          <p>
            If you believe this isn't correct, please email <ContactEmail /> for
            support.
          </p>
          <Link to={LINK_PHYSICS_LIST} className={D_BLOCK}>
            Head back and select a different problem to challenge.
          </Link>
        </section>
      </ContainerRowCol>
    )
  }

  return <PhysicsPracticeProblem problem={problem} />
}

const PhysicsPracticeProblem = ({
  problem,
}: {
  problem: ProblemV2MultipleChoiceType
}) => {
  useSetProblemIntoStore(problem)
  useSetInProductOnboardingShow(false)

  const { metaStatusValue } = useFetchUserSynced()
  const isSignedIn = useIsSignedIn()

  const { monster, monsterTitle } = createMonster({
    difficulty:
      problem.difficulty === undefined
        ? UNSCORED_DIFFICULTY
        : problem.difficulty,
    dungeonMonster: getMonsterTypeByPhysicsModule(
      problem.syllabusCodes[0].moduleCode
    ),
  })

  const aboveProblemPartElement = (
    <SpacedWrapper>
      <Link to={LINK_PHYSICS_LIST} className={`${D_BLOCK} ${MB_3}`}>
        <Button>Back</Button>
      </Link>
      <GoogleFormLinkGeneralFeedback />
    </SpacedWrapper>
  )

  const belowSolutionElement = (
    <Button
      variant="success"
      className={`${D_BLOCK} ${MT_3}`}
      onClick={() => {
        navigate(LINK_PHYSICS_LIST)
      }}
    >
      Back to Dungeon
    </Button>
  )

  const successElement = (
    <MultipleChoiceProblemView
      aboveProblemPartElement={aboveProblemPartElement}
      belowSolutionElement={belowSolutionElement}
      monster={monster}
      monsterTitle={monsterTitle}
    />
  )

  return (
    <RenderByMetaStatusValue
      metaStatusValue={isSignedIn ? metaStatusValue : META_STATUS_SUCCESS}
      successElement={successElement}
    />
  )
}
