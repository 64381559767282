import React from "react"
import { useDispatch } from "react-redux"

import { NavButton } from "@components/nav-item/nav-button"

import { signIn, signOut, useUserFromState, getIsSignedIn } from "@helpers/auth"

export const SignInOrOutButton = () => {
  const user = useUserFromState()

  const isSignedIn = getIsSignedIn(user)

  const dispatch = useDispatch()

  return (
    <NavButton
      onClick={
        isSignedIn
          ? () => {
              signOut(dispatch)
            }
          : signIn
      }
      label={isSignedIn ? "Sign Out" : "Sign In"}
    />
  )
}
