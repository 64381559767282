import { CrossedSwordsEmoji } from "accessible-emojis"
import React from "react"
import Button from "react-bootstrap/Button"
import { useSelector } from "react-redux"

import { L1_TO_L2_MODAL } from "@redux/ducks/ui/modal"
import { selectModalId } from "@redux/selectors"

import { SimpleCenteredModal } from "@components/centered-modal"

import { signIn } from "@helpers/auth"
import { H2, TEXT_PRIMARY, MB_0, D_BLOCK } from "@helpers/constants/style"

const HeaderContent = () => (
  <div className={`${H2} ${TEXT_PRIMARY} ${MB_0}`}>
    Create A Free Account <CrossedSwordsEmoji />
  </div>
)
const BodyContent = ({
  completedQuestionsCount,
}: {
  completedQuestionsCount: number
}) => (
  <section>
    <p>
      Congrats, you've completed {completedQuestionsCount} questions! Create a
      free account to continue the adventure.
    </p>
    <p>With a free account you can*:</p>
    <ul>
      <li>Get answers and solutions.</li>
      <li>Track the questions you've done.</li>
      <li>Access notes and videos.</li>
    </ul>
    <strong className={D_BLOCK}>*Limited to the most recent past paper.</strong>
  </section>
)
const FooterContent = () => <Button onClick={signIn}>Create Account</Button>

// L1 = Unsigned Users
// L2 = Free Signed Users
export const L1ToL2Modal = () => {
  const show = useSelector(selectModalId) === L1_TO_L2_MODAL

  if (!show) {
    return null
  }

  return (
    <SimpleCenteredModal
      show={show}
      headerContent={<HeaderContent />}
      bodyContent={<BodyContent completedQuestionsCount={3} />}
      footerContent={<FooterContent />}
      mode="free"
    />
  )
}
