import { CheckMarkEmoji, CrossMarkEmoji } from "accessible-emojis"
import React from "react"

import { HOVER_CLICKABLE, CURSOR_POINTER } from "@helpers/constants/style"
import { FONT_WEIGHT_NORMAL } from "@helpers/constants/style"

import { StatefulMultipleChoiceOptionType } from "./types"

type Props = {
  statefulOption: StatefulMultipleChoiceOptionType
  onClickCallback?: (statefulOption: StatefulMultipleChoiceOptionType) => void
}

export const MultipleChoiceOption = ({
  statefulOption,
  onClickCallback,
}: Props) => {
  // TODO consider accessibility and turning these into buttons

  const shouldBeSelected = Boolean(statefulOption.shouldBeSelected)
  const marked = shouldBeSelected ? (
    <CheckMarkEmoji className={FONT_WEIGHT_NORMAL} />
  ) : (
    <CrossMarkEmoji className={FONT_WEIGHT_NORMAL} />
  )

  const className = statefulOption.selected
    ? undefined
    : `${HOVER_CLICKABLE} ${CURSOR_POINTER}`

  const onClick = statefulOption.selected
    ? undefined
    : () => {
        statefulOption.setSelected(true)
        onClickCallback && onClickCallback(statefulOption)
      }

  return (
    <p className={className} onClick={onClick}>
      <span>{statefulOption.label}) </span>
      {statefulOption.content}
      {statefulOption.selected && marked}
    </p>
  )
}
