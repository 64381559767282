// extracted by mini-css-extract-plugin
export var cls1 = "dwh-module--cls1--S-L6N";
export var cls10 = "dwh-module--cls10--Bzi3y";
export var cls11 = "dwh-module--cls11--U4TPU";
export var cls12 = "dwh-module--cls12--WewTn";
export var cls13 = "dwh-module--cls13--7H4Kq";
export var cls14 = "dwh-module--cls14--U1UDH";
export var cls15 = "dwh-module--cls15--10F-G";
export var cls16 = "dwh-module--cls16--Zcdau";
export var cls4 = "dwh-module--cls4--U8jda";
export var cls5 = "dwh-module--cls5--ev-wN";
export var cls6 = "dwh-module--cls6--HYymZ";
export var cls9 = "dwh-module--cls9--+hT4m";