import { PHYSICS_PROBLEMS } from "@views/physics/common/constants"

import { createRemoteThunk } from "@helpers/create-remote-thunk"
import { useMetaState } from "@helpers/hooks/use-meta-state"

export const usePhysicsProblemMetaState = (problemKey: string) => {
  const remoteThunk = createRemoteThunk(
    `${PHYSICS_PROBLEMS}/${problemKey}.json`
  )

  const metaState = useMetaState({
    key: problemKey,
    remoteThunk,
  })

  return metaState
}
