import * as Driver from "driver.js"
import { useEffect } from "react"

import { NoArgVoid } from "@components/types"

interface DriverStep {
  element: string
  popover: {
    title?: string
    description: string
    position?: string
  }
}

export interface UseDriverActionsProps {
  steps: DriverStep[]
  shouldShow: boolean
  callback?: NoArgVoid
  driverOptions?: {
    onReset: () => void
  }
}

export function useDriverActions({
  steps,
  shouldShow,
  driverOptions,
}: UseDriverActionsProps) {
  useEffect(() => {
    if (shouldShow) {
      const timeout = setTimeout(() => {
        const driver = new Driver({
          allowClose: false,
          closeBtnText: "X",
          ...driverOptions,
        })

        driver.defineSteps(steps)

        driver.start()
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [shouldShow])
}
