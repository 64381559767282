import React from "react"
import Button from "react-bootstrap/Button"

import { Input } from "@components/Form/Input"
import { Wrapper } from "@components/Form/types"

import { HasntAnsweredViewProps } from "./types"

const LabelWrapper = ({ children }: Wrapper) => <b>{children}</b>

export const HasntAnsweredView = ({
  setHasAnswered,
  inputProps,
  hasAnsweredCorrectlyCallback,
}: HasntAnsweredViewProps) => {
  return (
    <>
      <Input LabelWrapper={LabelWrapper} {...inputProps}></Input>

      <Button
        variant="success"
        className="mb-4"
        onClick={() => {
          if (inputProps.value !== "") {
            hasAnsweredCorrectlyCallback &&
              hasAnsweredCorrectlyCallback(inputProps.value)
            setHasAnswered(true)
          }
        }}
      >
        Answer
      </Button>
    </>
  )
}
