import React from "react"

import { CenteredLoader } from "@components/Loader"
import { ErrorMessage } from "@components/error-message"

import {
  META_STATUS_NEUTRAL,
  META_STATUS_LOADING,
  META_STATUS_ERROR,
  META_STATUS_SUCCESS,
} from "@helpers/hooks/use-meta-status/constants"
import { MetaStatusValueType } from "@helpers/hooks/use-meta-status/types"

export { getCompoundMetaStatusValue } from "./util"

// A helper component to render out based on the render status and to make sane defaults

interface Props {
  metaStatusValue: MetaStatusValueType
  neutralElement?: JSX.Element | null
  loadingElement?: JSX.Element
  errorElement?: JSX.Element
  successElement: JSX.Element
}

export const RenderByMetaStatusValue = ({
  metaStatusValue,
  neutralElement = null,
  loadingElement = <CenteredLoader />,
  errorElement = <ErrorMessage />,
  successElement,
}: Props) => {
  switch (metaStatusValue) {
    default:
    case META_STATUS_NEUTRAL:
      return neutralElement
    case META_STATUS_SUCCESS:
      return successElement
    case META_STATUS_LOADING:
      return loadingElement
    case META_STATUS_ERROR:
      return errorElement
  }
}
