import { navigate } from "gatsby"
import React from "react"
import { useDispatch } from "react-redux"

import { ContainerRowCol } from "@components/Containers/positioning-wrappers"
import { Loader } from "@components/Loader"
import { ErrorMessage } from "@components/error-message"

import { resetUIState } from "@views/multiple-choice-problem/utilites/resetUIState"
import { QUERY_PARAM_KEY_PROBLEM_ID } from "@views/physics/common/constants"
import { usePhysicsProblemMetaState } from "@views/physics/common/hooks"

import { LINK_PHYSICS_PROBLEM } from "@helpers/constants/links"

import { PracticeRow } from "./practice-row"
import { PracticeRowContainerProps } from "./types"

export const PracticeRowContainer = ({
  problemKey,
}: PracticeRowContainerProps) => {
  const {
    data: problem,
    isFetching,
    hasErrored,
  } = usePhysicsProblemMetaState(problemKey)

  const dispatch = useDispatch()

  if (isFetching) {
    return (
      <ContainerRowCol>
        <Loader />
      </ContainerRowCol>
    )
  }

  if (hasErrored) {
    return (
      <ContainerRowCol>
        <ErrorMessage />
      </ContainerRowCol>
    )
  }

  if (problem === undefined) {
    return null
  }

  const onBegin = () => {
    resetUIState(dispatch)
    navigate(
      `${LINK_PHYSICS_PROBLEM}?${QUERY_PARAM_KEY_PROBLEM_ID}=${problem.id}`
    )
  }

  return <PracticeRow onBegin={onBegin} problem={problem} />
}
