import { Cache } from "aws-amplify"

interface Props {
  key: string
  remoteThunk: () => Promise<any>
}
const THREE_HOURS = 10800000

export const getFromCacheOrRemote = async ({ key, remoteThunk }: Props) => {
  // Try to get the data from cache
  const dataFromCache = Cache.getItem(key)
  console.log({ dataFromCache })
  // If successful, return the data from cache
  if (dataFromCache) {
    return dataFromCache
  }

  // Couldn't find the value in the cache
  // Fetch it instead, and then set it into the cache
  const dataFromRemote = await remoteThunk()
  console.log({ dataFromRemote })
  if (dataFromRemote != null) {
    // Don't set it into the cache if the remote data is null / undefined.
    const now = new Date().getTime()
    Cache.setItem(key, dataFromRemote, { expires: now + THREE_HOURS })
  }

  return dataFromRemote
}
