import React from "react"

import { MonsterPropsType } from "@components/types"

import { DifficultyType } from "@views/multiple-choice-problem/types"

import bladeDemon from "@images/monsters/demons/blade-demon.png"
import demonChampion from "@images/monsters/demons/demon-champion.png"
import poisonDemon from "@images/monsters/demons/poison-demon.svg"

const commonImgProps = {
  className: "d-block w-100",
  style: {
    maxWidth: "157px",
  },
}

export function demonCases(difficulty: DifficultyType): MonsterPropsType {
  if (difficulty >= 2 && difficulty <= 3) {
    return {
      src: poisonDemon,
      imgEl: <img {...commonImgProps} src={poisonDemon} alt="poison demon" />,
      monsterTitle: "Poison Demon",
      containerClassName: "containerGreater",
    }
  } else if (difficulty >= 4) {
    return {
      src: demonChampion,
      imgEl: (
        <img {...commonImgProps} src={demonChampion} alt="demon champion" />
      ),
      monsterTitle: "Demon Champion",
      containerClassName: "containerMajor",
    }
  }

  return {
    src: bladeDemon,
    imgEl: <img {...commonImgProps} src={bladeDemon} alt="blade demon" />,
    monsterTitle: "Blade Demon",
    containerClassName: "containerMinor",
  }
}
