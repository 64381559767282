import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { PaymentPayloadInput } from "@src/API"

import { H2 } from "@helpers/constants/style"
import { getIsLocalHost } from "@helpers/misc"

import { DropIn } from "../drop-in"

const OrderSummary = ({ amount }: { amount: number }) => {
  return (
    <>
      <h1 className={H2}>Order Summary</h1>
      <p>
        <strong>2022 Special Offer: </strong>Use EduCats for only ${amount} for
        the rest of 2022. One time payment.
      </p>
      <p>Regular recurring price of $10 / month starting in 2023.</p>
    </>
  )
}

export const ReadyView = ({
  paymentPayload,
  onSuccess,
}: {
  paymentPayload: PaymentPayloadInput
  onSuccess: (event: CustomEvent) => void
}) => {
  const env = getIsLocalHost() ? "demo" : "prod"
  return (
    <Container>
      <Row>
        <Col xs={12} md={6}>
          <OrderSummary amount={paymentPayload.amount} />
        </Col>
        <Col xs={12} md={6}>
          <DropIn
            env={env}
            paymentPayload={paymentPayload}
            onSuccess={onSuccess}
          />
        </Col>
      </Row>
    </Container>
  )
}
