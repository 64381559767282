import { Cache } from "aws-amplify"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import {
  PhysicsLinkButton,
  EnglishLinkButton,
} from "@components/Buttons/link-button"
import { CenteredWrapper } from "@components/Containers/positioning-wrappers"
import { AppLayout } from "@components/layouts/app-layout"
import { LogoImg } from "@components/logo"
import { RouterItem } from "@components/types"

import { LINK_PHYSICS, LINK_ENGLISH } from "@helpers/constants/links"
import { TEXT_CENTER, MB_4 } from "@helpers/constants/style"

export const SubjectSelectionView = (props: RouterItem) => {
  return (
    <AppLayout>
      <SubjectSelection />
    </AppLayout>
  )
}

const SubjectSelection = () => {
  Cache.clear()

  return (
    <Container className={TEXT_CENTER}>
      <Row>
        <Col xs={12}>
          <CenteredWrapper>
            <div>
              <CenteredWrapper>
                <LogoImg />
              </CenteredWrapper>
              <Col xs={12}>
                <h1>Subject Selection</h1>
              </Col>
            </div>
          </CenteredWrapper>
          <p>
            Practice against a set of real past paper and past paper style
            questions designed to help you become more exam ready for the HSC
            exam.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ span: 4, offset: 2 }} className={MB_4}>
          <EnglishLinkButton to={LINK_ENGLISH} />
        </Col>
        <Col xs={12} md={4} className={MB_4}>
          <PhysicsLinkButton to={LINK_PHYSICS} />
        </Col>
      </Row>
    </Container>
  )
}
