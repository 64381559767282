import React from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  selectRootState,
  selectOptionsWereSelected,
  selectOptionsAreLocked,
} from "@redux/selectors"
import { ReduxType } from "@redux/types"

import { OptionsType } from "@views/multiple-choice-problem/types"

import { PROBLEM_OPTIONS_DOM_ID } from "./constants"
import { L1ToL2Modal } from "./l1-to-l2-modal"
import { L2ToL3Modal } from "./l2-to-l3-modal"
import { Option } from "./option"
import { createOnClickHandler } from "./utils/create-on-click-handler/index"
import { getIsOptionUnclickable } from "./utils/get-is-option-unclickable"

interface Props {
  options: OptionsType
  onAnsweredCorrectly: (args: ReduxType) => void
  answer: string
}

export const Options = ({ options, onAnsweredCorrectly, answer }: Props) => {
  const dispatch = useDispatch()
  const rootState = useSelector(selectRootState)
  const optionsWereSelected = useSelector(selectOptionsWereSelected)
  const optionsAreLocked = useSelector(selectOptionsAreLocked)

  const optionsList = Object.keys(options)

  return (
    <>
      <section id={PROBLEM_OPTIONS_DOM_ID}>
        {optionsList.map((optionKey: string, i: number) => {
          const isUnclickable = getIsOptionUnclickable({
            optionKey,
            optionsWereSelected,
            optionsAreLocked,
          })
          const onClick = isUnclickable
            ? undefined
            : createOnClickHandler({
                optionKey,
                onAnsweredCorrectly,
                answer,
                rootState,
                dispatch,
              })

          return (
            <Option
              key={`option-${optionKey}`}
              optionKey={optionKey}
              optionContent={options[optionKey]}
              optionsWereSelected={optionsWereSelected}
              answer={answer}
              isUnclickable={isUnclickable}
              onClick={onClick}
            />
          )
        })}
      </section>
      <L1ToL2Modal />
      <L2ToL3Modal />
    </>
  )
}
