import { useDispatch, useSelector } from "react-redux"

import {
  setModalActionCreator,
  L1_TO_L2_MODAL,
  L2_TO_L3_MODAL,
} from "@redux/ducks/ui/modal"
import {
  selectUserCategory,
  selectProblem,
  selectPhysicsQuestionsAnsweredCount,
} from "@redux/selectors"

import { NoArgVoid } from "@components/types"

import {
  USER_CATEGORY_L1,
  USER_CATEGORY_L2,
} from "@helpers/constants/user-category"
import { isNumber } from "@helpers/validation"

interface UseInteractionStructureProps {
  ifSpentCount: number
  onUserIsCategoryL1?: NoArgVoid
  onUserIsCategoryL2AndLockedFromProblem?: NoArgVoid
  onCanAfford: NoArgVoid
  onCantAfford: NoArgVoid
}

const getIsLockedFromProblem = ({
  problemYear,
}: {
  problemYear: number | undefined
}) => {
  return false

  // Signed free users should only be able to access up to 1 years worth of questions for now
  const dyanmicCurrentYear = new Date().getFullYear()
  const currentYear = dyanmicCurrentYear >= 2022 ? dyanmicCurrentYear : 2022

  if (problemYear === undefined || !isNumber(problemYear)) {
    return true
  }

  if (currentYear - problemYear > 1) {
    return true
  }

  return false
}

export const useInteractionStructure = ({
  ifSpentCount,
  onUserIsCategoryL1,
  onUserIsCategoryL2AndLockedFromProblem,
  onCanAfford,
  onCantAfford,
}: UseInteractionStructureProps) => {
  const userCategory = useSelector(selectUserCategory)

  const problem = useSelector(selectProblem)
  const physicsQuestionsAnsweredCount = useSelector(
    selectPhysicsQuestionsAnsweredCount
  )
  const dispatch = useDispatch()

  if (userCategory === USER_CATEGORY_L1 && physicsQuestionsAnsweredCount > 3) {
    // Open the Sign Up modal by default
    if (onUserIsCategoryL1 === undefined) {
      return () => {
        dispatch(
          setModalActionCreator({
            modalId: L1_TO_L2_MODAL,
          })
        )
      }
    }

    return onUserIsCategoryL1
  } else if (userCategory === USER_CATEGORY_L2) {
    const isLockedFromProblem = getIsLockedFromProblem({
      problemYear: problem?.origin.year,
    })

    if (isLockedFromProblem) {
      // Open the pay modal
      if (onUserIsCategoryL2AndLockedFromProblem === undefined) {
        return () => {
          dispatch(
            setModalActionCreator({
              modalId: L2_TO_L3_MODAL,
            })
          )
        }
      }

      return onUserIsCategoryL2AndLockedFromProblem
    }
  }

  const canAfford = ifSpentCount >= 0

  if (canAfford) {
    return onCanAfford
  } else {
    return onCantAfford
  }
}
