import { emojiHOC } from "accessible-emojis"
import React from "react"
import { useSelector } from "react-redux"

import { L2_TO_L3_MODAL } from "@redux/ducks/ui/modal"
import { selectModalId } from "@redux/selectors"

import { LinkButton } from "@components/Buttons/link-button"
import { SimpleCenteredModal } from "@components/centered-modal"

import { LINK_CHECKOUT } from "@helpers/constants/links"
import { H2, TEXT_PRIMARY, MB_0, D_BLOCK } from "@helpers/constants/style"

const HeaderContent = () => (
  <div className={`${H2} ${TEXT_PRIMARY} ${MB_0}`}>
    Upgrade to a Paid Account to unlock{" "}
    {emojiHOC({
      ariaLabel: "lock",
      emoji: "🔒",
    })}
  </div>
)
const BodyContent = () => (
  <section>
    <p>
      This question is not covered by the <strong>free tier</strong>. Upgrade to
      a <strong>paid account</strong> to unlock.
    </p>
    <ul>
      <li>Unlock the whole catalog of questions.</li>
      <li>
        Continue the journey by answering more questions and gaining more stars.
      </li>
      <li>Use the stars to unlock more loot.</li>
    </ul>
  </section>
)
const FooterContent = () => <LinkButton to={LINK_CHECKOUT}>Upgrade</LinkButton>

// L1 = Unsigned Users
// L2 = Free Signed Users
// L3 = Paid Signed Users
export const L2ToL3Modal = () => {
  const show = useSelector(selectModalId) === L2_TO_L3_MODAL

  if (!show) {
    return null
  }

  return (
    <SimpleCenteredModal
      show={show}
      headerContent={<HeaderContent />}
      bodyContent={<BodyContent />}
      footerContent={<FooterContent />}
      mode="free"
    />
  )
}
