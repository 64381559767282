import React from "react"

import { HairAsset } from "@components/Avatar/types"
import { Svg } from "@components/Svg"

import {
  cls1,
  cls4,
  cls5,
  cls6,
  cls9,
  cls10,
  cls11,
  cls12,
  cls13,
  cls14,
  cls15,
  cls16,
} from "./dwh.module.scss"

const transform = "translate(-255.63 -358.72)"
export const InnerDwarfHair = ({ skinTone, hairColour }: HairAsset) => {
  return (
    <Svg viewBox="0 0 89.04 124.45">
      <g className={cls1}>
        <g id="Layer_1" data-name="Layer 1">
          <g id="HEAD">
            <g id="HAIR">
              <path
                className={cls5}
                d="M302.4,361.24c-10.07-.17-26.39.42-37.62,14.57-13.52,17-1.53,49.34-5.65,62.39,4.35,1.93,13.5,2,13.5,2l.84,7.67,9.83-4.34,5.56,5.84,7.94-5.5L303,454.08l6.24-3.21,7.71,10,7.78-12.36,4.92.86c1.08-2.92,1.54-6.05,2.59-8.75l3.12.75-.6-9.38-2.66,3.68L318,431.42l-21.62,3.84-14.91-5.89A38.39,38.39,0,0,1,275.63,413,85,85,0,0,0,279.35,397l4.49-1.5s.32-9.75,10.66-9.93c6.44-.11,20,4.21,25.4,9,6.16-5.86,16.42-7.52,21.83,4.28C344.78,385.77,331.87,361.75,302.4,361.24Z"
                transform={transform}
              />
              <path
                className={hairColour}
                d="M302.4,361.24c-10.07-.17-26.39.42-37.62,14.57-13.52,17-1.53,49.34-5.65,62.39,4.35,1.93,13.5,2,13.5,2l.84,7.67,9.83-4.34,5.56,5.84,7.94-5.5L303,454.08l6.24-3.21,7.71,10,7.78-12.36,4.92.86c1.08-2.92,1.54-6.05,2.59-8.75l3.12.75-.6-9.38-2.66,3.68L318,431.42l-21.62,3.84-14.91-5.89A38.39,38.39,0,0,1,275.63,413,85,85,0,0,0,279.35,397l4.49-1.5s.32-9.75,10.66-9.93c6.44-.11,20,4.21,25.4,9,6.16-5.86,16.42-7.52,21.83,4.28C344.78,385.77,331.87,361.75,302.4,361.24Z"
                transform={transform}
              />
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M328.3,446.72l-2.94-.79-8.64,6.68-2.65-5.5-9.52,2.95-7.56-7-7.56,3.33-5.79-5.49-7.8,2.63.7-7.94-4.74-1.95.17-9.55s-.11-2.78.66-12.42c-5.33-17-3.35-42.5,35.52-51.65-28.18-1.52-36.46,6-45.6,16.63-6.58,7.69-6.13,31.72-5.08,41.24,1.73,12.16,1.5,16.45.16,20.45,4,1.93,13.93,2.82,13.93,2.82l1.36,7.9,9.75-4.92,5.68,6.38,8.35-4.81,5.88,9.91,6.39-3.43,8,9.32,7.76-12,5.4.39,1.77-6.87Z"
                  transform={transform}
                />
              </g>
              <g className={cls11}>
                <path
                  className={cls12}
                  d="M264.78,375.81a31.47,31.47,0,0,0-5.44,14.73,81.05,81.05,0,0,0-.22,15.81c.36,5.29,1.07,10.55,1.57,15.88.24,2.66.45,5.34.49,8.06a27.7,27.7,0,0,1-.9,8.27l-.66-1.46a15.81,15.81,0,0,0,3,.9c1.07.23,2.17.39,3.28.53,2.21.26,4.49.39,6.7.43l1.11,0,.12,1.09.82,7.66-1.72-1,9.84-4.31.79-.34.57.61,5.52,5.88-1.48-.16,8-5.42.95-.65.56,1,6,10.31-1.14-.32,6.3-3.1.56-.28.36.49,3.77,5.06,3.72,5.11-.75,0,4.06-6.07c1.35-2,2.74-4,4.11-6-1.23,2.1-2.43,4.22-3.68,6.31l-3.71,6.29-.33.56-.43-.52-4-4.9-3.94-4.93.93.21-6.19,3.32-.69.37-.44-.69-6.35-10.11,1.52.32-7.89,5.57-.8.56-.69-.71-5.6-5.8,1.36.28L274,449l-1.55.68-.18-1.68-.84-7.66,1.22,1.1c-1.18,0-2.33-.07-3.49-.15s-2.32-.19-3.48-.34a34.79,34.79,0,0,1-3.49-.58,18.09,18.09,0,0,1-3.51-1.07l-1-.42.31-1a27,27,0,0,0,.9-7.54c0-2.61-.15-5.25-.34-7.89-.39-5.29-.86-10.62-1.07-16a73.24,73.24,0,0,1,.88-16.06C259.34,385.12,261.26,379.87,264.78,375.81Z"
                  transform={transform}
                />
              </g>
              <path
                d="M273.22,413.2a12.3,12.3,0,0,0-4-2.33,3.65,3.65,0,0,0-1.7,0,3.32,3.32,0,0,0-1.25.81,7.29,7.29,0,0,0-1.81,6.66,13.53,13.53,0,0,0,1.21,3.81,9.17,9.17,0,0,0,2.22,3,3.56,3.56,0,0,1,.31.26c.1.07.2.1.3.17a3.18,3.18,0,0,0,.8.31,5.06,5.06,0,0,0,1.87.09,7.53,7.53,0,0,0,4-2.14,6.53,6.53,0,0,1-3,4.42,6.54,6.54,0,0,1-6.16.3,10.78,10.78,0,0,1-4.36-4.08,14.42,14.42,0,0,1-2-5.37,11.77,11.77,0,0,1,.56-6,9.23,9.23,0,0,1,4-4.73c.24-.13.49-.26.73-.37a8,8,0,0,1,.83-.26,5.64,5.64,0,0,1,1.74-.19,5.93,5.93,0,0,1,3,1.16,7.82,7.82,0,0,1,1.84,2.09A8.31,8.31,0,0,1,273.22,413.2Z"
                transform={transform}
              />
              <path
                className={skinTone}
                d="M273.22,413.2s-4.45-7.33-9.29-2.16c-5,5.38-.23,15.56,4.41,16.39s6.75-3.6,6.75-3.6"
                transform={transform}
              />
              <path
                d="M271,423.08a2.85,2.85,0,0,1-1,.59,1.81,1.81,0,0,1-1.31-.06,2.14,2.14,0,0,1-1-1,3.44,3.44,0,0,1-.38-1.29,3.63,3.63,0,0,1,.16-1.37,2.28,2.28,0,0,1,1-1.3,2.26,2.26,0,0,1,1.76-.2,4.27,4.27,0,0,1,1.28.65l-1.57.74-.05-1.46a12.25,12.25,0,0,0-.19-1.39,3.78,3.78,0,0,0-1-2.2,1.47,1.47,0,0,0-.88-.39,1.74,1.74,0,0,0-1.07.34,3.93,3.93,0,0,0-1.47,2.43A3.58,3.58,0,0,1,266,414a2.78,2.78,0,0,1,1.68-1,2.94,2.94,0,0,1,2,.45,5.11,5.11,0,0,1,1.93,3.14,10,10,0,0,1,.25,1.69c0,.56,0,1.11,0,1.67l0,1.91-1.53-1.18a3.92,3.92,0,0,0-.64-.41.48.48,0,0,0-.37-.05c-.21,0-.57.57-.6,1.14a1.85,1.85,0,0,0,.4,1.56,1.35,1.35,0,0,0,.82.31A3.62,3.62,0,0,0,271,423.08Z"
                transform={transform}
              />
              <path
                className={cls4}
                d="M264.39,409.72c-4.63,6.37,1.25,19.25,10.25,15.12a7.54,7.54,0,0,1-6.69,3.44c-2.87-.25-6.07-4.8-7.06-9.19A8.67,8.67,0,0,1,264.39,409.72Z"
                transform={transform}
              />
              <path
                d="M308.76,440.92a4,4,0,0,1-1.76.72,5.37,5.37,0,0,1-1.79,0,5.2,5.2,0,0,1-1.69-.61,4.17,4.17,0,0,1-1.39-1.3,6.85,6.85,0,0,1,1.83-.22,9.34,9.34,0,0,1,1.61.17,8.55,8.55,0,0,1,1.57.43A6.62,6.62,0,0,1,308.76,440.92Z"
                transform={transform}
              />
              <path
                className={cls13}
                d="M317.61,428.74s5.56,2.5,8.5,2.63,8.05,2.26,8.81,5.12c2,7.5-2.1,18.38-2.1,18.38s-2.83-11-4.93-13c-1.45-1.38-1.7-.63-5.45-1.25s-4.83-2.38-4.83-2.38a30.16,30.16,0,0,1-7.34,2.38c-3.76.62-6.53-.13-8,1.25-2.09,2-4.93,13-4.93,13s-4.09-10.88-2.1-18.38c.76-2.86,8.4-5,11.33-5.12S317.61,428.74,317.61,428.74Z"
                transform={transform}
              />
              <path
                className={hairColour}
                d="M317.61,428.74s5.56,2.5,8.5,2.63,8.05,2.26,8.81,5.12c2,7.5-2.1,18.38-2.1,18.38s-2.83-11-4.93-13c-1.45-1.38-1.7-.63-5.45-1.25s-4.83-2.38-4.83-2.38a30.16,30.16,0,0,1-7.34,2.38c-3.76.62-6.53-.13-8,1.25-2.09,2-4.93,13-4.93,13s-4.09-10.88-2.1-18.38c.76-2.86,8.4-5,11.33-5.12S317.61,428.74,317.61,428.74Z"
                transform={transform}
              />
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M296.39,455.23s-1.66-12.5,4-15.88c3.22-1.92,6.38-1.4,9.93-2.06,2.7-.51,7.26-3.23,7.26-3.23a23,23,0,0,0,5.69,3.77c2.37.92,5.45,0,6.82,1.33,2.9,2.9,4,15.5,3,17.4,0,0-1.33.36-5.92-13.9a17.36,17.36,0,0,1-9.79-2.66c-4.44,1.88-13.22,3.22-14.14,2.66-1,.48-4.11,13-5.08,13.41S296.39,455.23,296.39,455.23Z"
                  transform={transform}
                />
              </g>
              <path
                className={cls14}
                d="M303,438.43a57.94,57.94,0,0,1,7.62-1.37"
                transform={transform}
              />
              <path
                className={cls14}
                d="M331.1,440.83c.48,1.11.64,2.32,1,3.46"
                transform={transform}
              />
              <path
                className={cls14}
                d="M291.1,365.87a40,40,0,0,0-15.35,13.77"
                transform={transform}
              />
              <line
                className={cls14}
                x1="20.63"
                y1="77.19"
                x2="20.4"
                y2="80.89"
              />
              <path
                d="M316.19,439.61a5.32,5.32,0,0,0,1.18-2.32,11.69,11.69,0,0,0,.35-2.87,12.27,12.27,0,0,1,.65,3,7.06,7.06,0,0,1-.46,3.25Z"
                transform={transform}
              />
              <path
                d="M272.08,400.52a40.86,40.86,0,0,0,1.72,5.68l2,5.53a49.65,49.65,0,0,1,1.7,5.88,22.34,22.34,0,0,1,.52,6.63l-5-2.53a4.63,4.63,0,0,0,1-1.48,9.72,9.72,0,0,0,.67-2.31,36.83,36.83,0,0,0,.47-5.77c.06-2,.06-4.16.17-6.32a30.33,30.33,0,0,1,.88-6.51c.73,2.09,1.44,4.08,2.09,6.13a51.9,51.9,0,0,1,1.62,6.3,26,26,0,0,1,.46,6.91,14.2,14.2,0,0,1-.86,3.83A9.73,9.73,0,0,1,277,426.2l-5.7,4.94.74-7.47a26.84,26.84,0,0,0-.12-5.18l-.67-5.74a48.82,48.82,0,0,1-.33-6.1A16.48,16.48,0,0,1,272.08,400.52Z"
                transform={transform}
              />
              <path
                className={hairColour}
                d="M272.08,400.52c-.75,6.92,3.66,16.31,3,23.44,5.16-4.54,1.45-16.93,1.23-24.64"
                transform={transform}
              />
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M271.78,402s3.59,8.29,4.34,14.59a15.94,15.94,0,0,1-1.84,9.58s-.5-3.83-.83-6.17-1.75-11.16-1.75-13.5C271.7,403.86,271.78,402,271.78,402Z"
                  transform={transform}
                />
              </g>
              <g className={cls11}>
                <path
                  className={cls12}
                  d="M332.82,454.87c-.73-2-1.44-4-2.21-5.91a41.11,41.11,0,0,0-2.56-5.62l-.4-.58a6.08,6.08,0,0,0-.44-.44,1.74,1.74,0,0,0-1-.49c-.38-.05-.94,0-1.48-.07s-1.09-.06-1.63-.12a13.07,13.07,0,0,1-3.29-.73,8.72,8.72,0,0,1-1.59-.79,5.62,5.62,0,0,1-.75-.56,3.92,3.92,0,0,1-.72-.83l1.29.39a28.08,28.08,0,0,1-7.36,2.4,22,22,0,0,1-3.86.3,11.78,11.78,0,0,0-3.4.29,1.94,1.94,0,0,0-.57.31,3.51,3.51,0,0,0-.48.57,11.65,11.65,0,0,0-.88,1.52c-.54,1.08-1,2.22-1.49,3.37-.94,2.29-1.78,4.64-2.65,7a68.67,68.67,0,0,1,1.66-7.33c.36-1.21.74-2.41,1.22-3.6.12-.3.25-.6.4-.89a7.64,7.64,0,0,1,.47-.89,5.32,5.32,0,0,1,.68-.9,3.7,3.7,0,0,1,1-.67,6.34,6.34,0,0,1,2.07-.47c.66-.06,1.29-.08,1.91-.11a25.82,25.82,0,0,0,3.57-.39,30.85,30.85,0,0,0,6.82-2.26l1-.43.32.82a2.22,2.22,0,0,0,.33.35,3.92,3.92,0,0,0,.5.39,7,7,0,0,0,1.22.63,13.27,13.27,0,0,0,2.84.72c.5.07,1,.13,1.5.18s1,0,1.66.15a3,3,0,0,1,1,.38,4.35,4.35,0,0,1,.76.59,6.4,6.4,0,0,1,.61.72c.17.25.3.5.45.75a31.83,31.83,0,0,1,2.14,6.07Q332.29,451.74,332.82,454.87Z"
                  transform={transform}
                />
              </g>
              <path
                d="M317.4,445.67a5.82,5.82,0,0,1,5,1,6.75,6.75,0,0,1,2.75,5.25,7.73,7.73,0,0,1-2.52,5.87,9,9,0,0,1-11.86.2,7.75,7.75,0,0,1-2.74-5.74,8.15,8.15,0,0,1,.17-1.54,6.6,6.6,0,0,1,.45-1.43,6.3,6.3,0,0,1,1.75-2.4,5.61,5.61,0,0,1,2.47-1.19,5.93,5.93,0,0,1,2.48,0,11.64,11.64,0,0,0-1.72,1.46,5,5,0,0,0-1,1.59,4.32,4.32,0,0,0-.25,1.56c0,.25.05.51.08.76a3.54,3.54,0,0,0,.09.38,2.33,2.33,0,0,1,.1.32,4.12,4.12,0,0,0,4,2.4,4,4,0,0,0,3.81-2.54,4,4,0,0,0-.2-2.92A6.81,6.81,0,0,0,317.4,445.67Z"
                transform={transform}
              />
              <path
                className={hairColour}
                d="M317.4,445.67a5.93,5.93,0,0,1,5.4,5.69,6.27,6.27,0,0,1-12.5,0c0-2.67,1.5-5,5-5.73"
                transform={transform}
              />
              <path
                className={cls15}
                d="M316.5,469.2c-1,0-3.32,1.2-3.56,3.13s1.33,2.17,1.23,3.88c-.06,1.1-2.07,4-2.07,4s8.77-1.58,8.87-5.8C321,471.28,317.5,469.17,316.5,469.2Z"
                transform={transform}
              />
              <path
                className={hairColour}
                d="M316.5,469.2c-1,0-3.32,1.2-3.56,3.13s1.33,2.17,1.23,3.88c-.06,1.1-2.07,4-2.07,4s8.77-1.58,8.87-5.8C321,471.28,317.5,469.17,316.5,469.2Z"
                transform={transform}
              />
              <path
                d="M317.4,453.77a5.82,5.82,0,0,1,5,1,6.76,6.76,0,0,1,2.75,5.25,7.73,7.73,0,0,1-2.52,5.87,9,9,0,0,1-11.86.2,7.71,7.71,0,0,1-2.74-5.74,8.15,8.15,0,0,1,.17-1.54,6.6,6.6,0,0,1,.45-1.43,6.3,6.3,0,0,1,1.75-2.4,5.66,5.66,0,0,1,2.47-1.19,5.93,5.93,0,0,1,2.48,0,11.64,11.64,0,0,0-1.72,1.46,5,5,0,0,0-1,1.59,4.32,4.32,0,0,0-.25,1.56c0,.25.05.51.08.77s.05.26.08.38a1.28,1.28,0,0,1,.11.31,4.12,4.12,0,0,0,4,2.4,3.77,3.77,0,0,0,3.61-5.46A6.81,6.81,0,0,0,317.4,453.77Z"
                transform={transform}
              />
              <path
                className={hairColour}
                d="M317.4,453.77a5.93,5.93,0,0,1,5.4,5.69,6.27,6.27,0,0,1-12.5,0c0-2.67,1.5-5,5-5.73"
                transform={transform}
              />
              <path
                className={cls13}
                d="M321.18,469.37a61.78,61.78,0,0,1-9.62,0v-3.79a56.18,56.18,0,0,1,9.62,0Z"
                transform={transform}
              />
              <path
                className={cls16}
                d="M321.18,469.37a61.78,61.78,0,0,1-9.62,0v-3.79a56.18,56.18,0,0,1,9.62,0Z"
                transform={transform}
              />
              <path
                className={cls4}
                d="M310.68,464.55c2.21,3.23,8,5,11.43,5.43l-11.88-.07Z"
                transform={transform}
              />
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M318.5,445.65c-7.85,1.3-5.61,6.58-.18,8.16-5.1,2.53-5.52,8-.51,10.38l-7.67,0c-1.8-2.73-2.57-5.62-.37-9-.4-2.61-1.16-4.23.51-7.29A8.62,8.62,0,0,1,318.5,445.65Z"
                  transform={transform}
                />
              </g>
              <g className={cls9}>
                <path
                  className={cls10}
                  d="M316.55,471c2.17,6.24-.63,8.26-5.24,10.12l1.49-4.75-.62-5.87Z"
                  transform={transform}
                />
              </g>
              <path
                className={cls14}
                d="M314.58,460c-.13,1.32.74,2.42,1.46,3.43"
                transform={transform}
              />
              <path
                className={cls6}
                d="M315.86,465.87c.12.31,2.64.84,3.65.78s1.53-.33,1-.53S315.64,465.28,315.86,465.87Z"
                transform={transform}
              />
              <g className={cls11}>
                <path
                  className={cls12}
                  d="M314.19,445.93a5.41,5.41,0,0,0-2.86,3.87,4.31,4.31,0,0,0,.15,2.18,4.5,4.5,0,0,0,1.2,1.78l.57.53-.37.68c-.36.67-.69,1.34-1,2a10.63,10.63,0,0,0-.67,2.07,6.34,6.34,0,0,0-.09,2.13,6.6,6.6,0,0,0,.74,2.19,4.59,4.59,0,0,1-1.67-1.89,5.73,5.73,0,0,1-.54-2.58,8.83,8.83,0,0,1,.45-2.57,13.68,13.68,0,0,1,1-2.32l.2,1.22a5.64,5.64,0,0,1-1.66-2.72,5.29,5.29,0,0,1,.16-3.13,5.58,5.58,0,0,1,1.72-2.45A4.23,4.23,0,0,1,314.19,445.93Z"
                  transform={transform}
                />
              </g>
              <g className={cls11}>
                <path
                  className={cls12}
                  d="M313.13,471.38a6.39,6.39,0,0,0,.88,2.11,10.22,10.22,0,0,0,.59.87,2.46,2.46,0,0,1,.16.24,2.24,2.24,0,0,1,.22.4,2.72,2.72,0,0,1,.22.87,4,4,0,0,1-1,2.76,5,5,0,0,1-2.12,1.54c.25-.86.55-1.6.77-2.31a5,5,0,0,0,.34-1.82,1.33,1.33,0,0,0-.06-.3.84.84,0,0,0-.06-.14s-.09-.18-.13-.27a4.58,4.58,0,0,1-.45-1.32A3,3,0,0,1,313.13,471.38Z"
                  transform={transform}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}
