import { API } from "aws-amplify"

import { caughtAwait } from "@helpers/error-handling"
import { get } from "@helpers/get"
import { BaseServiceResponse } from "@helpers/services/types"
import {
  MutationType,
  RemoteAttributeType,
  SaveProgressType,
} from "@helpers/user-data/types"

import { createUser, updateUser } from "@graphql/mutations"
import { getUser } from "@graphql/queries"

// Create a new user DynamoDB item and save with data
const createUserMutation = async ({
  remoteId,
  remoteAttribute,
  data,
}: MutationType) => {
  await API.graphql({
    query: createUser,
    variables: {
      input: {
        id: remoteId,
        [remoteAttribute]: JSON.stringify(data),
      },
    },
  })
}

const updateUserMutation = async ({
  remoteId,
  remoteAttribute,
  data,
}: MutationType) => {
  await API.graphql({
    query: updateUser,
    variables: {
      input: {
        id: remoteId,
        [remoteAttribute]: JSON.stringify(data),
      },
    },
  })
}

// WRITES: Tries to update, then tries to create
export const saveProgress = async ({
  remoteId,
  remoteAttribute,
  value,
}: SaveProgressType) => {
  const { error: updateUserError } = await caughtAwait(() => {
    // Update the user's data
    console.log("saveProgress: updateUserMutation")
    return updateUserMutation({
      remoteId,
      remoteAttribute,
      data: value,
    })
  })

  if (updateUserError) {
    // Create new user data since assumedly it doesn't exist which is why it errored

    await caughtAwait(() => {
      console.log("createUserMutation")
      return createUserMutation({
        remoteId,
        remoteAttribute,
        data: value,
      })
    })
  }
}

// READS: Tries to read only

export const getUserRemoteData = async ({
  remoteId,
  remoteAttribute,
}: {
  remoteId: string
  remoteAttribute: RemoteAttributeType
}): Promise<BaseServiceResponse> => {
  try {
    const getUserResponse = await API.graphql({
      query: getUser,
      variables: {
        id: remoteId,
      },
    })

    const strValFromRemote = get({
      object: getUserResponse,
      path: ["data", "getUser", remoteAttribute],
      defaultValue: undefined,
    })

    console.log({ remoteAttribute, getUserResponse, strValFromRemote })

    if (strValFromRemote === undefined) {
      throw new Error("strValFromRemote is undefined")
    }

    const jsonValFromRemote = JSON.parse(strValFromRemote)

    return {
      error: undefined,
      value: jsonValFromRemote,
    }
  } catch (error) {
    console.log(error)
    return {
      error,
      value: undefined,
    }
  }
}
