import { classnames } from "classnames-joiner"
import React from "react"

import { body } from "@components/Avatar/a.module.scss"
import { BaseAvatarProps } from "@components/Avatar/types"
import { createFactoredSizing } from "@components/Avatar/utils"

import src from "./body.svg"

const sizing = {
  top: 167,
  left: 35,
  width: 191,
}

export const ScholarBody = ({ className, factor }: BaseAvatarProps) => {
  return (
    <img
      style={createFactoredSizing(sizing, factor)}
      className={classnames([body, className])}
      src={src}
      alt="scholar body"
    ></img>
  )
}
