export const ANSWER_LATER = "Answer later."
export const ANSWER_HERE = "Answer here."
export const mainQuestion = "How does this text convey new experiences?"
export const mainQuestionMarkValue = 4
export const mainQuestionWithMarkValue = `${mainQuestion} (${mainQuestionMarkValue} Marks)`

export const ENGLISH_DEMO_ACTIVE_PROBLEM_ID = "english-demo-active-problem-id"

export const ENGLISH_DEMO_USER_RESPONSES = "english-demo-user-responses"
export const ENGLISH_BATCHED_PROBLEMS = `english-batched-problems`
export const ENGLISH_REMOTE_ATTRIBUTE_KEY_COMMON_MODULE = "triangle"

export const ENGLISH_REFLECTION_LABEL_DISPLAY =
  "Reflecting on your response with the marking criteria in mind, what mark would you give yourself?"
