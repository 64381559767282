import React, { FocusEvent } from "react"
import Form from "react-bootstrap/Form"

import { D_BLOCK, TEXT_RIGHT } from "@helpers/constants/style"

import { EmptyWrapper } from "../common/EmptyWrapper"
import { InputProps, UseInputProps, TYPE_TEXT } from "./types"

export function useInputProps({
  defaultValue = "",
  ...restProps
}: UseInputProps): InputProps {
  const [value, setValue] = React.useState(defaultValue)

  return {
    ...restProps,
    value,
    setValue,
  }
}

export const Input = ({
  id,
  labelDisplay,
  LabelWrapper = EmptyWrapper,
  feedback,
  belowContent,
  ...formControlProps
}: InputProps) => {
  const {
    type = TYPE_TEXT,
    value,
    setValue,
    ...otherFormControlProps
  } = formControlProps

  return (
    <Form.Group controlId={id}>
      <LabelWrapper>
        <Form.Label>{labelDisplay}</Form.Label>
      </LabelWrapper>

      <Form.Control
        type={type}
        defaultValue={value}
        onBlur={(e: FocusEvent<HTMLTextAreaElement>) => {
          setValue(e.target.value)
        }}
        {...otherFormControlProps}
      />
      {feedback && (
        <Form.Control.Feedback type={feedback.type} tooltip={feedback.tooltip}>
          {feedback.display}
        </Form.Control.Feedback>
      )}
      {belowContent && (
        <small className={`${D_BLOCK} ${TEXT_RIGHT}`}>
          <em>{belowContent}</em>
        </small>
      )}
    </Form.Group>
  )
}
