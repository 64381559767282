// import { createSelector } from "reselect"
import { initialState, StateType } from "@redux/ducks"
import { selectPayment, getIsPaymentValid } from "@redux/ducks/misc/payment"
import { selectPhysicsMCUserResponses } from "@redux/ducks/misc/physics-mc-user-responses"

import { getUsername } from "@helpers/auth"
import {
  USER_CATEGORY_L1,
  USER_CATEGORY_L2,
  USER_CATEGORY_L3,
  UserCategoryType,
} from "@helpers/constants/user-category"

// https://redux.js.org/usage/deriving-data-selectors
// A "selector function" is any function that accepts the Redux store state (or part of the state) as an argument, and returns data that is based on that state.

export const selectRootState = (rootState: StateType = initialState) =>
  rootState

export const selectProblem = (rootState: StateType = initialState) =>
  rootState.misc.problem

export const selectUser = (rootState: StateType = initialState) =>
  rootState.misc.user

export const selectUsername = (rootState: StateType = initialState) => {
  const user = selectUser(rootState)

  return getUsername(user)
}

export const selectProblemHasAlreadyBeenAnswered = (
  rootState: StateType = initialState
) => {
  const activeProblemId = selectProblem(rootState)?.id

  const { data: physicsMCUserResponsesData } =
    selectPhysicsMCUserResponses(rootState)

  if (
    activeProblemId &&
    physicsMCUserResponsesData?.responses?.[activeProblemId]
  ) {
    return true
  }
  return false
}

export const selectUserCategory = (
  rootState: StateType = initialState
): UserCategoryType => {
  const isSignedIn = selectUser(rootState) !== null

  if (!isSignedIn) {
    // Unsigned user
    return USER_CATEGORY_L1
  }

  // Paid user
  const payment = selectPayment(rootState)
  const isPaymentValid = getIsPaymentValid(payment.data)
  if (isPaymentValid) {
    return USER_CATEGORY_L3
  }

  // Singned in free user
  return USER_CATEGORY_L2
}

export const selectNotes = (rootState: StateType = initialState) =>
  rootState.misc.problem?.notes

export const selectVideo = (rootState: StateType = initialState) =>
  rootState.misc.problem?.video

export const selectModalId = (rootState: StateType = initialState) =>
  rootState.ui.modal.modalId

export const selectOptionsWereSelected = (
  rootState: StateType = initialState
) => rootState.ui.problemMultipleChoice.optionsWereSelected

export const selectOptionsAreLocked = (rootState: StateType = initialState) =>
  rootState.ui.optionsAreLocked

export const selectUserStatus = (rootState: StateType = initialState) =>
  rootState.userSynced.userStatus

export const selectShowSolution = (rootState: StateType = initialState) =>
  rootState.ui.solutionShow

export const selectPhysicsQuestionsAnsweredCount = (
  rootState: StateType = initialState
) => rootState.misc.physicsQuestionsAnsweredCount
