import { Cache, Analytics } from "aws-amplify"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { FullHeightColumnCenteredWrapper } from "@components/Containers/positioning-wrappers"
import { createMonster } from "@components/Monster"
import { NoArgVoid } from "@components/types"

import { ENGLISH_DEMO_ACTIVE_PROBLEM_ID } from "@views/english-demo-1/common/constants"
import { getUserResponses } from "@views/english-demo-1/utils/get-user-responses"
import { EnglishProblem } from "@views/english-demo-1/utils/types"
import { DifficultyType } from "@views/multiple-choice-problem/types"

import { CACHE_KEY_ACTIVE_MONSTER_DETAILS } from "@helpers/constants/monster-class"
import { MonsterClassType } from "@helpers/constants/monster-class"
import { H_100 } from "@helpers/constants/style"

import { StimulusBadge } from "./stimulus-badge"
import { marksToDifficultyForEnglish } from "./utils"
import { containerItems } from "./utils"

interface Props {
  problem: EnglishProblem
  nextScene: NoArgVoid
  dungeonMonster: MonsterClassType
}

export const PracticeRow = ({ problem, nextScene, dungeonMonster }: Props) => {
  const marks = problem.triangles.length + 1

  let difficulty: DifficultyType
  if (problem.id === "q8") {
    difficulty = 5
  } else {
    difficulty = marksToDifficultyForEnglish(marks)
  }

  const { src, monster, monsterTitle } = createMonster({
    className: H_100,
    difficulty,
    dungeonMonster,
  })
  const userResponses = getUserResponses()
  const userMark = userResponses[problem.id]?.mark

  return (
    <Row className="text-center">
      <Col xs={12} md={containerItems[0].md}>
        <FullHeightColumnCenteredWrapper>
          <>
            <small>
              <div>
                {problem.stimulus.title ? problem.stimulus.title : "Untitled"}
              </div>
              <em>by {problem.stimulus.author}</em>
            </small>
            <div className="mb-4 mb-md-0">
              {typeof problem.stimulus.type === "string" && (
                <StimulusBadge>{problem.stimulus.type}</StimulusBadge>
              )}
              {Array.isArray(problem.stimulus.type) &&
                problem.stimulus.type.map((singleType) => {
                  return (
                    <span key={`${problem.id}-${singleType}`} className="mx-1">
                      <StimulusBadge>{singleType}</StimulusBadge>
                    </span>
                  )
                })}
            </div>
          </>
        </FullHeightColumnCenteredWrapper>
      </Col>
      <Col xs={12} md={containerItems[1].md}>
        {monster}
      </Col>
      <Col xs={12} md={containerItems[2].md} className="text-left">
        <FullHeightColumnCenteredWrapper>
          <div className="my-4">{problem.question}</div>
        </FullHeightColumnCenteredWrapper>
      </Col>
      <Col xs={12} md={containerItems[3].md}>
        <FullHeightColumnCenteredWrapper>
          <div>{userMark ? `${userMark}/${marks}` : marks}</div>
        </FullHeightColumnCenteredWrapper>
      </Col>
      <Col xs={12} md={containerItems[4].md}>
        <FullHeightColumnCenteredWrapper>
          <Button
            onClick={() => {
              Cache.setItem(ENGLISH_DEMO_ACTIVE_PROBLEM_ID, problem.id)
              Cache.setItem(CACHE_KEY_ACTIVE_MONSTER_DETAILS, {
                src,
                title: monsterTitle,
              })
              nextScene()
              Analytics.record({
                name: "practice-list-button-begin",
                attributes: { id: problem.id },
              })
            }}
          >
            Begin
          </Button>
        </FullHeightColumnCenteredWrapper>
      </Col>
      <Col xs={12}>
        <hr />
      </Col>
    </Row>
  )
}
