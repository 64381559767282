import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import { PHYSICS_LIST_COLUMNS } from "@views/physics/common/constants"

import { D_NONE, D_MD_BLOCK, TEXT_CENTER, MY_2 } from "@helpers/constants/style"

export const PracticeListTitles = () => {
  return (
    <Container>
      <Row>
        {PHYSICS_LIST_COLUMNS.map((containerItem) => {
          return (
            <Col key={containerItem.title} xs={12} md={containerItem.md}>
              <strong className={`${D_NONE} ${D_MD_BLOCK} ${TEXT_CENTER}`}>
                {containerItem.title}
              </strong>
            </Col>
          )
        })}
        <Col xs={12}>
          <hr className={MY_2} />
        </Col>
      </Row>
    </Container>
  )
}
