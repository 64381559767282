import React from "react"

import { LeftAlignedVerticallyCenteredWrapper } from "@components/Containers/positioning-wrappers"
import Potion from "@components/Potion"
import { Star } from "@components/Stars"

interface Props {
  stars: number
  potions: number
}

export const CurrencyCounters = ({ stars, potions }: Props) => {
  return (
    <>
      <LeftAlignedVerticallyCenteredWrapper>
        <>
          <Star />
          <strong>&nbsp;x {stars}</strong>
        </>
      </LeftAlignedVerticallyCenteredWrapper>
      <LeftAlignedVerticallyCenteredWrapper>
        <>
          <Potion size="small" />
          <strong>&nbsp;&nbsp;x {potions}</strong>
        </>
      </LeftAlignedVerticallyCenteredWrapper>
    </>
  )
}
